<template>
  <div class="tag-name-copy">
    <div style="display: flex;justify-content: center;align-items: center;">
        {{ !showPhone ? maskedPhoneNumber : row?.[phoneKey] }}
      <template v-if="showAuthIcon" >
        <ShowAuth :row="row" class="icon"/>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import ShowAuth from '../table-cell-render/company-name/components/ShowAuth.vue'

const props = defineProps({
  // 手机号
  phoneKey: {
    type: String,
    default: 'mobileRec',
  },
  // 当前行
  row: {
    type: Object,
    default: () => null,
  },
  // 是否隐藏手机号
  showPhone: {
    type: Boolean,
    default: true,
  },
  // 是否展示权限图标
  showAuthIcon: {
    type: Boolean,
    default: false,
  },
})
const maskedPhoneNumber = computed(() => {
  let phone = props.row[props.phoneKey]
  return phone ? phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") : ""
})

</script>

<style scoped lang="scss">
.icon{
  margin-left: 3px;
}
.copy-name {
    color:var(--themeColor,#17a2b8);
    cursor: pointer;
    & + div,
    & + i {
      margin-left: 8px;
    }
  }

</style>
