<template>
  <div class="tag-name-copy">
    <div class="ellipsis name-text mr-10">
      <template v-if="showTag">
        <span
          v-if="row.type == '1'"
          class="xgm tag-item"
        >小</span>
        <span
          v-if="row.type == '2'"
          class="yb tag-item"
        >般</span>
      </template>
      <!-- <el-tooltip effect="dark"  placement="top"> -->
        <!-- <template #content>
          <div>{{ row[nameKey] }}</div>
        </template> -->
        <span
          style="cursor: pointer;"
          :style="{textDecoration:(showUnderLine && status == 2?'underline':'none')}"
          @click="nameJump"
        >{{ row[nameKey] }}</span>
      <!-- </el-tooltip> -->
    </div>
    <template v-if="showCopy">
      <el-tooltip
        content="复制"
        placement="top"
        effect="dark"
      >
        <i
          class="iconfont icon-fuzhi1 copy-name"
          @click="$copyComName(row[nameKey])"
        />
      </el-tooltip>
    </template>
    <slot name="middleIcon" />
    <template v-if="showAuthIcon">
      <ShowAuth :row="row" :idKey="idKey"/>
    </template>
    <slot />
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps } from 'vue'

import ShowAuth from './components/ShowAuth.vue'

const { proxy: currentVm, } = getCurrentInstance()
const props = defineProps({
  // 当前行
  row: {
    type: Object,
    default: () => null,
  },
  // 是否展示复制图标
  showCopy: {
    type: Boolean,
    default: true,
  },
  // 是否展示权限图标
  showAuthIcon: {
    type: Boolean,
    default: true,
  },
  // 是否展示名称前的标签
  showTag: {
    type: Boolean,
    default: true,
  },
  // 是否需要点击名称进行跳转
  needNameJump: {
    type: Boolean,
    default: true,
  },
  // 展示名称使用的 key，默认 name
  nameKey: {
    type: String,
    default: 'name',
  },
  // 跳转需要的参数，默认是 id
  idKey: {
    type: String,
    default: 'id',
  },
  //新增客户/记账客户
  status: {
    type: Number,
    default: 2,
  },
  //部分页面新增记账都显示，记账客户显示下划线
  showUnderLine:{
    type: Boolean,
    default: false
  }
})

function nameJump() {
  if (props.needNameJump && props.status == 2) {
    currentVm.$goEtax(props.row[props.idKey])
  }
}
</script>

<style scoped lang="scss">
.tag-name-copy {
  display: flex;
  align-items: center;
  .name-text {
    flex: 1;
    width: 0;

    .tag-item {
      padding: 0 1px;
      margin-right: 5px;
    }
    .xgm {
      border: 1px solid var(--themeColor, #17a2b8);
      background: var(--themeColorPlain);
      color: var(--themeColor, #17a2b8);
    }
    .yb {
      border: 1px solid #ff7a00;
      background: $color-danger-plain;
      color: #ff7a00;
    }
  }
  .copy-name {
    color:var(--themeColor,#17a2b8);
    cursor: pointer;
    & + div,
    & + i {
      margin-left: 8px !important;
    }
  }
}
</style>
