<template>
  <el-input
    :size="size"
    v-model="innerValue"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    :type="inputType"
    @change="changeValue"
  ></el-input>
</template>

<script>
export default {
  name: "selectCommon",
  props: {
    size: {
      type: String,
      default: "small",
    },
    placeholder: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        val = val.replace(/\s+/g, "");
        this.$emit("update:modelValue", val);
      },
    },
  },
  methods: {
    changeValue() {
      this.$emit("inputChange");
    },
  },
};
</script>
