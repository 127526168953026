import request from '@/utils/request'


// 集团
export function saveGroup(data) {
  return request({
    url: 'api/v2/org/saveGroup',
    method: 'post',
    data
  })
}
//集团
export function groupList(data) {
  return request({
    url: 'api/v2/org/groupList',
    method: 'post',
    data
  })
}
// 企业列表
export function orgList(data) {
  return request({
    url: 'api/v2/org/orgList',
    method: 'post',
    data
  })
}

// 新增修改企业
export function saveOrg(data) {
  return request({
    url: 'api/v2/org/saveOrg',
    method: 'post',
    data
  })
}

// 删除企业
export function delOrg(data) {
  return request({
    url: 'api/v2/org/delOrg',
    method: 'post',
    data
  })
}

// 企业管理员
export function findOrgAdmin(data) {
  return request({
    url: 'api/v2/org/findOrgAdmin',
    method: 'post',
    data
  })
}

// 企业管理员新增修改
export function saveOrgAdmin(data) {
  return request({
    url: 'api/v2/org/saveOrgAdmin',
    method: 'post',
    data
  })
}

export function updateList(data) {
  return request({
      url: '/api/v2/org/updateList',
      method: 'post',
      data
  })
}
export function updateListNew(data) {
  return request({
      url: '/api/v2/org/updateListNew',
      method: 'post',
      data
  })
}
// 更新提示详情
export function updateListOne(data) {
  return request({
      url: '/api/v2/org/updateListOne',
      method: 'post',
      data
  })
}

export function updateSave(data) {
  return request({
      url: '/api/v2/org/updateSave',
      method: 'post',
      data
  })
}
export function updateDel(data) {
  return request({
      url: '/api/v2/org/updateDel',
      method: 'post',
      data
  })
}
export function updateUserSave(data) {
  return request({
      url: '/api/v2/org/updateUserSave',
      method: 'post',
      data
  })
}

export function updateRead(data) {
  return request({
      url: '/api/v2/org/updateRead',
      method: 'post',
      data
  })
}
//全部已读
export function updateUserSaveAll(data) {
  return request({
      url: '/api/v2/org/updateUserSaveAll',
      method: 'post',
      data
  })
}
//锁
export function getLockList(data) {
  return request({
      url: '/api/v2/org/getLockList',
      method: 'post',
      data
  })
}

//锁删除
export function delLock(data) {
  return request({
      url: '/api/v2/org/delLock',
      method: 'post',
      data
  })
}

//
export function systemUpdate(data) {
  return request({
      url: '/api/v2/org/systemUpdate',
      method: 'post',
      data
  })
}

//
export function systemUpdateInfo(data) {
  return request({
      url: '/api/v2/org/systemUpdateInfo',
      method: 'post',
      data
  })
}

// 申报设置info
export function orgSettingInfo(data) {
  return request({
      url: '/api/v2/org/orgSettingInfo',
      method: 'post',
      data
  })
}

// 申报设置保存
export function orgSettingSave(data) {
  return request({
      url: '/api/v2/org/orgSettingSave',
      method: 'post',
      data
  })
}
// 平台集团账号管理
export function getGroupAdmin(data) {
  return request({
      url: '/api/v2/org/getGroupAdmin',
      method: 'post',
      data
  })
}
// 平台集团账号管理保存
export function saveGroupAdmin(data) {
  return request({
      url: '/api/v2/org/saveGroupAdmin',
      method: 'post',
      data
  })
}
//集团账号管理删除
export function delGroup(data) {
  return request({
      url: '/api/v2/org/delGroup',
      method: 'post',
      data
  })
}

// 保存绑定微信客服
export function bindingContact(data) {
  return request({
    url: 'api/v2/org/bindingContact',
    method: 'post',
    data
  })
}

// 绑定显示列表
export function getBindingOrUnBindingOrgList(data) {
  return request({
    url: 'api/v2/org/getBindingOrUnBindingOrgList',
    method: 'post',
    data
  })
}

// 客服账号列表
export function kfList(data) {
  return request({
    url: 'api/v2/org/kfList',
    method: 'post',
    data
  })
}

//公司迁移
export function comMigrate(data) {
  return request({
    url: "api/v2/org/comMigrate",
    method: "post",
    data,
  });
}
//不同db迁移
export function dbComMigrate(data) {
  return request({
    url: "api/v2/org/dbComMigrate",
    method: "post",
    data,
  });
}
//集团的代账公司列表
export function groupOrgList(data) {
  return request({
    url: "api/v2/org/groupOrgList",
    method: "post",
    data,
  });
}

//集团的新增代账公司
export function addGroupOrg(data) {
  return request({
    url: "api/v2/org/addGroupOrg",
    method: "post",
    data,
  });
}

//org批量设置存货
export function inventoryStatusSetting(data) {
  return request({
    url: "api/v2/org/inventoryStatusSetting",
    method: "post",
    data,
  });
}


//订单管理
export function orderOrgInfo(data) {
  return request({
    url: "api/v2/org/orderOrgInfo",
    method: "post",
    data,
  });
}

//订单记录
export function autoExpenseList(data) {
  return request({
    url: "api/v2/org/autoExpenseList",
    method: "post",
    data,
  });
}

//订单记录详情
export function autoExpenseDetailList(data) {
  return request({
    url: "api/v2/org/autoExpenseDetailList",
    method: "post",
    data,
  });
}

//org统计
export function orgStatList(data) {
  return request({
    url: "api/v2/org/orgStatList",
    method: "post",
    data,
  });
}

//公司统计
export function zxOrgStat(data) {
  return request({
    url: "api/v2/org/zxOrgStat",
    method: "post",
    data,
  });
}

//设置充值金额
export function saveOrgCoins(data) {
  return request({
    url: "api/v2/org/saveOrgCoins",
    method: "post",
    data,
  });
}

//集团统计列表
export function groupOrgStatList(data) {
  return request({
    url: "api/v2/org/groupOrgStatList",
    method: "post",
    data,
  });
}

//mq队列
export function getLxMqList(data) {
  return request({
    url: "api/v2/org/getLxMqList",
    method: "post",
    data,
  });
}

//mq队列删除ready
export function delLxMqList(data) {
  return request({
    url: "api/v2/org/delLxMqList",
    method: "post",
    data,
  });
}

//集团账务管理列表
export function groupReportData(data) {
  return request({
    url: "api/v2/org/groupReportData",
    method: "post",
    data,
  });
}

//账务管理导出
export function exportReportData(data) {
  return request({
    url: "api/v2/org/exportReportData",
    method: "post",
    data,
  });
}

//公司管理列表
export function getSysComsDataList(data) {
  return request({
    url: "api/v2/org/getSysComsDataList",
    method: "post",
    data,
  });
}

//导出公司管理列表
export function exportComsData(data) {
  return request({
    url: "api/v2/org/exportComsData",
    method: "post",
    data,
  });
}