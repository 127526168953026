//企业类型option
export const COMPANY_TYPE_OPTION = [
  { label: "有限责任公司", value: "有限责任公司" },
  { label: "个体工商户", value: "个体工商户" },
  { label: "个人独资企业", value: "个人独资企业" },
  { label: "合伙企业", value: "合伙企业" },
  { label: "农村合作社", value: "农村合作社" },
  { label: "民办非企业单位", value: "民办非企业单位" },
];

//会计准则option
export const ACCOUNT_SYSTEM_OPTION = [
  { label: "小企业会计准则2013版", value: "小企业会计准则2013版" },
  { label: "一般企业会计准则(未执行-常规科目)", value: "一般企业会计准则" },
  {
    label: "一般企业会计准则(已执行-常规科目)",
    value: "一般企业会计准则(已执行新金融准则)",
  },
  {
    label: "一般企业会计准则(未执行-全科目)",
    value: "一般企业会计准则(全科目)",
  },
  {
    label: "一般企业会计准则(已执行-全科目)",
    value: "一般企业会计准则(已执行新金融准则)(全科目)",
  },
  { label: "企业会计制度", value: "企业会计制度" },
  { label: "民间非营利", value: "民办非" },
  { label: "农村合作社", value: "农业合作社" },
];

//采集银行option
export const COLLECT_BANK_OPTION = [
  {
    value: "招商银行",
    label: "招商银行",
    icon: "iconfont icon-zhaoshangyinhang",
  },
  {
    value: "农业银行",
    label: "农业银行",
    icon: "iconfont icon-nongyeyinhang",
  },
  {
    value: "中国银行",
    label: "中国银行",
    icon: "iconfont icon-yinhang-zhongguoyinhang",
  },
  {
    value: "交通银行",
    label: "交通银行",
    icon: "iconfont icon-jiaotongyinhang",
  },
  {
    value: "建设银行",
    label: "建设银行",
    icon: "iconfont icon-jiansheyinhang",
  },
  {
    value: "光大银行",
    label: "光大银行",
    icon: "iconfont icon-guangdayinhang",
  },
  {
    value: "中国邮政储蓄银行",
    label: "中国邮政储蓄银行",
    icon: "iconfont icon-youzhengyinhang",
  },
  {
    value: "宁波银行",
    label: "宁波银行",
    icon: "iconfont icon-ningboyinhang",
  },
  {
    value: "安徽农金",
    label: "安徽农金",
    icon: "iconfont icon-anhuinongjin-icon",
  },
  {
    value: "江苏银行",
    label: "江苏银行",
    icon: "iconfont icon-jiangsuyinhang",
  },
  {
    value: "常熟农商银行",
    label: "常熟农商银行",
    icon: "iconfont icon-changshunongshangyinhang",
  },
  {
    value: "江苏农村商业银行",
    label: "江苏农村商业银行",
    icon: "iconfont icon-jiangsunongcunshangyeyinhang",
  },
  {
    value: "莱商银行",
    label: "莱商银行",
    icon: "iconfont icon-laishangyinhang",
  },
  {
    value: "齐鲁银行",
    label: "齐鲁银行",
    icon: "iconfont icon-qiluyinhang",
  },
  {
    value: "深圳农商银行",
    label: "深圳农商银行",
    icon: "iconfont icon-shenzhennongshangyinhang",
  },
  {
    value: "中国民生银行",
    label: "中国民生银行",
    icon: "iconfont icon-zhongguominshengyinhang",
  },
  {
    value: "成都农商银行",
    label: "成都农商银行",
    icon: "iconfont icon-yinhanglogo-",
  },
  {
    value: "济宁银行",
    label: "济宁银行",
    icon: "iconfont icon-jiningyinhang",
  },
  {
    value: "河北农商银行",
    label: "河北农商银行",
    icon: "iconfont icon-ziyuannongshang",
  },
  {
    value: "承德银行",
    label: "承德银行",
    icon: "iconfont icon-chengdeyinhang",
  },
  {
    value: "华夏银行",
    label: "华夏银行",
    icon: "iconfont icon-huaxiayinhang",
  },
  {
    value: "临商银行",
    label: "临商银行",
    icon: "iconfont icon-linshangyinhang",
  },
  {
    value: "湖北银行",
    label: "湖北银行",
    icon: "iconfont icon-hubeiyinhanglogo",
  },
  {
    value: "廊坊银行",
    label: "廊坊银行",
    icon: "iconfont icon-langfangyinhang",
  },
  {
    value: "鄂尔多斯银行",
    label: "鄂尔多斯银行",
    icon: "iconfont icon-yinhanglogo-1",
  },
  {
    value: "衡水银行",
    label: "衡水银行",
    icon: "iconfont icon-hengshuiyinhang",
  },
  {
    value: "河北银行",
    label: "河北银行",
    icon: "iconfont icon-hebeiyinhang",
  },
  {
    value: "中信银行",
    label: "中信银行",
    icon: "iconfont icon-zhongxinyinhang",
  },
];

//凭证来源option
export const VOUCHER_SOURCE_OPTION = [
  {
    value: "",
    label: "全部",
  },
  {
    value: "手工录入",
    label: "手工录入",
  },
  {
    value: "1",
    label: "进项",
  },
  {
    value: "2",
    label: "销项",
  },
  {
    value: "3",
    label: "费用",
  },
  {
    value: "4",
    label: "现金",
  },
  {
    value: "5",
    label: "票据",
  },
  {
    value: "6",
    label: "银行",
  },
  {
    value: "7",
    label: "工资",
  },
  {
    value: "8",
    label: "结转损益",
  },
  {
    value: "9",
    label: "计提税金",
  },
  {
    value: "10",
    label: "资产",
  },
  {
    value: "12",
    label: "存货结转",
  },
  {
    value: "13",
    label: "暂估回冲",
  },
  {
    value: "14",
    label: "暂估入库",
  },
  {
    value: "20",
    label: "进项认证",
  },
  {
    value: "30",
    label: "固定资产清理",
  },
  {
    value: "40",
    label: "汇兑损益",
  },
  {
    value: "100",
    label: "年末结转利润",
  },
  {
    value: "16",
    label: "成本调差",
  },
];

//存货类型option
export const INVENTORY_TYPE_OPTION = [
  {
    value: 1,
    label: "原材料",
  },
  {
    value: 2,
    label: "库存商品",
  },
  {
    value: 3,
    label: "半成品",
  },
  {
    value: 4,
    label: "委托加工物资",
  },
  {
    value: 5,
    label: "周转材料",
  },
  {
    value: 6,
    label: "包装物",
  },
  {
    value: 7,
    label: "消耗性生物资产",
  },
  {
    value: 20,
    label: "低值易耗品",
  },
  {
    value: 21,
    label: "材料采购",
  },
  {
    value: 22,
    label: "生产性生物资产",
  },
  {
    value: 8,
    label: "工程物资",
  },
  {
    value: 9,
    label: "开发产品",
  },
  {
    value: 10,
    label: "发出产品",
  },
  {
    value: 11,
    label: "其他",
  },
];

//费用类型
export const COST_TYPE_OPTION = [
  {
    value: "管理费用",
    label: "管理费用",
  },
  {
    value: "生产费用",
    label: "生产费用",
  },
  {
    value: "工程施工",
    label: "工程施工",
  },
  {
    value: "制造费用",
    label: "制造费用",
  },
  {
    value: "销售费用",
    label: "销售费用",
  },
  {
    value: "劳务成本",
    label: "劳务成本",
  },
  {
    value: "其他",
    label: "其他",
  },
];

//进项发票选项
export const IN_INVOICE_OPTION = [
  {
    value: "1",
    label: "增值税专用发票",
  },
  {
    value: "2",
    label: "增值税普通发票",
  },
  {
    value: "3",
    label: "农副产品发票(免税)(9%)",
  },
  {
    value: "4",
    label: "增值税电子专用发票",
  },
  {
    value: "5",
    label: "增值税电子普通发票",
  },
  {
    value: "6",
    label: "海关进口增值税专用缴款书",
  },
  {
    value: "7",
    label: "其他发票(可抵扣)",
  },
  {
    value: "8",
    label: "农副产品发票(免税)(10%)",
  },
  {
    value: "11",
    label: "其他费用",
  },
];

//个税减免option
export const GS_REDUCTION_OPTION = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "个税减免为0",
  },
  {
    value: 2,
    label: "经营所得减免为0",
  },
  {
    value: 3,
    label: "全部减免为0",
  },
];
//境外人员身份证件类型
export const CERTIFICATE_TYPE_OPTION = [
  {
    value: "中国护照",
    label: "中国护照",
  },
  {
    value: "港澳居民来往内地通行证",
    label: "港澳居民来往内地通行证",
  },
  {
    value: "台湾居民来往大陆通行证",
    label: "台湾居民来往大陆通行证",
  },
  {
    value: "中华人民共和国港澳居民居住证",
    label: "中华人民共和国港澳居民居住证",
  },
  {
    value: "中华人民共和国台湾居民居住证",
    label: "中华人民共和国台湾居民居住证",
  },
  {
    value: "外国护照",
    label: "外国护照",
  },
  {
    value: "外国人永久居留身份证（外国人永久居留证）",
    label: "外国人永久居留身份证（外国人永久居留证）",
  },
  {
    value: "中华人民共和国外国人工作许可证（A类）",
    label: "中华人民共和国外国人工作许可证（A类）",
  },
  {
    value: "中华人民共和国外国人工作许可证（B类）",
    label: "中华人民共和国外国人工作许可证（B类）",
  },
  {
    value: "中华人民共和国外国人工作许可证（C类）",
    label: "中华人民共和国外国人工作许可证（C类）",
  },
];

//不同身份证件类型对应的国籍
export const FOREIGN_PASSPORT_OPTION = [
  { label: "阿富汗", value: "阿富汗" },
  { label: "阿尔巴尼亚", value: "阿尔巴尼亚" },
  { label: "南极洲", value: "南极洲" },
  { label: "阿尔及利亚", value: "阿尔及利亚" },
  { label: "美属萨摩亚", value: "美属萨摩亚" },
  { label: "安道尔", value: "安道尔" },
  { label: "安哥拉", value: "安哥拉" },
  { label: "安提瓜和巴布达", value: "安提瓜和巴布达" },
  { label: "阿塞拜疆", value: "阿塞拜疆" },
  { label: "阿根廷", value: "阿根廷" },
  { label: "澳大利亚", value: "澳大利亚" },
  { label: "奥地利", value: "奥地利" },
  { label: "巴哈马", value: "巴哈马" },
  { label: "巴林", value: "巴林" },
  { label: "孟加拉国", value: "孟加拉国" },
  { label: "亚美尼亚", value: "亚美尼亚" },
  { label: "巴巴多斯", value: "巴巴多斯" },
  { label: "比利时", value: "比利时" },
  { label: "百慕大", value: "百慕大" },
  { label: "不丹", value: "不丹" },
  { label: "玻利维亚", value: "玻利维亚" },
  { label: "波黑", value: "波黑" },
  { label: "博茨瓦纳", value: "博茨瓦纳" },
  { label: "布维岛", value: "布维岛" },
  { label: "巴西", value: "巴西" },
  { label: "伯利兹", value: "伯利兹" },
  { label: "英属印度洋领地", value: "英属印度洋领地" },
  { label: "所罗门群岛", value: "所罗门群岛" },
  { label: "英属维尔京群岛", value: "英属维尔京群岛" },
  { label: "文莱", value: "文莱" },
  { label: "保加利亚", value: "保加利亚" },
  { label: "缅甸", value: "缅甸" },
  { label: "布隆迪", value: "布隆迪" },
  { label: "白俄罗斯", value: "白俄罗斯" },
  { label: "柬埔寨", value: "柬埔寨" },
  { label: "喀麦隆", value: "喀麦隆" },
  { label: "加拿大", value: "加拿大" },
  { label: "佛得角", value: "佛得角" },
  { label: "开曼群岛", value: "开曼群岛" },
  { label: "中非", value: "中非" },
  { label: "斯里兰卡", value: "斯里兰卡" },
  { label: "乍得", value: "乍得" },
  { label: "智利", value: "智利" },
  { label: "圣诞岛", value: "圣诞岛" },
  { label: "科科斯（基林）群岛", value: "科科斯（基林）群岛" },
  { label: "哥伦比亚", value: "哥伦比亚" },
  { label: "科摩罗", value: "科摩罗" },
  { label: "马约特", value: "马约特" },
  { label: "刚果（布）", value: "刚果（布）" },
  { label: "刚果（金）", value: "刚果（金）" },
  { label: "库克群岛", value: "库克群岛" },
  { label: "克罗地亚", value: "克罗地亚" },
  { label: "古巴", value: "古巴" },
  { label: "塞浦路斯", value: "塞浦路斯" },
  { label: "捷克", value: "捷克" },
  { label: "贝宁", value: "贝宁" },
  { label: "丹麦", value: "丹麦" },
  { label: "多米尼克", value: "多米尼克" },
  { label: "多米尼加", value: "多米尼加" },
  { label: "厄瓜多尔", value: "厄瓜多尔" },
  { label: "萨尔瓦多", value: "萨尔瓦多" },
  { label: "赤道几内亚", value: "赤道几内亚" },
  { label: "埃塞俄比亚", value: "埃塞俄比亚" },
  { label: "厄立特里亚", value: "厄立特里亚" },
  { label: "爱沙尼亚", value: "爱沙尼亚" },
  { label: "法罗群岛", value: "法罗群岛" },
  { label: "福克兰群岛（马尔维纳斯）", value: "福克兰群岛（马尔维纳斯）" },
  { label: "南乔治亚岛和南桑德韦奇岛", value: "南乔治亚岛和南桑德韦奇岛" },
  { label: "斐济", value: "斐济" },
  { label: "芬兰", value: "芬兰" },
  { label: "法国", value: "法国" },
  { label: "法属圭亚那", value: "法属圭亚那" },
  { label: "法属波利尼西亚", value: "法属波利尼西亚" },
  { label: "法属南部领地", value: "法属南部领地" },
  { label: "吉布提", value: "吉布提" },
  { label: "加蓬", value: "加蓬" },
  { label: "格鲁吉亚", value: "格鲁吉亚" },
  { label: "冈比亚", value: "冈比亚" },
  { label: "巴勒斯坦", value: "巴勒斯坦" },
  { label: "德国", value: "德国" },
  { label: "加纳", value: "加纳" },
  { label: "直布罗陀", value: "直布罗陀" },
  { label: "基里巴斯", value: "基里巴斯" },
  { label: "希腊", value: "希腊" },
  { label: "格陵兰", value: "格陵兰" },
  { label: "格林纳达", value: "格林纳达" },
  { label: "瓜德罗普", value: "瓜德罗普" },
  { label: "关岛", value: "关岛" },
  { label: "危地马拉", value: "危地马拉" },
  { label: "几内亚", value: "几内亚" },
  { label: "圭亚那", value: "圭亚那" },
  { label: "海地", value: "海地" },
  { label: "赫德岛和麦克唐纳岛", value: "赫德岛和麦克唐纳岛" },
  { label: "梵蒂冈", value: "梵蒂冈" },
  { label: "洪都拉斯", value: "洪都拉斯" },
  { label: "匈牙利", value: "匈牙利" },
  { label: "冰岛", value: "冰岛" },
  { label: "印度", value: "印度" },
  { label: "印度尼西亚", value: "印度尼西亚" },
  { label: "伊朗", value: "伊朗" },
  { label: "伊拉克", value: "伊拉克" },
  { label: "爱尔兰", value: "爱尔兰" },
  { label: "以色列", value: "以色列" },
  { label: "意大利", value: "意大利" },
  { label: "科特迪瓦", value: "科特迪瓦" },
  { label: "牙买加", value: "牙买加" },
  { label: "日本", value: "日本" },
  { label: "哈萨克斯坦", value: "哈萨克斯坦" },
  { label: "约旦", value: "约旦" },
  { label: "肯尼亚", value: "肯尼亚" },
  { label: "朝鲜", value: "朝鲜" },
  { label: "韩国", value: "韩国" },
  { label: "科威特", value: "科威特" },
  { label: "吉尔吉斯斯坦", value: "吉尔吉斯斯坦" },
  { label: "老挝", value: "老挝" },
  { label: "黎巴嫩", value: "黎巴嫩" },
  { label: "莱索托", value: "莱索托" },
  { label: "拉脱维亚", value: "拉脱维亚" },
  { label: "利比里亚", value: "利比里亚" },
  { label: "利比亚", value: "利比亚" },
  { label: "列支敦士登", value: "列支敦士登" },
  { label: "立陶宛", value: "立陶宛" },
  { label: "卢森堡", value: "卢森堡" },
  { label: "马达加斯加", value: "马达加斯加" },
  { label: "马拉维", value: "马拉维" },
  { label: "马来西亚", value: "马来西亚" },
  { label: "马尔代夫", value: "马尔代夫" },
  { label: "马里", value: "马里" },
  { label: "马耳他", value: "马耳他" },
  { label: "马提尼克", value: "马提尼克" },
  { label: "毛里塔尼亚", value: "毛里塔尼亚" },
  { label: "毛里求斯", value: "毛里求斯" },
  { label: "墨西哥", value: "墨西哥" },
  { label: "摩纳哥", value: "摩纳哥" },
  { label: "蒙古", value: "蒙古" },
  { label: "摩尔多瓦", value: "摩尔多瓦" },
  { label: "黑山", value: "黑山" },
  { label: "蒙特塞拉特", value: "蒙特塞拉特" },
  { label: "摩洛哥", value: "摩洛哥" },
  { label: "莫桑比克", value: "莫桑比克" },
  { label: "阿曼", value: "阿曼" },
  { label: "纳米比亚", value: "纳米比亚" },
  { label: "瑙鲁", value: "瑙鲁" },
  { label: "尼泊尔", value: "尼泊尔" },
  { label: "荷兰", value: "荷兰" },
  { label: "荷属安的列斯", value: "荷属安的列斯" },
  { label: "阿鲁巴", value: "阿鲁巴" },
  { label: "新喀里多尼亚", value: "新喀里多尼亚" },
  { label: "瓦努阿图", value: "瓦努阿图" },
  { label: "新西兰", value: "新西兰" },
  { label: "尼加拉瓜", value: "尼加拉瓜" },
  { label: "尼日尔", value: "尼日尔" },
  { label: "尼日利亚", value: "尼日利亚" },
  { label: "纽埃", value: "纽埃" },
  { label: "诺福克岛", value: "诺福克岛" },
  { label: "挪威", value: "挪威" },
  { label: "北马里亚纳", value: "北马里亚纳" },
  { label: "美国本土外小岛屿", value: "美国本土外小岛屿" },
  { label: "密克罗尼西亚联邦", value: "密克罗尼西亚联邦" },
  { label: "马绍尔群岛", value: "马绍尔群岛" },
  { label: "帕劳", value: "帕劳" },
  { label: "巴基斯坦", value: "巴基斯坦" },
  { label: "巴拿马", value: "巴拿马" },
  { label: "巴布亚新几内亚", value: "巴布亚新几内亚" },
  { label: "巴拉圭", value: "巴拉圭" },
  { label: "秘鲁", value: "秘鲁" },
  { label: "菲律宾", value: "菲律宾" },
  { label: "皮特凯恩", value: "皮特凯恩" },
  { label: "波兰", value: "波兰" },
  { label: "葡萄牙", value: "葡萄牙" },
  { label: "几内亚比绍", value: "几内亚比绍" },
  { label: "东帝汶", value: "东帝汶" },
  { label: "波多黎各", value: "波多黎各" },
  { label: "卡塔尔", value: "卡塔尔" },
  { label: "留尼汪", value: "留尼汪" },
  { label: "罗马尼亚", value: "罗马尼亚" },
  { label: "俄罗斯联邦", value: "俄罗斯联邦" },
  { label: "卢旺达", value: "卢旺达" },
  { label: "圣赫勒拿", value: "圣赫勒拿" },
  { label: "圣基茨和尼维斯", value: "圣基茨和尼维斯" },
  { label: "安圭拉", value: "安圭拉" },
  { label: "圣卢西亚", value: "圣卢西亚" },
  { label: "圣皮埃尔和密克隆", value: "圣皮埃尔和密克隆" },
  { label: "圣文森特和格林纳丁斯", value: "圣文森特和格林纳丁斯" },
  { label: "圣马力诺", value: "圣马力诺" },
  { label: "圣多美和普林西比", value: "圣多美和普林西比" },
  { label: "沙特阿拉伯", value: "沙特阿拉伯" },
  { label: "塞内加尔", value: "塞内加尔" },
  { label: "塞尔维亚", value: "塞尔维亚" },
  { label: "塞舌尔", value: "塞舌尔" },
  { label: "塞拉利昂", value: "塞拉利昂" },
  { label: "新加坡", value: "新加坡" },
  { label: "斯洛伐克", value: "斯洛伐克" },
  { label: "越南", value: "越南" },
  { label: "斯洛文尼亚", value: "斯洛文尼亚" },
  { label: "索马里", value: "索马里" },
  { label: "南非", value: "南非" },
  { label: "津巴布韦", value: "津巴布韦" },
  { label: "西班牙", value: "西班牙" },
  { label: "南苏丹", value: "南苏丹" },
  { label: "西撒哈拉", value: "西撒哈拉" },
  { label: "苏丹", value: "苏丹" },
  { label: "苏里南", value: "苏里南" },
  { label: "斯瓦尔巴岛和扬马延岛", value: "斯瓦尔巴岛和扬马延岛" },
  { label: "斯威士兰", value: "斯威士兰" },
  { label: "瑞典", value: "瑞典" },
  { label: "瑞士", value: "瑞士" },
  { label: "叙利亚", value: "叙利亚" },
  { label: "塔吉克斯坦", value: "塔吉克斯坦" },
  { label: "泰国", value: "泰国" },
  { label: "多哥", value: "多哥" },
  { label: "托克劳", value: "托克劳" },
  { label: "汤加", value: "汤加" },
  { label: "特立尼达和多巴哥", value: "特立尼达和多巴哥" },
  { label: "阿联酋", value: "阿联酋" },
  { label: "突尼斯", value: "突尼斯" },
  { label: "土耳其", value: "土耳其" },
  { label: "土库曼斯坦", value: "土库曼斯坦" },
  { label: "特克斯和凯科斯群岛", value: "特克斯和凯科斯群岛" },
  { label: "图瓦卢", value: "图瓦卢" },
  { label: "乌干达", value: "乌干达" },
  { label: "乌克兰", value: "乌克兰" },
  { label: "前南马其顿", value: "前南马其顿" },
  { label: "埃及", value: "埃及" },
  { label: "英国", value: "英国" },
  { label: "根西岛", value: "根西岛" },
  { label: "坦桑尼亚", value: "坦桑尼亚" },
  { label: "美国", value: "美国" },
  { label: "美属维尔京群岛", value: "美属维尔京群岛" },
  { label: "布基纳法索", value: "布基纳法索" },
  { label: "乌拉圭", value: "乌拉圭" },
  { label: "乌兹别克斯坦", value: "乌兹别克斯坦" },
  { label: "委内瑞拉", value: "委内瑞拉" },
  { label: "瓦利斯和富图纳", value: "瓦利斯和富图纳" },
  { label: "美属萨也门", value: "美属萨也门" },
  { label: "南斯拉夫", value: "南斯拉夫" },
  { label: "赞比亚", value: "赞比亚" },
];

// 线索状态
export const CULE_STATUS = [
  { label: "全部", value: 0 },
  { label: "待分配1", value: 1 },
  { label: "待跟进", value: 2 },
  { label: "跟进中", value: 3 },
  { label: "已成交", value: 4 },
];

// 跟进阶段
export const FOLLOW_STATUS = [
  { label: "全部", value: "0" },
  { label: "待联系", value: "2" },
  { label: "已联系", value: "3" },
  { label: "待签单", value: "4" },
  { label: "已暂停", value: "5" },
];

// 客户来源
export const CUSTOMER_STATUS = [
  { label: "全部", value: 0 },
  { label: "客户推荐", value: 1 },
  { label: "员工推荐", value: 3 },
  { label: "朋友推荐", value: 2 },
  { label: "网络推广", value: 4 },
  { label: "品牌效应", value: 5 },
  { label: "电话销售", value: 6 },
  { label: "中介介绍", value: 7 },
  { label: "活动", value: 8 },
  { label: "App申请", value: 9 },
  { label: "其他", value: 10 },
];

// 线索等级
export const CULE_GRADE = [
  { label: "一星", value: "一星" },
  { label: "二星", value: "二星" },
  { label: "三星", value: "三星" },
  { label: "四星", value: "四星" },
  { label: "五星", value: "五星" },
];

// 线索等级
export const FOLLOW_VISIT = [
  { label: "电话", value: 1 },
  { label: "QQ", value: 2 },
  { label: "微信", value: 3 },
  { label: "面谈", value: 4 },
];

// 纳税人资格
export const TRAXPAYER = [
  { label: "小规模纳税人", value: 1 },
  { label: "一般纳税人", value: 2 },
];
// 资料类型
export const INFORMATION_TYPE = [
  { label: "原件", value: 1 },
  { label: "复印件", value: 2 },
  { label: "电子资料", value: 3 },
  { label: "打印资料", value: 4 },
];

// 纳税人资格
export const CLUELOG = [
  { label: "创建", value: 1 },
  { label: "分配", value: 2 },
  { label: "领取", value: 3 },
  { label: "回收", value: 4 },
  { label: "跟进", value: 5 },
  { label: "成交", value: 6 },
  { label: "释放", value: 7 },
  { label: "保护", value: 8 },
  { label: "修改线索", value: 9 },
  { label: "取消保护", value: 10 },
  { label: "彻底删除", value: 11 },
];
// 纳税人资格
export const HANDL_STATUE = [
  { label: "全部", value: 0 },
  { label: "未开始", value: 1 },
  { label: "办理中", value: 2 },
  { label: "已终止", value: 3 },
  { label: "已完成", value: 4 },
  { label: "未开始+办理中", value: 5 },
];

//下载工具option
export const DOWNLOAD_OPTIONS = [
  {
    label: "谷歌浏览器",
    value:
      "https://file.jsptax.com/mnt/down/%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8.exe",
    img: require("@/assets/chrome.png"),
  },
  {
    label: "税局小助手",
    value:
      "https://file.jsptax.com/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B.exe",
    img: require("@/assets/app.png"),
  },
  {
    label: "税局小助手新版",
    value:
      "https://file.jsptax.com/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B%E6%96%B0%E7%89%88.exe",

    img: require("@/assets/sjxzsxb.png"),
  },
  {
    label: "税局短信助手(安卓)",
    value: "税局短信助手",

    img: require("@/assets/shuijuduanxin.png"),
  },
  {
    label: "个税同步助手",
    value: "https://file.jsptax.com/mnt/down/个税同步助手安装程序.exe",

    img: require("@/assets/icon.png"),
  },
  {
    label: "科密设备驱动",
    value: "https://file.jsptax.com/mnt/down/KMGpyServer_3.9.2.exe",
    img: require("@/assets/KMGpyServer.jpg"),
  },
];

//合同状态
export const CONTRACT_STATUS = [
  { label: "待提交", value: 1 },
  { label: "审核中", value: 5 },
  { label: "审核通过", value: 2 },
  { label: "结束", value: 3 },
  { label: "终止", value: 4 },
  { label: "驳回", value: 6 },
];

//合同收款状态
export const CHARGE_STATUS = [
  { label: "还未收款", value: "还未收款" },
  { label: "已收部分款", value: "已收部分款" },
  { label: "已完成收款", value: "已完成收款" },
];

//服务方式option
export const SERVICE_OPTION = [
  { label: "快递", value: "快递" },
  { label: "取票", value: "取票" },
  { label: "送票", value: "送票" },
  { label: "会计上门", value: "会计上门" },
];

//收款周期option
export const COLLECTION_CYCLE = [
  { label: "一次性", value: "一次性" },
  { label: "月", value: "月" },
  { label: "季", value: "季" },
  { label: "半年", value: "半年" },
  { label: "年", value: "年" },
  { label: "2年", value: "2年" },
];

//是否的option
export const YES_OR_NO = [
  { label: "是", value: 1 },
  { label: "否", value: 0 },
];

//确认状态的option
export const HOME_CONFIRM_STATUS = [
  { label: "全部", value: 0 },
  { label: "未确认", value: 2 },
  { label: "确认中", value: 5 },
  { label: "已确认", value: 1 },
  { label: "调整税款", value: 4 },
  { label: "无需确认", value: 3 },
];
