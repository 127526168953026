<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="新增绑定登录信息"
    width="550px"
    append-to-body
  >
    <!-- <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="新增绑定办税人信息" name="1"> -->
    <el-form
      :model="form"
      label-width="130px"
      :rules="rules"
      ref="ruleFormRef"
      size="small"
    >
      <el-form-item label="登录方式" prop="loginMethod">
        <select-common
          placeholder="请选择登录方式"
          v-model:modelValue="form.loginMethod"
          :options="loginOption"
          class="w250 margin0"
        />
      </el-form-item>
      <el-form-item
        label="用户名/手机号："
        prop="zzridno"
        v-if="form.loginMethod != '政务网登录'"
      >
        <el-autocomplete
          v-model="form.zzridno"
          style="width: 250px"
          :fetch-suggestions="searchZzridNo"
          @select="handleSelectZzridno"
        >
          <template #default="scope">
            <div>{{ scope?.item?.loginMethod }}（{{ scope?.item?.zzridno }}）</div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item
        :label="form.loginMethod == '政务网登录' ? '账号：' : '代理机构号：'"
        prop="idNo"
        v-if="form.loginMethod != '新版登录'"
      >
        <input-common
          v-model:modelValue="form.idNo"
          class="w250"
          :placeholder="
            form.loginMethod == '政务网登录'
              ? '请输入账号'
              : '请输入代理机构号(纳税人识别号)'
          "
        ></input-common>
      </el-form-item>
      <el-form-item label="密码：" prop="zzrmn">
        <input-common
          v-model:modelValue="form.zzrmn"
          class="w250"
        ></input-common>
      </el-form-item>
      <el-form-item
        label="短信接收方式"
        prop="yzmType"
        v-if="form.loginMethod != '政务网登录'"
      >
        <select-common
          placeholder="请选择短信接收方式"
          v-model:modelValue="form.yzmType"
          :options="yzmOptions"
          class="w250 margin0"
          :clearable="false"
        />
      </el-form-item>
    </el-form>
    <!-- </el-tab-pane> -->
    <!--  <el-tab-pane label="绑定办税人信息" name="2">
        <el-form :model="temp" size="small">
          <el-form-item label="登录信息：">
            <el-select-v2
              v-model="temp.loginIds"
              :options="options"
              placeholder="请选择"
              style="width: 270px"
              filterable
              multiple
              collapse-tags
              collapse-tags-tooltip
              @change="changeSelect"
            >
              <template #default="{ item }">
                <span :style="{ color: item.label == '+新增' ? 'red' : '' }">{{
                  item.label
                }}</span>
              </template>
            </el-select-v2>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs> -->
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="updateSure"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <taxManForm ref="taxManForm" @success="getList" @update="getInfo" />
</template>

<script>
import {
  companyLoginUserList,
  companyLoginComUpdate,
  creatCompanyLoginUser,
} from "@/api/company";
import taxManForm from "@/components/authorizedLogin/taxManForm.vue";
export default {
  name: "",
  components: {
    taxManForm,
  },
  props: {},
  data() {
    return {
      dialogVisible: false,
      options: [],
      temp: {
        comIds: [],
        loginIds: [],
      },
      form: {
        loginMethod: "代理登录",
        zzridno: "",
        zzrmn: "",
        idNo: "",
        yzmType: 1,
      },
      yzmOptions: [
        { label: "自动登录", value: 0 },
        { label: "手动登录", value: 1 },
      ],
      loginOption: [
        { label: "新版登录", value: "新版登录" },
        { label: "代理登录", value: "代理登录" },
        { label: "政务网登录", value: "政务网登录" },
      ],
      activeName: "1",
      rules: {
        loginMethod: [
          { required: true, message: "请选择登录方式", trigger: "change" },
        ],
        zzridno: [
          { required: true, message: "请输入用户名或手机号", trigger: "blur" },
        ],
        zzrmn: [{ required: true, message: "请输入密码", trigger: "blur" }],
        idNo: [
          {
            required: true,
            message: "请输入代理机构号/纳税人识别号/账号",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted() {},
  methods: {
    init(ids, arr) {
      this.reset();
      this.temp.comIds = ids;
      this.temp.loginIds = arr ? arr : [];
      this.getList();
    },
    getList() {
      companyLoginUserList({}).then((res) => {
        if (res.data.msg == "success") {
          this.dialogVisible = true;
          if (res.data.data && res.data.data.length != 0) {
            this.options = res.data.data.list;
            this.options = this.options.map((item) => {
              item.label = `${item.loginMethod}（${
                item.loginMethod === "政务网登录" ? item.idNo : item.zzridno
              }）`;
              item.value = item.id;
              return item;
            });
            this.options.unshift({
              label: "+新增",
              value: 0,
            });
          } else {
            // this.$qzfMessage("请先点击授权管理新增办税人信息", 1);
          }
        }
      });
    },
    updateSure() {
      if (this.activeName == "2") {
        if (this.temp.loginIds.length == 0) {
          this.$qzfMessage("请选择登录信息", 1);
          return;
        }
        companyLoginComUpdate(this.temp).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("绑定成功");
            this.dialogVisible = false;
            this.$emit("success");
            this.$bus.emit('addCustomerUpdate')
          }
        });
      } else {
        this.$refs["ruleFormRef"].validate((valid) => {
          if (valid) {
            this.addAndBind();
          }
        });
      }
    },
    async addAndBind() {
      let newId = null;
      if (this.form.loginMethod == "政务网登录") {
        this.form.zzridno = "";
      }
      if (this.form.loginMethod == "新版登录") {
        this.form.idNo = "";
      }
      await creatCompanyLoginUser(this.form).then((res) => {
        if (res.data.msg == "success") {
          newId = res.data.data.id;
        }
      });
      let param = {
        comIds: this.temp.comIds,
        loginIds: [newId],
      };
      companyLoginComUpdate(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("绑定成功");
          this.dialogVisible = false;
          this.$emit("success");
          this.$bus.emit('addCustomerUpdate')
        }
      });
    },
    changeSelect(val) {
      if (val.includes(0)) {
        this.temp.loginIds = [];
        this.$refs.taxManForm.init();
      }
    },
    getInfo(row) {
      this.temp.loginIds = [row.id];
    },
    handleClick(tab) {
      this.activeName = tab.props.name;
    },
    reset() {
      this.form = {
        loginMethod: "代理登录",
        zzridno: "",
        zzrmn: "",
        idNo: "",
        yzmType: 1,
      };
    },
    searchZzridNo(queryString, cb) {
      if (queryString == "" || queryString.length < 2) {
        return;
      }
      companyLoginUserList({
        zzridno: queryString,
        loginMethod: this.form.loginMethod,
      }).then((res) => {
        let results = res.data?.data?.list || [];
        if (results) {
          cb && cb(results);
        }
      });
    },
    handleSelectZzridno(item) {
      this.form.zzridno = item.zzridno;
      this.form.yzmType = item.yzmType;
      this.form.zzrmn = item.zzrmn;
      this.form.idNo = item.idNo;
    },
  },
};
</script>

<style scoped lang="scss">
.w250 {
  width: 250px !important;
}
.margin0 {
  margin: 0;
}
:deep(.el-select-dropdown__option-item:first-child) {
  color: red !important;
}
.hint {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
