<template>
<!-- :render-label="renderLabel" -->
    <n-select filterable v-model:value="id" :show-on-focus="true" :render-option="renderOption" :options="subjectListOldList" @update:value="fn" size="large" @focus="focusEvent" :consistent-menu-width="false" >
      <template #action>
        <span @click="addCode" style="color:var(--themeColor, #17a2b8);cursor: pointer;" v-if="$buttonStatus('kjkm_xz')">+新增科目</span>
        <add-subject @success="addSuccess" ref="addSubject"></add-subject>
      </template>
    </n-select>
    <!-- <add-subject @success="addSuccess" ref="addSubject"></add-subject> -->
</template>
<script lang="jsx">


export default {
  props: {
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
    fzhsItemId: {
      type: Number,
      default: 0
    },
    subjectName: {//新增科目的名字
      type: String,
      default: "",
    },
    signName: {
      type: String,
      default: "",
    },
  },
  computed: {
    subjectListOldList(){
      let list = this.$subjectFilterCode(this.codes, 1)
      list.map(v=>{
        v.value = v.sign
        if(v.fzhsItemName){
          v.label = v.name3 + " --辅助核算：" + v.fzhsItemName
        }else{
          v.label = v.name3
        }
      })
      return list
    },
  },
  data () {
    return {
      renderOption({node,option}){
      return <node>
        <div class="subject_option">
          <span>{option.label}</span>
          <span class={`${option.amount<0?'subject_option_red':'subject_option_theme'}`}>{`${option.label?`余额：${option.amount}`:''}`}</span>
        </div>
      </node>
    },
      id: this.subjectId?this.subjectId + "-" + this.fzhsItemId:""
    }
  },
  methods: {
    focusEvent() {
      this.$emit('focus')
    },  
    fn(a,b){
      this.changeCode()
      this.$emit('update-enter')
    },
    addCode(){
      let data = {
        id: undefined,
        name: this.subjectName,
        type: 0,
      }
      this.$refs.addSubject.init(data, this.codes)
    },
    addSuccess(e){
      this.id = e.sign
      this.changeCode()
    },
    changeCode(){
      let infos = this.subjectListOldList.filter(v=>{return this.id == v.sign})
      if(infos.length != 0){
        if(infos[0].fzhsItemName){
          this.$emit("update:signName", infos[0].name3 + " --辅助核算：" + infos[0].fzhsItemName)
        }else{
          this.$emit("update:signName", infos[0].name3)
        }
      }
      this.$emit("update:subjectId", this.id.split('-')[0] * 1)
      this.$emit("update:fzhsItemId", this.id.split('-')[1] * 1)
      this.$emit("success", this.id)
    },
  }
}
</script>
<style lang="scss">

.subject_option{
display: flex;
align-items: center;
justify-content: space-between;
position: relative;
z-index: 10000;
}
.subject_option_red{
color: red;
font-size: 12px;
}
.subject_option_theme{
color: var(--themeColor, #17a2b8);
font-size: 12px;

}
.n-base-select-option{
display: grid !important;
}
.n-base-select-menu .n-base-select-option.n-base-select-option--pending::before{
  background-color: #e5e5eb;
}
</style>