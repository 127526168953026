import request from '@/utils/request'

//部门列表
export function getBaseDepts(data) {
  return request({
      url: 'api/v2/sys/getBaseDepts',
      method: 'post',
      data
  })
}
//部门新增修改
export function saveBaseDept(data) {
  return request({
      url: 'api/v2/sys/saveBaseDept',
      method: 'post',
      data
  })
}
//部门删除
export function deleteBaseDept(data) {
  return request({
      url: 'api/v2/sys/deleteBaseDept',
      method: 'post',
      data
  })
}
//角色列表
export function getSysRoles(data) {
  return request({
      url: 'api/v2/sys/getSysRoles',
      method: 'post',
      data
  })
}
//角色新增
export function saveSysRole(data) {
  return request({
      url: 'api/v2/sys/saveSysRole',
      method: 'post',
      data
  })
}
//角色删除
export function DeleteSysRole(data) {
  return request({
      url: 'api/v2/sys/deleteSysRole',
      method: 'post',
      data
  })
}
//角色绑定
export function getFunctionList(data) {
  return request({
      url: 'api/v2/sys/getFunctionList',
      method: 'post',
      data
  })
}
//角色树状保存
export function roleBingFunction(data) {
  return request({
      url: 'api/v2/sys/roleBingFunction',
      method: 'post',
      data
  })
}
//角色修改权限控制
export function sysButtonRoleList(data) {
  return request({
      url: 'api/v2/sys/sysButtonRoleList',
      method: 'post',
      data
  })
}
//角色修改权限控制保存
export function sysButtonSave(data) {
  return request({
      url: 'api/v2/sys/sysButtonSave',
      method: 'post',
      data
  })
}
//人员列表
export function getUsers(data) {
  return request({
      url: 'api/v2/sys/getUsers',
      method: 'post',
      data
  })
}
//人员新增修改
export function saveUser(data) {
  return request({
      url: 'api/v2/sys/saveUser',
      method: 'post',
      data
  })
}
//人员删除
export function deleteUser(data) {
  return request({
      url: 'api/v2/sys/deleteUser',
      method: 'post',
      data
  })
}
//用户绑定角色(角色管理)
export function userBindingRole(data) {
  return request({
      url: 'api/v2/sys/userBindingRole',
      method: 'post',
      data
  })
}
//人员状态
export function saveUserStatus(data) {
  return request({
      url: 'api/v2/sys/saveUserStatus',
      method: 'post',
      data
  })
}

//修改密码
export function editPass(data) {
  return request({
      url: 'api/v2/sys/editPass',
      method: 'post',
      data
  })
}
//重置密码
export function resetPasswords(data) {
  return request({
      url: 'api/v2/sys/resetPasswords',
      method: 'post',
      data
  })
}
//修改头像
export function editImg(data) {
  return request({
      url: 'api/v2/sys/editImg',
      method: 'post',
      data
  })
}