import request from "@/utils/request";

//查询客户信息
export function getCustomerList(data) {
  return request({
    url: "api/v2/m_crm/selectCustomerList",
    method: "post",
    data,
  });
}

//新增客户
export function addCustomer(data) {
  return request({
    url: "api/v2/m_crm/saveCustomer",
    method: "post",
    data,
  });
}
//修改客户
export function updateCustomer(data) {
  return request({
    url: "api/v2/m_crm/saveUpdateCustomer",
    method: "post",
    data,
  });
}
//删除客户
export function delCustomer(data) {
  return request({
    url: "api/v2/m_crm/delCustomer",
    method: "post",
    data,
  });
}
//批量删除客户信息
export function batchDelCustomer(data) {
  return request({
    url: "api/v2/m_crm/batchDelCustomer",
    method: "post",
    data,
  });
}
//记录信息
export function getRecodeList(data) {
  return request({
    url: "api/v2/m_crm/selectRecordList",
    method: "post",
    data,
  });
}
//新增记录
export function saveRecord(data) {
  return request({
    url: "api/v2/m_crm/saveRecord",
    method: "post",
    data,
  });
}
//删除记录
export function delRecode(data) {
  return request({
    url: "api/v2/m_crm/delRecord",
    method: "post",
    data,
  });
}
//跟进状态列表
export function getStateList(data) {
  return request({
    url: "api/v2/m_crm/customerStateList",
    method: "post",
    data,
  });
}
//新增跟进状态
export function saveCustomerState(data) {
  return request({
    url: "api/v2/m_crm/saveCustomerState",
    method: "post",
    data,
  });
}
//批量修改状态
export function saveStatus(data) {
  return request({
    url: "api/v2/m_crm/saveTotalSaves",
    method: "post",
    data,
  });
}
//查询业务类型
export function getCategoryList(data) {
  return request({
    url: "api/v2/m_crm/selectCategoryList",
    method: "post",
    data,
  });
}
//新增业务类型
export function addCategory(data) {
  return request({
    url: "api/v2/m_crm/saveCategory",
    method: "post",
    data,
  });
}
//新增业务类型名称
export function addCategoryName(data) {
  return request({
    url: "api/v2/m_crm/saveCategoryName",
    method: "post",
    data,
  });
}
//删除业务类型
export function delCategory(data) {
  return request({
    url: "api/v2/m_crm/delCategory",
    method: "post",
    data,
  });
}
//删除业务类型名称
export function delCategoryName(data) {
  return request({
    url: "api/v2/m_crm/delCategoryName",
    method: "post",
    data,
  });
}
//查询合同
export function getContractList(data) {
  return request({
    url: "api/v2/m_crm/selectContractList",
    method: "post",
    data,
  });
}

//新增合同
export function addContract(data) {
  return request({
    url: "api/v2/m_crm/saveContract",
    method: "post",
    data,
  });
}
//修改合同
export function updateContract(data) {
  return request({
    url: "api/v2/m_crm/saveUpdateContract",
    method: "post",
    data,
  });
}
//单独删除合同
export function delContract(data) {
  return request({
    url: "api/v2/m_crm/delContract",
    method: "post",
    data,
  });
}
//批量删除合同
export function delAllContract(data) {
  return request({
    url: "api/v2/m_crm/batchDelContract",
    method: "post",
    data,
  });
}
//查询即将到期合同
export function termContractList(data) {
  return request({
    url: "api/v2/m_crm/termContractList",
    method: "post",
    data,
  });
}
//批量分配合同跟进人修改合同状态
export function saveContractStatus(data) {
  return request({
    url: "api/v2/m_crm/saveTotalContract",
    method: "post",
    data,
  });
}
//续约合同
export function renewalContract(data) {
  return request({
    url: "api/v2/m_crm/renewalContract",
    method: "post",
    data,
  });
}

//首页echarts
export function homeList(data) {
  return request({
    url: "api/v2/m_crm/homeList",
    method: "post",
    data,
  });
}

//导入合同
export function importContract(data) {
  return request({
    url: "api/v2/m_crm/contractImport",
    method: "post",
    data,
  });
}
//导出合同
export function exportContract(data) {
  return request({
    url: "api/v2/m_crm/exportContract",
    method: "post",
    data,
  });
}
//导入合作客户
export function importCustomer(data) {
  return request({
    url: "api/v2/m_crm/customerImport",
    method: "post",
    data,
  });
}
//导出合作客户
export function exportCustomer(data) {
  return request({
    url: "api/v2/m_crm/exportCustomer",
    method: "post",
    data,
  });
}
//导入跟进客户
export function importFollowCustomer(data) {
  return request({
    url: "api/v2/m_crm/followCustomerImport",
    method: "post",
    data,
  });
}

//导出档案
export function exportRecord(data) {
  return request({
    url: "api/v2/m_crm/exportRecordNew",
    method: "post",
    data,
  });
}
//商品列表
export function productsList(data) {
  return request({
    url: "api/v2/wz/productsList",
    method: "post",
    data,
  });
}

//保存商标
export function saveTrademark(data) {
  return request({
    url: "api/v2/wz/saveTrademark",
    method: "post",
    data,
  });
}

// 商标审核列表
export function trademarkList(data) {
  return request({
    url: "api/v2/wz/trademarkList",
    method: "post",
    data,
  });
}

// 商标审核
export function trademarkExamine(data) {
  return request({
    url: "api/v2/wz/trademarkExamine",
    method: "post",
    data,
  });
}

//v3新增意向客户
export function saveCustomerNew(data) {
  return request({
    url: "api/v2/m_crm/saveCustomerNew",
    method: "post",
    data,
  });
}
//v3查询意向客户
export function customerListNew(data) {
  return request({
    url: "api/v2/m_crm/customerListNew",
    method: "post",
    data,
  });
}
//v3获取业务类型list
export function categoryListNew(data) {
  return request({
    url: "api/v2/m_crm/categoryListNew",
    method: "post",
    data,
  });
}

//v3新增合同
export function saveContractNew(data) {
  return request({
    url: "api/v2/m_crm/saveContractNew",
    method: "post",
    data,
  });
}

//v3删除合同
export function batchDelContractNew(data) {
  return request({
    url: "api/v2/m_crm/batchDelContractNew",
    method: "post",
    data,
  });
}

//v3查询合同
export function contractListNew(data) {
  return request({
    url: "api/v2/m_crm/contractListNew",
    method: "post",
    data,
  });
}

//v3获取客户来源list
export function sourceList(data) {
  return request({
    url: "api/v2/m_crm/sourceList",
    method: "post",
    data,
  });
}

//v3新增修改客户来源
export function saveSource(data) {
  return request({
    url: "api/v2/m_crm/saveSource",
    method: "post",
    data,
  });
}

//v3删除客户来源
export function deleteSource(data) {
  return request({
    url: "api/v2/m_crm/deleteSource",
    method: "post",
    data,
  });
}

//v3获取收款方式list
export function paymentMethodList(data) {
  return request({
    url: "api/v2/m_crm/paymentMethodList",
    method: "post",
    data,
  });
}

//v3新增修改收款方式
export function saveMethod(data) {
  return request({
    url: "api/v2/m_crm/saveMethod",
    method: "post",
    data,
  });
}
//v3删除付款方式
export function deleteMethod(data) {
  return request({
    url: "api/v2/m_crm/deleteMethod",
    method: "post",
    data,
  });
}

//新增收款登记
export function saveCollectionRecord(data) {
  return request({
    url: "api/v2/m_crm/saveCollectionRecord",
    method: "post",
    data,
  });
}

//收款记录查询
export function collectionRecordList(data) {
  return request({
    url: "api/v2/m_crm/collectionRecordList",
    method: "post",
    data,
  });
}

//任务列表
export function taskList(data) {
  return request({
    url: "api/v2/m_crm/taskList",
    method: "post",
    data,
  });
}
//新增任务
export function saveTask(data) {
  return request({
    url: "api/v2/m_crm/saveTask",
    method: "post",
    data,
  });
}
///删除任务
export function delTask(data) {
  return request({
    url: "api/v2/m_crm/delTask",
    method: "post",
    data,
  });
}

//任务类型列表
export function taskTypeList(data) {
  return request({
    url: "api/v2/m_crm/taskTypeList",
    method: "post",
    data,
  });
}

//新增任务类型
export function saveTaskType(data) {
  return request({
    url: "api/v2/m_crm/saveTaskType",
    method: "post",
    data,
  });
}
//删除任务类型
export function delTaskType(data) {
  return request({
    url: "api/v2/m_crm/delTaskType",
    method: "post",
    data,
  });
}

//批量修改执行人
export function saveExecutor(data) {
  return request({
    url: "api/v2/m_crm/saveExecutor",
    method: "post",
    data,
  });
}

//查询详情
export function customerInfo(data) {
  return request({
    url: "api/v2/m_crm/customerInfo",
    method: "post",
    data,
  });
}

//新增任务记录情况表
export function saveTaskMemory(data) {
  return request({
    url: "api/v2/m_crm/saveTaskMemory",
    method: "post",
    data,
  });
}

//查询任务记录情况表
export function taskMemoryList(data) {
  return request({
    url: "api/v2/m_crm/taskMemoryList",
    method: "post",
    data,
  });
}

//导出任务
export function exportTask(data) {
  return request({
    url: "api/v2/m_crm/exportTask",
    method: "post",
    data,
  });
}

//收款登记服务类别
export function contractCategoryList(data) {
  return request({
    url: "api/v2/m_crm/contractCategoryList",
    method: "post",
    data,
  });
}

//删除收款记录
export function deleteCollectionRecord(data) {
  return request({
    url: "api/v2/m_crm/deleteCollectionRecord",
    method: "post",
    data,
  });
}

//合同列表筛选服务项目list
export function categoryNameList(data) {
  return request({
    url: "api/v2/m_crm/categoryNameList",
    method: "post",
    data,
  });
}

//合同统计
export function contractStatistics(data) {
  return request({
    url: "api/v2/m_crm/contractStatistics",
    method: "post",
    data,
  });
}

//业务类型
export function categoryTypeList(data) {
  return request({
    url: "api/v2/m_crm/categoryTypeList",
    method: "post",
    data,
  });
}

//新增服务类别
export function saveCategoryNew(data) {
  return request({
    url: "api/v2/m_crm/saveCategoryNew",
    method: "post",
    data,
  });
}

//删除客户v3
export function delCustomerNew(data) {
  return request({
    url: "api/v2/m_crm/delCustomerNew",
    method: "post",
    data,
  });
}

//批量删除客户
export function batchDelCustomerNew(data) {
  return request({
    url: "api/v2/m_crm/batchDelCustomerNew",
    method: "post",
    data,
  });
}

//新版导入合同
export function contractImportNew(data) {
  return request({
    url: "api/v2/m_crm/contractImportNew",
    method: "post",
    data,
  });
}

//新版导出合同
export function exportContractNew(data) {
  return request({
    url: "api/v2/m_crm/exportContractNew",
    method: "post",
    data,
  });
}

//到期合同查询
export function termContractListNew(data) {
  return request({
    url: "api/v2/m_crm/termContractListNew",
    method: "post",
    data,
  });
}

//新版导出客户
export function exportCustomerNew(data) {
  return request({
    url: "api/v2/m_crm/exportCustomerNew",
    method: "post",
    data,
  });
}

//根据id查询
export function contractInfo(data) {
  return request({
    url: "api/v2/m_crm/contractInfo",
    method: "post",
    data,
  });
}

//推送到新增账套
export function pushSaveEaCompany(data) {
  return request({
    url: "api/v2/m_crm/pushSaveEaCompany",
    method: "post",
    data,
  });
}

// 收款管理列表查询
export function contractRecordStList(data) {
  return request({
    url: "api/v2/m_crm/contractRecordStList",
    method: "post",
    data,
  });
}
// 收款管理导出

export function exportContractRecordStList(data) {
  return request({
    url: "api/v2/m_crm/exportContractRecordStList",
    method: "post",
    data,
  });
}