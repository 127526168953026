<template>
  <div>
    <el-drawer v-model="drawer" title="预计人工降耗金额" destroy-on-close size="27%">
      <el-container v-if="!sfopen">
        <div class="headers">
          近12个月平均数
        </div>
        <el-main>
          <el-table :data="count" border style="width: 100%" v-loading="tableLoading">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" type="index" width="60" fixed label="序号"></el-table-column>
            <el-table-column prop="name" label="以单据数量统计" fixed  align="left">
            </el-table-column>
            <el-table-column prop="count" label="企业数" width="100" align="center">
              <template #default="scope">
                <span class="tableText" @click="openCompany(scope.row)">
                  {{ scope.row.count }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <el-table  :data="qylx" border style="width: 100%;margin-top: 20px" v-loading="tableLoading">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" type="index" width="60" fixed label="序号"></el-table-column>
            <el-table-column prop="ywlx" label="以业务类型统计" fixed  align="left">
            </el-table-column>
            <el-table-column prop="count" label="企业数" width="100" align="center">
              <!-- <template #default="scope">
                <span class="tableText" @click="openCompany">
                  {{ scope.row.count }}
                </span>
              </template> -->
            </el-table-column>
          </el-table>
          <el-table  :data="totalList" border style="width: 100%;margin-top: 20px" v-loading="tableLoading">
            <template #empty>
              <el-empty :image-size="150" description="没有数据"></el-empty>
            </template>
            <el-table-column align="center" type="index" width="60" fixed label="序号"></el-table-column>
            <el-table-column prop="name" label="会计工厂预测报告" fixed  align="left">
            </el-table-column>
            <el-table-column prop="count" label="" width="100" align="center">
              <!-- <template #default="scope">
                <span class="tableText" @click="openCompany">
                  {{ scope.row.count }}
                </span>
              </template> -->
            </el-table-column>
          </el-table>
        </el-main>
        <el-footer class="foot">
          <div v-if="!tableLoading">
            <el-button
              style="padding: 15px 20px;"
              @click="goStatistics('2')"
              size="small"
              type="primary"
              >重新生成预测报告</el-button>
            <span style="color: red;margin-bottom: 7px;">（报告已生成）</span>
          </div>
          <el-button
            style="padding: 15px 20px;"
            size="small"
            type="primary"
            loading
            v-else
            >生成中...</el-button>
        </el-footer>
      </el-container>
      <div v-else v-loading="sfloading" class="drawerbg">
        <div class="drawerText">
          <div style="vertical-align: middle;display: flex">
            <img src="../../../assets/hand.svg" alt="">
            <div style="color: #1FA5B9;margin-left: 3px;">点击下方预测按钮</div>
          </div>
          <div>
            可以根据你的企业性质，单据数量，预测启用会计工厂的成本降耗方案！
          </div>
          <div style="margin-top: 40px;">
            <el-button
              style="padding: 20px 30px;border-radius: 20px;"
              @click="goStatistics('1')"
              size="small"
              type="primary"
              >生成预测报告</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="详细"
      v-model="openVisible"
    >   
      <div style="float: right;margin-bottom: 5px;">
        <el-button size="small" @click="daochu()" icon="FolderOpened" :loading="dcLoading" type="success">导出</el-button>
      </div>
      <div style="height: 560px;">
        <el-table height="500px" :data="list" border style="width: 100%">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column align="center" type="index" width="60" fixed label="序号"></el-table-column>
          <el-table-column prop="name" label="公司名称" fixed width="300" align="left">
            <template #default="scope">
            <TagNameCopy :row="scope.row" idKey="comId" :showCopy="false" :needNameJump="true" :showAuthIcon="false">
            </TagNameCopy>
          </template>
          </el-table-column>
          <el-table-column prop="taxNo" label="税号" width="200" align="left">
          </el-table-column>
          <el-table-column prop="ywlx" label="业务类型" width="100" align="center">
          </el-table-column>
          <el-table-column prop="billCount" label="单据数量" width="100" align="center">
            <template #default="scope">
              <!-- <span>{{$filterWebsite(scope.row.type)}}</span> -->
            </template>
          </el-table-column>
          <el-table-column prop="voucherCount" label="凭证数量"  align="center">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <qzf-pagination
            v-show="total > 0"
            :total="total"
            v-model:page="listQuery1.page"
            v-model:limit="listQuery1.limit"
            @pagination="getComList"
          />
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      title="预测报告"
      v-model="totalVisible"
      width="25%"
    >   
      <el-form :model="peopleForm" :rules="peopleRules" ref="peopleForm" label-width="120px" style="margin: 0 auto;width: 400px;height: 130px;">
        <el-form-item label="人均负责账套数" prop="peopleNum">
          <el-input v-model="peopleForm.peopleNum" size="small" placeholder="请输入人均负责张套数" style="width: 200px;"></el-input>
        </el-form-item>
        <el-form-item label="人均工资" prop="peopleMoney">
          <el-input v-model="peopleForm.peopleMoney" size="small" placeholder="请输入人均工资" style="width: 200px;"></el-input>
        </el-form-item>
        <div>
          <el-button
            style="float: right;"
            @click="goSta"
            size="small"
            type="primary"
            >生成预测报告</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { automaticComTypeList,automaticComList,automaticComTypeCheck,expectAutomaticComList } from "@/api/automatic.js";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import { tr } from "element-plus/es/locale";
//
export default {
  name: "websiteInfomation",
  // components: { vWangeditor},
  components: { 
    TagNameCopy
  },

  data() {
    return {
      dcLoading:false,
      drawer:false,//抽屉
      listQuery: {
        comId:null,
        period:'',
        reqNo:'',
      },
      listQuery1: {
        billName:'',
        page: 1,
        limit: 20,
      },
      count: [],
      lock: false,
      qylx: [],
      contentStyleObj: {}, //高度变化
      openVisible: false,
      total:0,
      list:[],
      timer:null, //定时器名称
      totalVisible:false,
      peopleForm:{
        peopleNum:null,
        peopleMoney:null,
      },
      peopleRules:{
        peopleNum: [
          { required: true, message: '请输入人均负责账套数', trigger: 'blur' },
        ],
        peopleMoney: [
          { required: true, message: '请输入人均工资', trigger: 'blur' },
        ],
      },
      totalList:[],
      drewTitle:'转型'+'"'+'会计工厂'+'"'+'预测',
      sfopen:true,//判断是否是第一次生成
      sfloading:false,//抽屉loading
      tableLoading:false,//tableLoading
    };
  },
  beforeDestroy(){
    clearInterval(this.timer);// 清除定时器
    this.timer = null;
  },
  created() {
    this.contentStyleObj = this.$getHeight(320);
    window.addEventListener("resize", this.getHeight);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList()
    },
    extractNumberOrHandleSpecial(name) {  
      if (name.includes('以内')) {  
        // 提取“以内”前面的数字，但这里我们实际上不需要它，因为“以内”总是排在最前面  
        // 返回一个足够小的数作为占位符  
        return [-Infinity, null];  
      } else if (name.includes('以上')) {  
          // “以上”没有具体的起始数字，所以我们返回一个足够大的数和null作为结束数字  
          return [Infinity, null];  
      }  
      // 提取起始和结束数字（如果有的话）  
      const match = name.match(/^(\d+)-?(\d+)?张$/);  
      if (match) {  
          const start = parseInt(match[1], 10);  
          const end = match[2] ? parseInt(match[2], 10) : start; // 如果没有结束数字，则假设与起始数字相同  
          return [start, end];  
      }  
      // 如果都不是，返回NaN（理论上不应该发生，因为name格式已知）  
      return [NaN, NaN];  
    },   
    getList(){
      automaticComTypeList(this.listQuery).then(res => {
        this.count = Object.entries(res.data.data.count).map(([name, count]) => ({name,count}))
        this.count.sort((a, b) => {  
          const [startA, endA] = this.extractNumberOrHandleSpecial(a.name);  
          const [startB, endB] = this.extractNumberOrHandleSpecial(b.name);  
        
          // 首先处理特殊情况  
          if (isNaN(startA) || isNaN(startB)) {  
              // 如果其中一个无法提取数字，则根据它们是否是特殊情况来处理  
              if (isNaN(startA) && isNaN(startB)) {  
                  // 两个都是特殊情况，但这种情况不应该发生（因为我们已经处理了所有情况）  
                  throw new Error('Unexpected name format');  
              } else if (isNaN(startA)) {  
                  // a是特殊情况，b不是  
                  return 1; // 将a（特殊情况）放在b之后  
              } else {  
                  // a不是特殊情况，b是  
                  return -1; // 将b（特殊情况）放在a之后，但在这个例子中，我们实际上想要“以内”在最前面  
              }  
          }  
        
          // 处理正常情况，首先按起始数字排序  
          if (startA !== startB) {  
              return startA - startB;  
          }    
        });
        this.lock = res.data.data.lock
        this.qylx =res.data.data.qylx
        this.totalList =[
          {
            name:'总账套数量',
            count:res.data.data.total.total+'个'
          },
          {
            name:'可迁入工厂数量',
            count:res.data.data.total.kqr+'个'
          },
          {
            name:'预计工厂费用',
            count:res.data.data.total.yjgcfy+'元/月'
          },
          {
            name:'启用'+'"'+'会计工厂'+'"'+'预计节省人工成本',
            count:res.data.data.total.jsfy+'元/月'
          },
        ]
        if(!res.data.data.total.total){
          this.sfopen = true
        }else{
          this.sfopen = false
        }
        if(this.lock){
          this.timer = setInterval(()=>{
            if(this.lock){
              this.getList();
            }else{
              clearInterval(this.timer);
              this.timer = null;
              this.sfopen = false
              if(res.data.data.total.total){
                this.tableLoading = false
              }else{
                this.sfloading = false;
              }
            }
          },20000)
        }
      }).catch(err => {
        console.log(err);
      });  
    },
    openCompany(val){
      this.openVisible = true;
      this.listQuery1.billName = val.name
      this.getComList()
    },
    getComList(){
      automaticComList(this.listQuery1).then(res => {
        this.list = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        console.log(err);
      });
    },
    goStatistics(type){
      this.totalVisible = true;
      this.peopleForm={
        peopleMoney:'',
        peopleNum:'',
        sfType:type
      }
    },
    goSta(){
      this.$refs['peopleForm'].validate((valid) => {
        if (valid) {
          this.totalVisible = false;
          this.lock = true;
          this.peopleForm.peopleMoney = this.peopleForm.peopleMoney*1
          this.peopleForm.peopleNum = this.peopleForm.peopleNum*1
          automaticComTypeCheck(this.peopleForm).then(res => {
            if(res.data.msg == "success"){
              if(this.peopleForm.sfType==1){
                this.sfloading = true;
              }else if(this.peopleForm.sfType==2){
                this.tableLoading = true
              }
              if(this.timer){
                clearInterval(this.timer);
                this.timer = null;
              }
              this.timer = setInterval(()=>{
                if(this.lock){
                  this.getList();
                }else{
                  clearInterval(this.timer);
                  this.timer = null;
                  this.sfopen = false
                  if(this.peopleForm.sfType==1){
                    this.sfloading = false;
                  }else if(this.peopleForm.sfType==2){
                    this.tableLoading = false
                  }
                }
              },20000)
            }
          }).catch(err => {
            console.log(err);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    daochu(){
      this.dcLoading = true
      expectAutomaticComList(this.listQuery1).then(res => {
        if (res.data.msg == 'success') {
          window.open(res.data.data.url)
          this.dcLoading = false
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
};
</script>
<style scoped>
.headers {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 20px;
}
.tableText{
  text-decoration: underline;
  cursor: pointer;
}
.tableText:hover{
  color: blue;
}
.foot{
  position: fixed;
  bottom: 20px;
  display: flex; /* 启用Flexbox布局 */
  justify-content: center; /* 水平居中子元素 */
  align-items: flex-end; /* 垂直对齐子元素到容器的底部 */
  margin-top: 20px
}
.drawerbg{
  width: 100%;
  height: 100%;
  background-image: url('https://file.listensoft.net/mnt/v2/img/b71b31ba2f7a4987ceddfd51a125dbd.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.drawerText{
  text-align: center;
  position: relative;
  top: 48%;
}
.drawerText div{
  margin: 8px 0;
}
.drawerText > div:first-child { /* 假设第一个<div>是你想要居中的元素 */  
  display: flex;  
  justify-content: center; /* 水平居中 */  
  align-items: center; /* 垂直居中，如果需要的话 */  
}
:deep(.el-drawer__body){
  padding: 0!important;
  overflow: hidden;
}
</style>
