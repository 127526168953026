<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dayinCustomStatus"
    title="自定义打印"
    width="50%"
  >
    <el-form :model="form" class="print_box" label-width="170px">
      <el-form-item label=" 是否打印签名栏：" class="item_row">
        <el-switch v-model="customTemp.signLine" :active-value="1" :inactive-value="0" size="small" /> 
      </el-form-item>
      <el-form-item label="左偏移(mm)：" class="item_row">
        <el-input v-model.number="customTemp.left" placeholder="请输入内容" size="small" style="width:80%"></el-input>
      </el-form-item>


      <el-form-item label="右偏移(mm)：" class="item_row">
        <el-input v-model.number="customTemp.right" placeholder="请输入内容" size="small" style="width:80%"></el-input>
      </el-form-item>

      <el-form-item label="字体：" class="item_row">
        <el-radio v-model="customTemp.ttf" :label="1">宋体</el-radio>
        <el-radio v-model="customTemp.ttf" :label="2">微软雅黑</el-radio>
      </el-form-item>
    
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dayinCustomStatus = false" size="small">取 消</el-button>
        <el-button type="primary" @click="printingCustom" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data(){
    return{
      dayinCustomStatus:false,
      customTemp:{
        left:20,
        right: 20,
        ttf:1,
        signLine:0
      },
    }
  },
  methods:{
    printingCustom(){
      if(this.customTemp.left > 50){
        this.$qzfMessage('左偏移最大为50！',1);
        return
      }
      if(this.customTemp.right > 25){
        this.$qzfMessage('右偏移最大为25！',1);
        return
      }
      let params
      params = {
        paperType:'A5-H',
        ttf: this.customTemp.ttf*1,
        left: this.customTemp.left*1,
        right: this.customTemp.right*1,
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
        signLine:this.customTemp.signLine
      }
      this.dayinCustomStatus = false
      this.$emit('success',params)
    },
  }
}
</script>

<style>

</style>