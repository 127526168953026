<template>
  <el-select-v2
    v-model="id"
    filterable
    :options="subjectListOldList"
    placeholder="选择会计科目"
    clearable
    size="small"
    style="width: 136px;"
  >
    <template #default="{ item }">
      <div class="show-box">
        <p class="ellipsis">
          <el-tooltip :disabled="item.name3.length < 32" :content="item.name3" placement="top">
            <span :ref="item.name3" @mouseover="onMouseOver(item.name3)">{{ item.name3 }}</span>
          </el-tooltip>
        </p>
        <span style="color:var(--themeColor, #17a2b8);font-size: 14px;">{{item.fzhsName}}</span>
      </div>
    </template>
  </el-select-v2>
</template>
<script lang="jsx">
export default {
  props:{
    codes: {//默认下拉框的code
      type: Array,
      default: () => []
    },
    subjectId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      isShowTooltip: false,
    }
  },
  computed: {
    subjectListOldList(){
      return this.$subjectFilterCode(this.codes, 2).map(item => {
        return {
          name3: item.name3,
          fzhsName: item.fzhsName,
          label: item.name3,
          value: item.id,
          code: item.code,
        }
      })
    },
    id: {
      get() {
        if(!this.subjectId){
          return undefined
        }
        return this.subjectId
      },
      set(val) {
        this.$emit("update:subjectId", val * 1)
        this.$emit("success", val * 1)
        let info = this.subjectListOldList.find(v => v.value == val)
        this.$emit("update:code", info?.code)
      }
    }
  },
  methods: {
    onMouseOver(str){
      let contentWidth = this.$refs[str].offsetWidth;
        // 判断是否开启tooltip功能
      if (contentWidth > 290) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.show-box {
  display: flex;
  justify-content: space-between;
  & > p {
    width: 300px;
  }
}
</style>
