//注册全局方法
import { ElMessage, ElMessageBox } from "element-plus";
import { h } from "vue";
import store from "@/store";

export function message(text, type) {
  let types = "success";
  if (type == 1) {
    types = "warning";
  } else if (type == 2) {
    ElMessage.error(text);
    return;
  }
  ElMessage({
    message: text,
    type: types,
  });
}

export function alertMsg(text) {
  ElMessageBox.alert(text, "提示", {
    confirmButtonText: "确认",
  });
}

// 队列
export function queueMessage(text) {
  if (!text || text.length == 0) {
    return;
  }
  ElMessageBox.alert(
    `
    <div style="height:350px;overflow:auto">
      ${text
      .map((item) => {
        return `<div>${item}已在队列中</div>`;
      })
      .join("")}
      </div>
    `,
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确认",
    }
  );
}

// 队列
export function cancelMsg(text) {
  if (!text || text.length == 0) {
    return;
  }
  ElMessageBox.alert(
    `
    <div style="height:350px;overflow:auto">
      ${text
      .map((item) => {
        return `<div>${item.name}-${item.taskName}-${item.errLog}</div>`;
      })
      .join("")}
      </div>
    `,
    {
      dangerouslyUseHTMLString: true,
      confirmButtonText: "确认",
    }
  );
}

//深copy
export function copy(obj) {
  if (!obj) {
    return;
  }
  var newobj = obj.constructor === Array ? [] : {};
  if (typeof obj !== "object") {
    return;
  }
  for (var i in obj) {
    newobj[i] = typeof obj[i] === "object" ? this.$qzfCopy(obj[i]) : obj[i];
  }
  return newobj;
}

export function checkStrInArray(arr, str) {
  let s = arr.filter((v) => {
    return str == v;
  });
  if (s.length != 0) {
    return true;
  }
  return false;
}

export function getUrlParams2(url, name) {
  // 不传name返回所有值，否则返回对应值
  if (url.indexOf("?") == 1) {
    return false;
  }
  url = url.substr(1);
  url = url.split("&");
  var name = name || "";
  var nameres;
  // 获取全部参数及其值
  for (var i = 0; i < url.length; i++) {
    var info = url[i].split("=");
    var obj = {};
    obj[info[0]] = decodeURI(info[1]);
    url[i] = obj;
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    for (var i = 0; i < url.length; i++) {
      for (const key in url[i]) {
        if (key == name) {
          nameres = url[i][key];
        }
      }
    }
  } else {
    nameres = url;
  }
  // 返回结果
  return nameres;
}

export function getUrlParams(url, name) {
  // 不传name返回所有值，否则返回对应值
  let s = url.split("?");
  if (s.length < 2) {
    return "";
  }
  let cs = s[1];
  let csArr = cs.split("&");
  let val = "";
  csArr.map((v) => {
    if (name == v.split("=")[0]) {
      val = v.split("=")[1];
    }
  });
  return val;
}

//过滤科目 1只要最下级
export function subjectFilterCode(codes, type) {
  //subjects
  let list = [];
  if (type == 1) {
    list = store.getters["commons/subjectsLower"];
  } else {
    list = store.getters["commons/subjects"];
  }
  if (codes?.length == 0) {
    return list || [];
  }
  return list.filter((v) => {
    if (codes.indexOf(v.code.substring(0, 4)) > -1) {
      return v;
    }
  });
}

export function findCode(arr, type) {
  let codes = type
    ? store.getters["commons/codes"]
    : store.getters["user/comInfo"].codes;
  let list = arr.map((v) => {
    return codes?.[v];
  });
  return list;
}
//原型链上增加方法
// String.prototype.moneyFilter = function(){
//   if(this.indexOf('(') > -1){
//     let amount = this.replace("(", "").replace(")","")
//     return Number((-amount.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }else{
//     return Number((this.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
//   }
// }

export function buttonStatus(code) {
  if (store.getters["user/user"].sysButton?.includes(code)) {
    return false;
  } else {
    return true;
  }
}

export function checkSettleStatus(type) {
  const periods = store.getters["commons/periods"];
  const comInfo = store.getters["user/comInfo"];
  const comPeriod = periods[0].options.find((v) => comInfo.period === v.period);
  if (comPeriod?.settleStatus == 4) {
    if (!type) {
      ElMessage.error({
        message: h(
          "p",
          {
            style:
              "line-height: 1; font-size: 14px;color:#f56c6c;cursor: pointer;",
          },
          [
            h("span", null, " 已结账不允许修改，请先操作[ "),
            h(
              "a",
              { style: "text-decoration: underline;color: #409eff" },
              "反结账"
            ),
            h("span", null, " ]"),
          ]
        ),
        duration: 1500,
        onClick: () => {
          this.$router.push({
            name: "settle-account",
          });
        },
      });
    }
    return "本期已结账，请先进行反结账";
  }
  return "";
}
//获取本账期最后一天
export function getPeriodTime(filterVal, jsonData) {
  let period = filterVal ? filterVal : store.getters["user/comInfo"].period;
  let ri = "31";
  if (period.slice(4, 6) == 2) {
    ri = "28";
  } else if (
    period.slice(4, 6) == "04" ||
    period.slice(4, 6) == "06" ||
    period.slice(4, 6) == "09" ||
    period.slice(4, 6) == "11"
  ) {
    ri = "30";
  }

  let str = period.slice(0, 4) + "-" + period.slice(4, 6) + "-" + ri;
  return new Date(str);
}

export function getCachedViews(name) {
  return (
    store.getters["tagsView/cachedViews"].filter((v) => {
      return v == name;
    }).length != 0
  );
}

// 千分符
export function comdify(n) {
  if (!n) return n;
  n = Number(n).toFixed(2);
  let str = n.split(".");
  let re = /\d{1,3}(?=(\d{3})+$)/g;
  let n1 = str[0].replace(re, "$&,");
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
}

// 保留两位小数 例如 227.545->227.55

String.prototype.toFixed2 = function () {
  let c = Number(this);
  return parseFloat((c * 100).toFixed(0) / 100);
};

Number.prototype.toFixed2 = function () {
  let c = Number(this);
  return parseFloat((c * 100).toFixed(0) / 100);
};

//全局复制公司名称
export function copyComName(name) {
  const newObj = name;
  //创建input标签
  var input = document.createElement("textarea");
  //将input的值设置为需要复制的内容
  input.value = newObj;
  //添加input标签
  document.body.appendChild(input);
  //选中input标签
  input.select();
  //执行复制
  document.execCommand("copy");
  //成功提示信息
  this.$message.success("复制成功！");
  //移除input标签
  document.body.removeChild(input);
}

//加8个小时
export function addEightHours(dateString) {
  const beijingTimeZoneOffset = 8; // Beijing is UTC+8
  const beijingDateTime =
    new Date(dateString).getTime() + beijingTimeZoneOffset * 60 * 60 * 1000;
  const convertedDate = new Date(beijingDateTime);
  return convertedDate.toISOString();
}

//获取当前月第一天和最后一天
export function getMonthFirstAndLastDay() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  return {
    firstDay: `${year}-${String(month + 1).padStart(2, "0")}-${String(
      firstDay.getDate()
    ).padStart(2, "0")}`,
    lastDay: `${year}-${String(month + 1).padStart(2, "0")}-${String(
      lastDay.getDate()
    ).padStart(2, "0")}`,
  };
}

//获取当天日期
export function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

//获取传入日期的最后一天
export const getEndDate = (time, type, period, give) => {
  const date = new Date(time);
  let monthsToAdd = 1;
  switch (type) {
    case "季":
      monthsToAdd = 3;
      break;
    case "半年":
      monthsToAdd = 6;
      break;
    case "年":
      monthsToAdd = 12;
      break;
    case "2年":
      monthsToAdd = 24;
      break;
  }
  if (period) {
    monthsToAdd = monthsToAdd * period;
  }
  if (give) {
    monthsToAdd = monthsToAdd + give;
  }
  date.setMonth(date.getMonth() + monthsToAdd - 1);
  const year = date.getFullYear();
  const month = date.getMonth(); // getMonth() 返回 0-11
  const lastDay = new Date(year, month + 1, 0).getDate();
  return `${year}-${String(month + 1).padStart(2, "0")}-${String(
    lastDay
  ).padStart(2, "0")}`;
};
//月份差
export const getMonthsDifference = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  if (start > end) {
    ElMessage.error("开始日期不能晚于结束日期");
    return;
  }
  const yearsDifference = end.getFullYear() - start.getFullYear();
  const monthsDifference = end.getMonth() - start.getMonth();
  const totalMonthsDifference = yearsDifference * 12 + monthsDifference + 1;
  return totalMonthsDifference;
};
//前一天的日期
export const getPreviousDay = () => {
  // 获取当前日期
  const today = new Date();
  // 获取前一天的日期
  const previousDay = new Date(today);
  previousDay.setDate(today.getDate() - 1);
  // 格式化日期
  const year = previousDay.getFullYear();
  const month = String(previousDay.getMonth() + 1).padStart(2, "0");
  const day = String(previousDay.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
//获取当月最后一天
export function getLastDayOfMonth(dateString, type) {
  const [year, month] = dateString.split("-").map(Number);
  if (type) {
    const date = new Date(year, month - 1, 1);
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    const lastDay = new Date(nextMonth - 1);
    return `${lastDay.getFullYear()}-${String(lastDay.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(lastDay.getDate()).padStart(2, "0")}`;
  } else {
    return `${year}-${String(month).padStart(2, "0")}-01`;
  }
}
//获取当月的下一个月的第一天
export function getNextMonthFirstDay(dateString) {
  const date = new Date(dateString);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  if (month === 12) {
    year += 1;
    month = 1;
  } else {
    month += 1;
  }
  const nextMonthFirstDay = new Date(year, month - 1, 1);
  const yearStr = nextMonthFirstDay.getFullYear();
  const monthStr = String(nextMonthFirstDay.getMonth() + 1).padStart(2, '0');
  const dayStr = '01';

  return `${yearStr}-${monthStr}-${dayStr}`;
}
