<template>
  <el-cascader
    v-model="innerValue"
    :options="options"
    @change="changeValue"
    filterable
    :clearable="clearable"
    :placeholder="placeholder"
    :props="casProps"
    :disabled="disabled"
    size="small"
  />
</template>

<script>
export default {
  name: "cascaderCommon",
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    casProps: {
      type: Object,
    },
    modelValue: { type: Array },
  },
  computed: {
    innerValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val[val.length - 1]);
        this.$emit("update:first", val[0]);
      },
    },
  },
  methods: {
    changeValue() {
      this.$emit("change");
    },
  },
};
</script>
