import { createStore } from 'vuex'

import { user } from '@/store/module/user'
import { router } from '@/store/module/router'
import { tagsView } from '@/store/module/tagsView'
import { commons } from '@/store/module/common'
import { inits } from '@/store/module/init'

export default createStore({
  modules: {
    user,
    router,
    tagsView,
    commons,
    inits,
  },
  state:{
     currMonth : new Date().getMonth(),
     currQuarter : Math.floor( ( new Date().getMonth() % 3 == 0 ? ( new Date().getMonth() / 3 ) : ( new Date().getMonth() / 3 + 1 ) ) )
  },
  getters:{
    currMonth(state){
      return state.currMonth.toString()
    },
    currQuarter(state){
      return state.currQuarter.toString()
    }
  }
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  // modules: {
  // }
})
