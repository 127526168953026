import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElIcons  from '@element-plus/icons-vue'
import locale from "element-plus/lib/locale/lang/zh-cn";
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'

// import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import 'element-plus/dist/index.css'
import './style/element.scss'

import './style/naive-reset.scss'
import './style/vxe-table-reset.scss'

import './style/common.scss'

import './assets/css/all.css'
import './assets/icon/iconfont.css'
import '@/permission'


import eventBus from '@/utils/bus.js'/// mitt 总线程引入
//拖动排序
// import VueDND from 'awe-dnd'
// Vue.use(VueDND)

import component from '@/utils/component';//注册全局组件
// import fun from '@/utils/fun';//注册全局组件

const app = createApp(App)

// Object.keys(Icons).forEach((key) => {
//   app.component(key, Icons[key]);//JS中用这行
//   // app.component(key, Icons[key as keyof typeof Icons]);//TS中用这行
// })
for (const iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName])
}
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$bus = eventBus//挂载全局属性
import { copy, message, checkStrInArray, subjectFilterCode, findCode, buttonStatus, checkSettleStatus ,queueMessage, alertMsg, cancelMsg, getCachedViews ,comdify ,copyComName  } from '@/utils/fun'
import {  inventoryTypeFilter , inventoryCategoryFilter , VoucherSourceFilter ,tableNameFilter ,setUpStatusGsSb,setUpStatusLogin,setUpStatusGsKk, setUpStatusGsIconSb ,setUpStatusGsWordSb,setUpStatusJc, setUpStatusIconJc,setUpStatusCj ,setUpStatusImport,setUpStatusMatch ,batchSendSb,batchSendIconSb ,batchSendWordSb,batchSendKk ,batchSendWordKk, batchSendJc, batchSendCj,batchSendIconCj,setUpStatusGsZf,setUpStatusGsIconZf,setUpStatusGsWordZf,setUpStatusJzCj,setUpStatusJzCjIcon,cityFilter,yearAcessStatus ,yearAcessIconStatus  ,yearAcessWordStatus ,batchSendYearSb ,batchSendGxrz ,setUpAllStatusIconJc ,batchSendWordJc,setUpStatusGsGz,taskNameType ,batchSendBusinessSb,setUpStatusWsJc ,accountSystemFilter,filterAutoStatusIcon,filterAutoStatusText,filterAutoStatusWord,filterWebsite,homeConfirmStatus,homeConfirmIcon } from '@/utils/filter'

import { parseTime, getHeight ,goEtax,toLoginTax, getLastDay ,saveBig} from '@/utils/index'
import { newformatDate } from '@/utils/timeConversion'
app.mixin({
  methods: {
    $qzfMessage: message,
    $qzfCopy: copy,
    $checkStrInArray: checkStrInArray,//检测数组中是否存在
    $subjectFilterCode: subjectFilterCode,//获取科目
    $findCode: findCode,//获取基础科目
    $buttonStatus: buttonStatus,//按钮权限
    $checkSettleStatus: checkSettleStatus,//结账
    $parseTime: parseTime,//格式化时间 parseTime(v.checkDate, "{y}-{m}-{d}")
    $inventoryTypeFilter:inventoryTypeFilter, //存货类别
    $inventoryCategoryFilter:inventoryCategoryFilter, //存货类型
    $VoucherSourceFilter:VoucherSourceFilter, //凭证来源
    $tableNameFilter:tableNameFilter, // 税务报表名称
    $getHeight: getHeight,
    $goEtax:goEtax,
    $toLoginTax:toLoginTax,

    $setUpStatusJc:setUpStatusJc,
    $setUpStatusIconJc: setUpStatusIconJc,
    $setUpStatusCj:setUpStatusCj,
    $setUpStatusGsSb:setUpStatusGsSb,
    $setUpStatusLogin:setUpStatusLogin,
    $setUpStatusGsKk:setUpStatusGsKk,
    $setUpStatusGsIconSb:setUpStatusGsIconSb,
    $setUpStatusGsWordSb:setUpStatusGsWordSb,
    $setUpStatusImport:setUpStatusImport,
    $setUpStatusMatch:setUpStatusMatch,
    $getLastDay: getLastDay,//获取账期最后一天
    $newformatDate:newformatDate,
    $queueMessage:queueMessage,
    $cancelMsg:cancelMsg,
    $getCachedViews: getCachedViews,
    $comdify: comdify,
    $alertMsg: alertMsg,
    $batchSendSb:batchSendSb,
    $batchSendIconSb:batchSendIconSb,
    $batchSendWordSb:batchSendWordSb,

    $batchSendKk:batchSendKk,
    $batchSendWordKk:batchSendWordKk,
    $batchSendJc:batchSendJc,
    $batchSendCj:batchSendCj,
    $batchSendIconCj:batchSendIconCj,
    $setUpStatusGsZf:setUpStatusGsZf,
    $setUpStatusGsIconZf:setUpStatusGsIconZf,
    $setUpStatusGsWordZf:setUpStatusGsWordZf,
    $setUpStatusGsGz:setUpStatusGsGz,

    $setUpStatusJzCj:setUpStatusJzCj,
    $setUpStatusJzCjIcon:setUpStatusJzCjIcon,
    
    $yearAcessStatus:yearAcessStatus,
    $yearAcessIconStatus:yearAcessIconStatus,
    $yearAcessWordStatus:yearAcessWordStatus,
    $batchSendYearSb:batchSendYearSb,
    $batchSendBusinessSb:batchSendBusinessSb,

    $cityFilter:cityFilter,
    $copyComName:copyComName, //复制公司名称
    $batchSendGxrz:batchSendGxrz,
    $setUpAllStatusIconJc:setUpAllStatusIconJc,
    $batchSendWordJc:batchSendWordJc,
    $saveBig:saveBig,

    $taskNameType:taskNameType,
    $setUpStatusWsJc:setUpStatusWsJc,

    $accountSystemFilter:accountSystemFilter, //会计准则过滤
    $filterAutoStatusWord:filterAutoStatusWord,//自动记账
    $filterAutoStatusText:filterAutoStatusText,//自动记账
    $filterAutoStatusIcon:filterAutoStatusIcon,//自动记账
    $filterWebsite:filterWebsite,//官网文章类型
    $homeConfirmIcon:homeConfirmIcon,//首页确认状态
    $homeConfirmStatus:homeConfirmStatus,//首页确认状态
  }
})

window.closeAllHostname = 'acc1.yinuojizhang.com'
// 'acc1.yinuojizhang.com'
window.addEventListener('storage', event => {
  if(event.key === 'closeAllWindow') {
    if (window.closeAllHostname.includes(window.location.hostname)) return
    localStorage.removeItem(event.key)
    window.close()
  }
})
window.addEventListener('beforeunload', function () {
  localStorage.removeItem('closeAllWindow')
})
String.prototype.moneyFilter = function(){
  if(this.indexOf('(') > -1){
    let amount = this.replace("(", "").replace(")","")
    return Number((-amount.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
  }else{
    return Number((this.replace(/,/g, "").replace("¥", "").replace("å", "").replace("元", "").replace("￥", "")  * 1).toFixed(2))
  }
}

/**
 * 检测图片是否存在
 * @param url
 */
 let imageIsExist = function(url) {
  return new Promise((resolve) => {
      var img = new Image();
      img.onload = function () {
          if (this.complete == true){
              resolve(true);
              img = null;
          }
      }
      img.onerror = function () {
          resolve(false);
          img = null;
      }
      img.src = url;
  })
}
app.directive('err-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
      let exist = await imageIsExist(imgURL);
      if (exist) {
        el.setAttribute('src', imgURL);
      } 
  }
})
//挂载自定指令
import autoFocus from '@/utils/auto-focus'
app.directive('auto-focus', autoFocus)

//挂载naive-ui
import naive from 'naive-ui'
app.use(naive)
app.use(VXETable)

app.use(component).use(ElementPlus,{locale}).use(store).use(router).mount('#app')