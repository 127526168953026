import request from '@/utils/request'

//业务数据list
export function corpCoopList(data) {
  return request({
      url: 'api/v2/coop/corpCoopList',
      method: 'post',
      data
  })
}

//新增
export function saveCorpCoop(data) {
  return request({
      url: 'api/v2/coop/saveCorpCoop',
      method: 'post',
      data
  })
}

//删除
export function corpCoopDel(data) {
  return request({
      url: 'api/v2/coop/corpCoopDel',
      method: 'post',
      data
  })
}

//自动匹配
export function automaticAddCoop(data) {
  return request({
      url: 'api/v2/coop/automaticAddCoop',
      method: 'post',
      data
  })
}