<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      title="提示"
      width="390px"
      :before-close="handleClose"
    >
      <span style=" font-size: 13px;color: var(--themeColor, #17a2b8); " >
        <!-- 未设置未认证进项税额科目 -->
        <i class="iconfont icon-gantanhao1" style="font-size: 13px"></i >检测到您{{settingMsg}},请点击
      </span>
      <span style="font-size: 13px;color: var(--themeColor, #17a2b8);display:inline-block;">
        <el-button
          icon="Pointer"
          size="small"
          type="primary"
          @click="goSetting"
          >去设置</el-button>
      </span>
      <div style="font-size: 13px;color: var(--themeColor, #17a2b8);">
        请设置后再重试
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" plain @click="dialogVisible = false" size="small">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'settingDialog',
  data() {
    return {
      dialogVisible:false,
      settingMsg:'',
      route:'',
      fromType:'',
    };
  },

  mounted() {
    
  },

  methods: {
    getInit(msg,route){
      this.settingMsg = msg
      this.route = route
      this.dialogVisible = true
    },
    goSetting(){
      // 不用这个了 刷新还在
      // this.$store.dispatch("commons/setParam", { paramSettingFrom: this.fromType });
      // this.$store.dispatch('tagsView/delCachedViewByName', "habbitSettingBook")
      this.$router.push({
        path: this.route,
        name: "habbitSettingBook",
        query: { from: this.settingMsg }
      });
      this.dialogVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>

</style>