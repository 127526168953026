<template>
  <el-drawer
    v-model="drawer"
    title="电子税局任务列表"
    destroy-on-close
    size="1100px"
  >
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <div class="top_select">
        <div>
          <el-button
            icon="RefreshRight"
            size="small"
            style="margin-right: 5px;"
            @click="getList()"
          ></el-button>
          <el-input
            size="small"
            placeholder="报税手机号后四位"
            v-model.trim="listQuery.tel"
            style="width:180px"
            @keyup.enter="getList"
            clearable
          ></el-input>
          <el-button
            size="small"
            type="primary"
            @click="getList"
            style="margin-right:10px"
          >
            <el-icon><Search /></el-icon><span> 搜索</span>
          </el-button>
          <addressList v-model:type="listQuery.address" style="width:90px;margin-left: 0;" @change="getList"></addressList>
          <span class="hint" v-show="activeName == '1'"
            >进行中任务的手机号数量：{{ sbzPhoneCount }}</span
          >
          <span class="hint" v-show="activeName == '2'"
            >排队中任务的手机号数量：{{ pdzPhoneCount }}</span
          >
        </div>
        <div>
          <!-- <el-button size="small" type="danger" plain @click="cancelSbzTask()" v-show="isAdmin == 1 && activeName == '1'">
            <el-icon><List /></el-icon><span  > 撤销任务</span>
          </el-button> -->
          <el-button
            size="small"
            type="primary"
            plain
            @click="updateStatus()"
            v-show="isAdmin == 1 && activeName == '2'"
          >
            <el-icon><Switch /></el-icon><span> 修改状态</span>
          </el-button>
          <el-button
            size="small"
            type="danger"
            plain
            @click="cancelPdzTask()"
            v-show="isAdmin == 1 && activeName == '2'"
          >
            <el-icon><List /></el-icon><span> 撤销任务</span>
          </el-button>
        </div>
      </div>
      <el-tab-pane name="1" label="进行中任务">
        <template #label>
          <span class="custom-tabs-label"> 进行中任务({{ sbzTotal }}) </span>
        </template>
        <el-table
          :data="sbzList"
          style="width: 100%"
          border
          :height="contentStyleObj"
          v-loading="loading"
          @selection-change="handleSbzSelectionChange"
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="公司名称" min-width="240">
            <template #default="scope">
              <div style="display: flex;align-items: center;">
                <div
                  class="item_icon"
                  style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                >
                  <span>{{ scope.row.name }}</span>
                </div>
                <el-tooltip content="复制" placement="top" effect="dark">
                  <i
                    class="iconfont icon-fuzhi1"
                    style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                    @click="$copyComName(scope.row.name)"
                  >
                  </i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="手机号"
            width="120"
            prop="tel"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="任务名称"
            min-width="220"
            show-overflow-tooltip
          >
            <template #default="scope">
              {{ $taskNameType(scope.row.taskName) }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="150">
            <template #default="scope">
              {{ $parseTime(scope.row.createdAt, "{y}-{m}-{d} {h}:{i}:{s}") }}
            </template>
          </el-table-column>
          <el-table-column label="申报时长" min-width="80" prop="sbTime">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <qzf-pagination
            v-show="sbzTotal > 0"
            :total="sbzTotal"
            v-model:page="listQuery.sbzPage"
            v-model:limit="listQuery.sbzPageSize"
            @pagination="getList"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane name="2" label="排队中任务">
        <template #label>
          <span> 排队中任务({{ pdzTotal }}) </span>
        </template>
        <el-table
          :data="pdzList"
          style="width: 100%"
          border
          :height="contentStyleObj"
          v-loading="loading"
          @selection-change="handlePdzSelectionChange"
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column
            align="center"
            prop="id"
            type="selection"
            width="55"
          />
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column prop="name" label="公司名称" min-width="240">
            <template #default="scope">
              <div style="display: flex;align-items: center;">
                <div
                  class="item_icon"
                  style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                >
                  <span>{{ scope.row.name }}</span>
                </div>
                <el-tooltip content="复制" placement="top" effect="dark">
                  <i
                    class="iconfont icon-fuzhi1"
                    style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                    @click="$copyComName(scope.row.name)"
                  >
                  </i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="手机号"
            width="135"
            prop="tel"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="任务名称"
            min-width="220"
            show-overflow-tooltip
          >
            <template #default="scope">
              {{ $taskNameType(scope.row.taskName) }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="80" show-overflow-tooltip>
            <template #default="scope">
              <div>
                <span v-if="scope.row.status == 0" style="color: green;"
                  >正常</span
                >
                <span v-if="scope.row.status == 1" style="color: red;"
                  >暂停</span
                >
                <span style="margin-left: 10px;"
                  ><i
                    class="iconfont icon-bianji23"
                    style="color: #17a2b8;cursor: pointer;"
                    @click="switchStatus(scope.row)"
                  ></i
                ></span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="优先级" width="100" show-overflow-tooltip>
            <template #default="scope">
              <div v-if="scope.row.id">
                <span v-if="scope.row.orgSeq == 0">排队</span>
                <span v-else style="color: green;"
                  >优先排队</span
                >
                <span style="margin-left: 10px;"
                  ><i
                    class="iconfont icon-bianji23"
                    style="color: #17a2b8;cursor: pointer;"
                    @click="switchOrgStatus(scope.row)"
                  ></i
                ></span>
              </div>
              <div v-else>
                等待回执
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" min-width="150">
            <template #default="scope">
              {{ $parseTime(scope.row.createdAt, "{y}-{m}-{d} {h}:{i}:{s}") }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <qzf-pagination
            v-show="pdzTotal > 0"
            :total="pdzTotal"
            v-model:page="listQuery.pdzPage"
            v-model:limit="listQuery.pdzPageSize"
            @pagination="getList"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
  <el-dialog v-model="dialogVisible" title="修改状态" width="450px">
    <div style="color: var(--themeColor,#17a2b8);font-size: 14px;margin-bottom: 10px;"><i class="iconfont icon-gantanhao1"></i>选择暂停仅暂停一小时，之后自动修改为正常</div>
    <el-select
      size="small"
      v-model="temp.status"
      placeholder="请选择状态"
      clearable
      filterable
    >
      <el-option
        v-for="item in option"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="updateSure" size="small"
          >确 定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { sbTaxList, updatePdTax, priorityPdTax,cancelTax } from "@/api/company";
import { quitTask } from "@/api/collection";
import addressList from "@/components/Screening/address";
export default {
  name: "",
  components: {
    addressList
  },
  props: {},
  data() {
    return {
      drawer: false,
      activeName: "1",
      listQuery: {
        tel: "",
        pdzPage: 1,
        pdzPageSize: 20,
        sbzPage: 1,
        sbzPageSize: 20,
      },
      pdzList: [],
      sbzList: [],
      contentStyleObj: {},
      loading: false,
      pdzTotal: 0,
      sbzTotal: 0,
      sbzPhoneCount: 0,
      pdzPhoneCount: 0,
      isAdmin: this.$store.getters["user/user"].isAdmin,
      sbzSels: [],
      pdzSels: [],
      option: [
        { label: "正常", value: 0 },
        { label: "暂停", value: 1 },
      ],
      dialogVisible: false,
      temp: {},
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(230);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList();
    },
    getList() {
      // if (!this.listQuery.tel) {
      //   this.$qzfMessage("请输入报税手机号", 1);
      //   return;
      // }
      // if (this.listQuery.tel && !/^1[3456789]\d{9}$/.test(this.listQuery.tel)) {
      //   this.$qzfMessage("请输入正确的手机号", 1);
      //   return;
      // }
      if (this.listQuery.tel && this.listQuery.tel.length != 4) {
        this.$qzfMessage("请输入报税手机号后四位", 1);
        return;
      }
      this.loading = true;
      sbTaxList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.pdzList = res.data.data.pdz ? res.data.data.pdz : [];
          this.sbzList = res.data.data.sbz ? res.data.data.sbz : [];
          this.pdzTotal = res.data.data.pdzTotal;
          this.sbzTotal = res.data.data.sbzTotal;
          this.sbzPhoneCount = res.data.data.sbzPhoneCount;
          this.pdzPhoneCount = res.data.data.pdzPhoneCount;
        }
      });
    },
    handleSbzSelectionChange(e) {
      this.sbzSels = e;
    },
    handlePdzSelectionChange(e) {
      this.pdzSels = e;
    },
    // cancelSbzTask() {
    //   if (this.sbzSels.length == 0) {
    //     this.$qzfMessage("请选择要撤销的任务", 1);
    //     return;
    //   }
    //   let ids = [];
    //   this.sbzSels.map((v) => {
    //     ids.push(v.id);
    //   });
    //   this.$confirm("确定撤销进行中的任务?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     cancelTax(ids).then((res) => {
    //       if (res.data.msg == "success") {
    //         this.$cancelMsg(res.data.data.list);
    //         this.getList();
    //       }
    //     });
    //   });
    // },
    cancelPdzTask() {
      if (this.pdzSels.length == 0) {
        this.$qzfMessage("请选择要撤销的任务", 1);
        return;
      }
      let ids = [];
      this.pdzSels.map((v) => {
        ids.push(v.id);
      });
      this.$confirm("确定撤销排队中的任务?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelTax({ids:ids}).then((res) => {
          if (res.data.msg == "success") {
            this.$cancelMsg(res.data.data.list);
            this.getList();
          }
        });
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.props.name;
    },
    switchStatus(row) {
      let str = row.status == 0 ? "确定将此任务改为暂停吗？仅暂停一小时，之后自动修改为正常" : "确定将此任务改为正常吗？";
      let type = row.status == 0 ? 1 : 0;
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          reqNo: [row.reqNo],
          status: type,
        };
        updatePdTax(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.getList();
          }
        });
      });
    },
    switchOrgStatus(row) {
      let str = row.orgSeq == 0 ? "优先处理" : "取消优先";
      let type = row.orgSeq == 0 ? 1 : 0;
      this.$confirm(`确定${str}此任务吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          reqNo: row.reqNo,
          status: type,
        };
        priorityPdTax(param).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.getList();
          }
        });
      });
    },
    //批量修改状态
    updateStatus() {
      if (this.pdzSels.length == 0) {
        this.$qzfMessage("请选择任务", 1);
        return;
      }
      let ids = [];
      this.pdzSels.map((v) => {
        ids.push(v.reqNo);
      });
      this.temp.reqNo = ids;
      this.temp.status = 0;
      this.dialogVisible = true;
    },
    updateSure() {
      updatePdTax(this.temp).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("操作成功");
          this.dialogVisible = false;
          this.getList();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.hint {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
}
.icon-gantanhao1{
  margin-right: 5px;
  font-size: 15px;
}
</style>
