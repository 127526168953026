import request from '@/utils/request'

// 员工导出
export function exportSalary(data) {
  return request({
    url: 'api/v2/export/salary',
    method: 'post',
    data
  })
}

// 进项发票导出
export function exportReceiptOfReceipts(data) {
  return request({
    url: 'api/v2/export/exportReceiptOfReceipts',
    method: 'post',
    data
  })
}


// 销项发票导出
export function exportInvoice(data) {
  return request({
    url: 'api/v2/export/exportInvoice',
    method: 'post',
    data
  })
}

// 库存汇总表导出
export function inventorySummarySheet(data) {
  return request({
    url: 'api/v2/export/inventorySummarySheet',
    method: 'post',
    data
  })
}

// 出入库明细导出
export function inOutInventorySummarySheet(data) {
  return request({
    url: 'api/v2/export/inOutInventorySummarySheet',
    method: 'post',
    data
  })
}

// 资产导出
export function exportFixedAssets(data) {
  return request({
    url: 'api/v2/export/exportFixedAssets',
    method: 'post',
    data
  })
}


//未认证进项导出
export function exportInInvoiceWzz(data) {
  return request({
    url: 'api/v2/export/exportInInvoiceWzz',
    method: 'post',
    data
  })
}


//org导出
export function exportOrgUser(data) {
  return request({
    url: 'api/v2/export/exportOrgUser',
    method: 'post',
    data
  })
}

//暂估入库明细表导出
export function estimateSheet(data) {
  return request({
    url: 'api/v2/export/estimateSheet',
    method: 'post',
    data
  })
}

//账套备份
export function accountBackups(data) {
  return request({
    url: 'api/v2/export/accountBackups',
    method: 'post',
    data
  })
}

//申报导出
export function declarationModuleExport(data) {
  return request({
    url: 'api/v2/export/declarationModuleExport',
    method: 'post',
    data
  })
}

//银行导出
export function inOutBankList(data) {
  return request({
    url: 'api/v2/export/inOutBankList',
    method: 'post',
    data
  })
}

//汇率调整打印导出
export function wbVoucherFxOther(data) {
  return request({
    url: 'api/v2/export/other',
    method: 'post',
    data
  })
}

//取数结账处导出
export function bulkExportMq(data) {
  return request({
    url: 'api/v2/export/bulkExportMq',
    method: 'post',
    data
  })
}

//薪酬汇总表导出
export function salaryHzSheet(data) {
  return request({
    url: 'api/v2/export/salaryHzSheet',
    method: 'post',
    data
  })
}

//首页客户导出
export function getIndexEtax2Print(data) {
  return request({
    url: 'api/v2/export/getIndexEtax2Print',
    method: 'post',
    data
  })
}

//批量修改导出客户
export function commonTemplate(data) {
  return request({
    url: 'api/v2/export/commonTemplate',
    method: 'post',
    data
  })
}

//核算导出
export function inventoryExport(data) {
  return request({
    url: 'api/v2/export/inventoryExport',
    method: 'post',
    data
  })
}

//单据统计导出
export function djtj(data) {
  return request({
    url: 'api/v2/export/djtj',
    method: 'post',
    data
  })
}

// 任务统计导出

export function exportBTax(data) {
  return request({
    url: 'api/v2/m_crm/exportBTax',
    method: 'post',
    data
  })
}

//贸易核算表导出
export function tradeCheckSheet(data) {
  return request({
    url: 'api/v2/export/tradeCheckSheet',
    method: 'post',
    data
  })
}
//生产核算方法导出
export function produceCheckSheet(data) {
  return request({
    url: 'api/v2/export/produceCheckSheet',
    method: 'post',
    data
  })
}
//委托核算方法导出
export function processCheckSheet(data) {
  return request({
    url: 'api/v2/export/processCheckSheet',
    method: 'post',
    data
  })
}
//工程核算方法导出
export function projectCheckSheet(data) {
  return request({
    url: 'api/v2/export/projectCheckSheet',
    method: 'post',
    data
  })
}
//服务核算方法导出
export function fwCheckSheet(data) {
  return request({
    url: 'api/v2/export/fwCheckSheet',
    method: 'post',
    data
  })
}

//账套迁移导出
export function exportMoveCompany(data) {
  return request({
    url: 'api/v2/export/exportMoveCompany',
    method: 'post',
    data
  })
}

//社保申报导出
export function companyShebaoListExport(data) {
  return request({
    url: 'api/v2/export/companyShebaoListExport',
    method: 'post',
    data
  })
}

//Ai财务工厂导出
export function aiExportAssignCustomers(data) {
  return request({
    url: 'api/v2/export/aiExportAssignCustomers',
    method: 'post',
    data
  })
}

//期初数据导出
export function exportQiChu(data) {
  return request({
    url: 'api/v2/export/exportQiChu',
    method: 'post',
    data
  })
}

//现金导出
export function exportCash(data) {
  return request({
    url: 'api/v2/export/exportCash',
    method: 'post',
    data
  })
}