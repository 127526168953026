<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" width="800px" :title="title" append-to-body>
    <el-scrollbar :maxHeight="contentStyleObj">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="right" :inline="true" label-width="120px">
        <div class="common_dialog_box clearfix">
          <div class="common_title" v-if="type == 1 || type == 2">资产信息</div>
          <div class="common_title" v-else>信息</div>
          <div class="item_one clearfix">
            <el-form-item v-if="type == 1 || type == 2" label="资产名称:" prop="name">
              <el-input v-model="temp.name" style="width:200px;" size="small" placeholder="请输入资产名称"/>
            </el-form-item>
            <el-form-item  label="单位:" prop="unit">
              <el-input v-model="temp.unit" style="width:200px;" size="small" placeholder="请输入单位"/>
            </el-form-item>  
            <el-form-item  label="规格型号:" prop="spec">
              <el-input v-model="temp.spec" style="width:200px;" size="small" placeholder="请输入规格型号"/>
            </el-form-item>  
            <el-form-item v-if="tyname == 'xzjx'" label="原值科目:" prop="yzSubjectId">
              <!-- <el-input v-model="temp.subjects" style="width:90%;" size="small" /> -->
              <subject-list v-if="this.temp.type == 1" v-model:subjectId="this.temp.yzSubjectId" v-model:fzhsItemId="yzFzhsId" :codes="$findCode(['code1601'],comId)" style="width:200px;"></subject-list>
              <subject-list v-if="this.temp.type == 2" v-model:subjectId="this.temp.yzSubjectId" v-model:fzhsItemId="yzFzhsId" :codes="$findCode(['code1701'],comId)" style="width:200px;"></subject-list>
              <subject-list v-if="this.temp.type == 3" v-model:subjectId="this.temp.yzSubjectId" v-model:fzhsItemId="yzFzhsId" :codes="$findCode(['code1801'],comId)" style="width:200px;"></subject-list>
            </el-form-item>
            <el-form-item v-if="type == 3" label="费用名称:" prop="name">
              <el-input v-model="temp.name" style="width:200px;" size="small" />
            </el-form-item>
            <el-form-item v-if="type == 1" label="资产类别:" prop="assetType">
              <el-select v-model="temp.assetType" placeholder="请选择资产类别" style="width:200px;" size="small" @change="changeAssetType">
                <el-option label="房屋、建筑物" value="房屋、建筑物"></el-option>
                <el-option label="飞机、火车、轮船、机器、机械和其他生产设备" value="飞机、火车、轮船、机器、机械和其他生产设备"></el-option>
                <el-option label="生产经营活动有关的器具、工具、家具" value="生产经营活动有关的器具、工具、家具"></el-option>
                <el-option label="飞机、火车、轮船以外的运输工具" value="飞机、火车、轮船以外的运输工具"></el-option>
                <el-option label="电子设备" value="电子设备"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="type == 2" label="资产类别:" prop="assetType">
              <el-select v-model="temp.assetType" placeholder="请选择资产类别" style="width:200px;" size="small">
                <el-option label="专利权" value="专利权"></el-option>
                <el-option label="非专利技术" value="非专利技术"></el-option>
                <el-option label="商标权" value="商标权"></el-option>
                <el-option label="著作权" value="著作权"></el-option>
                <el-option label="土地使用权" value="土地使用权"></el-option>
                <el-option label="商誉" value="商誉"></el-option>
                <el-option label="软件" value="软件"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="type == 3" label="类别:" prop="assetType">
              <el-select v-model="temp.assetType" placeholder="请选择类别" style="width:200px;" size="small">
                <el-option label="开办费" value="开办费"></el-option>
                <el-option label="固定资产修理支出" value="固定资产修理支出"></el-option>
                <el-option label="租入固定资产的改良支出" value="租入固定资产的改良支出"></el-option>
                <el-option label="已足额折旧资产改建" value="已足额折旧资产改建"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数量:" prop="count">
              <el-input v-model="temp.count" style="width:200px;" size="small"/>
            </el-form-item>
            <el-form-item label="入账日期:" prop="transDate">
              <el-date-picker :disabled="disabled" v-model="temp.transDate" value-format="YYYY-MM-DD" type="date" placeholder="选择日期时间" style="width:200px;" size="small" :disabled-date="disabledDate"></el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="录入日期:" prop="beginPeriod">
              <el-date-picker v-model="temp.beginPeriod" type="date" placeholder="选择日期时间" style="width:200px;" size="small"></el-date-picker>
            </el-form-item> -->
            <el-form-item prop="depreciationMethod" :label="type == 1?'折旧方法:':'摊销方法:'">
              <el-select v-model="temp.depreciationMethod" :placeholder="type == 1 ?'折旧方法':'摊销方法'" style="width:200px;" size="small">
                <el-option label="平均年限法" value="平均年限法"></el-option>
                <!-- <el-option label="工作量法" value="工作量法"></el-option>
                <el-option label="双倍余额递减法" value="双倍余额递减法"></el-option>
                <el-option label="年数总和法" value="年数总和法"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item v-if="type == 3 || type == 2" label="摊销方式:" prop="thisNext">
              <el-select v-model="temp.thisNext" placeholder="摊销方式" style="width:200px;" size="small">
                <el-option label="本月摊销" :value='1'></el-option>
                <el-option label="次月摊销" :value='2'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="depreciationPeriods" :label="type == 1?'折旧期限:':'摊销期限:'">
              <el-input v-model="temp.depreciationPeriods" style="width:200px;" size="small" placeholder="请输入折旧期限"/><span style="margin-left:5px">(月)</span>
            </el-form-item>
          
          </div>
        </div>
        <div class="common_dialog_box clearfix">
          <div class="common_title">入账科目</div>
          <div class="item_one clearfix">
            <el-form-item :label="type == 1?'折旧费用科目:':'成本费用科目:'" prop="fySubjectId">
              <subject-list v-model:subjectId="temp.fySubjectId" v-model:fzhsItemId="temp.fyFzhsId" style="width:200px;"></subject-list>
            </el-form-item>
            <el-form-item :label="type == 1?'累计折旧科目:':'累计摊销科目:'" prop="subjectId">
              <subject-list v-model:subjectId="temp.subjectId" v-model:fzhsItemId="temp.fzhsId" style="width:200px;"></subject-list>
            </el-form-item>
          </div>
        </div>
        <div class="common_dialog_box clearfix">
          <div class="common_title">原值、净值（本月折旧/摊销列无需填写）</div>
          <div class="item_one clearfix">
            <el-form-item label="原值:" prop="originalValue">
              <el-input v-model="temp.originalValue" style="width:200px;" size="small"/>
            </el-form-item>
            <el-form-item prop="initLjDepreciation" :label="type == 1?'期初累计折旧:':'期初累计摊销:'">
              <el-input v-model="temp.initLjDepreciation" style="width:200px;" size="small"/>
            </el-form-item>
            <el-form-item :label="type == 1?'本月折旧:':'本月摊销:'">
              <el-input v-model="temp.amortizeAmount" style="width:200px;" size="small"/>
            </el-form-item>
            <el-form-item v-if="type == 1" label="残值率(小数):" prop="rmValueRate">
              <el-input v-model="temp.rmValueRate" style="width:200px;" size="small"/>
              &nbsp;&nbsp;(小数) 
              <el-tooltip content="示例：0.05" placement="top">
                <i class="iconfont icon-wenhao" style="color:red;cursor: pointer;font-size:13px;position:absolute;right:-15px"></i>
              </el-tooltip>
            </el-form-item>
            <!-- <el-form-item v-if="type == 1" label="残值率:" prop="rmValueRateTemp">
              <el-input v-model="temp.rmValueRateTemp" style="width:200px;" size="small"/>&nbsp;%
            </el-form-item> -->
            <el-form-item label="状态:" prop="status">
              <el-select v-model="temp.status" placeholder="请选择类别" style="width:200px;" size="small">
                <el-option label="正常" :value="1"></el-option>
                <el-option label="暂停" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="temp.remark" style="width:200px;" size="small" placeholder="请输入备注"/>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-scrollbar>
    
    <template #footer>
      <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="createData()" size="small">确定</el-button>
      <!-- <qzf-button type="primary" @click="dialogStatus==='create'?createData():updateData()" size="small">确定</qzf-button> -->
    </template>

  </el-dialog>
</template>

<script>
import { saveAsset } from '@/api/assets'
export default {
  name:'addAssets',
  props:{
    type:{
      type:Number,
      default:1
    },
    tyname: {
      type: String,
      default:''
    },
    title: {
      type: String,
      default:''
    },
    comId:{
      type:Number,
      default:0
    },
    period:{
      type: String,
      default:''
    }
  },
  watch: {
    dialogFormVisible(val){
      console.log(this.type, '222222222222222222')
    }
  },
  data() {
    return {
      dialogFormVisible:false,
      textMap: {
        update: '修改',
        create: '新增'
      },
      disabled: false,
      temp:{},
      dialogStatus:'',
      disabledDate: (time) => {
        let date = this.period ? this.period : this.$store.getters['user/comInfo'].period
        return time >= new Date(this.$getLastDay(date)).getTime()
      },
      rules: {
        name: [{ required: true, message: '资产名称不能为空', trigger: 'change' }],
        assetType: [{ required: true, message: '请选择资产类型', trigger: 'change' }],
        count: [{ required: true, message: '数量不能为空', trigger: 'change' }],
        transDate: [{ required: true, message: '请选择入账时间', trigger: 'change' }],
        beginPeriod: [{ required: true, message: '请选择录入日期', trigger: 'change' }],
        depreciationMethod: [{ required: true, message: '请选择折旧方法', trigger: 'change' }],
        depreciationPeriods: [{ required: true, message: '折旧期限不能为空', trigger: 'change' }],
        rmValueRate: [{ required: true, message: '残值率不能为空', trigger: 'change' }],
        // rmValueRateTemp: [{ required: true, message: '残值率不能为空', trigger: 'change' }],
        amortizeAmount: [{ required: true, message: '请输入本月折旧', trigger: 'change' }],
        originalValue: [{ required: true, message: '请输入原值', trigger: 'change' }],
        initLjDepreciation: [{ required: true, message: '请输入期初累计折旧', trigger: 'change' }],
        subjectId: [{ required: true, message: '请输入累计折旧科目', trigger: 'change' }],
        fySubjectId: [{ required: true, message: '请输入折旧费用科目', trigger: 'change' }],
        thisNext:  [{ required: true, message: '请选择摊销方式', trigger: 'change' }],
        status: [{ required: true, message: '请选择摊销方式', trigger: 'change' }],
        yzSubjectId: [{ required: true, message: "请选择原值科目", trigger: "change" }],
      },
      yzSubjectId: 0,
      yzFzhsId: 0,
      contentStyleObj:{}
    }
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(260)
  },
  created() {
  },
  methods:{
    resetTemp() {
      this.temp = {
        type:this.type,
        status: null,
        name: '',
        assetType:'',
        count: 0,
        transDate:'',
        beginPeriod:'',
        depreciationMethod:'平均年限法',
        depreciationPeriods: "",
        initLjDepreciation: 0,
        rmValueRate:0,
        // rmValueRateTemp:0,
        amortizeAmount: 0,
        originalValue: 0,
        thisNext:'',
        fzhsId:'',
        yzFzhsId: 0,
        spec:""
      };
    },
    openDialog(row,typexsjx,glzcType){
      this.dialogFormVisible = true
      this.disabled = false
      this.resetTemp()
      if(typexsjx){
        this.temp.type = typexsjx
      }
      if(row && glzcType != "glzc"){
        this.temp = row
        this.disabled = true
      }else if(row){
        this.temp = row
      }
      // if(this.temp.rmValueRate){
      //   this.temp.rmValueRateTemp = this.temp.rmValueRate*100
      // }else{
      //   this.temp.rmValueRateTemp = 0
      // }
    },
    changeAssetType(){
      if(this.temp.assetType == '房屋、建筑物'){
        this.temp.depreciationPeriods = '240'
      }else if(this.temp.assetType == '飞机、火车、轮船、机器、机械和其他生产设备'){
        this.temp.depreciationPeriods = '120'
      }else if(this.temp.assetType == '生产经营活动有关的器具、工具、家具'){
        this.temp.depreciationPeriods = '60'
      }else if(this.temp.assetType == '飞机、火车、轮船以外的运输工具'){
        this.temp.depreciationPeriods = '48'
      }else if(this.temp.assetType == '电子设备'){
        this.temp.depreciationPeriods = '36'
      }else if(this.temp.assetType == '其他'){
        this.temp.depreciationPeriods = '12'
      }
    },
    createData(){
      console.log(Number(this.temp.originalValue));
      if(isNaN(Number(this.temp.originalValue))){
        this.$qzfMessage("请输入正确的原值",1)
        return
      }else{
        if(Number(this.temp.originalValue) == 0){
          this.$qzfMessage("填写的原值应大于0",1)
          return
        }
      }
      if(this.temp.amortizeAmount && isNaN(Number(this.temp.amortizeAmount))){
        let str = this.type == 1 ? "请输入正确的本月折旧" : "请输入正确的本月摊销"
        this.$qzfMessage(str,1)
        return
      }
      if(isNaN(Number(this.temp.initLjDepreciation))){
        let text = this.type == 1 ? "请输入正确的期初累计折旧" : "请输入正确的期初累计摊销"
        this.$qzfMessage(text,1)
        return
      }
      if(isNaN(Number(this.temp.rmValueRate)) && this.type == 1){
        this.$qzfMessage("请输入正确的残值率",1)
        return
      }
      // if(!Number(this.temp.rmValueRateTemp)){
      //   this.$qzfMessage("请输入正确的残值率",1)
      //   return
      // }
      this.temp.status = Number(this.temp.status)
      this.temp.thisNext = Number(this.temp.thisNext)
      this.temp.count = this.temp.count*1
      this.temp.rmValueRate = this.temp.rmValueRate*1
      // this.temp.rmValueRate = (this.temp.rmValueRateTemp*1/100).toFixed(2)*1
      this.temp.amortizeAmount = this.temp.amortizeAmount?this.temp.amortizeAmount*1:0
      this.temp.originalValue = this.temp.originalValue*1
      this.temp.initLjDepreciation = this.temp.initLjDepreciation*1
      this.yzSubjectId = this.temp.yzSubjectId
      //console.log(this.$parseTime("2023-07-08","{y}-{m}-{d}"));
      if(this.temp.transDate.length > 15){
        this.temp.transDate = this.$parseTime(this.temp.transDate,"{y}-{m}-{d}")
      }
      // this.$emit("success", this.yzSubjectId + "-" + this.yzFzhsId)
      // return
      this.$refs['dataForm'].validate((valid) => {
        if(valid) {
          if(this.comId && this.period){
            this.temp.comId = this.comId
            this.temp.period = this.period
          }
          saveAsset(this.temp).then(res => {
            if(res.data.msg == "success"){
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.dialogFormVisible = false
              this.$emit("success", this.yzSubjectId + "-" + this.yzFzhsId)
              this.resetTemp()
              this.$bus.emit('addIncome')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select{
  margin-left: 0px;
  margin-right: 0px;
}
.common_dialog_box  .item{
  // margin-bottom:20px
}
.common_dialog_box .common_title {
  text-align: left;
}
.item_one {
  display: flex;
  flex-flow: wrap;
}
</style>