import request from '@/utils/request'

//科目列表
export function findSubjects(data) {
  return request({
      url: 'api/v2/subject/findSubjects',
      method: 'post',
      data
  })
}

//会计科目列表
export function subjectTreeList(data) {
  return request({
      url: 'api/v2/subject/subjectTreeList',
      method: 'post',
      data
  })
}

//新增修改
export function saveSubject(data) {
  return request({
      url: 'api/v2/subject/saveSubject',
      method: 'post',
      data
  })
}

//删除
export function deleteEaSubject(data) {
  return request({
      url: 'api/v2/subject/deleteEaSubject',
      method: 'post',
      data
  })
}

//v3的会计科目删除
export function deleteEaSubjectV3(data) {
  return request({
      url: 'api/v2/subject/deleteEaSubjectV3',
      method: 'post',
      data
  })
}

//v3的会计科目删除
export function deleteMoveSubjectV3(data) {
  return request({
      url: 'api/v2/subject/deleteMoveSubjectV3',
      method: 'post',
      data
  })
}

// 辅助核算项目列表
export function fzhsList(data) {
  return request({
      url: 'api/v2/subject/fzhsList',
      method: 'post',
      data
  })
}

// 辅助核算项目明细列表
export function fzhsItemList(data) {
  return request({
      url: 'api/v2/subject/fzhsItemList',
      method: 'post',
      data
  })
}

// 新增 修改 辅助核算明细
export function fzhsItemSave(data) {
  return request({
      url: 'api/v2/subject/fzhsItemSave',
      method: 'post',
      data
  })
}

// 辅助核算明细删除
export function fzhsItemDel(data) {
  return request({
      url: 'api/v2/subject/fzhsItemDel',
      method: 'post',
      data
  })
}

// 会计科目 绑定 辅助核算
export function subjectBindFzhs(data) {
  return request({
      url: 'api/v2/subject/subjectBindFzhs',
      method: 'post',
      data
  })
}
// 会计科目 取消绑定 辅助核算
export function subjectCancelBindFzhs(data) {
  return request({
      url: 'api/v2/subject/subjectCancelBindFzhs',
      method: 'post',
      data
  })
}

// 辅助核算新增时生成code
export function newFzhsCode(data) {
  return request({
      url: 'api/v2/subject/newFzhsCode',
      method: 'post',
      data
  })
}

// 科目余额表
export function subjectBalanceList(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceList',
      method: 'post',
      data
  })
}

// 辅助项目余额表
export function fzhsBalanceList(data) {
  return request({
      url: 'api/v2/subject/fzhsBalanceList',
      method: 'post',
      data
  })
}

// 绑定外币
export function subjectWbEdit(data) {
  return request({
      url: 'api/v2/subject/subjectWbEdit',
      method: 'post',
      data
  })
}

// 外币列表
export function wbList(data) {
  return request({
      url: 'api/v2/subject/wbList',
      method: 'post',
      data
  })
}

//科目余额修改保存
export function subjectBalanceSave(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceSave',
      method: 'post',
      data
  })
}
//辅助核算新增
export function fzhsSave(data) {
  return request({
      url: 'api/v2/subject/fzhsSave',
      method: 'post',
      data
  })
}

//辅助核算删除
export function fzhsDel(data) {
  return request({
      url: 'api/v2/subject/fzhsDel',
      method: 'post',
      data
  })
}

//查看采集的科目余额表
export function subjectCollectCheck(data) {
  return request({
      url: 'api/v2/subject/subjectCollectCheck',
      method: 'post',
      data
  })
}


//查看采集的员工
export function empCollectCheck(data) {
  return request({
      url: 'api/v2/subject/empCollectCheck',
      method: 'post',
      data
  })
}


//查看采集的资产
export function assetCollectCheck(data) {
  return request({
      url: 'api/v2/subject/assetCollectCheck',
      method: 'post',
      data
  })
}

//查看采集的存货
export function inventoryCollectCheck(data) {
  return request({
      url: 'api/v2/subject/inventoryCollectCheck',
      method: 'post',
      data
  })
}

//批量删除最下级科目
export function delSubjectAllLower(data) {
  return request({
      url: 'api/v2/subject/delSubjectAllLower',
      method: 'post',
      data
  })
}

//v3修改期初科目余额表
export function subjectBalanceInitList(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceInitList',
      method: 'post',
      data
  })
}

//v3删除期初
export function deleteEaSubjectFromOut(data) {
  return request({
      url: 'api/v2/subject/deleteEaSubjectFromOut',
      method: 'post',
      data
  })
}

//v3修改初始科目余额表
export function subjectBalanceInitLastList(data) {
  return request({
      url: 'api/v2/subject/subjectBalanceInitLastList',
      method: 'post',
      data
  })
}


//辅助核算单独删除
export function fzhsItemDelSingle(data) {
  return request({
      url: 'api/v2/subject/fzhsItemDelSingle',
      method: 'post',
      data
  })
}

//辅助核算单独删除失败 绑定科目-->确定删除
export function deleteMoveFzhs(data) {
  return request({
      url: 'api/v2/subject/deleteMoveFzhs',
      method: 'post',
      data
  })
}

//禁用
export function stopUseSubject(data) {
  return request({
      url: 'api/v2/subject/stopUseSubject',
      method: 'post',
      data
  })
}

//启用
export function startUseSubject(data) {
  return request({
      url: 'api/v2/subject/startUseSubject',
      method: 'post',
      data
  })
}

//期初数据导入会计科目
export function subjectBalanceInit(formdata) {
  return request({
      url: 'api/v2/subject/subjectBalanceInit',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}