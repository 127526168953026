<template>
  <router-view />
</template>
<script></script>
<style lang="scss">
#app {
  color: #2c3e50;
  --bigLogo:url('https://file.listensoft.net/mnt/v2/html/pics/jsplogo.svg');
  --smallLogo:url('https://file.listensoft.net/mnt/v2/html/pics/jsplogo1.svg');
  --menuBg:url('https://file.listensoft.net/mnt/v2/html/pics/menuBg.svg')
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@import "./assets/font/font.css";
body{
  font-family: Mysh !important;
}
// 主题色变量
:root{
  --themeColor: #17a2b8; //主题色
  --themeColorLeftMenu: #ecf7f9; //主题浅色
  --themeColorPlain: #f0f9f8; //主题浅色按钮
  --themeColorPlainBc: #c3e5e3; //主要色按钮plain的边框
  --themeColorHeaderBg:#304456;//header背景色
  --themeColorMenuBg:#384e63;//header选中颜色
  --themeColorMenuBottom:#17a2b8;//border颜色
  --themeColorBackground:#d9d9d9;//页面背景色
  --themeColorStepBack:#17a2b833;//步骤条背景色
  }
</style>

