<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogFormVisible"
    title="打印任务"
    width="75%"
    style="margin-top: 10vh;"
  >
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入公司名/文件名"
          v-model="listQuery.likeField"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
      </div>
      <div>
        <el-button
          type="danger"
          size="small"
          plain
          :disabled="sels.length == 0"
          @click="del"
          >删除</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      v-loading="loading"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column type="index" label="序号" width="55" align="center" />
      <!-- <el-table-column
        prop="fileIcon"
        label="文件类型"
        min-width="80"
        align="left"
      >
        <template #default="scope">
          <div v-if="scope.row.fileIcon == 'pdf'">
            <i class="iconfont icon-pdf" style="color:red"></i>
          </div>
          <div v-if="scope.row.fileIcon == 'excel'">
            <i class="iconfont icon-Excel" style="color: rgb(13, 197, 13);"></i>
          </div>
          <div v-if="scope.row.fileIcon == 'pdf+zip'">
            <i class="iconfont icon-pdf" style="color:red"></i>
            <i
              class="iconfont icon-zip"
              style="color: orange;margin-left: 5px;"
            ></i>
          </div>
          <div v-if="scope.row.fileIcon == 'excel+zip'">
            <i class="iconfont icon-Excel" style="color: rgb(13, 197, 13);"></i>
            <i
              class="iconfont icon-zip"
              style="color: orange;margin-left: 5px;"
            ></i>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="comName"
        label="公司名"
        min-width="250"
        align="left"
      >
      </el-table-column>
      <el-table-column
        prop="fileName"
        label="生成内容"
        min-width="150"
        align="left"
      >
      </el-table-column>

      <el-table-column
        prop="status"
        label="任务状态"
        min-width="150"
        align="left"
      >
        <template #default="scope">
          <div class="item_icon" v-if="scope.row.status == 2">
            <div v-if="scope.row.fileIcon == 'pdf'">
              <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);"></i>
            </div>
            <div v-if="scope.row.fileIcon == 'excel'">
              <i class="iconfont icon-Excel" style="color: rgb(13, 197, 13);"></i>
            </div>
            <div v-if="scope.row.fileIcon == 'pdf+zip'">
              <i class="iconfont icon-pdf" style="color: rgb(13, 197, 13);"></i>
              <i
                class="iconfont icon-zip"
                style="color: rgb(13, 197, 13);;margin-left: 5px;"
              ></i>
            </div>
            <div v-if="scope.row.fileIcon == 'excel+zip'">
              <i class="iconfont icon-Excel" style="color: rgb(13, 197, 13);"></i>
              <i
                class="iconfont icon-zip"
                style="color: rgb(13, 197, 13);;margin-left: 5px;"
              ></i>
            </div>
            <div v-if="scope.row.fileIcon == 'voucherImage'">
              <i class="iconfont icon-duihao" style="color: rgb(13, 197, 13);"></i>
            </div>
            <p>{{ loadStatus(scope.row.status) }}</p>
          </div>
          <el-tooltip
           effect="dark"
            placement="top"
            v-else-if="scope.row.status == 3"
          >
          <template #content> <div v-html="scope.row.errLog"></div></template>
            <div class="item_icon">
              <i class="iconfont icon-cuowu"></i>
              <p>{{ loadStatus(scope.row.status) }}</p>
            </div>
          </el-tooltip>
          <el-tooltip
           effect="dark"
            placement="top"
            v-else-if="scope.row.status == 4"
          >
            <template #content>
              <div style="max-width: 800px" v-html="scope.row.errLog">
              </div>
            </template>
            <div class="item_icon">
              <i class="iconfont icon-duihao" style="color:orange"></i>
              <p>{{ loadStatus(scope.row.status) }}</p>
            </div>
          </el-tooltip>
          <div class="item_icon" v-else>
            <i class="iconfont icon-info"></i>
            <p>{{ loadStatus(scope.row.status) }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" label="操作" width="120">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            plain
            @click="check(scope.row)"
            v-if="scope.row.fileIcon == 'pdf'"
            :disabled="scope.row.status != 2 && scope.row.status != 4"
            icon="Printer"
            >打印预览</el-button
          >
          <el-button
            type="primary"
            size="small"
            plain
            @click="check(scope.row)"
            v-else-if="scope.row.fileIcon != 'pdf' && scope.row.fileIcon != 'voucherImage'"
            :disabled="scope.row.status != 2 && scope.row.status != 4"
            icon="Download"
            >点击下载</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="left" prop="time" label="时间" min-width="150">
        <template #default="scope">
          <div>{{ $parseTime(scope.row.downloadedAt) }}</div>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="dialogFormVisible = false"
          size="small"
        >
          确 定
        </el-button>
      </span>
    </template>
    <div class="pagination" style="margin-top: 5px">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>
  </el-dialog>
</template>

<script>
import { getDownloadList, delDownLoad } from "@/api/printSet";
export default {
  name: "download",
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
      contentStyleObj: {}, //高度变化
      sels: [],
      listQuery: {
        page: 1,
        limit: 20,
        likeField: "",
      },
      total: 0,
      loading: false,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(350);
  },
  methods: {
    init() {
      this.dialogFormVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getDownloadList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.data && res.data.data.length != 0) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
          // let a = "";
          // this.tableData.map((v) => {
          // if (v.filePath) {
          //   a = v.filePath.substring(
          //     v.filePath.length - 3,
          //     v.filePath.length
          //   );
          // if (a == "zip") {
          //   v.fileIcon = v.fileIcon + "+zip";
          // }
          // }
          // });
        }
      });
    },
    check(row) {
      window.open(row.filePath);
    },
    loadStatus(e) {
      if (e == 0) {
        return "排队中";
      } else if (e == 1) {
        return "生成中";
      } else if (e == 2) {
        return "生成完成";
      } else if (e == 3) {
        return "生成失败";
      } else if (e == 4) {
        return "部分成功";
      }
    },
    handleSelectionChange(e) {
      this.sels = e;
    },
    del() {
      this.$confirm("确定删除" + this.sels.length + "条记录吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        this.sels.map((item) => {
          ids.push(item.id);
        });
        delDownLoad({
          ids: ids,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("成功删除");
            this.getList();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon {
  //display: inline-block;
  display: flex;
  align-items: center;
  i {
    display: inline-block;
    font-size: 13px;
  }
  .iconfont icon-cuowu {
    color: #f56c6c;
  }
  .iconfont icon-duihao {
    color: #67c23a;
  }
  .iconfont icon-cuowu {
    color: #e6a23c;
  }
  .iconfont icon-info {
    color: #409eff;
  }
  p {
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
    margin-left: 3px;
  }
}
</style>
