import request from '@/utils/request'

// 首页echarts数据
export function indexEtax(data) {
  return request({
    url: 'api/v2/home/indexEtax',
    method: 'post',
    data
  })
}

export function indexEtax2(data) {
  return request({
    url: 'api/v2/home/indexEtax2',
    method: 'post',
    data
  })
}

export function indexTax(data) {
  return request({
    url: 'api/v2/home/indexTax',
    method: 'post',
    data
  })
}

export function getOutStatistics(data) {
  return request({
    url: 'api/v2/home/getOutStatistics',
    method: 'post',
    data
  })
}

export function outStatisticsSave(data) {
  return request({
    url: 'api/v2/home/outStatisticsSave',
    method: 'post',
    data
  })
}

//红包
export function removeRedEnvelopes(data) {
  return request({
    url: 'api/v2/sys/removeRedEnvelopes',
    method: 'post',
    data
  })
}

//v3账簿内首页
export function indexTax2(data) {
  return request({
    url: 'api/v2/home/indexTax2',
    method: 'post',
    data
  })
}

//操作日志
export function operationLog(data) {
  return request({
    url: 'api/v2/home/operationLog',
    method: 'post',
    data
  })
}

//季度销售额/税款统计
export function indexTaxItem(data) {
  return request({
    url: 'api/v2/home/indexTaxItem',
    method: 'post',
    data
  })
}


//首页记账状态报税状态
export function getIndexAccounting(data) {
  return request({
    url: 'api/v2/home/getIndexAccounting',
    method: 'post',
    data
  })
}

//首页发票银行list
export function cjCompanyList(data) {
  return request({
    url: 'api/v2/home/cjCompanyList',
    method: 'post',
    data
  })
}

//首页薪酬list
export function companySalaryList(data) {
  return request({
    url: 'api/v2/home/companySalaryList',
    method: 'post',
    data
  })
}

//首页确认
export function ackCompany(data) {
  return request({
    url: 'api/v2/home/ackCompany',
    method: 'post',
    data
  })
}

//首页统计数
export function indexEtax3(data) {
  return request({
    url: 'api/v2/home/indexEtax3',
    method: 'post',
    data
  })
}

//单据确认各统计数
export function sureTotal(data) {
  return request({
    url: 'api/v2/home/sureTotal',
    method: 'post',
    data
  })
}