<template>
  <el-button
    size="small"
    :plain="link ? false : true"
    type="warning"
    @click="InTax"
    :loading="btnLoading"
    :disabled="!$buttonStatus('jzkh_jrsj')"
    :icon="link ? '' : 'HomeFilled'"
    :link="link"
    >{{ label }}
  </el-button>
  <!-- 税局小助手未安装提示 -->
  <loginTaxAlert ref="loginTaxAlert"></loginTaxAlert>
  <!-- 弹窗选择 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    title="选择身份"
    width="800px"
    append-to-body
  >
    <el-table
      :data="list"
      style="width: 100%"
      border
      :maxHeight="400"
      stripe
      :minHeight="200"
    >
      <el-table-column prop="loginMethod" label="登录方式" min-width="120" />
      <el-table-column prop="zzridno" label="用户名/手机号" min-width="120" />
      <el-table-column prop="idNo" label="代理机构号/账号" min-width="120" />
      <el-table-column label="在线状态" width="155">
        <template #default="scope">
          <el-tooltip
            :disabled="!scope.row.keepErr || scope.row.keepStatus != 2"
            effect="dark"
            placement="top-start"
            :content="scope.row.keepErr"
          >
            <div class="item_icon">
              <span
                v-if="
                  ['330000', '440000'].includes(scope.row?.district_code) ||
                  ['12', '42'].includes(
                    scope.row?.districtCode?.substring(0, 2)
                  ) ||
                  scope.row.loginMethod != '新版登录'
                "
                style="color: green"
              >
                无需授权
              </span>
              <span v-else-if="scope.row.keepStatus == 1" style="color: green">
                已授权，税局在线</span
              >
              <span
                v-else-if="[2, 3].includes(scope.row.keepStatus)"
                class="sbyc2"
              >
                <i class="iconfont icon-cuowu"></i>
                {{ scope.row.keepStatus == 2 ? "授权失败" : "已掉线" }}</span
              >
              <span v-else-if="scope.row.keepStatus == 4" class="sbz">
                <i class="iconfont icon-gantanhao"></i>
                授权中</span
              >
              <span v-else class="wsb">
                <i class="iconfont icon-gantanhao"></i>
                未授权</span
              >
              <i
                class="iconfont icon-wenhao"
                style="color: red; margin-left: 5px"
                v-if="
                  [2, 3].includes(scope.row.keepStatus) && scope.row.keepErr
                "
              ></i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="使用状态" width="100" v-if="!from">
        <template #default="scope">
          {{ scope.row.isLock ? "机器人使用中" : "空闲" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="130">
        <template #default="scope">
          <el-button
            size="small"
            plain
            type="warning"
            @click="directAccess(scope.row)"
            :loading="scope.row.loading"
            icon="HomeFilled"
            >{{ label }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { loginTaxInfo } from "@/api/company";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
  props: {
    comId: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "进税局",
    },
    link: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      btnLoading: false,
      list: [],
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    InTax() {
      this.btnLoading = true;
      loginTaxInfo({ id: this.comId }).then((res) => {
        this.btnLoading = false;
        if (res.data.data.list) {
          this.list = res.data.data.list;
          this.dialogVisible = true;
        } else {
          this.$qzfMessage("请先绑定登录信息", 1);
        }
      });
    },
    directAccess(param) {
      let that = this;
      this.dialogVisible = false;
      if (this.from == "fpdk") {
        param.key = getToken();
        axios
          .post("http://localhost:9111/loginTax/fpdk", param)
          .then((res) => {
            if (res.data.msg != "success") {
              that.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                confirmButtonClass: "ExitConfirmButton",
              });
            }
          })
          .catch(function () {
            setTimeout(() => {
              that.$refs.loginTaxAlert.dialogVisible = true;
            }, 1000);
          });
      } else if (param.isLock) {
        this.$confirm(
          "任务端机器人正在执行报税任务，如要强制进入税局，账号将会被顶掉，导致任务中断，您确认进入税局吗？",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.$qzfMessage("打开中，预计15秒...");
          param.loading = true;
          param.key = getToken();
          axios
            .post("http://localhost:9111/loginTax/autoLoginTax", param)
            .then((res) => {
              param.loading = false;
              if (res.data.msg != "success") {
                that.$emit("success");
                that.$alert(res.data.msg, "提示", {
                  confirmButtonText: "确定",
                });
              }
            })
            .catch(function () {
              param.loading = false;
              setTimeout(() => {
                that.$refs.loginTaxAlert.dialogVisible = true;
              }, 1000);
            });
        });
      } else {
        this.$qzfMessage("打开中，预计15秒...");
        param.loading = true;
        param.key = getToken();
        axios
          .post("http://localhost:9111/loginTax/autoLoginTax", param)
          .then((res) => {
            param.loading = false;
            if (res.data.msg != "success") {
              that.$emit("success");
              that.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
              });
            }
          })
          .catch(function () {
            param.loading = false;
            setTimeout(() => {
              that.$refs.loginTaxAlert.dialogVisible = true;
            }, 1000);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  span {
    color: #17a2b8;
    margin-right: 15px;
  }
}
.item_icon {
  display: inline-block;
  cursor: pointer;
  i {
    display: inline-block;
    font-size: 13px;
  }
  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
</style>
