<template>
  <el-date-picker @change="changeTime" v-model="period" type="month" value-format="YYYYMM" placeholder="账期" style="width: 100px;display:inline-block;" :clearable="clearable" size="small"></el-date-picker>
</template>

<script>
export default {
  name:'searchPeriod',
  props:{
    period:'',
    clearable:{
      default:false,
      type:Boolean
    }
  },
  methods:{
    changeTime(){
      this.$emit('update:period', this.period)
      this.$emit("success", this.period)
    }
  }
}
</script>

<style>

</style>