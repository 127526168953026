<template>
  <div class="ai_home" v-loading="loading">
    <!-- <div class="back_btn" @click="toHome">
      <i class="iconfont icon-ai207"></i>
      返回
    </div> -->
    <el-row :gutter="10" :span="24">
      <el-col :span="8" style="border: none">
        <el-row :span="24">
          <el-col :span="24">
            <div class="each_content ">

              <div class="com_content">
                <img src="./components/homeComponents/image/customer.png" />
                <div class="my_com">
                  <p class="my_com_count">{{ info.countTotal }}</p>
                  <p class="my_com_title">我的客户</p>
                </div>
                <div class="fgx"></div>
                <div style="margin-right: 10%">
                  <p class="my_com_title com_right_fwz">税局在线</p>
                  <p class="my_com_count com_right_count">{{ info.comCount - info.countEmpower3 }}</p>
                </div>
                <div>
                  <p class="my_com_title com_right_dx">税局掉线</p>
                  <p class="my_com_count com_right_count" style="color: #ff3d3d">
                    {{ info.countEmpower3 }}
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :span="24" class="mt-10">
          <el-col :span="24">
            <div class="each_content statistics_content">

              <div class="statistics_each">
                <img src="./components/homeComponents/image/task.png" />
                <div>
                  <p class="my_com_count">{{ info.curentStart }}</p>
                  <p class="my_com_title">本期税局任务已启动</p>
                </div>
              </div>
              <div class="statistics_each ellipsis">
                <img src="./components/homeComponents/image/aicount.png" />
                <div>
                  <p class="my_com_count">{{ info.shebaoCurentStart }}</p>
                  <p class="my_com_title ellipsis">本期社保任务已启动</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :span="24" class="mt-10">
          <el-col :span="24">
            <div class="each_content">
              <el-scrollbar :height="leftHeight">
                <div class="record">单据确认进度</div>
                <div class="echarts_box">
                  <div class="echarts_each_pie">
                    <billPie ref="billPieRef" />
                  </div>
                  <div class="echarts_each_bar">
                    <billBar ref="billBarRef" />
                  </div>
                </div>
                <div class="record">税款确认进度</div>
                <div class="echarts_box">
                  <div class="echarts_each_pie">
                    <taxPie ref="taxPieRef" />
                  </div>
                  <div class="echarts_each_bar">
                    <taxBar ref="taxBarRef" />
                  </div>
                </div>
                <div class="record">社保确认进度</div>
                <div class="echarts_box">
                  <div class="echarts_each_pie">
                    <declarePie ref="declarePieRef" />
                  </div>
                  <div class="echarts_each_bar">
                    <declareBar ref="declareBarRef" />
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16" style="border: none">
        <el-row :span="24">
          <el-col :span="24">
            <div class="each_content">
              <div class="select_box">
                <div>
                  <i class="iconfont icon-guangbo"></i>
                  <span style="font-size: 13px; color: #333">你好{{ cnName }},今天是我们相识的第<span class="time_color">{{
                    apartTime
                  }}</span>天,本月报税期截止时间为<span class="time_color">{{ deadlineDay }}</span>号,<span
                      v-if="today <= deadlineDay && showText">距离报税期结束还有<span class="time_color">{{
                        deadlineDay - today
                      }}</span>天,</span>继续加油！</span>
                </div>
                <div>
                  账期：
                  <qzf-search-period v-model:period="listQuery.period" :clearable="false"
                    style="width: 100px" @success="getInfo"></qzf-search-period>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :span="24" class="mt-10">
          <el-col :span="12">
            <div class="each_content">
              <el-scrollbar :height="rightHeight">

                <div class="tax_title">
                  <img src="./components/homeComponents/image/sj.png" alt="" />
                  <div class="tax_title_text">电子税局申报统计</div>
                </div>
                <div class="record">申报目标</div>
                <div class="tax_tab">
                  <div class="tax_tab_each">
                    <img src="./components/homeComponents/image/sbmb.png" />
                    <div>
                      <p class="my_com_count">{{ info.comCount }}</p>
                      <p class="my_com_title">申报目标</p>
                    </div>
                  </div>
                  <div class="tax_tab_each tax_tab_each_right">
                    <img src="./components/homeComponents/image/sq.png" />
                    <div>
                      <p class="my_com_count">{{ info.keepStatusCount }}</p>
                      <p class="my_com_title">授权可申报</p>
                    </div>
                  </div>
                </div>
                <div class="record">申报结果</div>
                <div class="tax_sb">
                  <div class="flex_between">
                    <statisticsOne icon="icon-gantanhao" title="未申报" :count="info.taxWsb" :total="info.comCount" />
                    <statisticsOne icon="icon-info" title="无需申报" :count="info.taxWxsb" :total="info.comCount" />
                  </div>
                  <div class="flex_between mt-10">
                    <statisticsOne icon="icon-duihao" title="已申报" :count="info.taxYsb" :total="info.comCount" />
                  </div>
                  <statisticsTwo :count="info.taxSbFail" :total="info.comCount" title="申报失败 [数据有误]" />
                  <statisticsTwo :count="info.taxSbFail2" :total="info.comCount" title="申报失败 [权限不够]" />
                </div>
                <div class="record">扣款结果</div>
                <div class="tax_sb">
                  <div class="flex_between">
                    <statisticsOne icon="icon-gantanhao" title="未扣款" :count="info.taxWkk" :total="info.comCount" />
                    <statisticsOne icon="icon-info" title="无需扣款" :count="info.taxWxkk" :total="info.comCount" />
                  </div>
                  <div class="flex_between mt-10">
                    <statisticsOne icon="icon-duihao" title="已扣款" :count="info.taxYkk" :total="info.comCount" />
                  </div>
                  <statisticsTwo :count="info.taxKkFail" :total="info.comCount" title="扣款失败 [数据有误]" />
                  <statisticsTwo :count="info.taxKkFail2" :total="info.comCount" title="扣款失败 [权限不够]" />
                </div>
              </el-scrollbar>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="each_content ml-10">
              <el-scrollbar :height="rightHeight">

                <div class="tax_title">
                  <img src="./components/homeComponents/image/gs.png" alt="" />
                  <div class="tax_title_text">个税申报统计</div>
                </div>
                <div class="record">申报目标</div>
                <div class="tax_tab">
                  <div class="tax_tab_each">
                    <img src="./components/homeComponents/image/sbmb.png" />
                    <div>
                      <p class="my_com_count">{{ info.comCount }}</p>
                      <p class="my_com_title">申报目标</p>
                    </div>
                  </div>
                  <div class="tax_tab_each tax_tab_each_hd">
                    <img src="./components/homeComponents/image/hd.png" />
                    <div>
                      <p class="my_com_count">{{ info.gsYhd }}</p>
                      <p class="my_com_title">已核定个税</p>
                    </div>
                  </div>
                </div>
                <div class="record">综合所得</div>
                <div class="tax_sb">
                  <div class="flex_between">
                    <statisticsOne icon="icon-gantanhao" title="未申报" :count="info.gsWsb" :total="info.comCount" />
                    <statisticsOne icon="icon-info" title="无需申报" :count="info.gsWxsb" :total="info.comCount" />
                  </div>
                  <div class="flex_between mt-10">
                    <statisticsOne icon="icon-duihao" title="已申报" :count="info.gsYsb" :total="info.comCount" />
                  </div>
                  <statisticsTwo :count="info.gsSbFail" :total="info.comCount" title="申报失败 [数据有误]" />
                  <statisticsTwo :count="info.gsSbFail2" :total="info.comCount" title="申报失败 [权限不够]" />
                </div>
                <div class="record">生产经营</div>
                <div class="tax_sb">
                  <div class="flex_between">
                    <statisticsOne icon="icon-gantanhao" title="未申报" :count="info.gdWsb" :total="info.comCount" />
                    <statisticsOne icon="icon-info" title="无需申报" :count="info.gdWxsb" :total="info.comCount" />
                  </div>
                  <div class="flex_between mt-10">
                    <statisticsOne icon="icon-duihao" title="已申报" :count="info.gdYsb" :total="info.comCount" />
                  </div>
                  <statisticsTwo :count="info.gdSbFail" :total="info.comCount" title="申报失败 [数据有误]" />
                </div>
              </el-scrollbar>
            </div>
          </el-col>
        </el-row>
        <el-row :span="24" class="mt-10">
          <el-col :span="24">
            <div class="each_content" style="padding: 2px 0">
              <div class="record">社保申报统计</div>
              <div class="shebao_sb">
                <div class="shebao_each flex_between">
                  <div style="width:49%">
                    <homeProgress title="未申报" :count="info.socialWsb" :total="info.comCount" />
                    <homeProgress title="无需申报" :count="info.socialWxsb" :total="info.comCount" />
                  </div>
                  <div style="width:49%">
                    <homeProgress title="已申报" :count="info.socialYsb" :total="info.comCount" />
                    <homeProgress title="申报失败" :count="info.socialSbFail" :total="info.comCount" />
                  </div>

                </div>
                <div class="shebao_each flex_between">
                  <div style="width:49%">
                    <homeProgress title="未扣款" :count="info.socialWkk" :total="info.comCount" color="#fdd057" />
                    <homeProgress title="无需扣款" :count="info.socialWxkk" :total="info.comCount" color="#fdd057" />
                  </div>
                  <div style="width:49%">
                    <homeProgress title="已扣款" :count="info.socialYkk" :total="info.comCount" color="#fdd057" />
                    <homeProgress title="扣款失败" :count="info.socialKkFail" :total="info.comCount" color="#fdd057" />
                  </div>

                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue"
import { currentAccountPeriod } from "@/utils"
import { eaCompanySbmx } from "@/api/automatic"
import { indexEtax3 } from '@/api/home'
import billBar from "./components/homeComponents/billBar.vue"
import billPie from "./components/homeComponents/billPie.vue"
import taxBar from "./components/homeComponents/taxBar.vue"
import taxPie from "./components/homeComponents/taxPie.vue"
import declareBar from "./components/homeComponents/declareBar.vue"
import declarePie from "./components/homeComponents/declarePie.vue"
import homeProgress from "./components/homeComponents/homeProgress.vue"
import statisticsTwo from "./components/homeComponents/statisticsTwo.vue"
import statisticsOne from "./components/homeComponents/statisticsOne.vue"
import { get } from "xe-utils"
const emit = defineEmits()
const { proxy } = getCurrentInstance()
const rightHeight = ref({})
const leftHeight = ref({})
const listQuery = ref({
  period: currentAccountPeriod(),
})
const info = ref({})
const loading = ref(false)
const billBarRef = ref()
const billPieRef = ref()
const declareBarRef = ref()
const declarePieRef = ref()
const taxBarRef = ref()
const taxPieRef = ref()
const deadlineDay = ref("");
const apartTime = ref("");
const today = ref(new Date().getDate());
const cnName = proxy.$store.getters["user/user"].cnName;
const showText = ref(false);

onMounted(() => {
  rightHeight.value = proxy.$getHeight(326)
  leftHeight.value = proxy.$getHeight(311)
  getList()
  getInfo()
})
const getInfo = () => {
  loading.value = true
  eaCompanySbmx(listQuery.value).then((res) => {
    if (res.data.msg == "success") {
      info.value = res.data.data
      billBarRef.value.init(info.value)
      billPieRef.value.init(info.value)
      declareBarRef.value.init(info.value)
      declarePieRef.value.init(info.value)
      taxBarRef.value.init(info.value)
      taxPieRef.value.init(info.value)
      loading.value = false
    }
  })
}
const getList = () => {
  showText.value = listQuery.value.period != currentAccountPeriod() ? false : true;
  indexEtax3(listQuery.value).then(res => {
    if (res.data.msg == 'success') {
      apartTime.value = res.data.data.apartTime;
      deadlineDay.value = res.data.data.day;
    }
  })
}
const toHome = () => {
  emit('back')
}
</script>

<style scoped lang="scss">
.ai_home {
  width: 100%;
  height: 100%;
  font-size: 14px;
  background-color: #f0f3f8;
  // max-height: calc(100vh - 96px) !important;
  padding: 30px 45px;
}

.select_box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.each_content {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px #cbcbcb;
}

//客户总数部分
.com_content {
  display: flex;
  align-items: center;
  padding: 20px;

  img {
    width: 35px;
    height: 35px;
  }

  .my_com {
    text-align: center;
    margin-left: 10px;
  }

  .com_right_fwz::before {
    content: "";
    background: #17a2b8;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    margin-right: 3px;
    bottom: 2px;
  }

  .com_right_dx::before {
    content: "";
    background: #ff3d3d;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    margin-right: 3px;
    bottom: 2px;
  }

  .com_right_count {
    font-size: 13px;
    margin-top: 6px;
    margin-left: 9px;
  }
}

.my_com_count {
  font-size: 14px;
  font-weight: 700;
}

.my_com_title {
  font-size: 13px;
  color: #666;
}

.fgx {
  margin: 0 8%;
  border-left: 1px solid #c7c5c5;
  width: 1px;
  height: 32px;
}

//统计数
.statistics_content {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statistics_each {
  width: 49%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f2f5fa;
  padding: 15px 10px;

  img {
    width: 44px;
    height: 44px;
  }
}

//echarts
.record {
  font-size: 13px;
  margin-left: 10px;
  margin-top: 2px;
}

.record::before {
  content: "";
  background: #17a2b8;
  display: inline-block;
  width: 4px;
  height: 17px;
  position: relative;
  margin-right: 5px;
  top: 4px;
}

.echarts_box {
  display: flex;
  margin: 10px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #e5e5e5;

  .echarts_each_bar {
    width: 65%;
  }

  .echarts_each_pie {
    margin-left: 20px;
  }
}

//申报相关
.tax_title {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;

  img {
    width: 24px;
    height: 24px;
  }

  .tax_title_text {
    font-size: 14px;
    font-weight: 700;
    color: #2c3e50;
    margin-left: 4px;
  }
}

.tax_tab {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tax_tab_each {
    width: 49%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    background-image: url("./components/homeComponents/image/mb_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    img {
      width: 44px;
      height: 44px;
    }
  }

  .tax_tab_each_right {
    background-image: url("./components/homeComponents/image/sq_bg.png");
  }

  .tax_tab_each_hd {
    background-image: url("./components/homeComponents/image/hd_bg.png");
  }
}

.tax_sb {
  margin: 10px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.tax_sb_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shebao_sb {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shebao_each {
  width: 49%;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
}

.back_btn {
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
  color: #17a2b8;

  .icon-ai207 {
    margin-right: 4px;
  }
}

.time_color {
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
}
.icon-guangbo{
  color: var(--themeColor, #17a2b8);
  margin-right: 10px;
}
</style>
