<template>
  <!-- <el-select size="small" v-model="period" placeholder="账期">
    <el-option
      v-for="item in cities"
      :key="item.id"
      :label="item.period"
      :value="item.period"
    >
      <span style="float: left">{{ item.period }}</span>
      <span v-if="item.status == '4'" style="float: right;color: green;font-size: 14px;">已结账</span>
      <span v-else style="float: right;color: var(--themeColor,#17a2b8);font-size: 14px;">未结账</span>
    </el-option>
  </el-select> -->

  <el-select size="small" @change="changeTime" v-model="period" :placeholder="placeholder" :clearable="clearable">
    <el-option-group
      v-for="group in list"
      :key="group.label"
      :label="group.label"
    >
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.name"
        :value="item.period"
      >

      <el-tooltip v-if="item.isOld == 1" content="历史" placement="top" effect="dark">
        <el-icon  style="float: left;position: relative;top: 7px;margin-right: 3px;"><clock /> </el-icon>
      </el-tooltip>
      <span style="float: left;margin-right: 3px;">{{ item.name }}</span>
      <span v-if="item.settleStatus == '4'" style="float: right;color: green;font-size: 14px;">已结账</span>
      <span v-else-if="item.settleStatus" style="float: right;;font-size: 14px;">未结账</span>

      </el-option>
    </el-option-group>
  </el-select>


</template>
<script>
// <qzf-period @success="xxxx" v-model:period="period"></qzf-period>    重置后调用更新 this.$store.dispatch('commons/InitPeriod',"")


export default {
  name: 'period',
  props: {
    period: '',
    initPeriod: '',
    hidden: {
      type: Boolean,
      default: false
    },
    lastStartPeriod:{
      type: String,
      default: ''
    },
    placeholder:{
      type: String,
      default: '账期'
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    list(){
      let options = this.$store.getters['commons/periods']
      let roptions = []
      if(this.hidden){
        roptions = this.$qzfCopy(options.slice(0,1))
      }else{
        roptions = this.$qzfCopy(options)
      }
      if(this.lastStartPeriod && this.lastStartPeriod.length == 6){
        let param = {
          label:'初始账期(仅供查看)',
          options:[{
            id:0,
            name:this.lastStartPeriod.slice(0,4) +'年'+ this.lastStartPeriod.slice(4) +'期',
            period:"初始账期",
          }]
        }
        roptions.unshift(param)
      }
      this.$emit('changeTitle', roptions)
      return roptions
    },
  },
  data() {
    return {
    }
  },
  created () {
    
  },
  methods: {
    changeTime(){
      //历史数据
      var old = false

      //科目余额表判断初始账期是否存在历史账期中
      var existOld = false

      let olds = []
      let init = []
      let normal = []
      this.list.map(v=>{
        if(v.label == '初始账期'){
          init = v.options
        }
        if(v.label == '正常账期'){
          normal = v.options
        }
        if(v.label == '历史账期'){
          olds = v.options
        }
      })
      olds.map(v=>{
        if(this.period == v.period){
          old = true
        }
        if(this.lastStartPeriod == v.period){
          existOld = true
        }
      })
      if(this.period == "初始账期"){
        this.$emit('update:initPeriod', this.lastStartPeriod)
        this.$emit('update:period', this.lastStartPeriod)
        this.$emit('update:existOld', existOld)
      } else {
        this.$emit('update:period', this.period)
        this.$emit('update:initPeriod', "")
        this.$emit('update:existOld', false)
      }
      let data = {
        "old": old,
        "period": this.period
      }
      this.$emit("success", data)
    },
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
