<template>
  <div class="big">
    <div class="bottom">
      <div class="right">
        <div class="left">
          <div :class="widths == '50px' ? 'logo1' : 'logo'" style="cursor: pointer" @click="goBack">
            <span class="version" v-if="widths == '130px' && showStatus">
              {{ roleName == 'automaticBook' ? '工厂' : 'v5.0' }}
            </span>
          </div>
          <el-scrollbar :style="{ height: '100%', backgroundColor: '#fff' }">
            <left></left>
          </el-scrollbar>
        </div>
        <div :class="!this.comId ? 'rightStyle' : 'rightyStyle1'">
          <headers></headers>
          <div>
            <tags></tags>
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./components/header.vue"
import left from "./components/left.vue"
import right from "./components/right.vue"
import tags from "./components/tags.vue"
export default {
  name: "layout",
  data() {
    return {
      comId: this.$store.getters["user/comInfo"].comId * 1,
      widths: "130px",
      widthss: "133px",
      bigLogo: "https://file.listensoft.net/mnt/v2/html/pics/jsplogo.svg",
      smallLogo: "https://file.listensoft.net/mnt/v2/html/pics/jsplogo1.svg",
      showStatus: false,
      roleName: this.$store.getters["user/roleName"],
      fromComId: this.$store.getters["user/user"].fromComId,

    }
  },
  computed: {
    right() {
      return {
        "--width": this.widths,
        "--widthss": this.widthss,
      }
    },
  },
  components: {
    left,
    right,
    tags,
    headers,
  },
  created() {
    document.getElementById("app").style.setProperty("--width", this.widths)
    document.getElementById("app").style.setProperty("--widthss", this.widthss)
  },
  mounted() {
    if (window.location.hostname == "daizhang.jsptax.com" || window.location.hostname == "localhost") {
      this.showStatus = true
    }
  },
  methods: {
    changeWidths(widths, widthss) {
      this.widths = widths
      this.widthss = widthss
      document.getElementById("app").style.setProperty("--width", this.widths)
      document
        .getElementById("app")
        .style.setProperty("--widthss", this.widthss)
    },
    goBack() {
      if(this.fromComId) return
      if (this.comId || this.roleName == "automaticBook") {
        if (window.opener) {
          window.close()
        } else {
          window.open(location.origin, "_blank")
          window.close()
        }
      } else {
        if (this.$store.getters["user/model"] == "财务核算") {
          return
        }
        this.$store.dispatch("user/setModel", "财务核算")
        this.$store
          .dispatch("user/GetUserInfo")
          .then((res) => {
            let indexPath = res.indexPath
            this.$store
              .dispatch("router/SetAsyncRouter", res.roles)
              .then(() => {
                this.$router.push(indexPath)
                this.$store.dispatch("tagsView/delAllViews")
              })
          })
      }
    },
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.big {
  height: 100%;
  .top {
    width: 100%;
    height: 50px;
    background: #ccc;
  }
  .bottom {
    width: 100%;
    height: calc(100% - 45px);
    .left {
      float: left;
      width: var(--width);
      height: calc(100% - 24px);
    }
    .left::-webkit-scrollbar {
      width: 4px;
    }
    /*滚动条里面小方块*/
    .left::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: rgba(100, 100, 100, 0.2);
    }
    .left1 {
      float: left;
      width: var(--width);
      height: calc(100% - 24px);
    }
    .leftSecond {
      float: left;
      width: 25px;
      height: 94%;
    }
    .right {
      float: left;
      width: calc(100% - 1px);
      // 变量
      height: 100%;
      // background: #d9d9d9;
      background: var(--themeColorBackground, #d9d9d9);
      .rightStyle {
        float: right;
        width: calc(100% - var(--width));
      }
      .rightyStyle1 {
        float: right;
        width: calc(100% - var(--width));
      }
    }
  }
}
.logo {
  width: 130px;
  height: 50px;
  font-size: 14px;
  background-color: var(--themeColor, #17a2b8);
  background-image: var(--bigLogo);
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
}
.logo1 {
  width: 50px;
  height: 50px;
  font-size: 14px;
  background-color: var(--themeColor, #17a2b8);
  background-image: var(--smallLogo);
  //background-size: 70% 70%;
  background-size: 80%;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}
.version {
  background-color: #2f4455;
  color: #fff;
  position: relative;
  left: 100px;
  top: 12px;
  padding: 1px 3px;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  font-weight: lighter;
}
</style>
