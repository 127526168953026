import request from '@/utils/request'

// 自动记账list
export function companyAutomaticList(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticList',
    method: 'post',
    data
  })
}

//启动
export function startUpAutomatic(data) {
  return request({
    url: 'api/v2/automatic/startUpAutomatic',
    method: 'post',
    data
  })
}

//设置info
export function companyAutomaticSetList(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticSetList',
    method: 'post',
    data
  })
}

//保存设置
export function companyAutomaticSet(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticSet',
    method: 'post',
    data
  })
}

//跳过
export function jumpAutomatic(data) {
  return request({
    url: 'api/v2/automatic/jumpAutomatic',
    method: 'post',
    data
  })
}

//确认
export function confirmAutomatic(data) {
  return request({
    url: 'api/v2/automatic/confirmAutomatic',
    method: 'post',
    data
  })
}

//自动记账迁入迁出
export function testAuto(data) {
  return request({
    url: 'api/v2/automatic/testAuto',
    method: 'post',
    data
  })
}

//转到自动记账
export function companyAutomaticSetEnd(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticSetEnd',
    method: 'post',
    data
  })
}

//发票银行数据
export function autoCjCompanyList(data) {
  return request({
    url: 'api/v2/automatic/autoCjCompanyList',
    method: 'post',
    data
  })
}
//银行
export function autoCjCompanyListBank(data) {
  return request({
    url: 'api/v2/automatic/autoCjCompanyListBank',
    method: 'post',
    data
  })
}

//设置标签
export function autoLabelSet(data) {
  return request({
    url: 'api/v2/automatic/autoLabelSet',
    method: 'post',
    data
  })
}

//银行测试
export function autoBankList(data) {
  return request({
    url: 'api/v2/automatic/autoBankList',
    method: 'post',
    data
  })
}

//保存
export function autoBankSave(data) {
  return request({
    url: 'api/v2/automatic/autoBankSave',
    method: 'post',
    data
  })
}

//测试数据
export function autoBankTest(data) {
  return request({
    url: 'api/v2/automatic/autoBankTest',
    method: 'post',
    data
  })
}

//发票list
export function invoiceListGz(data) {
  return request({
    url: 'api/v2/automatic/invoiceListGz',
    method: 'post',
    data
  })
}

//发票保存
export function autoInvoiceSave(data) {
  return request({
    url: 'api/v2/automatic/autoInvoiceSave',
    method: 'post',
    data
  })
}

//发票测试
export function autoInvoiceTest(data) {
  return request({
    url: 'api/v2/automatic/autoInvoiceTest',
    method: 'post',
    data
  })
}

//薪酬确认list
export function companyAutoSalaryList(data) {
  return request({
    url: 'api/v2/automatic/companyAutoSalaryList',
    method: 'post',
    data
  })
}

//银行导入
export function autoBankImportTem(formdata) {
  return request({
      url: 'api/v2/automatic/autoBankImportTem',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//银行通用导入
export function autoBankImportCurrency(data) {
  return request({
    url: 'api/v2/automatic/autoBankImportCurrency',
    method: 'post',
    data
  })
}

//统计
export function automaticStat(data) {
  return request({
    url: 'api/v2/automatic/automaticStat',
    method: 'post',
    data
  })
}

//凭证
export function automaticVoucher(data) {
  return request({
    url: 'api/v2/automatic/automaticVoucher',
    method: 'post',
    data
  })
}

//单据确认
export function confirmAutomaticBill(data) {
  return request({
    url: 'api/v2/automatic/confirmAutomaticBill',
    method: 'post',
    data
  })
}

//发票确认统计
export function automaticStatInvoice(data) {
  return request({
    url: 'api/v2/automatic/automaticStatInvoice',
    method: 'post',
    data
  })
}

//银行确认统计
export function automaticStatBank(data) {
  return request({
    url: 'api/v2/automatic/automaticStatBank',
    method: 'post',
    data
  })
}

//薪酬确认统计
export function automaticStatSalary(data) {
  return request({
    url: 'api/v2/automatic/automaticStatSalary',
    method: 'post',
    data
  })
}

//税款确认统计
export function automaticStatTax(data) {
  return request({
    url: 'api/v2/automatic/automaticStatTax',
    method: 'post',
    data
  })
}

//客服页面
//客服页面筛选org
export function orgAutomaticAdminList(data) {
  return request({
    url: 'api/v2/automatic/orgAutomaticAdminList',
    method: 'post',
    data
  })
}

//客服页面公司列表
export function companyAutomaticAdminList(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticAdminList',
    method: 'post',
    data
  })
}
//客服页面公司导出
export function companyAutomaticAdminExport(data) {
  return request({
    url: 'api/v2/automatic/companyAutomaticAdminExport',
    method: 'post',
    data
  })
}

//客服页面统计列表
export function automaticAdminStat(data) {
  return request({
    url: 'api/v2/automatic/automaticAdminStat',
    method: 'post',
    data
  })
}

//客服页面异常列表
export function companyAdminCarryOverList(data) {
  return request({
    url: 'api/v2/automatic/companyAdminCarryOverList',
    method: 'post',
    data
  })
}

//自动记账异常
export function companyCarryOverList(data) {
  return request({
    url: 'api/v2/automatic/companyCarryOverList',
    method: 'post',
    data
  })
}

//ai统计查询列表
export function automaticComTypeList(data) {
  return request({
    url: 'api/v2/automatic/automaticComTypeList',
    method: 'post',
    data
  })
}

//ai分类统计公司列表
export function automaticComList(data) {
  return request({
    url: 'api/v2/automatic/automaticComList',
    method: 'post',
    data
  })
}

//ai开始检测
export function automaticComTypeCheck(data) {
  return request({
    url: 'api/v2/automatic/automaticComTypeCheck',
    method: 'post',
    data
  })
}

//ai申报开通
export function saveApply(data) {
  return request({
    url: 'api/v2/automatic/saveApply',
    method: 'post',
    data
  })
}

//ai点击红包
export function endMoneyOrgInfo(data) {
  return request({
    url: 'api/v2/automatic/endMoneyOrgInfo',
    method: 'post',
    data
  })
}
//ai是否申报开通
export function orgAutoSettingInfo(data) {
  return request({
    url: 'api/v2/automatic/orgAutoSettingInfo',
    method: 'post',
    data
  })
}

//取消迁入
export function cancelMove(data) {
  return request({
    url: 'api/v2/automatic/cancelMove',
    method: 'post',
    data
  })
}

//质检确认list
export function companyAutoZjList(data) {
  return request({
    url: 'api/v2/automatic/companyAutoZjList',
    method: 'post',
    data
  })
}

//批量跳过质检
export function batchJumpAutomatic(data) {
  return request({
    url: 'api/v2/automatic/batchJumpAutomatic',
    method: 'post',
    data
  })
}

//手工确认
export function handSure(data) {
  return request({
    url: 'api/v2/automatic/handSure',
    method: 'post',
    data
  })
}

//社保list
export function autoSocialList(data) {
  return request({
    url: 'api/v2/automatic/autoSocialList',
    method: 'post',
    data
  })
}
//社保启动
export function startUpAutomaticShebao(data) {
  return request({
    url: 'api/v2/automatic/startUpAutomaticShebao',
    method: 'post',
    data
  })
}

//社保跳过
export function jumpAutomaticShebao(data) {
  return request({
    url: 'api/v2/automatic/jumpAutomaticShebao',
    method: 'post',
    data
  })
}

//社保确认
export function confirmAutomaticShebao(data) {
  return request({
    url: 'api/v2/automatic/confirmAutomaticShebao',
    method: 'post',
    data
  })
}

//社保统计
export function automaticSocialStat(data) {
  return request({
    url: 'api/v2/automatic/automaticSocialStat',
    method: 'post',
    data
  })
}

//财务工厂社保list
export function autoAdminSocialList(data) {
  return request({
    url: 'api/v2/automatic/autoAdminSocialList',
    method: 'post',
    data
  })
}

//财务工厂社保统计
export function automaticAdminSocialStat(data) {
  return request({
    url: 'api/v2/automatic/automaticAdminSocialStat',
    method: 'post',
    data
  })
}

//重新质检接口
export function companyZj(data) {
  return request({
    url: 'api/v2/automatic/companyZj',
    method: 'post',
    data
  })
}

//模型生成任务
export function invoiceMx(data) {
  return request({
    url: 'api/v2/automatic/invoiceMx',
    method: 'post',
    data
  })
}

//首页统计
export function eaCompanySbmx(data) {
  return request({
    url: 'api/v2/automatic/eaCompanySbmx',
    method: 'post',
    data
  })
}

//社保确认接口
export function autoSocialQrList(data) {
  return request({
    url: 'api/v2/automatic/autoSocialQrList',
    method: 'post',
    data
  })
}

//设置行业
export function sshy(data) {
  return request({
    url: 'api/v2/automatic/sshy',
    method: 'post',
    data
  })
}
//保存设置行业
export function sshySave(data) {
  return request({
    url: 'api/v2/automatic/sshySave',
    method: 'post',
    data
  })
}

//记账配置模板列表
export function eaCompanyAutomaticSettingTempList(data) {
  return request({
    url: 'api/v2/automatic/eaCompanyAutomaticSettingTempList',
    method: 'post',
    data
  })
}

//删除模板
export function eaCompanyAutomaticSettingTempDel(data) {
  return request({
    url: 'api/v2/automatic/eaCompanyAutomaticSettingTempDel',
    method: 'post',
    data
  })
}

//新增模板
export function eaCompanyAutomaticSettingTempCre(data) {
  return request({
    url: 'api/v2/automatic/eaCompanyAutomaticSettingTempCre',
    method: 'post',
    data
  })
}

//账套迁入第一步导出
export function eaExCompanyAutomaticList(data) {
  return request({
    url: 'api/v2/export/eaExCompanyAutomaticList',
    method: 'post',
    data
  })
}
//账套迁出导出
export function exMoveOutList(data) {
  return request({
    url: 'api/v2/export/exMoveOutList',
    method: 'post',
    data
  })
}

//集团人员列表
export function getAdminUserList(data) {
  return request({
    url: 'api/v2/automatic/getAdminUserList',
    method: 'post',
    data
  })
}
//预计预计人工预计人工降耗导出
export function expectAutomaticComList(data) {
  return request({
    url: 'api/v2/export/expectAutomaticComList',
    method: 'post',
    data
  })
}

//获取工厂余额
export function automaticBalance(data) {
  return request({
    url: 'api/v2/automatic/automaticBalance',
    method: 'post',
    data
  })
}