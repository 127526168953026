<template>
  <el-select v-model="innerValue" :placeholder="placeholder" filterable @change="changeValue" :clearable="clearable"
    :disabled="disabled" :size="size">
    <el-option v-for="item in options" :key="item[valueKey]" :label="item[labelKey]" :value="item[valueKey]">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "selectCommon",
  props: {
    size: {
      type: String,
      default: "small",
    },
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    modelValue: {},
  },
  computed: {
    innerValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        if (val === "") {
          val = null;
        }
        this.$emit("update:modelValue", val);
      },
    },
  },
  methods: {
    changeValue() {
      this.$emit("selectChange");
    },
  },
};
</script>
