<template>
  <el-drawer v-model="drawer" title="操作日志" destroy-on-close size="1200px">
    <div class="top_select">
      <el-scrollbar :maxHeight="130">
        <div class="search_btn">
          <el-button size="small" icon="Search" type="primary" @click="getList">查询</el-button>
        </div>
        <el-form label-position="right" ref="dataForm" size="small" :inline="true" label-width="80px">
          <div>
            <el-form-item label="操作时间：" prop="time">
              <el-date-picker style="width: 160px" size="small" v-model="listQuery.begin" type="datetime"
                placeholder="开始时间" :clearable="false" :disabled-date="disabledDate" @change="getList" />
              -
              <el-date-picker style="width: 160px" size="small" v-model="listQuery.end" type="datetime"
                placeholder="结束时间" :clearable="false" :disabled-date="disabledDate" @change="getList" />
            </el-form-item>
            <el-form-item label="公司：" required>
              <el-input v-model="listQuery.actionFilter[0].value" style="width: 200px" placeholder="请输入公司名称"
                clearable></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="动作：" prop="time">
              <el-select v-model="listQuery.entityFilter[0].value" placeholder="请选择操作动作"
                style="width: 200px; margin-left: 0" clearable filterable @change="getList">
                <el-option v-for="item in entityOption" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="操作人员：" prop="userId" label-width="199px">
              <el-input v-model="listQuery.userId" style="width: 200px" clearable></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-scrollbar>
    </div>
    <el-table :data="list" style="width: 100%" border :height="contentStyleObj" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
      <el-table-column prop="action" label="操作公司" min-width="240">
        <template #default="scope">
          <i class="iconfont icon-fuzhi1" @click="$copyComName(scope.row.action)"></i>
          {{ scope.row.action }}
        </template>
      </el-table-column>
      <el-table-column label="操作人" width="120" prop="userId">
      </el-table-column>
      <el-table-column label="参数" min-width="120" prop="target">
        <template #default="scope">
          <i class="iconfont icon-fuzhi1" @click="$copyComName(scope.row.target)"></i>
          <el-tooltip effect="dark" placement="top-start">
            <template #content>
              <el-scrollbar :maxHeight="350">
                <div style="max-width: 500px">{{ scope.row.target }}</div>
              </el-scrollbar>
            </template>
            {{ scope.row.target }}
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="行为" min-width="100" prop="entity">
      </el-table-column>
      <el-table-column label="操作时间" width="160">
        <template #default="scope">
          <span>{{
            $parseTime(scope.row.eventTime, "{y}-{m}-{d} {h}:{i}:{s}")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-drawer>
</template>

<script>
import { getLogNewCompany } from "@/api/company";
import { OPERATION_LOG_OPTIONS } from '@/utils/commonData.js'
export default {
  name: "operationLogNew",
  data() {
    return {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      drawer: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 20,
        entityFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        actionFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        targetFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        category: "v3",
        begin: new Date(),
        end: new Date(),
      },
      list: [],
      contentStyleObj: {},
      loading: false,
      total: 0,
      opOption: [
        {
          label: "模糊查询",
          value: "like",
        },
        {
          label: "全等查询",
          value: "eq",
        },
        {
          label: "不等查询",
          value: "neq",
        },
      ],
      entityOption: OPERATION_LOG_OPTIONS
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(320);
  },
  methods: {
    init() {
      this.drawer = true;
      // this.getList();
    },
    getList() {
      this.loading = true;
      getLogNewCompany(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.search_btn {
  position: absolute;
  right: 20px;
}

.icon-fuzhi1 {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
</style>
