<template>
  <div id="declarePie" style="width: 100px; height: 100px"></div>
</template>

<script setup>
import { nextTick } from "vue";
import * as echarts from "echarts";
const init = (info) => {
  nextTick(() => {
    initChart(info);
  })
}
const initChart = (info) => {
  let total = info.countSbWks + info.countSbJxz + info.countSbYwc
  let percentage = total ? ((info.countSbYwc / total) * 100).toFixed(2) : 0
  let isGraphicVisible = true;
  var myChart = echarts.init(document.getElementById("declarePie"));
  myChart.setOption({
    tooltip: {
      show: false
    },
    color: ["#ffd023", "#3fbcff", "#43d1a2"],
    grid: {
      left: 'center',
      top: '4%',
    },
    series: [
      {
        type: 'pie',
        radius: ["80%", "65%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: info.countSbWks, name: '未开始' },
          { value: info.countSbJxz, name: '进行中' },
          { value: info.countSbYwc, name: '已完成' },
        ]
      }
    ],
    graphic: {
      type: "text",
      left: "center",
      top: "center",
      style: {
        text: "社保确认:\n\n" + percentage + "%", // 显示总数
        textAlign: "center",
        textVerticalAlign: "middle",
        fontSize: 11, // 根据需要调整字体大小
        fontColor: "#666",
        opacity: isGraphicVisible ? 1 : 0, // 根据变量设置透明度
      },
    },
  });
  myChart.on("mouseover", { seriesType: "pie" }, () => {
    isGraphicVisible = false; // 鼠标移入时隐藏 graphic
    myChart.setOption({
      graphic: {
        style: {
          opacity: 0, // 更新 graphic 的透明度
        },
      },
    });
  });
  myChart.on("mouseout", { seriesType: "pie" }, () => {
    isGraphicVisible = true; // 鼠标移出时显示 graphic
    myChart.setOption({
      graphic: {
        style: {
          opacity: 1, // 更新 graphic 的透明度
        },
      },
    });
  });
  window.onresize = function () {
    myChart.resize();
  };
}
defineExpose({
  init
})
</script>
