<template>
  <el-dialog v-model="dialogVisible" title="提示" width="270px" :before-close="handleClose" append-to-body>
    <span style="font-size: 13px"><i class="iconfont icon-gantanhao1" style="font-size: 13px"></i>检测到您未安装{{ title }}，请点击
    </span>
    <span style="font-size: 13px; display: inline-block; margin-top: 10px">
      <el-button icon="Pointer" size="small" type="primary" @click="checkOpen">去下载</el-button>
      安装后重试
    </span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" type="primary">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "loginTaxAlert",
  props: {
    title: {
      type: String,
      default: "税局小助手"
    }
  },

  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    checkOpen() {
      if (this.title == '税局小助手') {
        window.open(
          "https://file.jsptax.com/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B.exe"
        );
      }else if(this.title == '微信转发助手'){
        window.open(
          "https://file.jsptax.com/mnt/down/微信转发助手.exe"
        );
      }

    },
  },
};
</script>