import request from "@/utils/request";

//选择区域
export function getBaseDistrict(data) {
  return request({
    url: "api/public/getBaseDistrict",
    method: "post",
    data,
  });
}

//
export function getCaptcha(data) {
  return request({
    url: "api/public/getCaptcha",
    method: "post",
    data,
  });
}

export function sendTemplateMsg(data) {
  return request({
    url: "api/v2/wx/sendTemplateMsg",
    method: "post",
    data,
  });
}

//查询当前主题色
export function publicComColourList(data) {
  return request({
    url: "api/public/publicComColourList",
    method: "post",
    data,
  });
}
