<template>
  <!-- <el-select @change="changeSelect" size="mini" clearable filterable v-model="id" placeholder="请选择">
    <el-option-group
      v-for="group in options"
      :key="group.label"
      :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.sign"
        :label="item.name"
        :value="item.sign">
      </el-option>
    </el-option-group>
  </el-select> -->

  <!-- <el-cascader :class="{boxShadowNone:borderNone}" clearable filterable v-model="id" :props="cascaderProps" :options="options" :disabled="disabledIn" style="width:100%"/> -->
  <n-space vertical>
    <n-cascader
      placeholder="没啥用的值"
      v-model:value="id"
      :options="options"
      label-field="name"
      value-field="sign"
      check-strategy="child"
      children-field="items"
      :filterable="filterable"
      @update:value="handleUpdateValue"
    />
  </n-space>



  <add-inventory @success="addInventorySuccess" ref="addInventory"></add-inventory>
  <add-subject @success="addSubjectSuccess" ref="addSubject"></add-subject>
  <addAssets @success="addAssetSuccess" :type="this.typexsjx" ref="addAssets" :tyname="'xzjx'"></addAssets>
   <!-- <business-type v-model:businessId="a" v-model:itemId="b" v-model:fzhsId="c" type="in"></business-type> -->
</template>
<script>
import addAssets from "../../views/input/assets/components/addAssets.vue"

export default {
  props:{
    type: {//in 进项
      type: String,
      default: ""
    },
    businessId: {//sign 第一个值 相当于itemType
      type: Number,
      default: 0
    },
    itemId: {//sign 第二个值 可为存货id 或者 subjectId
      type: Number,
      default: 0
    },
    fzhsId: {//sign 第三个值 相当于辅助核算id
      type: Number,
      default: 0
    },
    itemType: {//
      type: String,
      default: ""
    },
    name: {//默认新增存货名称
      type: String,
      default: ""
    },
    spec: {//默认新增存货型号
      type: String,
      default: ""
    },
    unit: {//默认新增存货单位
      type: String,
      default: ""
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    direction: {//支出收入 （银行）
      type: String,
      default: ""
    },
    add:{
      type:Boolean,
      default:false
    },
  },
  components:{ addAssets },
  computed: {
    options(){
      let data = []
      if(this.type == 'in'){
        data = this.$store.getters["commons/businessType"].in
      }else if(this.type == 'bank'){
        let datas = this.$store.getters["commons/businessType"].bank
        if(this.add){
          datas = this.$store.getters["commons/businessType"].bank2
        }
        if(this.direction == 'in'){//入账
          data = datas.filter(v=>{return v.szbz == 1})
        }else if(this.direction == 'out'){//出账
          data = datas.filter(v=>{return v.szbz != 1})
        }else{//入出都存在
          data = datas
        }
      }else if(this.type == 'cash'){
        data = this.$store.getters["commons/businessType"].cash
      }else if(this.type == 'bill'){
        data = this.$store.getters["commons/businessType"].bill
      }
      this.data = data
      return data
    },
    id: {
      get() {
        if(!this.businessId || !this.itemId*1){
          return []
        }
        //console.log([String(this.businessId), this.businessId + "-" + this.itemId + "-" + this.fzhsId]);
        let val = [String(this.businessId), this.businessId + "-" + this.itemId + "-" + this.fzhsId]
        return val
      },
      set(val) {
        //console.log(val);
        if(!val){
          this.$emit("update:businessId", 0)
          this.$emit("update:itemId", 0)
          this.$emit("update:fzhsId", 0)
          this.$emit("success", [0, "0-0"])
          return
        }
        this.bid = val.split('-')[0]
        this.addId = val.split('-')[0] * 1
        let businessTypeInfo = this.findInfo(this.addId)
        if(val == 'addInventory'){
          let data = {
            id: undefined,
            name: this.name,
            spec: this.spec,
            unit: this.unit,
            type: this.addId,
          }
          //console.log(data);
          this.$refs.addInventory.init(data)
        }else if(val == 'addSubject'){
          let data = {
            id: undefined,
            name: this.name,
            type: 0,
          }
          let useCodes = null
          if(businessTypeInfo){
            useCodes = businessTypeInfo.useCodes
          }
          this.$refs.addSubject.init(data, useCodes)
        } else if(val == 'addAssage'){
          //14 固定资产
          //16 无形资产
          //17 待摊费用
          if(this.bid == 14) {
            this.typexsjx = 1
          }else if(this.bid == 16) {
            this.typexsjx = 2
          }else if(this.bid == 17) {
            this.typexsjx = 3
          }
          this.$refs.addAssets.openDialog('',this.typexsjx)
        }
        if(val.length > 1){
          this.$emit("update:businessId", val.split('-')[0] * 1)
          this.$emit("update:itemId", val.split('-')[1] * 1)
          this.$emit("update:fzhsId", val.split('-')[2] * 1)
          //存货的 双向绑定一下 型号 单位
          if(val.split('-')[0] * 1 <= 11 || val.split('-')[0] * 1 == 20 || val.split('-')[0] * 1 == 21 || val.split('-')[0] * 1 == 22){
            let info = {}
            this.options.map(v=>{
              if(v.dataType == 'inventory'){
                v.items.map(z=>{
                  if(z.sign == val){
                    info = z
                  }
                })
              }
            })
            this.$emit("update:spec", info.spec)
            this.$emit("update:unit", info.unit)
            this.$emit("update:itemType", "inventory")
          }else{
            this.$emit("update:itemType", "item")
          }
        }
        if(businessTypeInfo){
          this.$emit("update:szbz", businessTypeInfo.szbz)
          this.$emit("update:businessTypeName", businessTypeInfo.name)
        }
        // this.$emit("success", [String(val.split('-')[0]), String(val.split('-')[0]) + "-" + String(val.split('-')[1]) + "-" + String(val.split('-')[2])])
        this.$emit("success", val)

      }
      
    }
  },
  data () {
    return {
      bid: "",
      cascaderProps: {
        "label": "name",
        "value": "sign",
        "children": "items",
      },
      typexsjx: 1,
      filterable:true
    }
  },
  methods: {
    //关联资产回调
    addAssetSuccess(sign){
      this.id = [this.bid, this.bid + '-' + sign]
    },
    findInfo(val){
      let info
      this.data.map(v=>{
        if(v.id == val){
            info = v
          }
      })
      return info
    },
    addInventorySuccess(e){
      this.id = [this.bid, this.bid + "-" + String(e.id) + "-0"]
    },
    addSubjectSuccess(e){
      this.id = [this.bid, this.bid + "-" + e.sign]
    },
    handleUpdateValue (value, option) {
        //console.log(value, option)
      }
  }
}
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
</style>