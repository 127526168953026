<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="办税人信息"
    width="450px"
    append-to-body
  >
    <div class="hint" v-if="temp?.id">
      温馨提示：编辑登录信息会修改所有与此绑定信息的公司
    </div>
    <el-form
      :model="temp"
      label-width="130px"
      :rules="rules"
      ref="ruleFormRef"
      size="small"
    >
      <el-form-item label="登录方式" prop="loginMethod">
        <select-common
          placeholder="请选择登录方式"
          v-model:modelValue="temp.loginMethod"
          :options="options"
          class="w250 margin0"
          :disabled="temp.id"
        />
      </el-form-item>
      <el-form-item
        label="用户名/手机号："
        prop="zzridno"
        v-if="temp.loginMethod != '政务网登录'"
      >
        <input-common
          v-model:modelValue="temp.zzridno"
          class="w250"
          :disabled="temp.id"
        ></input-common>
      </el-form-item>
      <el-form-item
        :label="temp.loginMethod == '政务网登录' ? '账号：' : '代理机构号：'"
        prop="idNo"
        v-if="temp.loginMethod != '新版登录'"
      >
        <input-common
          v-model:modelValue="temp.idNo"
          class="w250"
          :placeholder="
            temp.loginMethod == '政务网登录'
              ? '请输入账号'
              : '请输入代理机构号(纳税人识别号)'
          "
        ></input-common>
      </el-form-item>
      <el-form-item label="密码：" prop="zzrmn">
        <input-common
          v-model:modelValue="temp.zzrmn"
          class="w250"
        ></input-common>
      </el-form-item>
      <el-form-item
        label="短信接收方式"
        prop="yzmType"
        v-if="temp.loginMethod != '政务网登录'"
      >
        <select-common
          placeholder="请选择短信接收方式"
          v-model:modelValue="temp.yzmType"
          :options="yzmOptions"
          class="w250 margin0"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="createSure"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { creatCompanyLoginUser } from "@/api/company";
const emit = defineEmits();
const rules = {
  loginMethod: [
    { required: true, message: "请选择登录方式", trigger: "change" },
  ],
  zzridno: [
    { required: true, message: "请输入用户名或手机号", trigger: "blur" },
  ],
  zzrmn: [{ required: true, message: "请输入密码", trigger: "blur" }],
  idNo: [
    {
      required: true,
      message: "请输入代理机构号/纳税人识别号/账号",
      trigger: "blur",
    },
  ],
};
const temp = ref({
  loginMethod: "代理登录",
  zzridno: "",
  zzrmn: "",
  idNo: "",
  yzmType: 0,
});
const options = ref([
  { label: "新版登录", value: "新版登录" },
  { label: "代理登录", value: "代理登录" },
  { label: "政务网登录", value: "政务网登录" },
]);
const yzmOptions = ref([
  { label: "自动登录", value: 0 },
  { label: "手动登录", value: 1 },
]);
const dialogFormVisible = ref(false);
const ruleFormRef = ref();
const init = (item) => {
  reset();
  if (item) {
    temp.value = Object.assign({}, item);
  }
  dialogFormVisible.value = true;
};

const createSure = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      if (temp.value.loginMethod == "政务网登录") {
        temp.value.zzridno = "";
      }
      if (temp.value.loginMethod == "新版登录") {
        temp.value.idNo = "";
      }
      creatCompanyLoginUser(temp.value).then((res) => {
        if (res.data.msg == "success") {
          emit("success");
          emit("update", res.data.data);
          ElMessage.success("操作成功");
          dialogFormVisible.value = false;
        }
      });
    }
  });
};
const reset = () => {
  temp.value = {
    loginMethod: "代理登录",
    zzridno: "",
    zzrmn: "",
    idNo: "",
    yzmType: 0,
  };
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.margin0 {
  margin: 0;
}
.w250 {
  width: 250px;
}
.hint {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  margin-bottom: 10px;
}
</style>
