<template>
  <div class="message_content" ref="message">
    <div class="message_list" v-if="showBig">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="系统公告" name="first" v-if="status">
          <template #label>
            <el-badge
              :value="recordUnreadNum"
              :hidden="!recordUnreadNum"
              :max="99"
            >
              <span>系统公告</span>
            </el-badge>
          </template>
          <ul class="list_card" v-for="item in list" :key="item.id">
            <li>
              <div class="top_tag">
                <p :class="item.Read == 0 ? 'un_read' : 'read'">
                  {{ item.title }}
                </p>
              </div>
              <div class="message_main">
                <h5>{{ item.name }}</h5>
                <h6>尊敬的用户您好：</h6>
                <div class="message_p" v-html="item.content"></div>
              </div>
              <div class="bottom_btns">
                <span>{{ $parseTime(item.createdAt, "{y}-{m}-{d}") }}</span>
                <p @click="seeDetails(item)">查看详情</p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane name="second">
          <template #label>
            <el-badge :value="smsUnreadNum" :hidden="!smsUnreadNum" :max="99">
              <span>短信列表</span>
            </el-badge>
          </template>
          <div v-loading="loading">
            <div style="margin-bottom: 8px;">
              <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getSmsList()"></el-button>
              <el-input size="small" v-model="listQuery.content" style="width:270px" @keyup.enter="getSmsList" clearable placeholder="请输入短信内容关键词"></el-input>
              <el-button size="small" icon="Search" @click="getSmsList" type="primary">搜索</el-button>
            </div>
            
            <ul class="list_card" v-for="item in smsList" :key="item.id" >
              <li>
                <div class="top_tag">
                  <p :class="item.read == 0 ? 'un_read' : 'read'">
                    手机号：{{ item.mobile_receive }}
                  </p>
                </div>
                <div class="message_main">
                  <div class="message_p">{{ item.content }}</div>
                </div>
                <div class="bottom_row">
                  <el-row :span="24">
                    <el-col :xs="10" :sm="10" :lg="10">
                      <span> 短信来源：</span>
                    </el-col>
                    <el-col :xs="14" :sm="14" :lg="14">
                      <span>{{ item.mobile_send }}</span>
                    </el-col>
                  </el-row>
                  <el-row :span="24">
                    <el-col :xs="10" :sm="10" :lg="10">
                      <span>设备/APP接到短信时间：</span>
                    </el-col>
                    <el-col :xs="14" :sm="14" :lg="14">
                      <span>
                        {{
                          $parseTime(item.receive_time, "{y}-{m}-{d} {h}:{i}:{s}")
                        }}</span
                      >
                    </el-col>
                  </el-row>
                  <el-row :span="24">
                    <el-col :xs="10" :sm="10" :lg="10">
                      <span>系统接收时间：</span>
                    </el-col>
                    <el-col
                      :xs="14"
                      :sm="14"
                      :lg="14"
                      style="display: flex;align-items: center;justify-content: space-between;"
                    >
                      <span>{{
                        $parseTime(item.CreatedAt, "{y}-{m}-{d} {h}:{i}:{s}")
                      }}</span>
                      <p @click="seeSms(item)">查看详情</p>
                    </el-col>
                  </el-row>
                </div>
              </li>
            </ul>
          </div>
        
        </el-tab-pane>
      </el-tabs>
      <div class="all" @click="readAll" v-if="totalNum">
        <el-icon style="margin-right: 3px;">
          <chat-dot-square />
        </el-icon>
      <span>全部已读</span>
      </div>
      <i @click="showBig = false" class="iconfont icon-cuowu11 icon_close"></i>
    </div>
    <div class="message_list" v-if="showDownload">
      <i
        @click="showDownload = false"
        class="iconfont icon-cuowu11 icon_close"
      ></i>
      <ul class="load_list">
        <li v-for="item in downloadList" :key="item.id">
          <p v-html="item.content"></p>
        </li>
      </ul>
    </div>

    <div @click.stop="showBig = false" v-if="showBig" class="zz"></div>
    <div
      @click.stop="showDownload = false"
      v-if="showDownload"
      class="zz"
    ></div>
    <!-- 查看详情弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      :title="detail.title"
      v-model="dialogFormVisible"
      class="new_dialog1 button_bg"
      :showClose="false"
      width="75%"
      style="margin-top:1vh"
    >
      <el-form ref="dataForm">
        <el-scrollbar :height="contentStyleObj">
          <div class="message_detail clearfix">
            <!-- <h6>{{detail.name}}:</h6> -->
            <div
              v-html="detail.content"
              class="contentStyle"
              @click="wrapClick($event, detail.content)"
            ></div>
          </div>
        </el-scrollbar>
      </el-form>
      <el-image-viewer
        @close="closeViewer"
        v-if="showViewer"
        :url-list="srcList"
      />
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="closeBox" size="small"
            >我知道了</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 查看详情弹框 end -->
    <!-- 查看短信详情 -->
    <el-dialog
      :close-on-click-modal="false"
      destroy-on-close
      :title="smsDetail.mobile_receive + '-短信详情'"
      v-model="dialogSmsVisible"
      :showClose="false"
      width="50%"
    >
      <!-- <el-scrollbar :height="contentStyleObj"> -->
      <div class="message_detail clearfix">
        <el-card shadow="always">
          <el-row :span="24">
            <el-col :xs="5" :sm="5" :lg="5">
              <span>接收手机号：</span>
            </el-col>
            <el-col :xs="19" :sm="19" :lg="19">
              <span> {{ smsDetail.mobile_receive }}</span>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :xs="5" :sm="5" :lg="5">
              <span> 来源：</span>
            </el-col>
            <el-col :xs="19" :sm="19" :lg="19">
              <span> {{ smsDetail.mobile_send }}</span>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :xs="5" :sm="5" :lg="5">
              <span> 内容：</span>
            </el-col>
            <el-col :xs="19" :sm="19" :lg="19">
              <span> {{ smsDetail.content }}</span>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :xs="5" :sm="5" :lg="5">
              <span>接收时间：</span>
            </el-col>
            <el-col :xs="19" :sm="19" :lg="19">
              <span>
                {{
                  $parseTime(smsDetail.receive_time, "{y}-{m}-{d} {h}:{i}:{s}")
                }}</span
              >
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :xs="5" :sm="5" :lg="5">
              <span> 系统接收时间：</span>
            </el-col>
            <el-col :xs="19" :sm="19" :lg="19">
              <span>
                {{
                  $parseTime(smsDetail.CreatedAt, "{y}-{m}-{d} {h}:{i}:{s}")
                }}</span
              >
            </el-col>
          </el-row>
        </el-card>
      </div>
      <!-- </el-scrollbar> -->
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="readSms" size="small"
            >我知道了</el-button
          >
        </div>
      </template>
    </el-dialog>
    <!-- 查看短信详情end -->
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import {
  updateListNew,
  updateUserSave,
  updateListOne,
  updateUserSaveAll,
} from "@/api/org";
import { smsRecListNew, smsRecRead } from "@/api/company";
export default {
  name: "IndexMessage",
  props: {
    tabName: String,
  },
  data() {
    return {
      showBig: false,
      showDownload: false,
      activeName: "second",
      dialogFormVisible: false,
      list: [],
      detail: {},
      downloadList: [],
      srcList: [],
      showViewer: false,
      contentStyleObj: {},
      smsList: [],
      listQuery: {
        page: 1,
        limit: 100,
        content:""
      },
      smsDetail: {},
      dialogSmsVisible: false,
      recordUnreadNum: 0,
      smsUnreadNum: 0,
      totalNum:0,
      status:false,
      loading:false
    };
  },
  created() {
    this.getList();
    this.contentStyleObj = this.$getHeight(230);
  },
  mounted(){
    if (window.location.hostname == 'daizhang.jsptax.com' || window.location.hostname == 'localhost' || window.location.hostname == 'tax.jsptax.net') {
      this.status = true;
    }
  },
  methods: {
    showBig2(e) {
      if (e == 1) {
        // this.activeName = '1'
        this.showDownload = false;
        this.showBig = !this.showBig;
        this.getList();
        this.getSmsList()
      } else if (e == 2) {
        //this.activeName = '2'
        this.showBig = false;
        this.showDownload = !this.showDownload;
        updateListNew({ type: 2 }).then((res) => {
          this.downloadList = res.data.data.list;
        });
      }
    },
    seeDetails(e) {
      updateListOne({ id: e.id }).then((response) => {
        this.detail = response.data.data.info;
        this.dialogFormVisible = true;
        this.showBig = false;
      });
    },
    async getList() {
      await updateListNew({ type: 1 }).then((response) => {
        this.list = response.data.data.list;
        this.recordUnreadNum = response.data.data.unreadNum;
      });
      // await smsRecListNew(this.listQuery).then((res) => {
      //   this.smsList = res.data.data.list ? res.data.data.list : [];
      //   this.smsUnreadNum = res.data.data.unReadNum;
      // });
      this.totalNum = Number(this.recordUnreadNum) + Number(this.smsUnreadNum);
      this.$emit("success", this.totalNum);
    },
    getSmsList(){
      this.loading = true
      smsRecListNew(this.listQuery).then((res) => {
        this.loading = false
        this.smsList = res.data.data.list ? res.data.data.list : [];
        this.smsUnreadNum = res.data.data.unReadNum;
      });
      this.totalNum = Number(this.recordUnreadNum) + Number(this.smsUnreadNum);
      this.$emit("success", this.totalNum);
    },
    closeBox() {
      if (this.detail.id) {
        updateUserSave({ updateId: this.detail.id }).then((res) => {});
        this.dialogFormVisible = false;
        this.getList();
      }
    },
    // 关闭大图预览
    closeViewer() {
      this.showViewer = false;
    },
    seeSms(row) {
      this.smsDetail = row;
      this.dialogSmsVisible = true;
      this.showBig = false;
    },
    readSms() {
      smsRecRead({
        smsId: this.smsDetail.id,
      }).then((res) => {
        this.getList();
        this.dialogSmsVisible = false;
      });
    },
    //全部已读
    readAll() {
      updateUserSaveAll({}).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("全部已读");
          this.getList();
        }
      });
    },
    // 点击图片
    wrapClick(event, item) {
      const target = event.target
      if (target.tagName === 'IMG') {
        //console.log(event.target.src)
        event.preventDefault()
        const content = item
        const imgReg = /<img.*?(?:>|\/>)/gi                   // 匹配图片中的img标签
        const srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i            // 匹配图片中的src
        const arr = content.match(imgReg)                   // 筛选出所有的img
        const srcArr = []
        for (let i = 0; i < arr.length; i++) {
          const src = arr[i].match(srcReg)
          // 获取图片地址
          srcArr.push(src[1])
        }
        this.srcList = srcArr
        this.srcList.unshift(event.target.src)
        const set = new Set(this.srcList)
        this.srcList = Array.from(set)
        this.showViewer = true
      }
    },
   
  },
};
</script>

<style>
.zz {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0);
  z-index: 999;
}
/* .message_content .el-tabs__item{
  height: 24px !important;
} */
.message_content .el-tabs__nav-wrap::after {
  border-bottom: none !important;
}
</style>
<style lang="scss" scoped>
:deep(.el-badge__content.is-fixed) {
  top: 11px;
  right: 2px;
}
.el-tag {
  color: #fff !important;
}
.load_list {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  li {
    border-bottom: 1px solid #eee;
  }
  p {
    font-size: 14px;
    color: #333;
    font-weight: normal;
    position: relative;
    padding-left: 15px;
  }
  p:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--themeColor, #17a2b8);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}
.message_list {
  width: 520px;
  // height: 100%;
  height: calc(100vh - 56px);
  background: #fff;
  border: 1px solid #eee;
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 9999;
  overflow-y: auto;
  padding: 12px 12px 12px;
  box-shadow: 0px 8px 6px #ccc;
  .list_card {
    width: 100%;
    li {
      background: #fff;
      box-shadow: 0 2px 10px #ddd;
      border-radius: 8px;
      margin-bottom: 14px;
      border: 1px solid #eee;
      .top_tag {
        width: 100%;
        line-height: 30px;
        background: #f6f6f6;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        p {
          position: relative;
          padding-left: 15px;
          font-size: 13px;
        }
        // p::before{
        //   content: "";
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 50%;
        //   background: var(--themeColor,#17a2b8);
        //   position: absolute;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   left: 0;
        // }
        .read::before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #bbb;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        .un_read::before {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: red;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
      }
      .message_main {
        h5 {
          font-size: 12px;
          color: #333;
          font-weight: normal;
        }
        h6 {
          font-size: 12px;
          color: #333;
          font-weight: normal;
        }
        padding: 13px 10px;
      }
      .message_p {
        font-size: 12px;
        color: #666;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .bottom_btns {
        width: 100%;
        margin: 0 auto;
        line-height: 30px;
        border-top: 1px solid #eee;
        padding: 0 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
          color: #666;
          font-size: 13px;
          line-height: 30px;
        }
        p {
          font-size: 13px;
          color: var(--themeColor, #17a2b8);
          line-height: 30px;
          cursor: pointer;
        }
      }
      .bottom_row {
        width: 100%;
        margin: 0 auto;
        border-top: 1px solid #eee;
        p {
          font-size: 13px;
          color: var(--themeColor, #17a2b8);
          cursor: pointer;
          text-align: right;
          display: inline-block;
          margin-right: 10px;
        }
        .el-col {
          border-right: 1px solid #eee;
          border-bottom: 1px solid #eee;
          span {
            display: inline-block;
            padding: 5px 0 5px 10px;
            font-size: 13px;
          }
        }
      }
    }
  }
}
.message_detail {
  //height: 420px;
  //overflow-y: auto;
  h6 {
    width: 96%;
    text-align: left;
    font-weight: normal;
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  p {
    width: 96%;
    margin: 0 auto;
    font-size: 15px;
    color: #333;
    line-height: 24px;
  }
  .el-col {
    border-bottom: 1px solid #eee;
    span {
      display: inline-block;
      padding: 10px;
      font-size: 14px;
    }
  }
}
.icon_close {
  font-size: 18px;
  position: absolute;
  top: 19px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}
.all {
  font-size: 13px;
  position: absolute;
  top: 24px;
  right: 53px;
  cursor: pointer;
  z-index: 999;
  color: var(--themeColor, #17a2b8);
}
.each {
  display: flex;
  .each_left {
    width: 100px;
    text-align: right;
  }
}
</style>

<style lang="scss">
.contentStyle {
  display: inline-block;
  p {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.new_dialog1 {
  .el-dialog__header {
    background: #efefef;
    margin-right: 0px;
  }
  .el-dialog__body {
    padding-top: 0px;
  }
}
</style>
