import request from "@/utils/request";

//账期列表
export function getPeriods(data) {
  return request({
    url: "api/v2/company/getPeriods",
    method: "post",
    data,
  });
}

//公司列表
export function eaCompanyList(data) {
  return request({
    url: "api/v2/company/eaCompanyList",
    method: "post",
    data,
  });
}

//新增客户
export function saveEaCompany(data) {
  return request({
    url: "api/v2/company/saveEaCompany",
    method: "post",
    data,
  });
}
//零申报
export function accountingType(data) {
  return request({
    url: "api/v2/company/accountingType",
    method: "post",
    data,
  });
}

//其他设置
export function getComSetList(data) {
  return request({
    url: "api/v2/company/getComSetList",
    method: "post",
    data,
  });
}

export function setComSet(data) {
  return request({
    url: "api/v2/company/setComSet",
    method: "post",
    data,
  });
}

// 企业基本信息
export function eaCompanyInfo(data) {
  return request({
    url: "api/v2/company/eaCompanyInfo",
    method: "post",
    data,
  });
}

// 分配公司
export function assignCustomers(data) {
  return request({
    url: "api/v2/company/assignCustomers",
    method: "post",
    data,
  });
}

// 停报公司
export function changeStatus(data) {
  return request({
    url: "api/v2/company/changeStatus",
    method: "post",
    data,
  });
}

//删除公司
export function delCompany(data) {
  return request({
    url: "api/v2/company/delCompany",
    method: "post",
    data,
  });
}

// 个税初始化
export function companyInitTaxList(data) {
  return request({
    url: "api/v2/company/companyInitTaxList",
    method: "post",
    data,
  });
}

// 个税初始化
export function companyInitTaxListNoPeriod(data) {
  return request({
    url: "api/v2/company/companyInitTaxListNoPeriod",
    method: "post",
    data,
  });
}

// 新的合并的列表
export function companyInitTaxListNoPeriodNew(data) {
  return request({
    url: "api/v2/company/companyInitTaxListNoPeriodNew",
    method: "post",
    data,
  });
}

export function companySendTaxList(data) {
  return request({
    url: "api/v2/company/companySendTaxList",
    method: "post",
    data,
  });
}

//发票
export function companyInvoiceList(data) {
  return request({
    url: "api/v2/company/companyInvoiceList",
    method: "post",
    data,
  });
}

//分配的人员
export function assignCustomersList(data) {
  return request({
    url: "api/v2/company/assignCustomersList",
    method: "post",
    data,
  });
}

//科目设置批量保存
export function setComSets(data) {
  return request({
    url: "api/v2/company/setComSets",
    method: "post",
    data,
  });
}

//小记Info
export function comMemoryInfo(data) {
  return request({
    url: "api/v2/company/comMemoryInfo",
    method: "post",
    data,
  });
}

// 小鸡设置复制账期
export function comMemorySaveSet(data) {
  return request({
    url: "api/v2/company/comMemorySaveSet",
    method: "post",
    data,
  });
}

//小记保存
export function comMemorySave(data) {
  return request({
    url: "api/v2/company/comMemorySave",
    method: "post",
    data,
  });
}
//新增账套小记Info
export function comMemoryInfoNewly(data) {
  return request({
    url: "api/v2/company/comMemoryInfoNewly",
    method: "post",
    data,
  });
}

//新增账套小记保存
export function comMemorySaveNewly(data) {
  return request({
    url: "api/v2/company/comMemorySaveNewly",
    method: "post",
    data,
  });
}
//建账采集数据采集
export function companyHisList(data) {
  return request({
    url: "api/v2/company/companyHisList",
    method: "post",
    data,
  });
}

//历史数据采集
export function companyHisImportList(data) {
  return request({
    url: "api/v2/company/companyHisImportList",
    method: "post",
    data,
  });
}

//验证码list
export function smsRecList(data) {
  return request({
    url: "api/v2/company/smsRecList",
    method: "post",
    data,
  });
}

//到票状态
export function getDpStatus(data) {
  return request({
    url: "api/v2/company/getDpStatus",
    method: "post",
    data,
  });
}

//到票状态保存
export function saveDpStatus(data) {
  return request({
    url: "api/v2/company/saveDpStatus",
    method: "post",
    data,
  });
}
//审核
export function toExamine(data) {
  return request({
    url: "api/v2/company/toExamine",
    method: "post",
    data,
  });
}
//账套迁移
export function company1To2List(data) {
  return request({
    url: "api/v2/company/company1To2List",
    method: "post",
    data,
  });
}

//勾选认证
export function loginTaxInfo(data) {
  return request({
    url: "api/v2/company/loginTaxInfo",
    method: "post",
    data,
  });
}

//编号排序
export function saveEaCompanySort(data) {
  return request({
    url: "api/v2/company/saveEaCompanySort",
    method: "post",
    data,
  });
}

//address 地区选择
export function getAddress(data) {
  return request({
    url: "api/v2/company/getAddress",
    method: "post",
    data,
  });
}

// 获取已绑定列表
export function getBindWxUser(data) {
  return request({
    url: "api/v2/company/getBindWxUser",
    method: "post",
    data,
  });
}

// 查询微信用户
export function searchWxUser(data) {
  return request({
    url: "api/v2/company/searchWxUser",
    method: "post",
    data,
  });
}
//绑定用户
export function wxCzComBindUser(data) {
  return request({
    url: "api/v2/company/wxCzComBindUser",
    method: "post",
    data,
  });
}
// 公司绑定微信用户
export function comBindUser(data) {
  return request({
    url: "api/v2/company/comBindUser",
    method: "post",
    data,
  });
}
// 删除绑定用户
export function delWxComUser(data) {
  return request({
    url: "api/v2/company/delWxComUser",
    method: "post",
    data,
  });
}
// 汇算清缴list
export function companyYearList(data) {
  return request({
    url: "api/v2/company/companyYearList",
    method: "post",
    data,
  });
}

//财务统计表列表
export function cwtjList(data) {
  return request({
    url: "api/v2/company/cwtjList",
    method: "post",
    data,
  });
}
//财务统计表更新数据
export function cwtjFresh(data) {
  return request({
    url: "api/v2/company/cwtj",
    method: "post",
    data,
  });
}
//财务统计批量更新数据
export function cwtjs(data) {
  return request({
    url: "api/v2/company/cwtjs",
    method: "post",
    data,
  });
}

//导出财务统计列表
export function cwtjExport(data) {
  return request({
    url: "api/v2/export/cwtj",
    method: "post",
    data,
  });
}

//打印财务统计列表
export function cwtjPrint(data) {
  return request({
    url: "api/v2/print/cwtj",
    method: "post",
    data,
  });
}

//获取可推送公众号列表
export function companySendWx(data) {
  return request({
    url: "api/v2/company/companySendWx",
    method: "post",
    data,
  });
}

//批量修改公司信息
export function saveEaCompanys(data) {
  return request({
    url: "api/v2/company/saveEaCompanys",
    method: "post",
    data,
  });
}
//结账打印
export function companyCarryOverList(data) {
  return request({
    url: "api/v2/company/companyCarryOverList",
    method: "post",
    data,
  });
}
//获取群聊公司列表
export function getChatCompanyList(data) {
  return request({
    url: "api/v2/company/getChatCompanyList",
    method: "post",
    data,
  });
}
//获取群聊列表
export function getChatList(data) {
  return request({
    url: "api/v2/company/getChatList",
    method: "post",
    data,
  });
}
//公司绑定群聊
export function bingChatCompany(data) {
  return request({
    url: "api/v2/company/bingChatCompany",
    method: "post",
    data,
  });
}
//org绑定corp
export function bindOrg(data) {
  return request({
    url: "api/v2/company/bindOrg",
    method: "post",
    data,
  });
}
//获取org下公司列表
export function getOrgCompanyList(data) {
  return request({
    url: "api/v2/company/getOrgCompanyList",
    method: "post",
    data,
  });
}
//table列
export function arrangeInfo(data) {
  return request({
    url: "api/v2/company/arrangeInfo",
    method: "post",
    data,
  });
}
//table列保存
export function arrangeSave(data) {
  return request({
    url: "api/v2/company/arrangeSave",
    method: "post",
    data,
  });
}

//手动登录税局
export function orgInfos(data) {
  return request({
    url: "api/v2/manualSms/OrgInfos",
    method: "post",
    data,
  });
}

//切换企业
export function changeUse(data) {
  return request({
    url: "api/v2/manualSms/changeUse",
    method: "post",
    data,
  });
}

//手动登录需要的list
export function taxBureComList(data) {
  return request({
    url: "api/v2/company/taxBureComList",
    method: "post",
    data,
  });
}

//改变登录方式
export function saveEaCompanyYzmType(data) {
  return request({
    url: "api/v2/company/saveEaCompanyYzmType",
    method: "post",
    data,
  });
}

//新增修改主题色
export function saveComColour(data) {
  return request({
    url: "api/v2/company/saveComColour",
    method: "post",
    data,
  });
}

//查询list
export function comColourList(data) {
  return request({
    url: "api/v2/company/comColourList",
    method: "post",
    data,
  });
}

//删除主题色
export function deleteComColour(data) {
  return request({
    url: "api/v2/company/deleteComColour",
    method: "post",
    data,
  });
}

//v3设置停报公司
export function changeStatusNew(data) {
  return request({
    url: "api/v2/company/changeStatusNew",
    method: "post",
    data,
  });
}
//v3删除公司
export function delCompanyNew(data) {
  return request({
    url: "api/v2/company/delCompanyNew",
    method: "post",
    data,
  });
}
//v3已读sms消息
export function smsRecRead(data) {
  return request({
    url: "api/v2/company/smsRecRead",
    method: "post",
    data,
  });
}

//v3取数结账页面
export function retrievalSettlementCompanyList(data) {
  return request({
    url: "api/v2/company/RetrievalSettlementCompanyList",
    method: "post",
    data,
  });
}

//v3银行采集列表
export function getCompanyBankList(data) {
  return request({
    url: "api/v2/company/getCompanyBankList",
    method: "post",
    data,
  });
}

//勾选认证列表
export function companyCheckCertified(data) {
  return request({
    url: "api/v2/company/companyCheckCertified",
    method: "post",
    data,
  });
}

//v3批量修改登录方式
export function companyLoginList(data) {
  return request({
    url: "api/v2/company/companyLoginList",
    method: "post",
    data,
  });
}

//v3电子账簿list
export function electronicBookList(data) {
  return request({
    url: "api/v2/company/electronicBookList",
    method: "post",
    data,
  });
}
//v3修改装订状态
export function updateBindingStatus(data) {
  return request({
    url: "api/v2/company/updateBindingStatus",
    method: "post",
    data,
  });
}
//v3验证码list
export function smsRecListNew(data) {
  return request({
    url: "api/v2/company/smsRecListNew",
    method: "post",
    data,
  });
}

//v3社保list
export function companyShebaoList(data) {
  return request({
    url: "api/v2/company/companyShebaoList",
    method: "post",
    data,
  });
}

//v3税款统计list
export function companyTaxAmountList(data) {
  return request({
    url: "api/v2/company/companyTaxAmountList",
    method: "post",
    data,
  });
}

//v3电子账簿备注
export function comElectronicMemoryInfo(data) {
  return request({
    url: "api/v2/company/comElectronicMemoryInfo",
    method: "post",
    data,
  });
}

//v3电子账簿备注保存
export function comElectronicMemorySave(data) {
  return request({
    url: "api/v2/company/comElectronicMemorySave",
    method: "post",
    data,
  });
}

//v3电子账本修改审核人
export function updateAuditUser(data) {
  return request({
    url: "api/v2/company/updateAuditUser",
    method: "post",
    data,
  });
}

//v3扣缴端数据导入list
export function companyMoveGsList(data) {
  return request({
    url: "api/v2/company/companyMoveGsList",
    method: "post",
    data,
  });
}

//获取已经绑定关系列表
export function getBindedRef(data) {
  return request({
    url: "api/v2/gzh/getBindedRef",
    method: "post",
    data,
  });
}

// 查询返回 有哪些绑定人员
export function getBindUserList(data) {
  return request({
    url: "api/v2/gzh/getBindUserList",
    method: "post",
    data,
  });
}

// 确定
export function confirmBind(data) {
  return request({
    url: "api/v2/gzh/confirmBind ",
    method: "post",
    data,
  });
}

// 解除绑定
export function cannelBind(data) {
  return request({
    url: "api/v2/gzh/cannelBind ",
    method: "post",
    data,
  });
}

// 模板列表
export function getMsgTemplate(data) {
  return request({
    url: "api/v2/gzh/getMsgTemplate ",
    method: "post",
    data,
  });
}
//选择的推送公司
export function getMsgHistory(data) {
  return request({
    url: "api/v2/gzh/getMsgHistory",
    method: "post",
    data,
  });
}


// 解除绑定
export function saveEaCompanyMobileRec(data) {
  return request({
    url: "api/v2/company/saveEaCompanyMobileRec ",
    method: "post",
    data,
  });
}

// 解除绑定
export function saveEaCompanyLoginMethod(data) {
  return request({
    url: "api/v2/company/saveEaCompanyLoginMethod ",
    method: "post",
    data,
  });
}

// 发票影像
export function companyInvoicePdfList(data) {
  return request({
    url: "api/v2/company/companyInvoicePdfList ",
    method: "post",
    data,
  });
}

// 设置社保
export function companyShebaoStatus(data) {
  return request({
    url: "api/v2/company/companyShebaoStatus ",
    method: "post",
    data,
  });
}

export function getBindWechatCompany(data) {
  return request({
    url: "api/v2/company/getBindWechatCompany",
    method: "post",
    data,
  });
}

//批量设置
export function companyGets(data) {
  return request({
    url: "api/v2/company/companyGets ",
    method: "post",
    data,
  });
}

//批量设置保存
export function companySets(data) {
  return request({
    url: "api/v2/company/companySets ",
    method: "post",
    data,
  });
}

//授权管理list
export function companyLoginUserList(data) {
  return request({
    url: "api/v2/company/companyLoginUserList ",
    method: "post",
    data,
  });
}

//授权管理新增信息
export function creatCompanyLoginUser(data) {
  return request({
    url: "api/v2/company/creatCompanyLoginUser ",
    method: "post",
    data,
  });
}

//修改办税人信息
export function companyLoginUserUpdate(data) {
  return request({
    url: "api/v2/company/companyLoginUserUpdate ",
    method: "post",
    data,
  });
}

//授权管理删除信息
export function deleteCompanyLoginUser(data) {
  return request({
    url: "api/v2/company/deleteCompanyLoginUser ",
    method: "post",
    data,
  });
}

//单据统计
export function getCompanyDjtjList(data) {
  return request({
    url: "api/v2/company/getCompanyDjtjList ",
    method: "post",
    data,
  });
}


//年度亏损list
export function companyNdksList(data) {
  return request({
    url: "api/v2/company/companyNdksList ",
    method: "post",
    data,
  });
}

//登录授权链接
export function getQrcode(data) {
  return request({
    url: "api/v2/company/getQrcode",
    method: "post",
    data,
  });
}

//自动授权
export function autoEmpower(data) {
  return request({
    url: "api/v2/company/autoEmpower",
    method: "post",
    data,
  });
}

//手动授权
export function handEmpower(data) {
  return request({
    url: "api/v2/company/handEmpower",
    method: "post",
    data,
  });
}


//修改工商年报手机号
export function saveGsnbTel(data) {
  return request({
    url: "api/v2/company/saveGsnbTel",
    method: "post",
    data,
  });
}

//导出结账列表
export function exRetrievalSettlementCompany(data) {
  return request({
    url: "api/v2/company/exRetrievalSettlementCompany",
    method: "post",
    data,
  });
}

//往期未结账公司导出
export function exCompanyCarryOver(data) {
  return request({
    url: "api/v2/company/exCompanyCarryOver",
    method: "post",
    data,
  });
}


//报税小程序使用情况
export function xcxComInfo(data) {
  return request({
    url: "api/v2/company/xcxComInfo",
    method: "post",
    data,
  });
}

//工商年报批量设置短信接收方式
export function saveSetGsnb(data) {
  return request({
    url: "api/v2/company/saveSetGsnb",
    method: "post",
    data,
  });
}

export function authorization(data) {
  return request({
    url: "api/v2/company/xcxUserList",
    method: "post",
    data,
  });
}

export function accredit(data) {
  return request({
    url: "api/v2/company/xcxAuthUser",
    method: "post",
    data,
  });
}

export function xcxEditRemark(data) {
  return request({
    url: "api/v2/company/xcxEditRemark",
    method: "post",
    data,
  });
}

//申报监控list
export function sbTaxList(data) {
  return request({
    url: "api/v2/company/sbTaxList",
    method: "post",
    data,
  });
}

//header查询公司list
export function checkoutEaCompanyList(data) {
  return request({
    url: "api/v2/company/checkoutEaCompanyList",
    method: "post",
    data,
  });
}

//修改税局任务状态
export function updatePdTax(data) {
  return request({
    url: "api/v2/company/updatePdTax",
    method: "post",
    data,
  });
}
//撤销税局任务
export function cancelTax(data) {
  return request({
    url: "api/v2/company/cancelTax",
    method: "post",
    data,
  });
}

//优先处理任务
export function priorityPdTax(data) {
  return request({
    url: "api/v2/company/priorityPdTax",
    method: "post",
    data,
  });
}

// 任务统计列表

export function taxTaskStatisticsList(data) {
  return request({
    url: "api/v2/taxTaskStatisticsList",
    method: "post",
    data,
  });
}

// 公司任务统计列表

export function eaCompanyStatisticsList(data) {
  return request({
    url: "api/v2/taxEaCompanyStatisticsList",
    method: "post",
    data,
  });
}

// 公司明细统计列表

export function taxEaCompanyDatailStatisticsList(data) {
  return request({
    url: "api/v2/taxEaCompanyDatailStatisticsList",
    method: "post",
    data,
  });
}

// 当天公司任务统计列表

export function taxEaCompanyDtStatisticsList(data) {
  return request({
    url: "api/v2/taxEaCompanyDtStatisticsList",
    method: "post",
    data,
  });
}

// 当天公司明细统计列表

export function taxEaCompanyDtDatailStatisticsList(data) {
  return request({
    url: "api/v2/taxEaCompanyDtDatailStatisticsList",
    method: "post",
    data,
  });
}


// 公司折线图
export function zxTaxEaCompanyStatisticsList(data) {
  return request({
    url: "api/v2/zxTaxEaCompanyStatisticsList",
    method: "post",
    data,
  });
}

// 公司负责人角色列表
//账期列表
export function companyUserList(data) {
  return request({
    url: "api/v2/company/companyUserList",
    method: "post",
    data,
  });
}

//顶呱呱同步
export function syncDggData(data) {
  return request({
    url: "api/v2/company/syncDggData",
    method: "post",
    data,
  });
}

//公司合并
export function companyMerge(data) {
  return request({
    url: "api/v2/company/companyMerge",
    method: "post",
    data,
  });
}

//查询合并公司
export function companyMergeList(data) {
  return request({
    url: "api/v2/company/companyMergeList",
    method: "post",
    data,
  });
}

// 上传票据
export function uploadOcrData(data) {
  return request({
    url: "api/v2/company/uploadBill",
    method: "post",
    data,
  });
}

// 删除票据
export function delBill(data) {
  return request({
    url: "api/v2/company/delBill",
    method: "post",
    data,
  });
}

//顶呱呱采集工商银行
export function bankDggCollection(data) {
  return request({
    url: "api/v2/company/bankDggCollection",
    method: "post",
    data,
  });
}


export function GetQccNew(data) {
  return request({
    url: "api/v2/company/getQccNew",
    method: "post",
    data,
  });
}


//获取codes
export function findCodes(data) {
  return request({
    url: "api/v2/company/findCodes",
    method: "post",
    data,
  });
}

//扫码登录授权
export function getCompanyQrCode(data) {
  return request({
    url: "api/v2/company/getCompanyQrCode",
    method: "post",
    data,
  });
}
//ai操作日志
export function getLogNew(data) {
  return request({
    url: "api/v2/company/getLogNew",
    method: "post",
    data,
  });
}

//编号排序
export function sortCom(data) {
  return request({
    url: "api/v2/company/sortCom",
    method: "post",
    data,
  });
}

//个税完税证明
export function companyWsInitTaxList(data) {
  return request({
    url: "api/v2/company/companyWsInitTaxList",
    method: "post",
    data,
  });
}

//新的操作日志
export function getLogNewCompany(data) {
  return request({
    url: "api/v2/company/getLogNewCompany",
    method: "post",
    data,
  });
}

//新增登录信息
export function companyLoginComUpdate(data) {
  return request({
    url: "api/v2/company/companyLoginComUpdate",
    method: "post",
    data,
  });
}

//账套登录信息
export function companyLoginComList(data) {
  return request({
    url: "api/v2/company/companyLoginComList",
    method: "post",
    data,
  });
}

//获取绑定办税人信息的公司
export function companyLoginUserToCom(data) {
  return request({
    url: "api/v2/company/companyLoginUserToCom",
    method: "post",
    data,
  });
}

//取消绑定办税人信息
export function cancelCompanyLoginCom(data) {
  return request({
    url: "api/v2/company/cancelCompanyLoginCom",
    method: "post",
    data,
  });
}

//账务质检list
export function qualityTest(data) {
  return request({
    url: "api/v2/company/qualityTest",
    method: "post",
    data,
  });
}

///质检
export function qualityCom(data) {
  return request({
    url: "api/v2/company/qualityCom",
    method: "post",
    data,
  });
}

//批量设置是否确认
export function batchSetSure(data) {
  return request({
    url: "api/v2/company/batchSetSure",
    method: "post",
    data,
  });
}

//公司设置标签
export function companyEaSetLable(data) {
  return request({
    url: "api/v2/company/companyEaSetLable",
    method: "post",
    data,
  });
}

//授权登录设置优先级
export function companyLoginToComSeq(data) {
  return request({
    url: "api/v2/company/companyLoginToComSeq",
    method: "post",
    data,
  });
}
//电子档案上传
export function accEFile(data) {
  return request({
    url: "api/v2/company/accEFile",
    method: "post",
    data,
  });
}
//电子档案列表
export function getAccEFile(query) {
  return request({
    url: "api/v2/company/accEFile",
    method: "get",
    params: query
  });
}
//电子档案列表
export function getCounts(query) {
  return request({
    url: "api/v2/company/accEFile/counts",
    method: "get",
    params: query
  });
}

//删除电子档案
export function accEFileDel(data) {
  return request({
    url: "api/v2/company/accEFile/del",
    method: "post",
    data,
  });
}
//电子档案修改文件名
export function accEFileEdit(id,data) {
  return request({
    url: "/api/v2/company/accEFile/" + id,
    method: "post",
    data,
  });
}
//设置微信昵称
export function companyEaSetWxNc(data) {
  return request({
    url: "api/v2/company/companyEaSetWxNc",
    method: "post",
    data,
  });
}

//自动记账公司列表
export function eaCompanyAutomaticList(data) {
  return request({
    url: "api/v2/company/eaCompanyAutomaticList",
    method: "post",
    data,
  });
}

//企业内留言 发送消息 必传字段 ComId content
export function leaveMessage(data) {
  return request({
    url: "api/v2/company/leaveMessage",
    method: "post",
    data,
  });
}

//公司列表加消息未读提示
export function companyReadList(data) {
  return request({
    url: "api/v2/company/companyReadList",
    method: "post",
    data,
  });
}

//读取消息  //ComId
export function readChat(data) {
  return request({
    url: "api/v2/company/readChat",
    method: "post",
    data,
  });
}

//全部已读
export function readAllChat(data) {
  return request({
    url: "api/v2/company/readAllChat",
    method: "post",
    data,
  });
}

//消息列表comId
export function chatList(data) {
  return request({
    url: "api/v2/company/chatList",
    method: "post",
    data,
  });
}

//查看未读条数
export function getUnReadCount(data) {
  return request({
    url: "api/v2/company/getUnReadCount",
    method: "post",
    data,
  });
}
//获取待办人员列表
export function getDaibanPeople(data) {
  return request({
    url: "api/v2/company/getDaibanPeople",
    method: "post",
    data,
  });
}
//设置待办人员
export function setDaibanPeople(data) {
  return request({
    url: "api/v2/company/setDaibanPeople",
    method: "post",
    data,
  });
}
//授权小程序手机号查询
export function xcxUserList(data) {
  return request({
    url: "api/v2/company/xcxUserList",
    method: "post",
    data,
  });
}
//保存授权小程序
export function assignCom2TaxApp(data) {
  return request({
    url: "api/v2/company/assignCom2TaxApp",
    method: "post",
    data,
  });
}
//广告空列表
export function xcxAdvertisementinList(data) {
  return request({
    url: "api/v2/company/xcxAdvertisementinList",
    method: "post",
    data,
  });
}
//广告新增修改
export function saveAdvertisement(data) {
  return request({
    url: "api/v2/company/saveAdvertisement",
    method: "post",
    data,
  });
}
//广告删除
export function delAdvertisement(data) {
  return request({
    url: "api/v2/company/delAdvertisement",
    method: "post",
    data,
  });
}
//付费管理列表
export function xcxComPaymentList(data) {
  return request({
    url: "api/v2/company/xcxComPaymentList",
    method: "post",
    data,
  });
}
//付费管理修改金额
export function updateTaxAppComVipPrice(data) {
  return request({
    url: "api/v2/company/updateTaxAppComVipPrice",
    method: "post",
    data,
  });
}
//激活
export function activeTaxApp(data) {
  return request({
    url: "api/v2/company/activeTaxApp",
    method: "post",
    data,
  });
}
//实缴资本列表查询
export function xcxComPaildCapitalList(data) {
  return request({
    url: "api/v2/company/xcxComPaildCapitalList",
    method: "post",
    data,
  });
}
//实缴资本添加
export function createPaildCapital(data) {
  return request({
    url: "api/v2/company/createPaildCapital",
    method: "post",
    data,
  });
}
//实缴资本删除
export function deleteCapital(data) {
  return request({
    url: "api/v2/company/deleteCapital",
    method: "post",
    data,
  });
}
//授权小程序手机号查询
export function findXcxPeople(data) {
  return request({
    url: "api/v2/company/findXcxPeople",
    method: "post",
    data,
  });
}
//取消激活
export function cancelActive(data) {
  return request({
    url: "api/v2/company/cancelActive",
    method: "post",
    data,
  });
}

//批量修改工商年报信息
export function saveGsnbSingle(data) {
  return request({
    url: "api/v2/company/saveGsnbSingle",
    method: "post",
    data,
  });
}

//ai只能无票收入
export function aiWpsr(data) {
  return request({
    url: "api/v2/company/aiWpsr",
    method: "post",
    data,
  });
}

//批量添加无票收入
export function batchWpsr(data) {
  return request({
    url: "api/v2/company/batchWpsr",
    method: "post",
    data,
  });
}