<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      v-model="dialogTableVisible"
      :title="comName + ' / 随手记'"
      width="900px"
    >
      <div class="box" id="flexImd" v-loading="loading">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in list"
            :key="index"
            icon="Document"
            size="large"
            :timestamp="item.period"
            placement="top"
            class="navbar-item"
            :class="item.period == period ? 'active' : ''"
          >
            <div style="display: flex; align-items: center">
              <div style="width: 95%">
                <el-card>
                  <!-- 编辑 -->
                  <div v-if="item.disabled">
                    <el-input
                      v-model="item.jotDown"
                      :rows="9"
                      type="textarea"
                      placeholder="请输入"
                    />
                    <div class="bigimg" style="margin-left: 0">
                      <el-button type="primary" size="small">
                        <input
                          @change="uploadimg($event, item)"
                          class="inputfl"
                          type="file"
                          name="upfile"
                          accept=".png, .jpg, .jpeg"
                          multiple
                        />上传图片
                      </el-button>
                    </div>
                    <div class="opera_pic">
                      <div
                        v-for="(imgUrl, index) in item.jotDownImgs"
                        :key="index"
                        style="display: flex"
                      >
                        <img
                          class="pic"
                          :src="imgUrl"
                          alt=""
                          @click="checkImage([imgUrl])"
                        />
                        <a
                          class="del_btn"
                          @click="delImg(index, item.jotDownImgs)"
                          >x</a
                        >
                      </div>
                    </div>
                  </div>
                  <!-- 渲染值 -->
                  <div v-else>
                    <div
                      style="font-size: 14px"
                      v-html="(item.jotDown).replace(/\n/g, '<br/>')"
                    ></div>
                    <div class="opera_pic">
                      <div
                        style="display: flex"
                        v-for="(imgUrl, index) in item.jotDownImgs"
                        :key="index"
                      >
                        <img
                          class="pic"
                          :src="imgUrl"
                          alt=""
                          v-if="imgUrl"
                          @click="checkImage(item.jotDownImgs, index)"
                        />
                      </div>
                    </div>
                  </div>
                  <p
                    style="
                      color: var(--themeColor, #17a2b8);
                      font-size: 12px;
                      margin-top: 10px;
                    "
                    v-if="item.jotDownPeople"
                  >
                    {{ item.jotDownPeople }} 编辑于 {{ item.jotDownTime }}
                  </p>
                </el-card>
              </div>
              <!-- 操作 -->
              <div
                style="
                  margin-left: 10px;
                  color: var(--themeColor, #17a2b8);
                  cursor: pointer;
                "
              >
                <el-tooltip
                  content="编辑"
                  v-if="!item.disabled"
                  placement="top"
                >
                  <i
                    class="iconfont icon-bianji23"
                    @click="item.disabled = true"
                  ></i>
                </el-tooltip>
                <el-tooltip content="保存" v-else placement="top">
                  <i
                    class="iconfont icon-baocun"
                    @click="updateContent(item)"
                    style="margin-left: 5px"
                  ></i>
                </el-tooltip>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <el-popconfirm
        v-if="jotDownStatus == 0"
        title="确认取消每个账期重复吗"
        @confirm="confirmEvent"
        @cancel="cancelEvent"
        width="200px"
      >
        <template #reference>
          <el-checkbox v-model="jotDownStatusBool" @click.prevent="() => {}"
            >默认每个账期重复</el-checkbox
          >
        </template>
      </el-popconfirm>
      <el-popconfirm
        v-else
        title="确认启用每个账期重复吗"
        @confirm="confirmEvent"
        @cancel="cancelEvent"
        width="200px"
      >
        <template #reference>
          <el-checkbox v-model="jotDownStatusBool" @click.prevent="() => {}"
            >默认每个账期重复</el-checkbox
          >
        </template>
      </el-popconfirm>
    </el-dialog>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
    :initial-index="currentIndex"
  />
</template>

<script>
import { comMemoryInfo, comMemorySave, comMemorySaveSet } from "@/api/company";
import axios from "axios";
export default {
  name: "jotDown",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      id: "",
      comName: "",
      comId: null,
      period: "",
      list: [],
      item: {},
      content: "",
      updatePeriod: "",
      loading: false,
      jotDownStatus: 0,
      jotDownStatusBool: false,
      files: null,
      imgSrc: [],
      showImagePreview: false,
      currentIndex: 0,
    };
  },
  mounted() {},
  methods: {
    init(name, comId, period) {
      this.comName = name;
      this.comId = comId;
      this.period = period;
      this.dialogTableVisible = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      comMemoryInfo({ comId: this.comId }).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.jotDownStatus = res.data.data.jotDownStatus;
          if (this.jotDownStatus == 0) {
            this.jotDownStatusBool = true;
          } else {
            this.jotDownStatusBool = false;
          }
          var currentIndex = this.list.findIndex((item) => {
            if (item.period == this.period) {
              this.item = item;
            }
            return item.period == this.period;
          });
          this.changeLi(currentIndex, this.item);
        }
      });
    },
    changeLi(index, item) {
      if (!index || !item) {
        return;
      }
      this.$nextTick(() => {
        const item = this.$el.querySelectorAll(".navbar-item")[index];
        const container = document.querySelector("#flexImd");
        const scrollTop =
          item.offsetTop - (container.offsetHeight - item.offsetHeight) / 2;
        container.scrollTo({
          top: scrollTop,
          behavior: "smooth",
        });
      });
    },
    updateContent(item) {
      let param = {
        comId: this.comId,
        period: item.period,
        jotDown: item.jotDown,
        jotDownImgs: item.jotDownImgs,
      };
      comMemorySave(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("保存成功");
          if (this.period == item.period) {
            this.$emit("jotDownUpdate", this.comId, item.jotDown);
          }
          this.img = "";
          this.getList();
        }
      });
    },
    confirmEvent() {
      this.jotDownStatusBool = !this.jotDownStatusBool;
      if (this.jotDownStatusBool) {
        this.jotDownStatus = 0;
      } else {
        this.jotDownStatus = 1;
      }
      let param = {
        comId: this.comId,
        jotCopy: this.jotDownStatus,
      };
      comMemorySaveSet(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          // this.getList()
        }
      });
    },
    uploadimg(event, item) {
      this.files = event.target.files;
      this.addSub(item);
    },
    async addSub(item) {
      if (this.files.length == 0) {
        this.$qzfMessage("请上传图片", 1);
        return;
      }
      for (let k = 0; k < this.files.length; k++) {
        const isLt3M = this.files[k].size / 1024 / 1024 < 3;
        if (!isLt3M) {
          this.$qzfMessage("请不要上传大于3M的文件。", 1);
          return;
        }
      }
      for (let i = 0; i < this.files.length; i++) {
        const param = new FormData(); // 创建form对象
        let title = this.files[i].name.replace(/,/g, "");
        param.append("upFile", this.files[i], title); // 单个图片 ，多个用循环 append 添加
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        await axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            const imgs = res.data.data;
            item.jotDownImgs.push("https://file.listensoft.net" + imgs.url);
            console.log(item.jotDownImgs);
          })
          .catch(() => {});
      }
      this.$qzfMessage("上传成功");
    },
    checkImage(img, index) {
      this.showImagePreview = true;
      this.currentIndex = index ? index : 0;
      this.imgSrc = img;
    },
    //删除图片
    delImg(index, list) {
      console.log(list, index);
      list.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.box :deep(.el-timeline-item__content) {
  font-size: 18px !important;
}
.box {
  height: 500px;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 0px;
}
/*滚动条里面小方块*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.active :deep(.el-timeline-item__node) {
  background-color: var(--themeColor, #17a2b8);
}
:deep(textarea) {
  min-height: 145px !important;
}
.bigimg {
  cursor: pointer;
  margin-top: 10px;
  position: relative;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic {
  height: 70px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.del_btn {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
  left: -8px;
}
.opera_pic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
