<template>
  <!-- <div> -->
  <el-button
    v-if="$buttonStatus(button_code)"
    :type="type"
    :size="size"
    @click="confirm()"
    :plain="plain"
    :loading="loading"
    :text="text"
    :disabled="disabled"
    :link="link"
    :icon="icon"
  >
    <slot></slot>
  </el-button>
  <!-- </div> -->
</template>
<script>
export default {
  props: {
    jz: {
      type: Boolean,
      default: true,
    },
    button_code: {
      type: String,
      default: "show",
    },
    type: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "small",
    },
    plain: {
      type: Boolean,
      default: false,
    },
    //加载中状态
    loading: {
      type: Boolean,
      default: false,
    },
    //是否使用
    isLoading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    icon:{
      type: String,
      default:''
    }
  },
  watch: {
    loading(e) {
      // //console.log(this.loading,e)
    },
  },
  data() {
    return {
      status: true,
    };
  },
  created() {},
  methods: {
    confirm() {
      if (this.jz && this.jz != "false" && this.$checkSettleStatus()) return;
      this.$emit("success");
    },
  },
};
</script>
<style lang="scss" scoped></style>
