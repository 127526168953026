import request from "@/utils/request";

//新增客户
export function saveCustomer(data) {
  return request({
    url: "/api/v2/new_crm/saveCustomer",
    method: "post",
    data,
  });
}
//客户列表
export function customerList(data) {
  return request({
    url: "/api/v2/new_crm/customerList",
    method: "post",
    data,
  });
}
//批量分配
export function totalDistributionCustomer(data) {
  return request({
    url: "/api/v2/new_crm/totalDistributionCustomer",
    method: "post",
    data,
  });
}
//权限查看列表
export function distributionCustomerList(data) {
  return request({
    url: "/api/v2/new_crm/distributionCustomerList",
    method: "post",
    data,
  });
}
//转派删除分配人员
export function delDistributionCustomer(data) {
  return request({
    url: "/api/v2/new_crm/delDistributionCustomer",
    method: "post",
    data,
  });
}
//批量客户修改部分字段
export function totalUpdateCustomer(data) {
  return request({
    url: "/api/v2/new_crm/totalUpdateCustomer",
    method: "post",
    data,
  });
}
//批量停用客户
export function totalStopCustomer(data) {
  return request({
    url: "/api/v2/new_crm/totalStopCustomer",
    method: "post",
    data,
  });
}
//停用原因列表
export function lossList(data) {
  return request({
    url: "/api/v2/new_crm/lossList",
    method: "post",
    data,
  });
}
//删除客户
export function delCustomer(data) {
  return request({
    url: "/api/v2/new_crm/delCustomer",
    method: "post",
    data,
  });
}
//物品列表
export function goodsList(data) {
  return request({
    url: "/api/v2/new_crm/goodsList",
    method: "post",
    data,
  });
}
//新增接收物品
export function saveGoods(data) {
  return request({
    url: "/api/v2/new_crm/saveGoods",
    method: "post",
    data,
  });
}
//接收商品编号
export function goodsCodeList(data) {
  return request({
    url: "/api/v2/new_crm/goodsCodeList",
    method: "post",
    data,
  });
}
//新增归还物品
export function saveReturnGoods(data) {
  return request({
    url: "/api/v2/new_crm/saveReturnGoods",
    method: "post",
    data,
  });
}
//标记完成
export function confirmReturnGoods(data) {
  return request({
    url: "/api/v2/new_crm/confirmReturnGoods",
    method: "post",
    data,
  });
}
//物品详情
export function goodsInfo(data) {
  return request({
    url: "/api/v2/new_crm/goodsInfo",
    method: "post",
    data,
  });
}
//物品类型列表
export function goodsTypeList(data) {
  return request({
    url: "/api/v2/new_crm/goodsTypeList",
    method: "post",
    data,
  });
}
//新增物品类型列表
export function saveGoodsType(data) {
  return request({
    url: "/api/v2/new_crm/saveGoodsType",
    method: "post",
    data,
  });
}
//合同业务类型列表
export function contractTypeList(data) {
  return request({
    url: "/api/v2/new_crm/contractTypeList",
    method: "post",
    data,
  });
}

//合同收费类型列表
export function contractPaymentTypeList(data) {
  return request({
    url: "/api/v2/new_crm/contractPaymentTypeList",
    method: "post",
    data,
  });
}
//新增合同
export function saveContract(data) {
  return request({
    url: "/api/v2/new_crm/saveContract",
    method: "post",
    data,
  });
}
//合同列表
export function contractList(data) {
  return request({
    url: "/api/v2/new_crm/contractList",
    method: "post",
    data,
  });
}
//合同提交审核获取option
export function submitApproval(data) {
  return request({
    url: "/api/v2/new_crm/submitApproval",
    method: "post",
    data,
  });
}
//合同提交审核
export function contractApprove(data) {
  return request({
    url: "/api/v2/new_crm/contractApprove",
    method: "post",
    data,
  });
}
//审核历史
export function approvalHistory(data) {
  return request({
    url: "/api/v2/new_crm/approvalHistory",
    method: "post",
    data,
  });
}
//合同审核列表
export function contractAuditList(data) {
  return request({
    url: "/api/v2/new_crm/contractAuditList",
    method: "post",
    data,
  });
}
//删除合同
export function delContract(data) {
  return request({
    url: "/api/v2/new_crm/delContract",
    method: "post",
    data,
  });
}
//收款结算方式列表
export function paymentMethodList(data) {
  return request({
    url: "/api/v2/new_crm/paymentMethodList",
    method: "post",
    data,
  });
}
//新增结算方式list
export function saveMethod(data) {
  return request({
    url: "/api/v2/new_crm/saveMethod",
    method: "post",
    data,
  });
}
//删除结算方式
export function deleteMethod(data) {
  return request({
    url: "/api/v2/new_crm/deleteMethod",
    method: "post",
    data,
  });
}
//查询对应客户收款记录
export function contractPaymentList(data) {
  return request({
    url: "/api/v2/new_crm/contractPaymentList",
    method: "post",
    data,
  });
}
// 获取线索池列表
export function poolList(data) {
  return request({
    url: "api/v2/new_crm/clue/pool",
    method: "post",
    data,
  });
}

// 新增线索池
export function addClue(data) {
  return request({
    url: "api/v2/new_crm/clue/create",
    method: "post",
    data,
  });
}

// 我的线索列表
export function myList(data) {
  return request({
    url: "api/v2/new_crm/clue/my",
    method: "post",
    data,
  });
}

// 分配线索
export function getAssign(data) {
  return request({
    url: "api/v2/new_crm/clue/assign",
    method: "post",
    data,
  });
}

// 领取线索
export function getCollect(data) {
  return request({
    url: "api/v2/new_crm/clue/collect",
    method: "post",
    data,
  });
}

// 删除线索
export function getRecycle(data) {
  return request({
    url: "api/v2/new_crm/clue/recycle",
    method: "post",
    data,
  });
}

// 跟进线索
export function getFollow(data) {
  return request({
    url: "api/v2/new_crm/clue/follow",
    method: "post",
    data,
  });
}

// 释放线索
export function getRelease(data) {
  return request({
    url: "api/v2/new_crm/clue/release",
    method: "post",
    data,
  });
}

// 线索操作记录
export function operation(data) {
  return request({
    url: "api/v2/new_crm/clue/operation",
    method: "post",
    data,
  });
}

// 行业级联
export function industry(data) {
  return request({
    url: "api/v2/new_crm/clue/industry",
    method: "post",
    data,
  });
}

// 资料树形数据
export function contractInformation(data) {
  return request({
    url: "api/v2/new_crm/clue/contractInformation",
    method: "post",
    data,
  });
}

// 线索保护
export function protect(data) {
  return request({
    url: "api/v2/new_crm/clue/protect",
    method: "post",
    data,
  });
}

// 线索彻底删除
export function deletes(data) {
  return request({
    url: "api/v2/new_crm/clue/delete",
    method: "post",
    data,
  });
}

// 成交线索
export function deal(data) {
  return request({
    url: "api/v2/new_crm/clue/deal",
    method: "post",
    data,
  });
}

// 选项设置
export function settings(data) {
  return request({
    url: "api/v2/new_crm/clue/settings2",
    method: "post",
    data,
  });
}

// 参数设置
export function optionSetting(data) {
  return request({
    url: "api/v2/new_crm/clue/settings",
    method: "post",
    data,
  });
}


// 线索跟进状态列表
export function getFollowStageList(data) {
  return request({
    url: "api/v2/new_crm/clue/followStage",
    method: "post",
    data,
  });
}

// 线索等级列表数据
export function clueLevel(data) {
  return request({
    url: "api/v2/new_crm/clue/clueLevel",
    method: "post",
    data,
  });
}

// 参数设置查询
export function getSettings(data) {
  return request({
    url: "api/v2/new_crm/clue/getSettings",
    method: "post",
    data,
  });
}

// 参数设置查询
export function customerContractList(data) {
  return request({
    url: "api/v2/new_crm/customerContractList",
    method: "post",
    data,
  });
}

// 跟进记录查询
export function followList(data) {
  return request({
    url: "api/v2/new_crm/clue/follow/list",
    method: "post",
    data,
  });
}

// 流程设置新增
export function flowCreat(data) {
  return request({
    url: "api/v2/new_crm/outWork/flow/create",
    method: "post",
    data,
  });
}

// 流程设置列表
export function flowList(data) {
  return request({
    url: "api/v2/new_crm/outWork/flow/list",
    method: "post",
    data,
  });
}

// 流程设置步骤删除
export function stepDel(data) {
  return request({
    url: "api/v2/new_crm/outWork/flowStep/delete",
    method: "post",
    data,
  });
}

// 流程设置步骤编辑
export function stepUpdate(data) {
  return request({
    url: "api/v2/new_crm/outWork/flowStep/update",
    method: "post",
    data,
  });
}

// 流程设置删除
export function flowDel(data) {
  return request({
    url: "api/v2/new_crm/outWork/flow/delete",
    method: "post",
    data,
  });
}

// 任务安排列表
export function taskList(data) {
  return request({
    url: "api/v2/new_crm/outWork/task/list",
    method: "post",
    data,
  });
}

// 任务安排新增
export function taskCreate(data) {
  return request({
    url: "api/v2/new_crm/outWork/task/create",
    method: "post",
    data,
  });
}

//审批流相关
//新增审批流
export function saveApproval(data) {
  return request({
    url: "api/v2/new_crm/saveApproval",
    method: "post",
    data,
  });
}

//修改审批流
export function updateApproval(data) {
  return request({
    url: "api/v2/new_crm/updateApproval",
    method: "post",
    data,
  });
}

//公司模板审批列表
export function approvalTypeList(data) {
  return request({
    url: "api/v2/new_crm/approvalTypeList",
    method: "post",
    data,
  });
}

//公司审批列表
export function approvalList(data) {
  return request({
    url: "api/v2/new_crm/approvalList",
    method: "post",
    data,
  });
}

//删除审批流
export function delApproval(data) {
  return request({
    url: "api/v2/new_crm/delApproval",
    method: "post",
    data,
  });
}

//合同收款
export function saveContractPaymentRecord(data) {
  return request({
    url: "api/v2/new_crm/saveContractPaymentRecord",
    method: "post",
    data,
  });
}

//合同收款List
export function paymentList(data) {
  return request({
    url: "api/v2/new_crm/paymentList",
    method: "post",
    data,
  });
}

//欠费查询列表
export function arrearsPaymentList(data) {
  return request({
    url: "api/v2/new_crm/arrearsPaymentList",
    method: "post",
    data,
  });
}