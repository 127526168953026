<template>
  <el-drawer v-model="drawer" title="协同消息" destroy-on-close size="1130px" custom-class="chat_drawer">
    <div class="chat_window" v-loading="loading">
      <div class="left_div">
        <div class="top_select">
          <el-input placeholder="请输入公司名称" v-model="listQuery.name" style="width: 300px;height:28px" size="small"
            prefix-icon="Search" @keyup.enter="findCom" @input="findCom" />
        </div>
        <div class="choose_list">
          <el-scrollbar :height="contentStyleObj">
            <el-empty description="暂无公司" v-if="tableData.length == 0"></el-empty>
            <div v-for="(item, index) in tableData" :key="index" @click="changeCom(item, index)"
              :class="['com_list_style', { com_active: mesForm.comId === item.com_id }]">
              <div class="flex_align_center">
                <div :style="{ backgroundColor: getBackgroundColor(index) }" class="com_name_one">{{
                  item.name.charAt(0)
                }}</div>
                <div class="ellipsis w-240">{{ item.name }}
                  <el-tag type="warning" v-if="item.daiban">待办</el-tag>
                </div>
              </div>
              <div>
                <span class="wd_style" v-if="item.wd_count">
                  {{ item.wd_count }}
                </span>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="right_div" style="width:calc(100% - 330px)">
        <div class="right_div_buttondiv">
          <div class="flex_align_center">
            <div class="com_name_one" :style="{ backgroundColor: mesForm.comColor }">
              {{ mesForm.comName.charAt(0) }}
            </div>
            <div class="title_message">
              <div>
                {{ mesForm.comName }}
              </div>
              <div class="tax_no">
                {{ taxNo }}
              </div>
            </div>
          </div>
          <div class="read_all">
            <span @click="readAll"> <i class="iconfont icon-qingchu"></i>全部已读</span>
            <span @click="fresh"> <el-icon class="ml-10 mr-5" size="16" style="top:2px">
                <RefreshRight />
              </el-icon>刷新</span>
          </div>
        </div>
        <div class="filter_box">
          <el-input v-model="mesForm.contentFilter" class="w-210 mr-5" placeholder="搜索沟通记录" size="small" clearable
            @keyup.enter="getChatList">
            <template #append>
              <el-button icon="Search" size="small" @click="getChatList" />
            </template>
          </el-input>
          <el-checkbox v-model="mesForm.todoFilter" label="只看我的待办" :true-label="1" :false-label="0" size="small"
            @change="getChatList" />
        </div>
        <div class="tabs_box">
          <div v-for="item in tabs" :key="item.name" @click="changeTabs(item)"
            :class="['tab_content', { activeTab: mesForm.type === item.name }]">
            {{ item.name }}({{ item.count }})
          </div>
        </div>
        <div class="msg_content">
          <el-scrollbar :height="contentStyleObj1" ref="scrollbarRef">
            <div ref="scrollbarBox" v-if="list && list.length">
              <div class="on_clounm" style="padding-top: 10px" v-for="(item, index) in list" :key="index">
                <div>
                  <div class="time_div">
                    <p>{{ $parseTime(item.createdAt, '{y}-{m}-{d} {h}:{i}:{s}') }}</p>
                  </div>
                  <div v-if="storeUserId == item.userId" class="mr-10">
                    <div class="flex_end">
                      <el-popover placement="left" trigger="hover" :width="110">
                        <div class="daiban mb-5" @click="setDaiban(item, 2)" v-if="item.daibanName">
                          <el-icon>
                            <Failed />
                          </el-icon>取消待办
                        </div>
                        <div class="daiban mb-5" @click="setDaiban(item, 1)" v-else>
                          <el-icon>
                            <Calendar />
                          </el-icon>我的待办
                        </div>
                        <div class="daiban" @click="setDaibanOther(item)">
                          <el-icon>
                            <User />
                          </el-icon>他人待办
                        </div>
                        <template #reference>
                          <div class="dives dives_right ml-10">
                            <p v-if="item.daibanName" class="daiban_text">
                              <el-tag type="warning">{{ item.daibanName }}待办</el-tag>
                            </p>
                            <p v-if="item.content.match('https://file.listensoft.net')" class="val_p">
                              <i :class="filterIcon(item.content)"></i>{{ filterFile(item.content) }}
                              <span class="clickable_style ml-5" @click="downLoadUrl(item.content)">下载</span>
                            </p>
                            <p class="val_p" v-else>
                              {{ item.content }}
                            </p>
                          </div>
                        </template>
                      </el-popover>
                      <div class="com_name_one mr-10 ml-10" style="background-color: #17a2b8;">{{
                        userName.charAt(0)
                      }}</div>
                    </div>
                  </div>
                  <div v-else style="display: flex; align-items: center">
                    <div class="com_name_one mr-10 ml-10" :style="{ backgroundColor: mesForm.comColor }">{{
                      item?.cnName.charAt(0)
                    }}</div>
                    <div>
                      <p style="font-size: 14px;">{{ item.cnName }}</p>
                      <el-popover placement="right" trigger="hover" :width="110">
                        <div class="daiban mb-5" @click="setDaiban(item, 2)" v-if="item.daibanName">
                          <el-icon>
                            <Failed />
                          </el-icon>取消待办
                        </div>
                        <div class="daiban mb-5" @click="setDaiban(item, 1)" v-else>
                          <el-icon>
                            <Calendar />
                          </el-icon>我的待办
                        </div>
                        <div class="daiban" @click="setDaibanOther(item)">
                          <el-icon>
                            <User />
                          </el-icon>他人待办
                        </div>
                        <template #reference>
                          <div class="dives dives_left ">
                            <p v-if="item.daibanName" class="daiban_text">
                              <el-tag type="warning">{{ item.daibanName }}待办</el-tag>
                            </p>
                            <p v-if="item.content.match('https://file.listensoft.net')" class="val_p">
                              <i :class="filterIcon(item.content)"></i>{{ filterFile(item.content) }}
                              <span class="clickable_style ml-5" @click="downLoadUrl(item.content)">下载</span>
                            </p>
                            <p class="val_p" v-else>
                              {{ item.content }}
                            </p>
                          </div>
                        </template>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <el-empty description="点击左侧公司名，查看消息" v-if="!mesForm.comId" />
            </div>
          </el-scrollbar>
        </div>
        <div class="under_div">
          <div class="input_div">
            <el-input type="textarea" v-model="mesForm.content" @keydown="handleKeydown" placeholder="请输入内容，不超过500个字"
              maxLength="500">
            </el-input>
            <div class="upload_div">
              <el-upload v-model:file-list="fileList" action="https://public.listensoft.net/api/uploadImg"
                :http-request="customUpload" :on-success="handleSuccess" :on-error="handleError"
                :before-upload="beforeUpload">
                <el-button type="primary" icon="Link" link :disabled="!mesForm.comId">上传文件</el-button>
              </el-upload>
            </div>
          </div>
          <div class="send_div">
            <el-button type="primary" @click="makeSurePush" :disabled="!mesForm.comId" icon="Promotion">发送</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
  <daibanSet ref="daibanSetRef" @success="fresh"></daibanSet>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance } from 'vue'
import { companyReadList, leaveMessage, readChat, readAllChat, chatList, setDaibanPeople } from "@/api/company"
import axios from 'axios'
import { ElMessage } from 'element-plus'
import daibanSet from './daibanSet.vue'
const { proxy } = getCurrentInstance()
const drawer = ref(false)
const tableData = ref([])
const list = ref([])
const listQuery = ref({
  name: "",
  page: 1,
  limit: 100,
})
const contentStyleObj = ref({})
const contentStyleObj1 = ref({})
const daibanSetRef = ref()
const mesForm = ref({
  comId: 0,
  comName: "",
  content: "",
  comColor: "",
  type: "发票"
})
const loading = ref(false)
const storeUserId = proxy.$store.getters["user/user"].userId
const userName = proxy.$store.getters["user/user"].cnName
const scrollbarBox = ref(null)
const scrollbarRef = ref(null)
const backgroundColors = ['#5db2ff', '#ffb769', '#acbfea', '#f7c9D4', '#778ccd']
const fileList = ref([])
const baseUrl = ref("https://file.listensoft.net");
const clickStatus = ref(false)
const tabs = ref([
  { name: "发票", count: 0 },
  { name: "银行", count: 0 },
  { name: "薪酬", count: 0 },
  { name: "税款", count: 0 },
  { name: "社保", count: 0 },
  { name: "其他", count: 0 },
])
const tabMapping = {
  fp: 0,
  yh: 1,
  xc: 2,
  sk: 3,
  sb: 4,
  qt: 5
};
const taxNo = ref('')
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(130);
  contentStyleObj1.value = proxy.$getHeight(424);
})
const findCom = () => {
  companyReadList(listQuery.value).then(res => {
    if (res.data.msg == 'success') {
      tableData.value = res.data.data.list ? res.data.data.list : []
    }
  })
}
//获取左侧公司list
const getComList = async () => {
  loading.value = true
  await companyReadList(listQuery.value).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      tableData.value = res.data.data.list ? res.data.data.list : []
    }
  })
}
//获取聊天记录list
const getChatList = () => {
  list.value = []
  if (!mesForm.value.comId) return ElMessage.warning("请选择公司");
  chatList(mesForm.value).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      list.value = res.data.data.list || []
      taxNo.value = res.data.data.taxNo || ""
      scrollEvent()
      Object.keys(tabMapping).forEach(key => {
        const index = tabMapping[key];
        tabs.value[index].count = res.data.data[key];
      });
    }
  })
}
const scrollEvent = () => {
  setTimeout(() => {
    if (scrollbarBox.value) {
      scrollbarRef.value.setScrollTop(scrollbarBox.value.offsetHeight)
    }
  }, 10)
}
//点击公司时
const changeCom = async (item, index) => {
  if (clickStatus.value) return
  mesForm.value = {
    comColor: getBackgroundColor(index),
    comId: item.com_id,
    comName: item.name,
    content: "",
    type: "发票",
    contentFilter: "",
    todoFilter: 0
  }
  item.wd_count = 0
  fileList.value = []
  await readChat(mesForm.value).then(() => { })
  getChatList()
}
//点击tab时
const changeTabs = async (item) => {
  if (clickStatus.value) return
  mesForm.value.type = item.name
  item.count = 0
  await readChat(mesForm.value).then(() => { })
  getChatList()
}
const makeSurePush = () => {
  clickStatus.value = true
  leaveMessage(mesForm.value).then(res => {
    clickStatus.value = false
    if (res.data.msg == 'success') {
      mesForm.value.content = ''
      fileList.value = []
      getChatList()
    }
  })
}
const readAll = () => {
  readAllChat({}).then(res => {
    if (res.data.msg == 'success') {
      tableData.value.map(v => {
        v.wd_count = 0
      })
    }
  })
}
const setDaiban = (item, flag) => {
  let param = {
    chatId: item.id,
    userId: storeUserId,
    type: flag
  }
  setDaibanPeople(param).then(res => {
    if (res.data.msg == 'success') {
      getChatList()
      getComList()
    }
  })
}

const setDaibanOther = (item) => {
  daibanSetRef.value.init(item)
}
const init = () => {
  drawer.value = true
  mesForm.value = {
    comId: 0,
    comName: "",
    content: "",
    comColor: "",
    type: "发票",
    contentFilter: "",
    todoFilter: 0
  }
  taxNo.value = ''
  fileList.value = []
  list.value = []
  tabs.value.forEach(v => {
    v.count = 0
  })
  getComList()
}
const fresh = () => {
  getComList()
  getChatList()
}
// 计算背景色
const getBackgroundColor = (index) => {
  return backgroundColors[index % backgroundColors.length]
}
const handleKeydown = (event) => {
  if (event.key === 'Enter') {
    if (!mesForm.value.comId) return
    event.preventDefault()
    makeSurePush()
  }
}
const downLoadUrl = (url) => {
  window.open(url)
}
//上传文件
const customUpload = (options) => {
  const { action, file, onSuccess, onError } = options;
  const formData = new FormData();
  let title = file.name.replace(/,/g, "");
  formData.append("upFile", file, title);
  formData.append("path", "/mnt/v2/chat");
  let config = { headers: { "Content-Type": "multipart/form-data" } };
  axios
    .post(action, formData, config)
    .then((res) => {
      if (res.data.msg == "success") {
        let url = baseUrl.value + res.data.data.url;
        fileList.value.push({ url, name: url.split("/").pop() });
        onSuccess(res);
      }
    })
    .catch((error) => {
      onError(error);
    });
};
const beforeUpload = (file) => {
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    ElMessage.error("文件大小不能超过10MB");
  }
  return isLt10M;
};
const handleSuccess = (response, file) => {
  file.url = baseUrl.value + response.data.data.url;
  ElMessage.success("文件上传成功");
  mesForm.value.content = file.url
  makeSurePush()
};

const handleError = () => {
  ElMessage.error("文件上传失败");
};
const filterIcon = (type) => {
  const mapData = {
    txt: 'iconfont icon-wenjianleixing-biaozhuntu-jishiben',
    doc: 'iconfont icon-wenjianleixing-biaozhuntu-Wordwendang',
    docx: 'iconfont icon-wenjianleixing-biaozhuntu-Wordwendang',
    xls: 'iconfont icon-wenjianleixing-biaozhuntu-gongzuobiao',
    xlsx: 'iconfont icon-wenjianleixing-biaozhuntu-gongzuobiao',
    pdf: 'iconfont icon-wenjianleixing-biaozhuntu-PDFwendang',
  }
  let arr = type.split('.')
  let str = arr[arr.length - 1]
  return mapData[str] || 'iconfont icon-wenjianleixing-biaozhuntu-weizhiwenjian'
}
const filterFile = (type) => {
  var match = type.match(/\/([^/?]+)$/);
  return match ? match[1] : null;
}
defineExpose({
  init
})

</script>

<style scoped lang="scss">
.chat_window {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: none;
  border-right: none;
}

.top_select {
  margin: 10px 0 10px 10px;
}

.com_list_style {
  border-radius: 4px;
  width: 320px;
  height: 58px;
  cursor: pointer;
  padding: 0 10px 0 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.com_name_one {
  width: 28px;
  height: 28px;
  border-radius: 10%;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}

.com_active {
  background-color: #f4f7ff;
}

.under_div {
  border: 1px solid #efefef;
  padding-bottom: 15px;
  height: 240px;
  position: relative;
  border-bottom: none;
}

.send_div {
  padding: 0 10px;
  margin-top: 5px;
  text-align: right;
}

.right_div {
  display: flex;
  flex-direction: column;
}

.right_div_buttondiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-left: 1px solid #efefef;
  align-items: center;

  .title_message {
    font-weight: bold;
    font-size: 14px;
  }
}

///聊天部分
.msg_content {
  background-color: #f4f7ff;
  padding-bottom: 10px;
  border-left: 1px solid #efefef;
}

.on_clounm {
  display: flex;
  justify-content: end;
  flex-direction: column;

  img {
    width: 30px;
    height: 30px;
  }
}

.time_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  p {
    color: #a6a6a6;
    font-size: 12px;
  }
}

.dives {
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}


.val_p {
  font-size: 13px;
  max-width: 380px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.dives_right {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.dives_left {
  background: #fff;
  margin-top: 2px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

//输入框样式
.input_div {
  padding: 10px 10px 0;
  position: relative;

  .el-input {
    border: none;
    width: 100%;
  }
}

.upload_div {
  position: absolute;
  bottom: 5px;
  left: 20px;
  z-index: 1;
}

:deep(.el-textarea__inner) {
  height: 185px;
  overflow-y: auto;
  resize: none;
  box-shadow: 0 0 0 1px #e7ebeb;
}

:deep(.el-textarea__inner::-webkit-scrollbar) {
  width: 0px;
}

/*滚动条里面小方块*/
:deep(.el-textarea__inner::-webkit-scrollbar-thumb) {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}

.wd_style {
  font-size: 12px;
  text-align: center;
  background-color: #fd0002;
  color: #fff;
  border-radius: 50%;
  padding: 0 5px;
}

.read_all {
  padding-right: 20px;
  font-size: 13px;
  cursor: pointer;
  color: #17a2b8;
  display: flex;
  align-items: center;

  .icon-qingchu {
    color: #17a2b8;
  }
}

:deep(.el-upload-list) {
  display: none;
}

.icon-wenjianleixing-biaozhuntu-gongzuobiao {
  color: #4cb050;
}

.icon-wenjianleixing-biaozhuntu-jishiben {
  color: #8289ad;
}

.icon-wenjianleixing-biaozhuntu-weizhiwenjian {
  color: #cccccc;
}

.icon-wenjianleixing-biaozhuntu-PDFwendang {
  color: #fa4e4e;
}

.icon-wenjianleixing-biaozhuntu-Wordwendang {
  color: #4876f9;
}

.iconfont {
  margin-right: 2px;
}

.tabs_box {
  display: flex;
  padding: 0 10px;

  .tab_content {
    cursor: pointer;
    padding-bottom: 5px;
    margin-right: 30px;
    font-size: 14px;
  }

  .activeTab {
    border-bottom: 2px solid #17a2b8;
  }
}

.daiban {
  display: flex;
  align-items: center;
  cursor: pointer;

  .el-icon {
    margin-right: 5px;
    font-size: 13px;
  }
}

.daiban_text {
  margin-bottom: 2px;
}

.filter_box {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 10px;
}

.tax_no {
  font-weight: normal;
  font-size: 11px;
}
</style>