<template>
  <el-select @change="changeValue" v-model="userId" placeholder="选择人员"  style="width: 100px;" size="small" filterable  :multiple="multiples" collapse-tags collapse-tags-tooltip clearable>
    <!-- <el-option
     key="0"
     :label="defaultOption.CnName"
     :value="defaultOption.id"
    >

    </el-option> -->
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.CnName"
      :value="item.id"
      size="small">
    </el-option>
  </el-select>
</template>

<script>
import { findPeople } from '@/api/base'
export default {
  name: 'selectUser',
  props: {
    options: Array,
    userId: {  
      type: [Number, Array],  // 修改类型定义  
      default: () => []  // 默认值为空数组  
    },  
    code: '',
    multiples: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options:[],
      defaultOption:{
        CnName:'全部',
        id:0
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      findPeople({code:this.code}).then(res => {
        this.options = res.data.data.list
      })
    },
    changeValue() {
      // 处理特殊的全选逻辑  
      this.$emit('update:userId', this.userId)
      this.$emit("success", this.userId)
      // if (this.userId.length === 1 && this.userId[0] === 0) {  
      //   this.$emit('update:userId', [])
      //   this.$emit("success", [])
      // }else{
      //   this.$emit('update:userId', this.userId)
      //   this.$emit("success", this.userId)
      // }
    },
    clearValue(){
      this.userId = null
    }
  }
}
</script>

<style scoped>
  
</style>
<!-- 参数type  回调方法select -->