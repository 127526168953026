import Layout from "@/views/layout/index";

// noCache 不缓存
// hidden 不在左侧菜单栏显示
//show 不受权限影响
const eaRouter = [
  {
    path: "/redirects",
    component: Layout,
    name: "redirects",
    redirect: "/redirects/redirectsindex",
    hidden: true,
    meta: {
      title: "跳转页",
      noCache: true,
      show: true,
    },
    children: [
      {
        path: "redirectsindex",
        component: () => import("@/views/redirect/index"),
        name: "redirectsindex",
        meta: {
          title: "跳转页子叶",
          noCache: true,
          show: true,
        },
      },
    ],
  },

  {
    path: "/company",
    component: Layout,
    name: "company",
    meta: { title: "客户管理", icon: "user" },
    children: [
      {
        path: "subject_balance_uploadExcel_new",
        component: () =>
          import("@/views/company/subject_balance_uploadExcel_new"),
        name: "subject_balance_uploadExcel_new",
        meta: { title: "导入科目余额", icon: "xinzengkehu1" },
      },
      {
        path: "editCustomer",
        component: () => import("@/views/company/comInfo"),
        name: "editCustomer",
        meta: { title: "修改客户信息", icon: "jinggao" },
        hidden: true,
      },
      {
        path: "redirection",
        component: () => import("@/views/company/redirection"),
        name: "redirection",
        meta: { title: "跳转修改", icon: "jinggao" },
        hidden: true,
      },
    ],
  },
  //票据采集
  {
    path: "/billCollect",
    component: Layout,
    name: "billCollect",
    meta: { title: "票据采集", icon: "reading" },
    children: [
      {
        path: "checkCerti",
        component: () => import("@/views/batch/checkCerti"),
        name: "checkCerti",
        meta: { title: "勾选认证", icon: "icon-piaojucaiji1" },
      },
      {
        path: "collectInvoice",
        component: () => import("@/views/batch/collectInvoice"),
        name: "collectInvoice",
        meta: { title: "发票采集", icon: "icon-piaojucaiji1" },
      },
      {
        path: "collectBank",
        component: () => import("@/views/batch/collectBank"),
        name: "collectBank",
        meta: { title: "银行采集", icon: "icon-lishishujucaiji" },
      },
      {
        path: "attachmentUpload",
        component: () => import("@/views/batch/attachmentUpload"),
        name: "attachmentUpload",
        meta: { title: "票据上传", icon: "icon-lishishujucaiji" },
      },
    ],
  },
  //财务核算
  {
    path: "/",
    name: "home",
    component: Layout,
    redirect: "/homeIndex",
    meta: {
      title: "首页",
      icon: "icon-home",
    },
    children: [
      {
        path: "homeIndex",
        component: () => import("@/views/home/homeEntrance"),
        name: "homeIndex",
        meta: { title: "首页", icon: "House" },
      },
    ],
  },
  {
    path: "/financial-acco",
    component: Layout,
    name: "financial-acco",
    meta: { title: "财务核算", icon: "CopyDocument" },
    children: [
      {
        path: "bookkeeping",
        component: () => import("@/views/company/bookkeeping"),
        name: "bookkeeping",
        meta: { title: "记账客户", icon: "icon-hetongliebiao1" },
      },
      {
        path: "batch-settle",
        component: () => import("@/views/batch/batch-settle"),
        name: "batch-settle",
        meta: { title: "批量取数", icon: "icon-jiezhangdayin1" },
      },
      {
        path: "eletric-book",
        component: () => import("@/views/company/eletric-book"),
        name: "eletric-book",
        meta: { title: "电子账簿", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "monitor",
        component: () => import("@/views/company/monitor"),
        name: "monitor",
        meta: { title: "云服务", icon: "icon-gongsiliebiao1" },
        hidden: true,
      },
    ],
  },
  {
    path: "/kefu",
    component: Layout,
    name: "kefu",
    meta: { title: "客服管理", icon: "user" },
    children: [
      {
        path: "empower",
        component: () => import("@/views/company/empower"),
        name: "empower",
        meta: { title: "授权页面", icon: "icon-shouquanyemian1" },
      },
      {
        path: "kefuOrgList",
        component: () => import("@/views/company/kefuOrgList"),
        name: "kefuOrgList",
        meta: { title: "公司列表", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "aiFinanceFactory",
        component: () => import("@/views/autoHome/aiFinanceFactory"),
        name: "aiFinanceFactory",
        meta: { title: "Ai财务工厂", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "operations",
        component: () => import("@/views/company/operations"),
        name: "operations",
        meta: { title: "操作说明", icon: "icon-gongsiliebiao1" },
        hidden: true,
      },
      {
        path: "accountState",
        component: () => import("@/views/company/accountState"),
        name: "accountState",
        meta: { title: "银行列表", icon: "icon-gongsiliebiao1" },
        hidden: true,
      },
    ],
  },

  {
    path: "/initEa",
    component: Layout,
    name: "initEa",
    meta: { title: "初始化操作", icon: "reading" },
    children: [
      {
        path: "initChushihua",
        component: () =>
          import("@/views/input/remuneration/components/information"),
        name: "initChushihua",
        meta: { title: "个税初始化", icon: "icon-geshuichushihua1" },
      },
      {
        path: "additionalDeduction",
        component: () =>
          import("@/views/initOperation/components/additionalDeduction"),
        name: "additionalDeduction",
        meta: { title: "专向附加扣除", icon: "icon-geshuichushihua1" },
      },
      {
        path: "initVatInvoice",
        component: () => import("@/views/report/tax/vat-invoice/vatInvoice"),
        name: "initVatInvoice",
        meta: { title: "增值税一般纳税人申报表", icon: "icon-home" },
      },
      {
        path: "initSmallVat",
        component: () => import("@/views/report/tax/small-vat/smallVat"),
        name: "initSmallVat",
        meta: { title: "小规模增值税及附加税费申报表", icon: "icon-home" },
      },
      {
        path: "initQysds",
        component: () => import("@/views/report/tax/qysds/qysds"),
        name: "initQysds",
        meta: { title: "企业所得税申报表", icon: "icon-home" },
      },
      {
        path: "initPropertyTax",
        component: () => import("@/views/report/tax/propertyTax/propertyTax"),
        name: "initPropertyTax",
        meta: { title: "财产和行为税纳税申报表", icon: "icon-home" },
      },
      {
        path: "initAdditionalTax",
        component: () => import("@/views/report/tax/otherTable/additionalTax"),
        name: "initAdditionalTax",
        meta: { title: "附加税", icon: "icon-home" },
      },
      {
        path: "initEditTax",
        component: () => import("@/views/report/tax/otherTable/editTax"),
        name: "initEditTax",
        meta: { title: "印花税纳税申报（报告）表", icon: "icon-home" },
      },
      {
        path: "initFcs",
        component: () => import("@/views/report/tax/otherTable/fcs"),
        name: "initFcs",
        meta: { title: "房产税土地税", icon: "icon-home" },
      },
      {
        path: "initTaxNeimengQtsr",
        component: () => import("@/views/report/tax/otherTable/taxNeimengQtsr"),
        name: "initTaxNeimengQtsr",
        meta: { title: " 其他收入(工会经费)", icon: "icon-home" },
      },
      {
        path: "initXfs",
        component: () => import("@/views/report/tax/otherTable/xfs"),
        name: "initXfs",
        meta: { title: "消费税", icon: "icon-home" },
      },
      {
        path: "initTaxNeimengSl",
        component: () => import("@/views/report/tax/otherTable/taxNeimengSl"),
        name: "initTaxNeimengSl",
        meta: { title: "水利建设专项收入", icon: "icon-home" },
      },
      {
        path: "initTaxCityLife",
        component: () => import("@/views/report/tax/otherTable/taxCityLife"),
        name: "initTaxCityLife",
        meta: { title: "城市生活垃圾处置费", icon: "icon-home" },
      },
      {
        path: "initCbj",
        component: () => import("@/views/report/tax/otherTable/cbj"),
        name: "initCbj",
        meta: { title: "残疾人保障金", icon: "icon-home" },
      },
      {
        path: "initCulturalTax",
        component: () => import("@/views/report/tax/otherTable/culturalTax"),
        name: "initCulturalTax",
        meta: { title: "文化事业建设费", icon: "icon-home" },
      },
      {
        path: "kjInitTable",
        component: () => import("@/views/report/finance/finance"),
        name: "kjInitTable",
        meta: { title: "初始化财务报表", icon: "icon-chushihuabaobiao" },
      },
      {
        path: "gsInitTable",
        component: () => import("@/views/report/tax/tax"),
        name: "gsInitTable",
        meta: { title: "初始化税务报表", icon: "icon-caiwubaobiao" },
      },
      {
        path: "oldInit",
        component: () => import("@/views/company/oldInit"),
        name: "oldInit",
        meta: { title: "历史数据", icon: "icon-lishishuju1" },
      },
      {
        path: "addAccounts",
        component: () => import("@/views/company/addAccounts"),
        name: "addAccounts",
        meta: { title: "建账采集", icon: "icon-lishishujucaiji" },
      },
    ],
  },
  //质检
  {
    path: "/qualityTesting",
    component: Layout,
    name: "qualityTesting",
    meta: { title: "账务质检", icon: "reading" },
    children: [
      {
        path: "bookQuality",
        component: () => import("@/views/batch/bookQuality"),
        name: "bookQuality",
        meta: { title: "账务质检", icon: "reading" },
      },
    ],
  },
  //统计风控
  {
    path: "/total-risk",
    component: Layout,
    name: "total-risk",
    meta: { title: "统计风控", icon: "files" },
    children: [
      {
        path: "taxStatistics",
        component: () => import("@/views/batch/taxStatistics"),
        name: "taxStatistics",
        meta: { title: "税款统计", icon: "icon-shuikuantongji1" },
      },

      {
        path: "financial-total",
        component: () => import("@/views/batch/cwtj"),
        name: "financial-total",
        meta: { title: "财务统计", icon: "icon-tubiao_caiwutongji" },
      },
      {
        path: "billStatistics",
        component: () => import("@/views/batch/billStatistics"),
        name: "billStatistics",
        meta: { title: "单据统计", icon: "icon-tubiao_caiwutongji" },
      },
      {
        path: "taskStatistics",
        component: () => import("@/views/batch/taskStatistics"),
        name: "taskStatistics",
        meta: { title: "任务统计", icon: "icon-tubiao_caiwutongji" },
      },
      {
        path: "progressTracking",
        component: () => import("@/views/batch/progressTracking"),
        name: "progressTracking",
        meta: { title: "进度跟踪", icon: "icon-shuikuantongji1" },
      },
      {
        path: "progressTask",
        component: () => import("@/views/batch/progressTask"),
        name: "progressTask",
        meta: { title: "任务进度", icon: "icon-shuikuantongji1" },
      },
    ],
  },

  {
    path: "/weHome",
    component: Layout,
    name: "weHome",
    redirect: "/weSev",
    meta: { title: "首页", icon: "icon-home" },
    children: [
      {
        path: "weSev",
        component: () => import("@/views/batch/weHome/weSev"),
        name: "weSev",
        meta: { title: "首页", icon: "House" },
      },
    ],
  },
  //企微服
  {
    path: "/en-we-service",
    component: Layout,
    name: "en-we-service",
    meta: { title: "企微服", icon: "files" },
    children: [
      {
        path: "pushInfomation",
        component: () => import("@/views/batch/pushInfomation"),
        name: "pushInfomation",
        meta: { title: "微信推送", icon: "icon-weixintuisong" },
      },
      {
        path: "wecom",
        component: () => import("@/views/batch/wecom"),
        name: "wecom",
        meta: { title: "企微服务", icon: "icon-qiyeweixin" },
      },
    ],
  },
  //报税小程序
  {
    path: "/taxApp",
    component: Layout,
    name: "taxApp",
    meta: { title: "报税小程序", icon: "files" },
    children: [
      {
        path: "adManagement",
        component: () => import("@/views/batch/adManagement"),
        name: "adManagement",
        meta: { title: "广告管理", icon: "icon-weixintuisong" },
      },
      {
        path: "payment",
        component: () => import("@/views/batch/payment"),
        name: "payment",
        meta: { title: "付费管理", icon: "icon-qiyeweixin" },
      },
    ],
  },
  {
    path: "/batch",
    component: Layout,
    name: "batch",
    meta: { title: "批量报税", icon: "files" },
    children: [
      {
        path: "batch-send-tax",
        component: () => import("@/views/batch/batch-send-tax"),
        name: "batch-send-tax",
        meta: { title: "电子税局", icon: "icon-dianzishuiju" },
      },
      {
        path: "gsgsSb",
        component: () => import("@/views/batch/gsgsSb"),
        name: "gsgsSb",
        meta: { title: "个税申报", icon: "icon-geshuishenbao2" },
      },
      {
        path: "taxCheck",
        component: () => import("@/views/batch/taxCheck"),
        name: "taxCheck",
        meta: { title: "批量检查", icon: "icon-piliangjiancha2" },
      },
      {
        path: "sendShebao",
        component: () => import("@/views/batch/taxPay"),
        name: "sendShebao",
        meta: { title: "社保申报", icon: "icon-shebaoshenbao1" },
      },
      {
        path: "loginTaxBureau",
        component: () => import("@/views/ea-setting/loginTaxBureau"),
        name: "loginTaxBureau",
        meta: { title: "授权登录", icon: "icon-shoudongdenglu" },
        hidden: true,
      },
      {
        path: "orgHabbitSetting",
        component: () => import("@/views/batch/orgHabbitSetting"),
        name: "orgHabbitSetting",
        meta: { title: "通用设置", icon: "icon-lishixinxi" },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    name: "user",
    meta: { title: "用户管理", icon: "user" },
    children: [
      {
        path: "user-dep",
        component: () => import("@/views/user/user-dep"),
        name: "user-dep",
        meta: { title: "部门管理", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "user-user",
        component: () => import("@/views/user/user-user"),
        name: "user-user",
        meta: { title: "人员管理", icon: "icon-genjinkehu" },
      },
      {
        path: "user-role",
        component: () => import("@/views/user/user-role"),
        name: "user-role",
        meta: { title: "角色管理", icon: "icon-yonghuguanli1" },
      },
      {
        path: "group1",
        component: () => import("@/views/user/group1"),
        name: "group1",
        meta: { title: "账户管理", icon: "icon-gongsi" },
      },
      {
        path: "group2",
        component: () => import("@/views/user/group2"),
        name: "group2",
        meta: { title: "账务管理", icon: "icon-chushihuacaozuo" },
      },
      // {
      //   path: "group3",
      //   component: () => import("@/views/user/group3"),
      //   name: "group3",
      //   meta: { title: "角色同步", icon: "icon-chushihuacaozuo" },
      // },
      {
        path: "group4",
        component: () => import("@/views/user/group4"),
        name: "group4",
        meta: { title: "公司管理", icon: "icon-chushihuacaozuo" },
      },
    ],
  },
  {
    path: "/indent",
    component: Layout,
    name: "indent",
    meta: { title: "订单管理", icon: "user" },
    children: [
      {
        path: "indent-manage",
        component: () => import("@/views/user/orderManage"),
        name: "indent-manage",
        meta: { title: "订单管理", icon: "icon-chushihuacaozuo" },
        hidden: true,
      },
    ],
  },
  {
    path: "/pt",
    component: Layout,
    name: "pt",
    meta: { title: "平台管理", icon: "monitor" },
    children: [
      {
        path: "org-manage",
        component: () => import("@/views/platform/orgManage"),
        name: "org-manage",
        meta: { title: "软件用户", icon: "icon-yonghuguanli1" },
      },
      // {
      //   path: "aiSaleManage",
      //   component: () => import("@/views/platform/aiSaleManage"),
      //   name: "aiSaleManage",
      //   meta: { title: "AI销售用户", icon: "icon-yonghuguanli1" },
      // },
      {
        path: "setpt",
        component: () => import("@/views/platform/setpt"),
        name: "setpt",
        meta: { title: "OEM设置", icon: "icon-xitongshezhi1" },
      },
    ],
  },
  {
    path: "/rabbitMq",
    component: Layout,
    name: "rabbitMq",
    meta: { title: "MQ队列", icon: "document" },
    children: [
      {
        path: "mqQueue",
        component: () => import("@/views/platform/mqQueue"),
        name: "mqQueue",
        meta: { title: "MQ队列", icon: "icon-xitongshezhi1" },
      },
    ],
  },
  {
    path: "/sampleSubmission",
    component: Layout,
    name: "sampleSubmission",
    meta: { title: "样本提交", icon: "document" },
    children: [
      {
        path: "accountTest",
        component: () => import("@/views/company/accountTest1"),
        name: "accountTest",
        meta: { title: "银行样本", icon: "icon-xitongshezhi1" },
      },
      {
        path: "invoiceTest",
        component: () => import("@/views/company/invoiceTest"),
        name: "invoiceTest",
        meta: { title: "发票样本", icon: "icon-xitongshezhi1" },
      },
    ],
  },
  {
    path: "/preserve",
    component: Layout,
    name: "preserve",
    meta: { title: "客户维护", icon: "user" },
    children: [
      {
        path: "customerList",
        component: () => import("@/views/platform/customerList"),
        name: "customerList",
        meta: { title: "客户列表", icon: "icon-yonghuguanli1" },
      },
      {
        path: "useSituation",
        component: () => import("@/views/platform/useSituation"),
        name: "useSituation",
        meta: { title: "使用情况", icon: "icon-xitongshezhi1" },
      },
      {
        path: "comprehensiveScore",
        component: () => import("@/views/platform/comprehensiveScore"),
        name: "comprehensiveScore",
        meta: { title: "综合评分", icon: "icon-xitongshezhi1" },
      },
      // {
      //   path: "autoRobot",
      //   component: () => import("@/views/platform/autoRobot"),
      //   name: "autoRobot",
      //   meta: { title: "智能回复", icon: "icon-xitongshezhi1" },
      // },
    ],
  },
  {
    path: "/information",
    component: Layout,
    name: "information",
    meta: { title: "信息管理", icon: "document" },
    children: [
      {
        path: "update",
        component: () => import("@/views/platform/update"),
        name: "update",
        meta: { title: "信息公告", icon: "icon-gengxinjilu1" },
      },
      {
        path: "courseManagement",
        component: () => import("@/views/platform/courseManagement"),
        name: "courseManagement",
        meta: { title: "课程管理", icon: "icon-kechengguanli" },
      },
      {
        path: "websiteInfomation",
        component: () => import("@/views/platform/websiteInfomation"),
        name: "websiteInfomation",
        meta: { title: "官网行业资讯", icon: "icon-gengxinjilu1" },
      },
    ],
  },
  {
    path: "/cjy",
    component: Layout,
    name: "cjy",
    meta: { title: "财鲸云", icon: "notebook" },
    children: [
      {
        path: "trademarkReview",
        component: () => import("@/views/platform/trademarkReview"),
        name: "trademarkReview",
        meta: { title: "商标审核", icon: "icon-shangbiao" },
      },
      // {
      //   path: "loanPlatform",
      //   component: () => import("@/views/platform/loanPlatform"),
      //   name: "loanPlatform",
      //   meta: { title: "助贷平台", icon: "icon-shangbiao" },
      // },
    ],
  },
  // 收款系统
  // {
  //   path: "/crm",
  //   component: Layout,
  //   name: "crm",
  //   redirect: "/crmIndex",
  //   meta: { title: "首页", icon: "icon-home" },
  //   children: [
  //     {
  //       path: "crmIndex",
  //       component: () => import("@/views/crm/crm/crmIndex"),
  //       name: "crmIndex",
  //       meta: { title: "首页", icon: "House" },
  //     },
  //   ],
  // },
  {
    path: "/fianceAccount",
    component: Layout,
    name: "fianceAccount",
    meta: { title: "初始建账", icon: "document" },
    children: [
      {
        path: "addCustomer",
        component: () => import("@/views/company/addCustomer"),
        name: "addCustomer",
        meta: { title: "新增账套", icon: "icon-xinzengkehu1" },
      },
      {
        path: "kjInit",
        component: () => import("@/views/initOperation/initFinance"),
        name: "kjInit",
        meta: { title: "期初数据", icon: "icon-chushihuabaobiao1" },
      },
      {
        path: "transfer",
        component: () => import("@/views/company/transfer"),
        name: "transfer",
        meta: { title: "账套迁移", icon: "icon-gongsiliebiao1" },
      },
      {
        path: "initSubject",
        component: () => import("@/views/initOperation/initSubject2"),
        name: "initSubject",
        meta: { title: "修改期初", icon: "icon-gongsiliebiao1" },
        hidden: true,
      },
    ],
  },
  {
    path: "/custom",
    component: Layout,
    name: "custom",
    meta: { title: "客户管理", icon: "user" },
    children: [
      {
        path: "taxApplet",
        component: () => import("@/views/crm/custom/taxApplet"),
        name: "taxApplet",
        meta: { title: "报税小程序", icon: "icon-genjinkehu1" },
      },
      {
        path: "followCustom",
        component: () => import("@/views/crm/custom/followCustom"),
        name: "followCustom",
        meta: { title: "意向客户", icon: "icon-genjinkehu1" },
      },
      {
        path: "coopCustom",
        component: () => import("@/views/crm/custom/coopCustom"),
        name: "coopCustom",
        meta: { title: "客户列表", icon: "icon-hezuokehu1" },
      },
      {
        path: "termCustom",
        component: () => import("@/views/crm/custom/termCustom"),
        name: "termCustom",
        meta: { title: "外勤管理", icon: "icon-jieyuekehu1" },
      },
      {
        path: "businessType",
        component: () => import("@/views/crm/crmSettimg/businessType"),
        name: "businessType",
        meta: { title: "业务类型", icon: "icon-yewuleixing1" },
        hidden: true,
      },
    ],
  },
  //收款系统新
  {
    path: "/clue",
    component: Layout,
    name: "clue",
    meta: { title: "线索管理", icon: "setUp" },
    children: [
      {
        path: "cluePool",
        component: () => import("@/views/collectionSystem/clue/cluePool"),
        name: "cluePool",
        meta: { title: "线索池", icon: "icon-xinzengkehu1" },
      },
      {
        path: "clueMine",
        component: () => import("@/views/collectionSystem/clue/clueMine"),
        name: "clueMine",
        meta: { title: "我的线索", icon: "icon-xinzengkehu1" },
      },
      {
        path: "clueRecycle",
        component: () => import("@/views/collectionSystem/clue/clueRecycle"),
        name: "clueRecycle",
        meta: { title: "回收站", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  {
    path: "/clientele",
    component: Layout,
    name: "clientele",
    meta: { title: "客户管理", icon: "memo" },
    children: [
      {
        path: "clientMine",
        component: () =>
          import("@/views/collectionSystem/clientele/clientMine"),
        name: "clientMine",
        meta: { title: "我的客户", icon: "icon-xinzengkehu1" },
      },
      {
        path: "deliveryGood",
        component: () =>
          import("@/views/collectionSystem/clientele/deliveryGood"),
        name: "deliveryGood",
        meta: { title: "物品交接", icon: "icon-xinzengkehu1" },
      },
      {
        path: "clientCheck",
        component: () =>
          import("@/views/collectionSystem/clientele/clientCheck"),
        name: "clientCheck",
        meta: { title: "签约客户审核", icon: "icon-xinzengkehu1" },
      },
      {
        path: "clientRun",
        component: () => import("@/views/collectionSystem/clientele/clientRun"),
        name: "clientRun",
        meta: { title: "流失客户", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  {
    path: "/contract",
    component: Layout,
    name: "contract",
    meta: { title: "合同管理", icon: "collection" },
    children: [
      {
        path: "contractMine",
        component: () =>
          import("@/views/collectionSystem/contract/contractMine"),
        name: "contractMine",
        meta: { title: "我的合同", icon: "icon-xinzengkehu1" },
      },
      {
        path: "contractCheck",
        component: () =>
          import("@/views/collectionSystem/contract/contractCheck"),
        name: "contractCheck",
        meta: { title: "合同审核", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  {
    path: "/charge",
    component: Layout,
    name: "charge",
    meta: { title: "收款管理", icon: "money" },
    children: [
      {
        path: "chargeStatistics",
        component: () =>
          import("@/views/collectionSystem/charge/chargeStatistics"),
        name: "chargeStatistics",
        meta: { title: "欠费统计", icon: "icon-xinzengkehu1" },
      },
      {
        path: "chargeRefer",
        component: () => import("@/views/collectionSystem/charge/chargeRefer"),
        name: "chargeRefer",
        meta: { title: "代账收费查询", icon: "icon-xinzengkehu1" },
      },
      {
        path: "chargeSign",
        component: () => import("@/views/collectionSystem/charge/chargeSign"),
        name: "chargeSign",
        meta: { title: "收款登记", icon: "icon-xinzengkehu1" },
      },
      {
        path: "chargeCheck",
        component: () => import("@/views/collectionSystem/charge/chargeCheck"),
        name: "chargeCheck",
        meta: { title: "收款审核", icon: "icon-xinzengkehu1" },
      },
      {
        path: "refundSign",
        component: () => import("@/views/collectionSystem/charge/refundSign"),
        name: "refundSign",
        meta: { title: "退款登记", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  {
    path: "/task",
    component: Layout,
    name: "task",
    meta: { title: "外勤管理", icon: "mapLocation" },
    children: [
      {
        path: "taskSchedule",
        component: () => import("@/views/collectionSystem/task/taskSchedule"),
        name: "taskSchedule",
        meta: { title: "流程设置", icon: "icon-xinzengkehu1" },
      },
      {
        path: "taskArrange",
        component: () => import("@/views/collectionSystem/task/taskArrange"),
        name: "taskArrange",
        meta: { title: "任务安排", icon: "icon-xinzengkehu1" },
      },
      {
        path: "taskTransact",
        component: () => import("@/views/collectionSystem/task/taskTransact"),
        name: "taskTransact",
        meta: { title: "任务办理", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  {
    path: "/crmSetting",
    component: Layout,
    name: "crmSetting",
    meta: { title: "设置", icon: "setting" },
    children: [
      {
        path: "clueSet",
        component: () => import("@/views/collectionSystem/setting/clueSet"),
        name: "clueSet",
        meta: { title: "线索设置", icon: "icon-xinzengkehu1" },
      },
      {
        path: "checkSet",
        component: () => import("@/views/collectionSystem/setting/checkSet"),
        name: "checkSet",
        meta: { title: "审核设置", icon: "icon-xinzengkehu1" },
      },
    ],
  },
  // 云服务
  {
    path: "/property",
    component: Layout,
    name: "property",
    meta: { title: "知识产权", icon: "document" },
    children: [
      {
        path: "trademark",
        component: () => import("@/views/crm/property/trademark"),
        name: "trademark",
        meta: { title: "商标注册", icon: "icon-shangbiao" },
      },
      {
        path: "propertyOther",
        component: () => import("@/views/crm/property/propertyOther"),
        name: "propertyOther",
        meta: { title: "其他", icon: "icon-qita" },
      },
    ],
  },
  //年度申报
  {
    path: "/year-declare",
    component: Layout,
    name: "year-declare",
    meta: { title: "年度申报", icon: "edit-pen" },
    children: [
      {
        path: "yearSb",
        component: () => import("@/views/batch/yearSb"),
        name: "yearSb",
        meta: { title: "汇算清缴", icon: "icon-shouquanyemian1" },
      },
      {
        path: "business-year",
        component: () => import("@/views/batch/businessYear"),
        name: "business-year",
        meta: { title: "工商年报", icon: "icon-gongsiliebiao1" },
      },
      // {
      //   path: "personalYear",
      //   component: () => import("@/views/batch/personalYear"),
      //   name: "personalYear",
      //   meta: { title: "个独汇算清缴", icon: "icon-gongsiliebiao1" },
      // },
    ],
  },
  // 做账内部页面

  {
    path: "/etaxHome",
    name: "etaxHome",
    component: Layout,
    redirect: "/etaxHome/etaxHomeIndex",
    meta: {
      title: "账簿首页",
      icon: "house",
    },
    children: [
      {
        path: "etaxHomeIndex",
        component: () => import("@/views/etaxHome/etaxHomeIndex"),
        name: "etaxHomeIndex",
        meta: { title: "账簿首页", icon: "house" },
        hidden: true,
      },
    ],
  },
  {
    path: "/input",
    name: "input",
    component: Layout,
    // redirect: '/input/invoice',
    meta: {
      title: "票据录入",
      icon: "edit-pen",
    },
    children: [
      {
        path: "invoice",
        component: () => import("@/views/input/invoice/invoice"),
        name: "invoice",
        meta: { title: "发票", icon: "icon-fapiao1" },
      },
      {
        path: "addIncome",
        component: () => import("@/views/input/invoice/addIncome"),
        name: "addIncome",
        meta: { title: "新增进项", icon: "icon-home" },
        hidden: true,
      },
      // {
      //   path: "addReimbursement",
      //   component: () => import("@/views/input/invoice/addReimbursement"),
      //   name: "addReimbursement",
      //   meta: { title: "报销单", icon: "icon-home" },
      // },
      {
        path: "incomeWzz",
        component: () => import("@/views/input/invoice/incomeWzz"),
        name: "incomeWzz",
        meta: { title: "未认证进项", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "addOutput",
        component: () => import("@/views/input/invoice/addOutput"),
        name: "addOutput",
        meta: { title: "新增销项", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "money",
        component: () => import("@/views/input/money/money"),
        name: "money",
        meta: { title: "资金", icon: "icon-zijin1" },
      },
      {
        path: "remuneration",
        component: () => import("@/views/input/remuneration/remuneration"),
        name: "remuneration",
        meta: { title: "薪酬", icon: "icon-xinchou1" },
      },
      {
        path: "salaryNormal",
        component: () => import("@/views/input/remuneration/salaryNormal"),
        name: "salaryNormal",
        meta: { title: "正常工资薪资", icon: "icon-home" },
      },
      {
        path: "salaryServices",
        component: () => import("@/views/input/remuneration/salaryServices"),
        name: "salaryServices",
        meta: { title: "劳务报酬所得", icon: "icon-home" },
      },
      {
        path: "salaryBonus",
        component: () => import("@/views/input/remuneration/salaryBonus"),
        name: "salaryBonus",
        meta: { title: "全年一次性奖金", icon: "icon-home" },
      },
      {
        path: "salaryPerson",
        component: () => import("@/views/input/remuneration/salaryPerson"),
        name: "salaryPerson",
        meta: { title: "个人生产经营所得(查账征收)", icon: "icon-home" },
      },
      {
        path: "salaryPersonHdzs",
        component: () => import("@/views/input/remuneration/salaryPersonHdzs"),
        name: "salaryPersonHdzs",
        meta: { title: "个人生产经营所得(核定征收)", icon: "icon-home" },
      },
      {
        path: "inventory",
        component: () => import("@/views/input/inventory/inventory"),
        name: "inventory",
        meta: { title: "存货", icon: "icon-cunhuo1" },
      },
      {
        path: "produceConvert",
        component: () => import("@/views/input/inventory/produceConvert"),
        name: "produceConvert",
        meta: { title: "领料加工", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "projectConvert",
        component: () => import("@/views/input/inventory/projectConvert"),
        name: "projectConvert",
        meta: { title: "工程核算", icon: "icon-home" },
        hidden: true,
      },

      {
        path: "fwConvert",
        component: () => import("@/views/input/inventory/fwConvert"),
        name: "fwConvert",
        meta: { title: "服务成本核算", icon: "icon-home" },
        hidden: true,
      },

      {
        path: "processConvert",
        component: () => import("@/views/input/inventory/processConvert"),
        name: "processConvert",
        meta: { title: "委托加工", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "tradeConvert",
        component: () => import("@/views/input/inventory/tradeConvert"),
        name: "tradeConvert",
        meta: { title: "贸易核算", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "assets",
        component: () => import("@/views/input/assets/assets"),
        name: "assets",
        meta: { title: "资产", icon: "icon-zichan1" },
      },
    ],
  },
  {
    path: "/bookkeeps",
    name: "bookkeeps",
    component: Layout,
    // redirect: '/bookkeeps/voucher',
    meta: {
      title: "记账核算",
      icon: "files",
    },
    children: [
      {
        path: "voucher",
        component: () => import("@/views/bookkeeps/voucher"),
        name: "voucher",
        meta: { title: "凭证", icon: "icon-pingzheng1" },
      },
      //历史凭证
      {
        path: "hisVoucher",
        component: () => import("@/views/bookkeeps/hisVoucher"),
        name: "hisVoucher",
        meta: { title: "凭证", icon: "icon-pingzheng1" },
      },
      //历史会计科目
      {
        path: "hisSubject",
        component: () => import("@/views/setting/subject"),
        name: "hisSubject",
        meta: { title: "会计科目", icon: "icon-kuaijikemu1" },
      },
      // {
      //   path: "addVoucher",
      //   component: () => import("@/views/bookkeeps/addVoucher"),
      //   name: "addVoucher",
      //   meta: { title: "添加凭证", icon: "icon-home" },
      // },
      {
        path: "addVoucher",
        component: () => import("@/views/bookkeeps/addVoucherNew"),
        name: "addVoucher",
        meta: { title: "添加凭证", icon: "icon-home" },
      },
      {
        path: "addAllVoucher",
        component: () => import("@/views/bookkeeps/addAllVoucher"),
        name: "addAllVoucher",
        meta: { title: "汇总生成凭证", icon: "icon-home" },
      },
      {
        path: "historyVoucher",
        component: () => import("@/views/bookkeeps/components/historyVoucher"),
        name: "historyVoucher",
        meta: { title: "修改凭证", icon: "icon-yewushuju" },
      },
      {
        path: "settleAccount",
        component: () => import("@/views/bookkeeps/newSettleAccount"),
        name: "settle-account",
        meta: { title: "结账", icon: "icon-jiezhang1" },
      },
      {
        path: "accountBook",
        component: () => import("@/views/bookkeeps/accountBook"),
        name: "accountBook",
        meta: { title: "账簿", icon: "icon-zhangbu1" },
      },
      {
        path: "recycle",
        component: () => import("@/views/bookkeeps/recycle"),
        name: "recycle",
        meta: { title: "回收站", icon: "icon-zhangbu1" },
      },
    ],
  },
  {
    path: "/report",
    name: "report",
    component: Layout,
    meta: {
      title: "报表统计",
      icon: "data-analysis",
    },
    children: [
      {
        path: "tax",
        component: () => import("@/views/report/tax/tax"),
        name: "tax",
        meta: { title: "税务报表", icon: "icon-shuiwubaobiao1" },
      },
      {
        path: "finance",
        component: () => import("@/views/report/finance/finance"),
        name: "finance",
        meta: { title: "会计报表", icon: "icon-kuaijibaobiao1" },
      },
      {
        path: "vatInvoice",
        component: () => import("@/views/report/tax/vat-invoice/vatInvoice"),
        name: "vatInvoice",
        meta: { title: "增值税一般纳税人申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "smallVat",
        component: () => import("@/views/report/tax/small-vat/smallVat"),
        name: "smallVat",
        meta: { title: "小规模增值税及附加税费申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "qysds",
        component: () => import("@/views/report/tax/qysds/qysds"),
        name: "qysds",
        meta: { title: "企业所得税申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "propertyTax",
        component: () => import("@/views/report/tax/propertyTax/propertyTax"),
        name: "propertyTax",
        meta: { title: "财产和行为税纳税申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "additionalTax",
        component: () => import("@/views/report/tax/otherTable/additionalTax"),
        name: "additionalTax",
        meta: { title: "附加税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "editTax",
        component: () => import("@/views/report/tax/otherTable/editTax"),
        name: "editTax",
        meta: { title: "印花税纳税申报（报告）表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "fcs",
        component: () => import("@/views/report/tax/otherTable/fcs"),
        name: "fcs",
        meta: { title: "房产税土地税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxNeimengQtsr",
        component: () => import("@/views/report/tax/otherTable/taxNeimengQtsr"),
        name: "taxNeimengQtsr",
        meta: { title: " 其他收入(工会经费)", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "xfs",
        component: () => import("@/views/report/tax/otherTable/xfs"),
        name: "xfs",
        meta: { title: "消费税", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxNeimengSl",
        component: () => import("@/views/report/tax/otherTable/taxNeimengSl"),
        name: "taxNeimengSl",
        meta: { title: "水利建设专项收入", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "taxCityLife",
        component: () => import("@/views/report/tax/otherTable/taxCityLife"),
        name: "taxCityLife",
        meta: { title: "城市生活垃圾处置费", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "cbj",
        component: () => import("@/views/report/tax/otherTable/cbj"),
        name: "cbj",
        meta: { title: "残疾人保障金", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "culturalTax",
        component: () => import("@/views/report/tax/otherTable/culturalTax"),
        name: "culturalTax",
        meta: { title: "文化事业建设费", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "normal",
        component: () => import("@/views/report/tax/otherTable/normal"),
        name: "normal",
        meta: { title: "综合所得申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "dqde",
        component: () => import("@/views/report/tax/otherTable/dqde"),
        name: "dqde",
        meta: { title: "个人所得税定期定额申报表", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A000000",
        component: () => import("@/views/report/yearTax/A000000"),
        name: "A000000",
        meta: { title: "A000000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A100000",
        component: () => import("@/views/report/yearTax/A100000"),
        name: "A100000",
        meta: { title: "A100000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A101010",
        component: () => import("@/views/report/yearTax/A101010"),
        name: "A101010",
        meta: { title: "A101010", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A102010",
        component: () => import("@/views/report/yearTax/A102010"),
        name: "A102010",
        meta: { title: "A102010", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A104000",
        component: () => import("@/views/report/yearTax/A104000"),
        name: "A104000",
        meta: { title: "A104000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105000",
        component: () => import("@/views/report/yearTax/A105000"),
        name: "A105000",
        meta: { title: "A105000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105050",
        component: () => import("@/views/report/yearTax/A105050"),
        name: "A105050",
        meta: { title: "A105050", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105060",
        component: () => import("@/views/report/yearTax/A105060"),
        name: "A105060",
        meta: { title: "A105060", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A105080",
        component: () => import("@/views/report/yearTax/A105080"),
        name: "A105080",
        meta: { title: "A105080", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A106000",
        component: () => import("@/views/report/yearTax/A106000"),
        name: "A106000",
        meta: { title: "A106000", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A107040",
        component: () => import("@/views/report/yearTax/A107040"),
        name: "A107040",
        meta: { title: "A107040", icon: "icon-home" },
        hidden: true,
      },
      {
        path: "A107010",
        component: () => import("@/views/report/yearTax/A107010"),
        name: "A107010",
        meta: { title: "A107010", icon: "icon-home" },
        hidden: true,
      },
    ],
  },
  {
    path: "/setting",
    name: "setting",
    component: Layout,
    meta: {
      title: "设置",
      icon: "setting",
    },
    children: [
      {
        path: "comInfo",
        component: () => import("@/views/company/comInfo"),
        name: "comInfo",
        meta: { title: "企业信息", icon: "icon-qiyexinxi1" },
      },
      {
        path: "subject",
        component: () => import("@/views/setting/subject3"),
        name: "subject",
        meta: { title: "会计科目", icon: "icon-kuaijikemu1" },
      },
      {
        path: "fzhs",
        component: () => import("@/views/setting/fzhs"),
        name: "fzhs",
        meta: { title: "辅助核算", icon: "icon-fuzhuhesuan1" },
      },
      {
        path: "business",
        component: () => import("@/views/setting/business"),
        name: "business",
        meta: { title: "业务数据", icon: "icon-yewushuju1" },
      },
      {
        path: "habbitSettingBook",
        component: () => import("@/views/setting/habbitSettingBook"),
        name: "habbitSettingBook",
        meta: { title: "习惯设置", icon: "icon-yewushuju1" },
      },
      {
        path: "electronicArchives",
        component: () => import("@/views/setting/electronicArchives"),
        name: "electronicArchives",
        meta: { title: "电子档案", icon: "icon-yewushuju1" },
      },
      {
        path: "contributedCapital",
        component: () => import("@/views/setting/paid"),
        name: "contributedCapital",
        meta: { title: "实缴资本", icon: "icon-yewushuju1"},
      },
    ],
  },
  {
    path: "/his",
    name: "his",
    component: Layout,
    meta: {
      title: "报表账簿",
      icon: "notebook",
    },
    children: [
      //历史账簿
      {
        path: "history",
        component: () => import("@/views/bookkeeps/history"),
        name: "history",
        meta: { title: "账簿", icon: "icon-zhangbu1" },
      },
      //历史会计报表
      {
        path: "hisBooks",
        component: () => import("@/views/report/finance/hisBooks"),
        name: "hisBooks",
        meta: { title: "会计报表", icon: "icon-kuaijibaobiao1" },
      },
    ],
  },
  {
    path: "/automaticData",
    name: "automaticData",
    component: Layout,
    meta: {
      title: "首页",
      icon: "notebook",
    },
    children: [
      {
        path: "automaticDataBoard",
        component: () => import("@/views/autoHome/automaticDataBoard"),
        name: "automaticDataBoard",
        meta: { title: "首页", icon: "notebook" },
      },
    ]
  },
  {
    path: "/automaticZw",
    name: "automaticZw",
    component: Layout,
    meta: {
      title: "代账中心",
      icon: "notebook",
    },
    children: [
      {
        path: "automaticZwzx",
        component: () => import("@/views/autoHome/automaticZwzx"),
        name: "automaticZwzx",
        meta: { title: "记账报税", icon: "notebook" },
      },
      {
        path: "automaticZwShebao",
        component: () => import("@/views/autoHome/automaticZwShebao"),
        name: "automaticZwShebao",
        meta: { title: "社保申报", icon: "notebook" },
      },
    ]
  },
  {
    path: "/automaticSetting",
    name: "automaticSetting",
    component: Layout,
    meta: {
      title: "设置",
      icon: "setting",
    },
    children: [
      {
        path: "automaticManage",
        component: () => import("@/views/autoHome/automaticManage"),
        name: "automaticManage",
        meta: { title: "账套管理", icon: "setting" },
      },
      {
        path: "automaticConfig",
        component: () => import("@/views/autoHome/automaticConfig"),
        name: "automaticConfig",
        meta: { title: "通用配置", icon: "setting" },
      },
    ]
  },
  {
    path: "/automaticZj",
    name: "automaticZj",
    component: Layout,
    meta: {
      title: "质检评分",
      icon: "setUp",
    },
    children: [
      {
        path: "automaticQuality",
        component: () => import("@/views/autoHome/automaticQuality"),
        name: "automaticQuality",
        meta: { title: "智能质检", icon: "setUp" },
      },
      {
        path: "automaticZjzx",
        component: () => import("@/views/autoHome/automaticZjzx"),
        name: "automaticZjzx",
        meta: { title: "人工核查", icon: "setUp" },
      },
    ]
  },
  {
    path: "/automaticHd",
    name: "automaticHd",
    component: Layout,
    meta: {
      title: "单据整理",
      icon: "dataLine",
    },
    children: [
      {
        path: "automaticInvoice",
        component: () => import("@/views/autoHome/automaticInvoice"),
        name: "automaticInvoice",
        meta: { title: "进销发票", icon: "house" },
      },
      {
        path: "automaticBank",
        component: () => import("@/views/autoHome/automaticBank"),
        name: "automaticBank",
        meta: { title: "银行流水", icon: "house" },
      },
      {
        path: "automaticSalary",
        component: () => import("@/views/autoHome/automaticSalary"),
        name: "automaticSalary",
        meta: { title: "人员工资", icon: "house" },
      },
    ]
  },
  {
    path: "/taxInfomation",
    name: "taxInfomation",
    component: Layout,
    meta: {
      title: "税款信息",
      icon: "collection",
    },
    children: [
      {
        path: "taxConfirm",
        component: () => import("@/views/autoHome/taxConfirm"),
        name: "taxConfirm",
        meta: { title: "税款确认", icon: "house" },
      },
      {
        path: "shebaoConfirm",
        component: () => import("@/views/autoHome/shebaoConfirm"),
        name: "shebaoConfirm",
        meta: { title: "社保确认", icon: "house" },
      },
    ]
  }
];

export default eaRouter;
