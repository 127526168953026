import request from '@/utils/request'

//已兼容 模板
export function importTemplate(data) {
  return request({
    url: 'api/v2/import/importTemplate',
    method: 'post',
    data
  })
}

//进项导入
export function inImport(formdata) {
  return request({
      url: 'api/v2/import/inImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//销项导入
export function outImport(formdata) {
  return request({
      url: 'api/v2/import/outImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//收购发票
export function shougouImport(formdata) {
  return request({
      url: 'api/v2/import/shougouImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//科目余额导入  建账
export function subjectImport(formdata) {
  return request({
      url: 'api/v2/import/subjectImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//员工导入
export function empImport(formdata) {
  return request({
      url: 'api/v2/import/empImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//境外人员导入
export function empForeignImport(formdata) {
  return request({
      url: 'api/v2/import/empForeignImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//薪酬导入
export function salaryImport(formdata) {
  return request({
      url: 'api/v2/import/salaryImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//劳务报酬导入
export function salaryRemImport(formdata) {
  return request({
      url: 'api/v2/import/salaryRemImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//全年一次性奖金导入
export function salaryOnlyImport(formdata) {
  return request({
      url: 'api/v2/import/salaryOnlyImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//薪酬累计数据初始化导入
export function salaryInitImport(formdata) {
  return request({
      url: 'api/v2/import/salaryInitImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//银行导入
export function bankImport(formdata) {
  return request({
      url: 'api/v2/import/bankImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//历史科目导入
export function oldSubjectImport(formdata) {
  return request({
      url: 'api/v2/import/oldSubjectImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//历史凭证导入
export function oldVoucherImport(formdata) {
  return request({
      url: 'api/v2/import/oldVoucherImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

export function voucherImport(formdata) {
  return request({
      url: 'api/v2/import/voucherImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

// 存货初始化导入
export function importInventory(data) {
  return request({
    url: 'api/v2/inventory/importInventory',
    method: 'post',
    data
  })
}

// 资产导入
export function assetImport(formdata) {
  return request({
      url: 'api/v2/import/assetImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

// 辅助核算导入
export function fzhsImport(formdata) {
  return request({
      url: 'api/v2/import/fzhsImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//excel导入解析
export function importExcel(formdata) {
  return request({
    url: 'api/v2/import/importExcel',
    method: 'post',
    data: formdata,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}

//票据导入
export function billImport(formdata) {
  return request({
      url: 'api/v2/import/billImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//费用导入
export function feeImport(formdata) {
  return request({
      url: 'api/v2/import/feeImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//会计科目导入
export function subjectFzhsImport(formdata) {
  return request({
      url: 'api/v2/import/subjectFzhsImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//专项扣除导入
export function empSpeImport(formdata) {
  return request({
      url: 'api/v2/import/empSpeImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//存货设置导入
export function inventoryImport(formdata) {
  return request({
      url: 'api/v2/import/inventoryImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//存货设置模板
export function commonTemplate(data) {
  return request({
      url: 'api/v2/export/commonTemplate',
      method: 'post',
      data
  })
}

//v3批量修改导入客户模板
export function companyImport(formdata) {
  return request({
      url: 'api/v2/import/companyImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//存货核算
export function inventoryCheckImport(formdata) {
  return request({
      url: 'api/v2/import/inventoryCheckImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//暂估入库导入
export function inventoryEsImport(formdata) {
  return request({
      url: 'api/v2/import/inventoryEsImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}

//现金导入
export function cashImport(formdata) {
  return request({
      url: 'api/v2/import/cashImport',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//领料加工导入
export function produceChecks(formdata) {
  return request({
      url: 'api/v2/import/produceCheck',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//贸易核算导入
export function tradeChecks(formdata) {
  return request({
      url: 'api/v2/import/tradeCheck',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//委托加工导入
export function processChecks(formdata) {
  return request({
      url: 'api/v2/import/processCheck',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//工程核算导入
export function projectChecks(formdata) {
  return request({
      url: 'api/v2/import/projectCheck',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}
//服务成本核算导入
export function fwChecks(formdata) {
  return request({
      url: 'api/v2/import/fwCheck',
      method: 'post',
      data: formdata,
      config: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
  })
}