<template>
  <el-dialog
    v-model="dialogVisible"
    title="提示"
    width="270px"
    :before-close="handleClose"
    append-to-body
  >
    <span style="font-size: 13px"
      ><i class="iconfont icon-gantanhao1" style="font-size: 13px"></i
      >检测到您未安装税局小助手，请点击
    </span>
    <span style="font-size: 13px; display: inline-block; margin-top: 10px">
      <el-button icon="Pointer" size="small" type="primary" @click="checkOpen"
        >去下载</el-button
      >
      安装后重试
    </span>

    <!-- <a href="https://file.listensoft.net/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B.exe">去下载</a>     -->

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" type="primary"
          >关闭</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "loginTaxAlert",

  data() {
    return {
      dialogVisible: false,
    };
  },

  mounted() {},

  methods: {
    checkOpen() {
      window.open(
        "https://file.listensoft.net/mnt/down/%E7%A8%8E%E5%B1%80%E5%B0%8F%E5%8A%A9%E6%89%8B.exe"
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
