<template>
  <div class="app-main">
    <router-view :key="key" v-slot="{ Component }">
      <!-- 缓存页面 3.0写法 -->
      <keep-alive :include="cachedViews">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <div class="mes_content">
    <div v-if="openUrlSrc" class="mb-10">
      <div v-show="expandOut" class="zxzx" @click="expandOut = false">
        <i style="font-size:16px;color:#fff" class="iconfont icon-rengongkefu"></i>
        <div class="zxzx_text">在线咨询</div>
      </div>
      <div v-show="!expandOut">
        <i @click="openUrl" style="font-size:23px;color:#1062fd"
          class="iconfont icon-jiqirenkehu"></i>
        <el-icon @click="expandOut = true">
          <DArrowRight />
        </el-icon>
      </div>
    </div>
    <div v-if="showStatus && !fromComId" class="zxzx" @click="openChat">
      <el-icon style="font-size: 16px;">
        <ChatDotSquare />
      </el-icon>
      <div class="zxzx_text">
        协同消息
      </div>
    </div>
  </div>
  <div class="message_style" style="bottom:20px" v-if="!showStatus && !fromComId">
    <div @click="openChat" class="flex_align_center">
      <el-icon color="#3781e0" class="mr-5">
        <Promotion />
      </el-icon>
      您有 <span style="font-weight: 600;"> {{ unRead }} </span> 条消息待查看
    </div>
    <el-icon class="message_right" @click="showStatus = true">
      <Close />
    </el-icon>
  </div>
  <chatWindow ref="chatWindow" />
</template>
<script>
import chatWindow from './chatWindow.vue'
import { getUnReadCount } from "@/api/company"
let audio
export default {
  name: 'right',
  components: { chatWindow },
  data() {
    return {
      sss: ["index1"],
      openUrlSrc: this.$store.getters['user/user'].wechatContactLink,
      expandOut: true,
      timer: null,
      showStatus: false,
      unRead: 0,
      audioUrl: require('@/assets/wx.mp3'),
      fromComId: this.$store.getters["user/user"].fromComId,
    }
  },
  computed: {
    cachedViews() {
      const cachedViews = this.$store.getters['tagsView/cachedViews']
      if (cachedViews.length <= 1) {
        return cachedViews
      }
      // console.log("appmain", this.$store.getters['tagsView/cachedViews'])
      // 直接使用使用 this.$store.getters['tagsView/cachedViews'] 会导致财务核算 记账客户 切出在切入之后 页面会一直处在 inactive 状态，即组件由于未知原因未激活，导致无响应
      // 使用 逗号分隔的字符串 或者 使用数组的原始值 就好了，原因未知
      return cachedViews.join(',')
      // return toRaw(this.$store.getters['tagsView/cachedViews'])
    },
    // key() {
    //   return this.$route.fullPath
    // }
  },
  methods: {
    openUrl() {
      window.open(this.openUrlSrc)
    },
    openChat() {
      this.unRead = 0
      this.showStatus = true
      this.$refs.chatWindow.init()
    },
    getList() {
      getUnReadCount({}).then((res) => {
        if (res.data.msg == "success") {
          if (res.data.data.count) {
            this.unRead = res.data.data.count
            this.showStatus = false
            this.audio?.play()
          } else {
            setTimeout(() => {
              this.showStatus = true
            }, 3000)
          }
        }
      });
    }
  },
  mounted() {
    this.audio = new Audio(this.audioUrl)
    this.getList()
    this.time && clearInterval(this.timer)
    this.timer = setInterval(() => {
      getUnReadCount({}).then((res) => {
        if (res.data.msg == "success" && res.data.data.count != 0) {
          this.unRead = res.data.data.count
          this.showStatus = false
          this.audio?.play()
        } else {
          clearInterval(this.timer)
        }
      });
    }, 1 * 60 * 1000);
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  width: calc(100% - 12px);
  min-height: calc(100vh - 96px);
  background: #fff;
  margin-top: 5px;
  margin-left: 5px;
  overflow: auto;
  float: left;
}

.message_style {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(to right, #d8e7fd, #f6faff);
  border-radius: 3px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #9fbce7;
  z-index: 2000;
  .message_right {
    margin-left: 80px;
  }
}

.mes_content {
  position: absolute;
  right: 0px;
  bottom: 40%;
  z-index: 2000;
  cursor: pointer;
  text-align:-webkit-right;
  text-align: -moz-right;
}

.zxzx {
  width: 24px;
  text-align: center;
  background: linear-gradient(to bottom, #1062fd, #59a6fc);
  color: #fff;
  border-radius: 18px;
  padding: 8px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}
.zxzx_text {
  width: 20px;
  margin: auto;
  display: block;
  font-size: 13px;
  line-height: 16px;
}
</style>