<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogFormVisible" title="新增待办" width="450px" append-to-body>
    <el-form :model="temp" size="small" v-loading="loading">
      <el-form-item label="待办执行人:" required>
        <select-common placeholder="请选择待办执行人" v-model:modelValue="form.userId" :options="options" labelKey="cnName"
          valueKey="id" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="createSure">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getDaibanPeople, setDaibanPeople } from '@/api/company'
const emit = defineEmits(['success'])
const form = ref({})
const dialogFormVisible = ref(false)
const options = ref([])
const loading = ref(false)
const init = (item) => {
  loading.value = true
  form.value = {
    chatId: item.id,
    type: 1
  }
  getDaibanPeople({ comId: item.comId }).then(res => {
    loading.value = false
    if (res.data.msg == 'success') {
      options.value = res.data.data.list
    }
  })
  dialogFormVisible.value = true
}
const createSure = () => {
  if (!form.value.userId) {
    ElMessage.warning('请选择待办执行人')
    return
  }
  setDaibanPeople(form.value).then(res => {
    if (res.data.msg == 'success') {
      ElMessage.success('设置成功')
      dialogFormVisible.value = false
      emit('success')
    }
  })
}
defineExpose({ init })
</script>

<style scoped lang="less"></style>
