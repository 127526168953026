<template>
  <!-- <div> -->
  <td :colspan="colspan" :rowspan="rowspan" :class="{ disabled: disabled }">
    <!-- @keyup="inputChange" -->
    <p @click="showInput" v-if="status">{{ nowAmount }}</p>
    <input
      @keyup="changeValue"
      ref="inputVal"
      v-else
      :disabled="disabled"
      v-model="amount"
      @blur="inputChange($event)"
      type="text"
    />
  </td>
  <!-- </div> -->

  <!--  <qzf-td :disabled="false" :amount.sync="cslist[0].amount" @change="changeList()"></qzf-td>
    <qzf-td :disabled="true" :amount.sync="cslist[1].amount" @change="changeList()"></qzf-td> -->
</template>
<script>
const MoneyTest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

// 金额添加千分位
const comdify = function(n) {
  if (!n) return n;
  n = keepTwoDecimalFull(n)
  let str = n.split(".");
  let re = /\d{1,3}(?=(\d{3})+$)/g;
  let n1 = str[0].replace(re, "$&,");
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
};
const keepTwoDecimalFull = function(num) {
  var result = parseFloat(num);
  if (isNaN(result)) {
    alert("传递参数错误，请检查！");
    return false;
  }
  result = Math.round(num * 100) / 100;
  var s_x = result.toString();
  var pos_decimal = s_x.indexOf(".");
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += ".";
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += "0";
  }
  return s_x;
};
//去除千分位中的‘，’
const delcommafy = function(num) {
  if (!num) return num;
  num = num.toString();
  num = num.replace(/,/gi, "");
  return num;
};
const valdateFn = function(rule, val, cb) {
  setTimeout(() => {
    if (val) {
      let inputVal = delcommafy(val);
      if (rule.test(inputVal)) {
        cb();
      } else {
        cb("只能是数字金额,最多两位小数");
      }
    }
    cb();
  });
};
// 验证金额数字可以为负数
const moneyValid = function(rule, val, cb) {
  valdateFn(/((^-?[1-9]\d*)|^-?0)(\.\d{0,2}){0,1}$/, val, cb);
};
// 验证金额数字不可以为负数
const moneyNValid = function(rule, val, cb) {
  valdateFn(MoneyTest, val, cb);
};

// 获取输入框的值
const getInputValue = function(value) {
  let inputVal = value || "";
  return comdify(delcommafy(inputVal));
};

export default {
  props: {
    colspan: {
      type: Number,
      default: 1,
    },
    rowspan: {
      type: Number,
      default: 1,
    },
    amount: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nowAmount: function() {
      return getInputValue(this.amount);
    },
  },
  watch: {
    amount() {},
  },
  data() {
    return {
      status: true,
    };
  },
  created() {},
  methods: {
    changeValue() {
      if (
        this.amount.indexOf("-") == String(this.amount).length - 1 ||
        this.amount.indexOf(".") == String(this.amount).length - 1 ||
        this.amount.indexOf(".0") == String(this.amount).length - 2
      ) {
        return;
      }

      //正则表达式匹配0.10
      var pattern = /\.[0-9]{0,2}[0]/;
      var pattern2 = /\.[0-9]{3,3}[0]/;
      var pattern3 = /\.[0-9]{3,8}[0]/;
      if (pattern.test(this.amount)) {
        if (!pattern2.test(this.amount) && !pattern3.test(this.amount)) {
          return;
        }
      }
      this.amount = this.amount.toFixed(2);
      console.log(this.amount);
      this.$emit(
        "update:amount",
        Number((this.amount + "").replace(/[,]/g, ""))
      );
      if (!this.amount) {
        this.$emit("update:amount", 0);
        this.$emit("change");
        return;
      }
      this.$emit(
        "update:amount",
        Number((this.amount + "").replace(/[,]/g, ""))
      );
      this.$emit("change");
    },
    inputChange() {
      // console.log(this.amount, "1");
      // // this.amount = Number((this.amount+'').replace(/[,]/g, ""))
      // console.log(Number((this.amount + "").replace(/[,]/g, "")), "2");
      if (!Number(this.amount)) {
        this.$emit("update:amount", 0);
      }
      this.$emit(
        "update:amount",
        Number((this.amount + "").replace(/[,]/g, ""))
      );
      this.$emit("change");
      this.status = true;
    },

    showInput() {
      if (this.disabled) {
        return;
      }
      this.status = false;
      this.$nextTick(function() {
        this.$refs.inputVal.focus();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  // width: 100%;
  // display: inline-block;
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 !important;
  color: #333;
  font-size: 14px;
}
input {
  width: calc(100% - 22px);
  line-height: 24px;
  // padding: 0 6px;
  background: rgb(241, 249, 253);
  font-size: 14px;
  padding-right: 20px;
  text-align: right;
  outline: none;
  color: #333;
  border: none;
}
p {
  // display: inline-block;
  // width: calc(100% - 22px);
  line-height: 27px;
  height: 27px;
  font-size: 14px;
  padding-right: 1px;
  // text-align: left;
  text-align: right;
  color: #333;
  padding-left: 5px;
}
.disabled {
  background: rgb(239, 239, 239);
}
</style>
