<template>
  <div class="tax_sb_each">
    <div>
      <i :class="['iconfont', icon]"></i>{{ title }}&nbsp;&nbsp;{{ count }}
    </div>
    {{ percentage }}%
  </div>
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  title: String,
  count: Number,
  total: Number,
  icon: String,
})
const percentage = computed(() => {
  if (props.total === 0) {
    return 0
  }
  let num = (props.count / props.total) * 100
  return parseFloat(num.toFixed(2))
})
</script>
<style lang="scss" scoped>
.tax_sb_each {
  width: 49%;
  padding: 3px 5px;
  border-radius: 4px;
  background: #eff9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 13px;
  .iconfont {
    margin-right: 5px;
    font-size: 13px;
  }
}
</style>
