export function inventoryTypeFilter(type) {
  const INVENTORY_TYPES = {
    1: "原材料",
    2: "库存商品",
    3: "半成品",
    4: "委托加工物资",
    5: "周转材料",
    6: "包装物",
    7: "消耗性生物资产",
    8: "工程物资",
    9: "开发产品",
    10: "发出产品",
    11: "其他",
    20: "低值易耗品",
    21: "材料采购",
    22: "生产性生物资产",
    default: "未知类型",
  };
  return INVENTORY_TYPES[type] || INVENTORY_TYPES.default;
}

export function inventoryCategoryFilter(type) {
  const categoryMap = {
    1: "初始化导入",
    2: "进项入库-进项发票录入",
    3: "暂估入库",
    4: "生产商品入库(生产结转)",
    6: "领料出库(生产结转)",
    7: "商品结转出库(贸易结转)",
    8: "抵消销项卖出(特殊)",
    9: "销项卖出-销项发票录入",
    10: "手工出库",
    11: "手工入库",
    12: "凭证关联出库",
    13: "凭证关联入库",
    31: "暂估回冲",
    91: "销项回冲",
  };
  return categoryMap[type] || "未知来源";
}

export function VoucherSourceFilter(type) {
  const typeMap = {
    1: "进项",
    2: "销项",
    3: "费用",
    4: "现金",
    5: "票据",
    6: "银行",
    7: "工资",
    8: "结转损益",
    9: "计提税金",
    10: "资产",
    12: "存货结转",
    13: "暂估回冲",
    14: "暂估入库",
    20: "进项认证",
    30: "固定资产清理",
    40: "汇兑损益",
    100: "年末结转利润",
    手工录入: "手工录入",
    15: "结转",
    16: "成本调差",
    110: "顶呱呱",
  };
  return typeMap[type] || "手工录入";
}

export function tableNameFilter(type, accountSystem) {
  // 使用对象映射来替代 if-else 结构
  const mapping = {
    kj_lrb: {
      民办非: "业务活动表",
      农业合作社: "盈余及盈余分配表",
      default: "利润表",
    },
    kj_zcfz: "资产负债表",
    gs_vat: "增值税及附加税一般纳税人申报表",
    gs_small_vat: "小规模增值税纳税及附加税申报表",
    gs_deed: "财产和行为税纳税申报表",
    gs_tax_quarter: "企业所得税申报表",
    gs_gs: "综合所得个人所得税",
    gs_fjs: "附加税",
    gs_whsyjsfsbbygz: "文化事业建设费申报表",
    gs_stamp_duty_item: "印花税纳税申报表", // 合并重复项
    gs_xfs: "消费税",
    gs_cbj: "残疾人就业保障金申报表",
    gs_ghjf: "工会经费", // 合并重复项
    gs_fcs: "房产税土地税",
    gs_fssr: "非税收入",
    gs_sl: "水利建设专项收入",
    gs_ljcl: "城市生活垃圾处置费",
    gs_qtsr: "工会经费", // 合并重复项
    gs_gs_one: "个人生产经营所得(查账)",
    gs_gs_hd: "个人生产经营所得(核定征收)",
    kj_xjllb: "现金流量表",
    gs_sb: "单位社会保险费日常申报(全责)",
    gs_cwbb: "财务报表",
    gs_tax_dqde: "个人所得税(定期定额)",
  };
  // 根据 type 和 accountSystem 返回相应的报表名称
  if (mapping[type] instanceof Object) {
    return mapping[type][accountSystem] || mapping[type].default;
  } else {
    return mapping[type];
  }
}

// 采集状态
export function setUpStatusCj(type) {
  const statusMap = {
    0: "未采集",
    1: "排队中",
    2: "采集中",
    3: "采集完成",
    4: "采集失败",
    99: "发起中",
    5: "异常",
  };
  // 返回对应的描述或默认值
  return statusMap[type] || "未知状态";
}

// 导入状态
export function setUpStatusImport(type) {
  const statusMap = {
    0: "未导入",
    1: "排队中",
    2: "导入中",
    3: "导入完成",
    4: "导入失败",
    99: "发起中",
    5: "错误",
  };
  // 返回对应的描述或默认值
  return statusMap[type] || "未知状态";
}

// 匹配状态
export function setUpStatusMatch(type) {
  const statusMap = {
    0: "未匹配",
    1: "排队中",
    2: "匹配中",
    3: "匹配完成",
    4: "匹配失败",
    99: "发起中",
    5: "错误",
  };
  // 返回对应的描述或默认值
  return statusMap[type] || "未知状态";
}
// 个税申报状态
export function setUpStatusGsSb(status, type) {
  const statusMapping = {
    0: "未申报",
    1: "排队中",
    2: "申报中",
    4: "申报失败",
    99: "发起中",
  };

  const typeMapping = {
    3: "超时失败",
    2: "异常",
    4: "超时失败",
    5: "申报异常",
    6: "申报失败",
    7: "申报成功，待缴款",
    8: "排队中",
    10: "申报成功，无需缴款",
    11: "扣款中",
    12: "扣款异常",
    13: "申报成功，扣款成功",
    14: "已申报过",
    20: "申报成功",
    default: "未知状态",
  };

  let result;
  if (
    status === 0 ||
    status === "" ||
    (typeof status === "string" && !status.trim())
  ) {
    result = "未申报";
  } else if (status in statusMapping) {
    result = statusMapping[status];
  } else if (type in typeMapping) {
    result = typeMapping[type] || typeMapping.default;
  }
  return result;
}

export function setUpStatusGsKk(status, type) {
  const combinedMap = {
    0: "未缴款",
    1: "排队中",
    2: "扣款中",
    4: "失败",
    99: "发起中",
    "": "未缴款",
  };
  const combinedMap1 = {
    3: "超时失败",
    2: "申报处理中",
    4: "超时失败", // 重复处理
    5: "申报异常",
    6: "申报失败",
    7: "申报成功，待缴款",
    8: "自动检查中",
    10: "申报成功，无需缴款",
    11: "扣款中",
    12: "扣款异常",
    13: "申报成功，扣款成功",
    14: "已申报过",
    20: "申报成功",
    default: "未知状态",
  };
  return combinedMap[status] || combinedMap1[type] || combinedMap1.default;
}

export function setUpStatusGsIconSb(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "2") {
      return "iconfont icon-gantanhao gantanhao-yc";
    } else if (type == "4") {
      return "iconfont icon-cuowu";
    } else if (type == "5") {
      return "iconfont icon-cuowu";
    } else if (type == "6") {
      return "iconfont icon-cuowu";
    } else if (type == "7") {
      return "iconfont icon-info";
    } else if (type == "8") {
      return "iconfont icon-info";
    } else if (type == "10") {
      return "iconfont icon-duihao";
    } else if (type == "11") {
      return "iconfont icon-info";
    } else if (type == "12") {
      return "iconfont icon-cuowu";
    } else if (type == "13") {
      return "iconfont icon-duihao";
    } else if (type == "14") {
      return "iconfont icon-duihao";
    } else if (type == "20") {
      return "iconfont icon-duihao";
    }
  }
}

export function setUpStatusGsWordSb(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else if (status == 99) {
    return "sbz";
  } else {
    if (type == "3") {
      return "sbyc2";
    } else if (type == "2") {
      return "yczt";
    } else if (type == "4") {
      return "sbyc2";
    } else if (type == "5") {
      return "sbyc2";
    } else if (type == "6") {
      return "sbyc2";
    } else if (type == "7") {
      return "sbz";
    } else if (type == "8") {
      return "sbz";
    } else if (type == "10") {
      return "sbcg2";
    } else if (type == "11") {
      return "sbz";
    } else if (type == "12") {
      return "sbyc2";
    } else if (type == "13") {
      return "sbcg2";
    } else if (type == "14") {
      return "sbcg2";
    } else if (type == "20") {
      return "sbcg2";
    }
  }
}

// 税种申报 申报任务结果状态
export function batchSendSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "申报失败";
  } else if (status == 8) {
    return "任务已撤销";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "申报成功，无需扣款";
    } else if (type == "3") {
      return "申报失败";
    } else if (type == "4") {
      return "申报已提交,等待回执";
    } else if (type == "5") {
      return "申报成功，待缴款";
    } else if (type == "6") {
      return "申报成功，已缴款";
    } else if (type == "20") {
      return "已申报过，无需扣款";
    } else if (type == "21") {
      return "已申报过，待缴款";
    } else if (type == "22") {
      return "已申报过，已缴款";
    } else if (type == "23") {
      return "已申报过，但税额不符";
    } else if (type == "40") {
      return "无需申报";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendIconSb(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 8) {
    return "iconfont icon-gantanhao";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "4") {
      return "iconfont icon-info";
    } else if (type == "5") {
      return "iconfont icon-info";
    } else if (type == "6") {
      return "iconfont icon-duihao";
    } else if (type == "20") {
      return "iconfont icon-duihao";
    } else if (type == "13") {
      return "iconfont icon-duihao";
    } else if (type == "21") {
      return "iconfont icon-info";
    } else if (type == "22") {
      return "iconfont icon-duihao";
    } else if (type == "23") {
      return "iconfont icon-gantanhao gantanhao-yc";
    } else if (type == "40") {
      return "iconfont icon-gantanhao gantanhao-blue";
    } else if (type == "60") {
      return "iconfont icon-info";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendWordSb(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else if (status == 8) {
    return "wsb";
  } else if (status == 99) {
    return "sbz";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else if (type == "4") {
      return "sbz";
    } else if (type == "5") {
      return "sbz";
    } else if (type == "6") {
      return "sbcg2";
    } else if (type == "20") {
      return "sbcg2";
    } else if (type == "21") {
      return "sbz";
    } else if (type == "22") {
      return "sbcg2";
    } else if (type == "23") {
      return "yczt";
    } else if (type == "40") {
      return "sbz";
    } else if (type == "60") {
      return "sbz";
    } else {
      return "未知状态";
    }
  }
}

// 税种缴款 扣款任务结果状态
export function batchSendKk(status, type) {
  if (status == 0 || status == "") {
    return "未缴款";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "缴款中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else if (type == "6") {
      return "成功(系统扣款)";
    } else if (type == "22") {
      return "成功(非系统扣款)";
    } else if (type == "13") {
      return "缴款成功";
    } else {
      return "未知状态";
    }
  }
}

// 税种缴款 扣款任务结果状态 字体颜色
export function batchSendWordKk(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else if (status == 99) {
    return "sbz";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else {
      return "未知状态";
    }
  }
}
//检查任务结果状态 字体颜色
export function batchSendWordJc(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else if (status == 99) {
    return "sbz";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else if (type == "30") {
      return "yczt";
    } else {
      return "未知状态";
    }
  }
}
// 申报检查任务结果状态
export function batchSendJc(status, type) {
  if (status == 0 || status == "") {
    return "未检查";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "检查中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else if (type == "30") {
      return "有遗漏";
    } else {
      return "未知状态";
    }
  }
}

// 采集发票
export function batchSendCj(status, type) {
  if (status == 0 || status == "") {
    return "未采集";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "采集中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 8) {
    return "任务已撤销";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else if (type == "4") {
      return "税局审核中";
    } else if (type == "5") {
      return "成功（未勾选发票）";
    } else if (type == "20") {
      return "已申报过";
    } else if (type == "30") {
      return "有遗漏";
    } else if (type == "40") {
      return "无需申报";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendIconCj(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "4") {
      return "iconfont icon-info";
    } else if (type == "5") {
      return "iconfont icon-duihao";
    } else if (type == "30") {
      return "iconfont icon-gantanhao gantanhao-yc";
    } else if (type == "40") {
      return "iconfont icon-gantanhao gantanhao-blue";
    }
  }
}

// 检查状态
export function setUpStatusJc(status, type) {
  if (type == "task") {
    if (status == 0 || status == "") {
      return "未检查";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "检查中";
    } else if (status == 3) {
      return "检查完成";
    } else if (status == 4) {
      return "检查失败";
    } else if (status == 99) {
      return "发起中";
    }
  } else {
    if (status == "2") {
      return "检查通过";
    } else if (status == "3") {
      return "检查失败";
    } else if (status == "30") {
      return "有遗漏";
    } else if (status == "50") {
      return "已申报，有异常";
    }
  }
}

// 检查状态
export function setUpStatusLogin(status, type) {
  if (status == 0 || status == "") {
    return "未授权登录";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "授权验证中";
  } else if (status == 4) {
    return "授权失败";
  } else {
    if (type == "2") {
      return "已授权，税局在线";
    } else if (type == "3") {
      return "授权失败";
    }
  }
}

export function setUpStatusIconJc(status, type) {
  if (type == "task") {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 3) {
      return "iconfont icon-duihao";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    } else if (status == 99) {
      return "iconfont icon-info";
    }
  } else {
    if (status == "2") {
      return "iconfont icon-duihao";
    } else if (status == "3") {
      return "iconfont icon-cuowu";
    } else if (status == "30") {
      return "iconfont icon-gantanhao gantanhao-cw";
    } else if (status == "40") {
      return "iconfont icon-gantanhao gantanhao-blue";
    } else if (status == "50") {
      return "iconfont icon-gantanhao gantanhao-yc";
    }
  }
}
export function setUpAllStatusIconJc(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "30") {
      return "iconfont icon-gantanhao gantanhao-yc";
    }
  }
}
// 个税作废状态
export function setUpStatusGsZf(status, type) {
  if (status == 0 || status == "") {
    return "未作废";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    }
  }
}
//更正状态
export function setUpStatusGsGz(status, type) {
  if (status == 0 || status == "") {
    return "未更正";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "更正中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "3") {
      return "超时失败";
    }
    if (type == "2") {
      return "异常";
    } else if (type == "4") {
      return "超时失败";
    } else if (type == "5") {
      return "申报异常";
    } else if (type == "6") {
      return "申报失败";
    } else if (type == "7") {
      return "申报成功，待缴款";
    } else if (type == "8") {
      return "排队中";
    } else if (type == "10") {
      return "申报成功，无需缴款";
    } else if (type == "11") {
      return "扣款中";
    } else if (type == "12") {
      return "扣款异常";
    } else if (type == "13") {
      return "申报成功，扣款成功";
    } else if (type == "14") {
      return "已申报过";
    } else if (type == "20") {
      return "申报成功";
    } else {
      return "未知状态";
    }
  }
}

export function setUpStatusGsIconZf(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    }
  }
}

export function setUpStatusGsWordZf(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else if (status == 99) {
    return "sbz";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else if (type == "30") {
      return "sbyc2";
    } else if (type == "40") {
      return "sbz";
    } else if (type == "50") {
      return "yczt";
    }
  }
}

// 采集科目余额表状态
export function setUpStatusJzCj(status, type) {
  if (status == 0 || status == "") {
    return "未采集";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "采集中";
  } else if (status == 4) {
    return "失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "采集成功";
    } else if (type == "3") {
      return "失败";
    }
  }
}

export function setUpStatusJzCjIcon(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else if (status == 99) {
    return "iconfont icon-info";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    }
  }
}

// 城市筛选
export function cityFilter(type) {
  // 使用对象来存储映射关系
  const cityMap = {
    beijing: "北京",
    shandong: "山东",
    qingdao: "青岛",
    neimenggu: "内蒙古",
    shanxi: "山西",
    tianjin: "天津",
    sichuan: "四川",
    jiangsu: "江苏",
    hebei: "河北",
    xizang: "西藏",
    qinghai: "青海",
    henan: "河南",
    shaanxi: "陕西",
    hainan: "海南",
    liaoning: "辽宁",
    shanghai: "上海",
    hunan: "湖南",
    guangdong: "广东",
    shenzhen: "深圳",
    guangxi: "广西",
    chongqing: "重庆",
    yunnan: "云南",
    xinjiang: "新疆",
    anhui: "安徽",
    hubei: "湖北",
    guizhou: "贵州",
    dalian: "大连",
    ningbo: "宁波",
    xiamen: "厦门",
    ningxia: "宁夏",
    jiangxi: "江西",
    jilin: "吉林",
    heilongjiang: "黑龙江",
    zhejiang: "浙江",
    fujian: "福建",
    gansu: "甘肃",
  };

  // 返回对应的城市名或默认值
  return cityMap[type] || "未知城市";
}
// 汇算清缴取数状态
export function yearAcessStatus(type) {
  if (type == 0) {
    return "未取数";
  } else if (type == 1) {
    return "取数中";
  } else if (type == 3) {
    return "取数成功";
  } else if (type == 4) {
    return "取数失败";
  }
}

export function yearAcessIconStatus(type) {
  if (type == 0) {
    return "iconfont icon-gantanhao";
  } else if (type == 1) {
    return "iconfont icon-info";
  } else if (type == 3) {
    return "iconfont icon-duihao";
  } else if (type == 4) {
    return "iconfont icon-cuowu";
  }
}

export function yearAcessWordStatus(type) {
  if (type == 0) {
    return "wsb";
  } else if (type == 1) {
    return "sbz";
  } else if (type == 3) {
    return "sbcg2";
  } else if (type == 4) {
    return "sbyc2";
  } else if (type == 99) {
    return "sbz";
  }
}

// 税种申报 申报任务结果状态
export function batchSendYearSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "申报失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "申报成功，无需扣款";
    } else if (type == "3") {
      return "申报失败";
    } else if (type == "4") {
      return "申报已提交,等待回执";
    } else if (type == "5") {
      return "申报成功，待缴款";
    } else if (type == "6") {
      return "申报成功,已缴款";
    } else if (type == "20") {
      return "已申报过，无需扣款";
    } else if (type == "21") {
      return "己申报过，待缴款";
    } else if (type == "22") {
      return "已申报过，已缴款";
    } else if (type == "60") {
      return "申报成功，待退税";
    } else if (type == "40") {
      return "无需申报";
    } else {
      return "未知状态";
    }
  }
}
//工商年报申报状态
export function batchSendBusinessSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "申报失败";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "申报成功";
    } else if (type == "3") {
      return "申报失败";
    } else if (type == "4") {
      return "申报已提交,等待回执";
    } else if (type == "5") {
      return "申报成功，待缴款";
    } else if (type == "6") {
      return "申报成功，已缴款";
    } else if (type == "20") {
      return "已申报过";
    } else if (type == "21") {
      return "己申报过，待缴款";
    } else if (type == "22") {
      return "已申报过，已缴款";
    } else {
      return "未知状态";
    }
  }
}

// 勾选认证
export function batchSendGxrz(status, type) {
  if (status == 0 || status == "") {
    return "未确认";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "确认中";
  } else if (status == 4) {
    return "确认失败";
  } else if (status == 8) {
    return "任务已撤销";
  } else if (status == 99) {
    return "发起中";
  } else {
    if (type == "2") {
      return "确认成功";
    } else if (type == "3") {
      return "确认失败";
    } else {
      return "未知状态";
    }
  }
}

export function taskNameType(type) {
  const typeDescriptions = {
    "tax-sb-smallVat": "申报-小规模增值税纳税人",
    "tax-sb-vat": "申报-增值税一般纳税人",
    "tax-sb-taxQuarter": "申报-企业所得税申报表",
    "tax-sb-deed": "申报-行为税",
    "tax-sb-qtsr": "申报-其他收入",
    "tax-sb-sl": "申报-水利建设专项收入",
    "tax-sb-whsyjsf": "申报-文化事业建设费",
    "tax-sb-cbj": "申报-残疾人保障金",
    "tax-sb-xfs": "申报-消费税",
    "tax-sb-ljcl": "申报-城市生活垃圾处置费",
    "tax-sb-shebao": "申报-社保申报",
    "tax-sb-cwbb": "申报-财务报表",
    "tax-cwyear-sb": "申报-财务报表(年)",
    "tax-year-sb": "申报-汇算清缴",
    "tax-sb-dqde": "申报-个税定期定额",
    "tax-cj-jianzhang": "采集-建账采集(企业信息和税种税表)",
    "tax-cj-jump": "采集-跳过采集",
    "tax-cj-taxCategory": "采集-更新税种",
    "tax-cj-resetTaxs": "采集-重新采集税表",
    "tax-cj-outInvoice": "采集-销项发票",
    "tax-cj-inInvoice": "采集-进项发票",
    "tax-cj-invoice": "采集-进项销项发票",
    "tax-cj-shebao": "采集-社保采集",
    "tax-year-cj": "采集-汇算清缴",
    "tax-cj-cwbb": "采集-财务报表",
    "tax-ybjc": "采集-一表集成",
    "tax-cj-ndks": "采集-年度亏损",
    "tax-kk-smallVat": "扣款-小规模增值税纳税人",
    "tax-kk-vat": "扣款-增值税一般纳税人",
    "tax-kk-taxQuarter": "扣款-企业所得税申报表",
    "tax-kk-deed": "扣款-行为税",
    "tax-kk-qtsr": "扣款-其他收入",
    "tax-kk-sl": "扣款-水利建设专项收入",
    "tax-kk-whsyjsf": "扣款-文化事业建设费",
    "tax-kk-cbj": "扣款-残疾人保障金",
    "tax-kk-xfs": "扣款-消费税",
    "tax-kk-ljcl": "扣款-城市生活垃圾处置费",
    "tax-kk-fjs": "扣款-附加税",
    "tax-kk-shebao": "扣款-社保申报",
    "tax-kk-cwbb": "扣款-财务报表",
    "tax-kk-dqde": "扣款-个税定期定额",
    "tax-jc-shenbao": "检查-检查",
    "tax-jc-shebao": "检查-社保",
    "his-cj-historyCollect": "采集-历史数据采集",
    "other-cj-jianzhang": "采集-科目余额表",
    "cj-bank": "采集-银行明细账",
    "ssjg-people-sync": "涉税-涉税机构人员同步",
    "ssjg-xy-sync": "涉税-涉税机构协议同步",
    "cj-invoice-pdf": "采集-发票pdf",
    "tax-cj-pdf": "采集-发票影像",
    "open-invoice": "开发票",
    "tax-tpass-login": "登录上线-保活tpass任务",
    "tax-cj-hisData": "采集-历史数据(账套迁移)",
    "tax-cj-comInfo": "采集-历史公司(账套迁移)",
    "tax-gxrz": "勾选认证",
    "gsnb-cj": "采集-工商年报",
    "gsnb-sb": "申报-工商年报",
    "tax-cj-sbqc": "申报-清册",
    "tax-year-kk": "扣款-汇算清缴",
    "gs-sb-gs": "个税申报",
    "gs-sb-gd": "个独申报",
    "gs-kk-gs": "个税扣款",
    "gs-kk-gd": "个独扣款",
    "gs-cx": "撤销",
    "gs-cj-ljsjcj": "累计数据采集",
    "gs-cj-zxfjkccj": "专项附加扣除采集",
    "gs-zf-gs": "作废个税",
    "gs-zf-gd": "作废个独",
    "gs-jc-gs": "检查个税",
    "gs-jc-gd": "检查个独",
    "gs-cj-gs": "个税不申报采集",
    "gs-gz-gs": "个税更正",
    "gs-sb-gdhsqj": "个独年度汇算申报",
    "his-cj-historyMatch": "历史数据匹配",
    "his-cj-historyImport": "历史数据导入",
    "year-cw-qs": "汇算清缴财报取数",
    "year-tax-qs": "汇算清缴取数",
    plqushu: "批量取数",
    "tax-gs-move": "个税迁移",
    qianyi: "迁移",
    qianyiOld: "迁移",
    default: "未知类型",
  };

  return typeDescriptions[type] || typeDescriptions.default;
}

// 完税状态
export function setUpStatusWsJc(status, type) {
  const statusMap = {
    task: {
      0: "未采集",
      "": "未采集",
      1: "排队中",
      2: "采集中",
      3: "采集完成",
      4: "采集失败",
      99: "发起中",
      default: "未知状态",
    },
    default: {
      2: "采集完成",
      3: "采集失败",
      40: "无需采集",
      default: "未知状态",
    },
  };

  const statusObj = statusMap[type] || statusMap.default;

  return statusObj[status] || statusObj.default;
}

//会计制度过滤
export function accountSystemFilter(type) {
  const mapping = {
    "一般企业会计准则(已执行新金融准则)": "一般企业会计准则(已执行-常规科目)",
    "一般企业会计准则(已执行新金融准则)(全科目)":
      "一般企业会计准则(已执行-全科目)",
    "一般企业会计准则(全科目)": "一般企业会计准则(未执行-全科目)",
    一般企业会计准则: "一般企业会计准则(未执行-常规科目)",
    民办非: "民间非营利",
    农业合作社: "农村合作社",
  };
  return mapping[type] || type;
}

//自动记账状态过滤
export function filterAutoStatusIcon(type) {
  if ([1, 2, 7].includes(type)) {
    return "iconfont icon-info";
  } else if ([3, 6, 9].includes(type)) {
    return "iconfont icon-duihao";
  } else if (type == 4) {
    return "iconfont icon-cuowu";
  } else if (type == 5) {
    return "iconfont icon-duihao duihao-yc";
  } else if (type == 8) {
    return "iconfont icon-duihao duihao-blue";
  } else {
    return "iconfont icon-gantanhao";
  }
}

export function filterAutoStatusText(type, flag) {
  const statusMap = {
    1: "进行中",
    2: {
      qr: "待确认",
      skqr: "待确认",
      cj: "采集中",
      bc: "补充中",
      sc: "生成中",
      jz: "结账中",
      sb: "申报中",
      kk: "扣款中",
      jc: "检查中",
      zj: "待质检",
      default: "执行中",
    },
    3: {
      qr: "已确认",
      zj: "已确认",
      skqr: "申报并扣款",
      default: "成功",
    },
    4: "失败",
    5: "部分成功",
    6: {
      qr: "无需确认",
      skqr: "无需确认",
      default: "无需操作",
    },
    7: "人工跳过",
    8: "超时确认",
    9: "申报暂不扣款",
    default: {
      qr: "未确认",
      skqr: "未确认",
      zj: "未质检",
      default: "未开始",
    },
  };

  // 获取对应的 status 对象或直接返回字符串
  const statusObj = statusMap[type] || statusMap.default;

  // 如果 statusObj 是字符串，则直接返回；如果是对象，则根据 flag 返回对应值
  return typeof statusObj === "string"
    ? statusObj
    : statusObj[flag] || statusObj.default;
}

export function filterAutoStatusWord(type) {
  if ([1, 2, 7, 8].includes(type)) {
    return "sbz";
  } else if ([3, 6, 9].includes(type)) {
    return "sbcg2";
  } else if (type == 4) {
    return "sbyc2";
  } else if (type == 5) {
    return "yczt";
  } else {
    return "wsb";
  }
}

//官网文章类型
export function filterWebsite(type) {
  if (type == 0) {
    return "公司新闻";
  } else if (type == 1) {
    return "行业咨询";
  } else if (type == 3) {
    return "赋能特训营";
  }
}

//首页各确认状态
export function homeConfirmStatus(type, flag) {
  const statusMap = {
    0: "未" + (flag ? '核查' : '确认'),
    1: "已" + (flag ? '核查' : '确认'),
    2: "调整税款",
    3: "无需确认",
    4: "确认中",
  };
  // 返回对应的描述或默认值
  return statusMap[type] || "未知状态";
}
//首页税款确认状态
export function homeTaxStatus(type) {
  const statusMap = {
    0: '未确认',
    1: '已确认，可扣款',
    2: "调整税款",
    3: "无需确认",
    4: "确认中",
    6: "已确认，暂不扣款"
  };
  // 返回对应的描述或默认值
  return statusMap[type] || "未知状态";
}
//首页确认icon
export function homeConfirmIcon(type) {
  const iconMap = {
    0: "iconfont icon-gantanhao",
    1: "iconfont icon-duihao",
    2: "iconfont icon-cuowu",
    3: "iconfont icon-duihao",
    4: "iconfont icon-info",
    6: "iconfont icon-duihao",
  };
  // 返回对应的描述或默认值
  return iconMap[type] || "iconfont icon-gantanhao";
}

//银行授权状态
export function bankAuthStatus(type) {
  if (type.indexOf("验证中") > -1) {
    return "验证中";
  } else if (['验证失败', '错误', '异常'].some(v => type.includes(v))) {
    return "验证失败";
  } else if (!type) {
    return "未授权";
  } else {
    return "已授权";
  }
}
//银行授权icon
export function bankAuthIcon(type) {
  if (type.indexOf("验证中") > -1) {
    return "iconfont icon-info";
  } else if (['验证失败', '错误', '异常'].some(v => type.includes(v))) {
    return "iconfont icon-cuowu";
  } else if (!type) {
    return "iconfont icon-gantanhao";
  } else {
    return "iconfont icon-duihao";
  }
}

//dgg确认状态显示气泡判断
export function filterHomeBadge(specifiedTime, flag) {
  const currentDate = new Date();
  const specifiedDate = new Date(specifiedTime);
  currentDate.setHours(0, 0, 0, 0);
  specifiedDate.setHours(0, 0, 0, 0);
  const timeDifference = currentDate - specifiedDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const colorMap = {
    0: "#67c23a",
    1: '#67c23a',
    2: "#e6a23c",
    3: "red",
  };
  if (flag) return colorMap[daysDifference] || 'red'
  return daysDifference;
}
