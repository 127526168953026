import request from '@/utils/request'
// 获取添加凭证时科目信息 
export function getVoucherCodeInfo(data) {
  return request({
      url: 'api/v2/voucher/getVoucherCodeInfo',
      method: 'post',
      data
  })
}

//新增修改凭证
export function saveVoucher(data) {
  return request({
      url: 'api/v2/voucher/saveVoucher',
      method: 'post',
      data
  })
}

//获取最新凭证号
export function getNewVoucherNo(data) {
  return request({
      url: 'api/v2/voucher/getNewVoucherNo',
      method: 'post',
      data
  })
}

//凭证列表
export function voucherList(data) {
  return request({
      url: 'api/v2/voucher/voucherList',
      method: 'post',
      data
  })
}
//凭证详情
export function voucherInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherInfo',
      method: 'post',
      data
  })
}
//删除凭证
export function voucherDel(data) {
  return request({
      url: 'api/v2/voucher/voucherDel',
      method: 'post',
      data
  })
}

//生成凭证
export function voucherPreview(data) {
  return request({
      url: 'api/v2/voucher/voucherPreview',
      method: 'post',
      data
  })
}

//一键生成凭证
export function oneKeyVoucher(data) {
  return request({
      url: 'api/v2/voucher/oneKeyVoucher',
      method: 'post',
      data
  })
}

//凭证合成习惯设置
export function mergeInfo(data) {
  return request({
      url: 'api/v2/voucher/mergeInfo',
      method: 'post',
      data
  })
}

//凭证合成习惯设置
export function mergeSet(data) {
  return request({
      url: 'api/v2/voucher/mergeSet',
      method: 'post',
      data
  })
}

//凭证合成习惯设置批量
export function mergeInfoOrg(data) {
  return request({
      url: 'api/v2/voucher/mergeInfoOrg',
      method: 'post',
      data
  })
}

//凭证合成习惯设置批量
export function mergeSetOrg(data) {
  return request({
      url: 'api/v2/voucher/mergeSetOrg',
      method: 'post',
      data
  })
}
//凭证一键排序
export function voucherSort(data) {
  return request({
      url: 'api/v2/voucher/voucherSort',
      method: 'post',
      data
  })
}

//排序
export function editVoucherNo(data) {
  return request({
      url: 'api/v2/voucher/editVoucherNo',
      method: 'post',
      data
  })
}
//凭证手动合并
export function HandMergeVoucher(data) {
  return request({
      url: 'api/v2/voucher/HandMergeVoucher',
      method: 'post',
      data
  })
}

//凭证模板
export function voucherTemplateList(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateList',
      method: 'post',
      data
  })
}

//保存为模板，保存
export function voucherTemplateSave(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateSave',
      method: 'post',
      data
  })
}

//选择自定义模板
export function voucherTemplateInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateInfo',
      method: 'post',
      data
  })
}

//删除自定义模板
export function voucherTemplateDel(data) {
  return request({
      url: 'api/v2/voucher/voucherTemplateDel',
      method: 'post',
      data
  })
}
//凭证一键审核
export function voucherLook(data) {
  return request({
      url: 'api/v2/voucher/voucherLook',
      method: 'post',
      data
  })
}

//复制
export function saveEaVoucherCopy(data) {
  return request({
      url: 'api/v2/voucher/saveEaVoucherCopy',
      method: 'post',
      data
  })
}

//上张下一张
export function voucherNextInfo(data) {
  return request({
      url: 'api/v2/voucher/voucherNextInfo',
      method: 'post',
      data
  })
}

//拖动排序
export function dragSort(data) {
  return request({
      url: 'api/v2/voucher/dragSort',
      method: 'post',
      data
  })
}

//v3电子账簿生成账本
export function createVoucherImage(data) {
  return request({
    url: "api/v2/voucher/createVoucherImage",
    method: "post",
    data,
  });
}

//查看电子账本
export function getVoucherImageByUuid(data) {
  return request({
    url: "api/public/getVoucherImageByUuid",
    method: "post",
    data,
  });
}

//凭证影像查看
export function getVoucherPng(data) {
  return request({
    url: "api/v2/voucher/getVoucherPng",
    method: "post",
    data,
  });
}

// 保存模板名称

export function saveTemplateName(data) {
  return request({
    url: "api/v2/voucher/saveVoucherMergeTemplate",
    method: "post",
    data,
  });
}

// 删除模板名称

export function delTemplateName(data) {
  return request({
    url: "api/v2/voucher/voucherMergeTemplateDel",
    method: "post",
    data,
  });
}

// 查询模板名称

export function getTemplateName(data) {
  return request({
    url: "api/v2/voucher/voucherMergeTemplateList",
    method: "post",
    data,
  });
}

//汇总凭证图片
export function voucherQuery(data) {
  return request({
    url: "api/v2/voucher/voucherQuery",
    method: "post",
    data,
  });
}
//汇总凭证图片分页
export function voucherQueryWithLimit(data) {
  return request({
    url: "api/v2/voucher/voucherQueryDetail",
    method: "post",
    data,
  });
}

//顶呱呱凭证保存
export function saveVoucherDgg(data) {
  return request({
    url: "api/v2/voucher/saveVoucherDgg",
    method: "post",
    data,
  });
}

// 保存制单人

export function batchMergeSet(data) {
  return request({
    url: "api/v2/voucher/batchMergeSet",
    method: "post",
    data,
  });
}

//上传附件二维码
export function getVoucherQrCode(data) {
  return request({
    url: "api/v2/voucher/getVoucherQrCode",
    method: "post",
    data,
  });
}

//查询上传附件list
export function voucherFjList(data) {
  return request({
    url: "api/v2/voucher/voucherFjList",
    method: "post",
    data,
  });
}

//附件删除
export function delVoucherFj(data) {
  return request({
    url: "api/v2/voucher/delVoucherFj",
    method: "post",
    data,
  });
}

//小程序上传附件
export function uploadVoucherFj(data) {
  return request({
    url: "api/v2/wxCz/uploadVoucherFj",
    method: "post",
    data,
  });
}

//凭证回收站列表
export function voucherWareList(data) {
  return request({
    url: "api/v2/voucher/voucherWareList",
    method: "post",
    data,
  });
}
//凭证回收站恢复删除
export function delectOrHfVoucher(data) {
  return request({
    url: "api/v2/voucher/delectOrHfVoucher",
    method: "post",
    data,
  });
}