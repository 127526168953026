<template>
  <el-select :class="{boxShadowNone:borderNone}" :disabled="disabledIn" filterable v-model="id" placeholder="请选择往来单位"  style="width:96%" size="small">
    <el-option style="color:red" label="新增+" value="add"></el-option>
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    >
    <span style="float: left">{{ item.name }}</span>
    <!-- <span style="float: left">&nbsp;{{ item.subjectCode }}</span> -->

    <span v-if="item.fzhsId" style="float: right;color: red;font-size: 14px;">(辅助)</span>
    <span style="float: right">&nbsp;&nbsp;{{ item.subjectName }}</span>


    </el-option>
  </el-select>
  <add-business @success="addSuccess" ref="addbusiness" :type="type" :comId="comId"></add-business>
  <!-- <corp-list corpName="北京公司" v-model:corpId="a" type="1"></corp-list> -->
</template>
<script>
import { dataTool } from 'echarts'
export default {
  props:{
    type: { //1客户 2供应商
      type: String,
      default: "1"
    },
    corpId: {
      type: Number,
      default: 0
    },
    corpName: {//新增单位的名字
      type: String,
      default: "",
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    comId: {
      type: Number,
      default: 0
    },
  },
  computed: {
    options(){
      let corps = this.$store.getters["commons/corps"]
      let kh = []
      let gys = []
      corps.listKh?kh = corps.listKh:kh = []
      corps.listGys?gys = corps.listGys:gys = []
      if(this.type == 1){
        return kh
      }else{
        return gys
      }
    },
    id: {
      get() {
        if(!this.corpId){
          return ""
        }
        return this.corpId
      },
      set(val) {
        if(val == 'add'){
          let data = {
            id: undefined,
            name: this.corpName,
            type: 0,
          }
          this.$refs.addbusiness.init(data)
          val = undefined
          return
        }
        // console.log(val)
        this.$emit("update:corpId", val)
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    addSuccess(e){
      this.id = e.id
    },
  }
}
</script>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-input__wrapper) {
    box-shadow: none;
    background: none;
  }
}
</style>