<template>
  <div class="app-main">
    <router-view :key="key" v-slot="{ Component }">
      <!-- 缓存页面 3.0写法 -->
      <keep-alive :include="cachedViews">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <el-icon style="position: absolute;right: 0px;bottom: 161px;color: var(--themeColor,#17a2b8);cursor: pointer;" @click="expandOut = true" v-if="openUrlSrc" v-show="!expandOut"><DArrowRight /></el-icon>
  <!-- <el-icon style="position: absolute;right: 0px;bottom: 160px;color: var(--themeColor,#17a2b8);cursor: pointer;" @click="expandOut = false" v-if="expandOut"><Service /></el-icon> -->
  <div class="zxzx"  @click="expandOut = false" v-show="expandOut" v-if="openUrlSrc">
    在线咨询
  </div>
  <div style="display: inline-block; position: fixed;right: 15px;bottom: 150px;z-index: 9999999;cursor: pointer;" @click="openUrl" v-if="openUrlSrc" v-show="!expandOut">
    <i style="font-size:40px;color:var(--themeColor,#17a2b8)" class="iconfont icon-jiqirenkehu"></i>
  </div>
</template>
<script>
export default {
  name: 'right',
  data () {
    return {
      sss: ["index1"],
      openUrlSrc:this.$store.getters['user/user'].wechatContactLink,
      expandOut:true
    }
  },
  computed: {
    cachedViews() {
      const cachedViews = this.$store.getters['tagsView/cachedViews']
      if (cachedViews.length <= 1) {
        return cachedViews
      }
      // console.log("appmain", this.$store.getters['tagsView/cachedViews'])
      // 直接使用使用 this.$store.getters['tagsView/cachedViews'] 会导致财务核算 记账客户 切出在切入之后 页面会一直处在 inactive 状态，即组件由于未知原因未激活，导致无响应
      // 使用 逗号分隔的字符串 或者 使用数组的原始值 就好了，原因未知
      return cachedViews.join(',')
      // return toRaw(this.$store.getters['tagsView/cachedViews'])
    },
    // key() {
    //   return this.$route.fullPath
    // }
  },
  setup() {
    
  },
  methods:{
    openUrl(){
      window.open(this.openUrlSrc)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main{
  width: calc(100% - 12px);
  min-height: calc(100vh - 96px);
  background: #fff;
  margin-top: 5px;
  margin-left: 5px;
  overflow: auto;
  float: left;
}
.zxzx{
  position: absolute;
  right:0px;
  bottom: 140px;
  // height: 71px;
  width: 20px;
  font-size: 12px;
  border: 1px solid var(--themeColor,#17a2b8);
  border-radius: 3px;
  padding: 3px;
  color: white;
  background-color: var(--themeColor,#17a2b8);
  cursor: pointer;
}
</style>