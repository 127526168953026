<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="drawer"
    :title="comInfo.name + '-授权'"
    :width="operaType == 'sq' || operaType == 'sqlj' ? '800px' : '1200px'"
    append-to-body
    destroy-on-close
  >
    <div class="top">
      <div>
        <select-common
          placeholder="请选择登录方式"
          v-model:modelValue="listQuery.loginMethod"
          :options="options"
          class="w120 ml-0"
        />
        <el-input
          size="small"
          placeholder="请输入用户名/手机号"
          v-model="listQuery.zzridno"
          clearable
          class="w120 mr-5"
          @keyup.enter="getList"
        ></el-input>
        <el-input
          size="small"
          placeholder="请输入密码"
          v-model="listQuery.zzrmn"
          clearable
          class="w120 mr-5"
          @keyup.enter="getList"
        ></el-input>
        <el-input
          size="small"
          placeholder="请输入代理机构号"
          clearable
          v-model="listQuery.idNo"
          @keyup.enter="getList"
          class="w120"
        ></el-input>
        <el-button size="small" type="primary" icon="Search" @click="getList"
          >搜索</el-button
        >
      </div>
      <div>
        <taxBureau
          :comId="listQuery.comId"
          @success="getList"
          v-if="!operaType"
        />
        <el-button
          size="small"
          type="primary"
          plain
          icon="CreditCard"
          @click="updateBsr"
          v-if="!operaType || operaType == 'edit'"
        >
          新增绑定登录信息</el-button
        >
        <el-button
          type="danger"
          plain
          size="small"
          icon="Unlock"
          @click="cancelAll()"
          v-if="operaType == 'edit'"
          >取消绑定</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="400"
      stripe
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        type="selection"
        width="55"
        align="center"
        v-if="operaType == 'edit'"
      />
      <el-table-column type="index" label="序号" width="55" align="center" />
      <el-table-column
        prop="loginMethod"
        label="登录方式"
        min-width="120"
        v-if="operaType != 'sq' && operaType != 'sqlj'"
      />
      <el-table-column prop="zzridno" label="用户名/手机号" min-width="120" />
      <el-table-column
        prop="zzrmn"
        label="密码"
        min-width="120"
        v-if="operaType == 'edit'"
      />
      <el-table-column
        prop="idNo"
        label="代理机构号"
        min-width="120"
        v-if="operaType != 'sq' && operaType != 'sqlj'"
      />
      <el-table-column prop="idNo" label="短信接收方式" min-width="120">
        <template #default="scope">
          {{ scope.row.yzmType == 1 ? "手动接收" : "自动接收" }}
        </template>
      </el-table-column>

      <el-table-column prop="idNo" label="在线状态" width="155">
        <template #default="scope">
          <el-tooltip
            :disabled="!scope.row.keepErr || scope.row.keepStatus != 2"
            effect="dark"
            placement="top-start"
            :content="scope.row.keepErr"
          >
            <div class="item_icon">
              <span
                v-if="
                  ['zhejiang', 'guangdong', 'tianjin', 'hubei'].includes(
                    comInfo?.district
                  ) || scope.row.loginMethod != '新版登录'
                "
                style="color: green"
              >
                无需授权
              </span>
              <span v-else-if="scope.row.keepStatus == 1" style="color: green">
                已授权，税局在线</span
              >
              <span
                v-else-if="[2, 3].includes(scope.row.keepStatus)"
                class="sbyc2"
              >
                <i class="iconfont icon-cuowu"></i>
                {{ scope.row.keepStatus == 2 ? "授权失败" : "已掉线" }}</span
              >
              <span v-else-if="scope.row.keepStatus == 4" class="sbz">
                <i class="iconfont icon-info"></i>
                授权中</span
              >
              <span v-else class="wsb">
                <i class="iconfont icon-gantanhao"></i>
                未授权</span
              >
              <i
                class="iconfont icon-wenhao"
                style="color: red; margin-left: 5px"
                v-if="
                  [2, 3].includes(scope.row.keepStatus) && scope.row.keepErr
                "
              ></i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        :width="operaType ? '230' : '430'"
      >
        <template #default="scope">
          <el-button
            @click="sendSms(scope.row)"
            size="small"
            type="primary"
            icon="ChatRound"
            plain
            :disabled="!$buttonStatus('sddl_dl')"
            v-if="
              scope.row.keepStatus == 4 &&
              scope.row.yzmType == 1 &&
              (!operaType || operaType == 'sq')
            "
            >输入验证码</el-button
          >
          <el-button
            type="primary"
            icon="SwitchButton"
            plain
            size="small"
            :loading="scope.row.loading"
            @click="shouquan(scope.row)"
            :disabled="
              !$buttonStatus('sddl_dl') ||
              !scope.row.zzridno ||
              !scope.row.zzrmn ||
              scope.row.loginMethod != '新版登录' ||
              scope.row.keepStatus == 4
            "
            v-if="!operaType || operaType == 'sq'"
            >授权</el-button
          >
          <el-button
            type="primary"
            plain
            size="small"
            icon="Edit"
            @click="edit(scope.row)"
            v-if="!operaType || operaType == 'edit' || scope.row.keepStatus == 4"
            >编辑</el-button
          >
          <el-button
            type="danger"
            plain
            size="small"
            icon="Unlock"
            @click="cancelOne(scope.row)"
            v-if="operaType == 'edit'"
            >取消绑定</el-button
          >

          <el-popover
            placement="top-start"
            :visible="scope.row.visible"
            :width="170"
            popperClass="login-tax-bureau-opera-get-auth-link"
            trigger="click"
          >
            <template #reference>
              <el-button
                size="small"
                type="primary"
                plain
                @click="getLink(scope.row)"
                v-if="!operaType || operaType == 'sqlj'"
                :disabled="
                  ['zhejiang', 'guangdong', 'tianjin', 'hubei'].includes(
                    comInfo?.district
                  )
                "
                >获取授权链接</el-button
              >
            </template>
            <ul>
              <li @click="openLink(scope.row, 1)">
                <el-icon>
                  <postcard />
                </el-icon>
                <span>二维码</span>
              </li>
              <li @click="openLink(scope.row, 2)">
                <el-icon>
                  <Link />
                </el-icon>
                <span>复制授权链接</span>
              </li>
            </ul>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-dialog>
  <taxManForm ref="taxManFormRef" @success="getList" />
  <el-dialog
    title="短信验证码验证"
    v-model="dialogFormVisible"
    width="20%"
    destroy-on-close
    :close-on-click-modal="false"
    append-to-body
  >
    <el-form :model="form" :rules="rules" ref="ruleForm" size="small">
      <el-form-item label="手机号" prop="mobileRec">
        <el-input
          v-model="form.mobileRec"
          placeholder="请输入手机号"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="code">
        <el-input
          v-model="form.code"
          placeholder="所收到的税局短信息验证码"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="makeSureSend"
          size="small"
          :loading="sendLoading"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>
  <updateInfo ref="updateInfo" @success="getList" />
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="[codeImg]"
    :preview-teleported="true"
    :teleported="true"
  />
</template>

<script>
import {
  autoEmpower,
  handEmpower,
  companyLoginComList,
  getQrcode,
  cancelCompanyLoginCom,
} from "@/api/company";
import taxManForm from "./taxManForm.vue";
import updateInfo from "../../views/ea-setting/components/updateInfo.vue";
import taxBureau from "@/components/loginTaxAlert/taxBureau.vue";
export default {
  name: "impowerManage",
  components: {
    taxManForm,
    updateInfo,
    taxBureau,
  },
  props: {
    operaType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      drawer: false,
      loading: false,
      dialogFormVisible: false,
      comInfo: {},
      options: [
        { label: "全部", value: "" },
        { label: "新版登录", value: "新版登录" },
        { label: "代理登录", value: "代理登录" },
        { label: "政务网登录", value: "政务网登录" },
      ],
      listQuery: {
        page: 1,
        limit: 20,
      },
      form: {},
      sendLoading: false,
      rules: {
        mobileRec: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      codeImg: "",
      showImagePreview: false,
      codeLink: "",
      visible: false,
      currentImgViewName: "",
      sels: [],
    };
  },

  mounted() {},
  methods: {
    init(row) {
      this.drawer = true;
      this.listQuery.comId = row.id;
      this.comInfo = row;
      this.getList();
    },
    getList() {
      this.loading = true;
      companyLoginComList(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    edit(row) {
      this.$refs.taxManFormRef.init(row);
    },
    //授权
    shouquan(row) {
      row.loading = true;
      autoEmpower({
        yzmType: row.yzmType,
        zzridno: row.zzridno,
        zzrmn: row.zzrmn,
        keepStatus: row.keepStatus,
        comId: this.listQuery.comId,
      }).then((res) => {
        row.loading = false;
        // 手动成功之后打开弹窗
        if (res.data.data == 2 && row.yzmType == 1) {
          this.getList();
          this.sendSms(row);
        } else if (res.data.msg == "success") {
          this.$qzfMessage("已授权");
          this.getList();
        } else {
          this.getList();
        }
      });
    },
    // 发送验证码
    sendSms(row) {
      this.form.mobileRec = row.zzridno;
      this.form.comId = this.listQuery.comId;
      this.form.zzrmn = row.zzrmn;
      this.dialogFormVisible = true;
      this.form.code = "";
    },
    makeSureSend() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.sendLoading = true;
          let param = {
            tel: this.form.mobileRec,
            code: this.form.code,
            zzrmn: this.form.zzrmn,
            comId: this.listQuery.comId,
          };
          handEmpower(param).then((res) => {
            this.sendLoading = false;
            if (res.data.msg == "success") {
              this.dialogFormVisible = false;
              this.$qzfMessage("授权成功");
              this.getList();
            } else {
              this.dialogFormVisible = false;
              this.getList();
            }
          });
        }
      });
    },
    //绑定登录信息
    updateBsr() {
      let arr = [];
      this.list.forEach((item) => {
        arr.push(item.id);
      });
      this.$refs.updateInfo.init([this.listQuery.comId], arr);
    },
    getLink(row) {
      getQrcode({ comId: this.listQuery.comId, tel: row.zzridno }).then(
        (res) => {
          this.codeImg =
            "data:image/png;base64," +
            res.data.data.qrcode.replace(/[\r\n]/g, "");
          this.codeLink = res.data.data.url;
          this.visible = true;
        }
      );
    },
    //取消绑定
    handleSelectionChange(e) {
      this.sels = e;
    },
    cancelOne(row) {
      this.$confirm("确认取消绑定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelCompanyLoginCom({ ids: [row.loginComId] }).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.successCount == 1) {
              this.$qzfMessage("解绑成功");
            } else {
              this.$qzfMessage("该公司只绑定一个登录方式，不可取消绑定", 1);
            }
            this.getList();
          }
        });
      });
    },
    cancelAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择要解绑的信息", 1);
        return;
      }
      this.$confirm("确认批量取消绑定吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        cancelCompanyLoginCom({
          ids: this.sels.map((v) => v.loginComId),
        }).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.successCount == this.sels.length) {
              this.$qzfMessage("解绑成功");
            } else {
              this.$qzfMessage(
                `解绑成功${res.data.data.successCount}个，解绑失败${res.data.data.errCount}个`
              );
            }
            this.getList();
          }
        });
      });
    },
    openLink(row, type) {
      if (type == 1) {
        this.currentImgViewName = this.comInfo.name;
        let canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        let img = new Image();
        img.src = this.codeImg;
        img.onload = () => {
          canvas.setAttribute("width", img.width);
          canvas.setAttribute("height", img.height + 52);
          //绘制图片
          context.drawImage(img, 0, 0, img.width, img.height);
          //绘制底部矩形
          context.fillStyle = "rgba(29,33,41,0.6)"; //fillstyle设置填充颜色
          context.fillRect(0, img.height, img.width, 52);
          //字体
          context.font = "12px Arial";
          context.fillStyle = "#fff"; //fil1Style设置填充颜色
          context.textAlign = "center";
          context.textBaseline = "middle";
          //字体文字，显示位置 图片上需要n个文字就写n个context.fillText(文字,上下,左右);
          context.fillText(
            this.currentImgViewName,
            img.width / 2,
            img.height + 26,
            img.width - 10
          );
          let type2 = this.codeImg.replace(/.+\./g, "");
          this.codeImg = canvas.toDataURL(`image/${type2}`, 1.0);
        };
        this.showImagePreview = true;
      } else {
        this.$copyComName(this.codeLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w120 {
  width: 120px;
}
.item_icon {
  cursor: pointer;
  .iconfont {
    font-size: 13px;
  }
}
.login-tax-bureau-opera-get-auth-link {
  li {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      margin-left: 5px;
    }
  }
  li:hover {
    color: #17a2b8;
  }
}
.foot {
  margin: 10px 0;
  & > div {
    float: right;
  }
}
</style>
