<template>
  <div class="home-progress">
    <p>{{ title }}&nbsp;&nbsp;{{ count }}</p>
    <div>
      <el-progress :percentage="percentage" :color="color" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  title: String,
  count: Number,
  total: Number,
  color: {
    type: String,
    default: "#5d9bfc",
  },
})
const percentage = computed(() => {
  if (props.total === 0) {
    return 0
  }
  let num = (props.count / props.total) * 100
  return parseFloat(num.toFixed(2))
})
</script>
<style scoped lang="scss">
.home-progress {
  color: #666;
  margin-bottom: 7px;
  font-size: 13px;
}
</style>
