<template>
  <div class="left-big" style="margin-left: 32px">
    <el-menu default-active="2" mode="horizontal" background-color="var(--themeColorHeaderBg, #304456)"
      text-color="#fff">
      <el-menu-item :class="{ cur: item.value == thisNav }" v-for="(item, index) in nav" :key="index"
        @click="goEtax(item.value, false)">
        <span class="iconfontStyle">
          <span class="spanName" v-if="item.value == '数据看板'">首页</span>
          <span class="spanName" v-if="item.value == '客户互动'">顾问中心</span>
          <span class="spanName" v-if="item.value == '账务中心'">代账中心</span>
          <span class="spanName" v-if="item.value == '质检中心'">质检评分</span>
        </span>
      </el-menu-item>
    </el-menu>
  </div>
  
</template>

<script>
import store from "@/store"
import autoHomeIndex from "../../autoHome/autoHomeIndex.vue";
export default {
  name: "menuTop",
  components: {
    autoHomeIndex
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    thisNav() {
      return store.getters["user/model"]
    },
    nav() {
      let nav2 = []
      if (this.$store.getters["user/user"].primaryRouting) {
        this.$store.getters["user/user"].primaryRouting.map((v) => {
          if (
            ["授权登录"].includes(v)
          )
            return;
          nav2.push({
            value: v,
          })
        })
      }
      return nav2
    },
  },
  methods: {
    goEtax(e, force) {
      // if (e == '数据看板') {
      //   this.drawer = true
      //   return
      // }
      let t = store.getters["user/model"]
      //为了防止重复调用 userInfo 做个判断
      if (store.getters["user/model"] == e && !force) {
        return
      }
      this.$store.dispatch("user/setModel", e)
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          let indexPath = res.indexPath
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then(() => {
              if (e != t) {
                this.$router.push({ path: indexPath })
                this.$store.dispatch("tagsView/delAllViews")
              }
            })
        })
        .catch(() => { })
    },
  },
}
</script>

<!-- 样式调整 -->
<style lang="scss" scoped>


.left-big {
  width: 95%;
  float: left;
  display: flex;

  span {
    font-size: 14px;
  }
}

.spanName {
  padding: 0 20px;
}

.left-big::-webkit-scrollbar {
  width: 4px;
}

.left-big::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}

.el-menu-item:not(.is-disabled):hover {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}

.el-menu-item:not(.is-disabled):focus {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}
</style>
<style lang="scss">
.el-sub-menu__title:hover {
  background-color: var(--themeColorLeftMenu) !important;
}

//子级的类名
.el-menu-item:hover {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
}
</style>
<style lang="scss" scoped>
.el-sub-menu__title {
  font-weight: 500;
  font-size: 16px;
}

.el-menu-item {
  height: 50px !important;
  width: 85px;
  font-size: 14px !important;
}
</style>
<style lang="scss" scoped>
:deep(.el-menu-item.is-active) {
  color: #fff !important;
  border-bottom: none;
}

.cur {
  background-color: var(--themeColorMenuBg, #384e63) !important;
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
}
</style>
