<template>
  <!-- <el-select @focus="selectFocus" :style="width != '0' ?{ width: width +'px' }: { width: '100%' }" :class="{boxShadowNone:borderNone}" :disabled="disabledIn" filterable v-model="id" :placeholder="placeholder" size="small" :clearable="addNotShow">
    <el-option v-if="!addNotShow" style="color:red" label="新增+" value="add"></el-option>
    <el-option
      v-for="item in subjectListOldList"
      :key="item.sign"
      :label="item.name3"
      :value="item.sign"
    >
    <span style="float: left">{{ item.name3 }}</span>
    <span style="float: right;color: red;font-size: 14px;">{{item.fzhsItemName}}</span>

    </el-option>
  </el-select> -->
  
  <el-select-v2
    :style="width != '0' ?{ width: width +'px' }: { width: '100%' }" :class="{boxShadowNone:borderNone}" :disabled="disabledIn" :clearable="addNotShow"
    v-model="id"
    filterable
    :options="subjectListOldList"
    :placeholder="placeholder"
    :size="size"
  >
    <template #default="{ item }">
      <span v-if="item?.name == '新增+'" style="color:red">{{ item?.name }}</span>
      <el-tooltip v-else :content="item?.name + item?.label2" placement="top-start" effect="dark">
        <div>
          <span>{{ item?.name }}</span>
          <span style="color: red;font-size: 13px;">{{item?.label2}}</span>
        </div>
      </el-tooltip>
    </template>
  </el-select-v2>
  <add-subject @success="addSuccess" ref="addSubject" :comId="comId" :spec="spec"></add-subject>
  <!-- <subject-list subjectName="测试科目" v-model:subjectId="listQuery.id" v-model:fzhsItemId="listQuery.id2" :codes="['1001','1002']"></subject-list> -->
</template>
<script>
import addSubject from './addSubject.vue'
export default {
  components: { addSubject },
  props:{
    width: {
      type: String,
      default: '200'
    },
    codes: {//默认下拉框的code
      type: Array,
      default: []
    },
    subjectId: {
      type: Number,
      default: 0
    },
    fzhsItemId: {
      type: Number,
      default: 0
    },
    suibjectSelectName: {
      type: String,
      default: "",
    },
    subjectName: {//新增科目的名字
      type: String,
      default: "",
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    addNotShow:{
      type:Boolean,
      default:false
    },
    disabledIn:{
      type:Boolean,
      default:false
    },
    placeholder: {//默认下拉框的code
      type: String,
      default: "选择会计科目"
    },
    index: {
      type: Number,
      default: 0
    },
    size:{
      type: String,
      default: "small",
    },
    disabledAdd:{
      type:Boolean,
      default:false
    },
    comId: {
      type: Number,
      default: 0
    },
    spec:{
      type: String,
      default: ''
    },
  },
  emits: ['update:subjectId', 'update:suibjectSelectName', 'update:subjectId', 'update:fzhsItemId', 'success'],
  computed: {
    subjectListOldList: {
      get(){
        let list = this.$subjectFilterCode(this.codes, 1)
        let ls = list.map((v)=> ({
          value:v.sign,
          label:v.fzhsItemName?v.name3 + '（'+v.fzhsItemName+'）' :v.name3,
          name:v.name3,
          label2:v.fzhsItemName,
          disabled:v.disabled,
        }))
        if(!this.disabledAdd && this.$buttonStatus('kjkm_xz')){
          ls.unshift({
            name: '新增+',//不填 新增存货
            value:'add-0'
          })
        }
        if(!ls){
          return []
        }
        return ls
      }
    },
    id: {
      get() {
        // 修改了不显示placeHolder得问题
        if(!this.subjectId){
          return undefined
        }
        return this.subjectId + "-" + this.fzhsItemId
      },
      set(val) {
        if(val == 'add-0'){
          let data = {
            id: undefined,
            name: this.subjectName,
            type: 0,
          }
          this.$refs.addSubject.init(data, this.codes)
          this.$emit("update:subjectId", 'add-0')
          let that = this
          setTimeout(() => {
            that.$emit("update:subjectId", val.split('-')[0] * 1)
          }, 1);
          return
        }
        const current = this.subjectListOldList.find(v=> val == v.value)
        if (current) {
          this.$emit("update:suibjectSelectName", current.label)
        }
        if(val){
          this.$emit("update:subjectId", val.split('-')[0] * 1)
          this.$emit("update:fzhsItemId", val.split('-')[1] * 1)
        }else{
          this.$emit("update:subjectId", 0)
          this.$emit("update:fzhsItemId", 0)
        }
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      addOptions:[
        {
          id: "add-0",
          name: '新增+',
        }
      ]
      // id: this.ids,
      // id: this.subjectId?this.subjectId + "-" + this.fzhsItemId:""
    }
  },
  methods: {
    addSuccess(e){
      this.id = e.sign
      // this.changeCode()
    },
    // changeCode(){
    //   this.$emit("update:subjectId", this.id.split('-')[0] * 1)
    //   this.$emit("update:fzhsItemId", this.id.split('-')[1] * 1)
    //   this.$emit("success", this.id)
    // }
  }
}
</script>

<style scoped>
.el-select{
  margin-left: 0px;
}
</style>

<style lang="scss" scoped>
.boxShadowNone {
  :deep(.el-select-v2__wrapper) {
    box-shadow: none;
    background: none;
    border: none;
  }
}
</style>
<style lang="scss">
.el-vl__window {
  width: 430px !important;
}
</style>