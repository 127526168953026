<template>
  <div class="left-big" style="margin-left: 32px">
    <el-menu
      default-active="2"
      mode="horizontal"
      background-color="var(--themeColorHeaderBg, #304456)"
      text-color="#fff"
    >
      <el-menu-item
        :class="{ cur: item.value == thisNav }"
        v-for="(item, index) in nav"
        :key="index"
        @click="goEtax(item.value, false)"
      >
        <span class="iconfontStyle">
          <span class="spanName" v-if="item.value == '平台'">平台管理</span>
          <span class="spanName" v-if="item.value == '信息管理'">信息管理</span>
          <span class="spanName" v-if="item.value == '客服'">客服管理</span>
          <span class="spanName" v-if="item.value == '客户维护'">客户维护</span>
          <span class="spanName" v-if="item.value == '财鲸云'">财鲸云</span>
          <span class="spanName" v-if="item.value == '客户管理'">客户管理</span>
          <span class="spanName" v-if="item.value == '票据采集'">票据采集</span>
          <span class="spanName" v-if="item.value == '财务核算'">财务核算</span>
          <span class="spanName" v-if="item.value == '批量申报'">批量申报</span>
          <span class="spanName" v-if="item.value == '年度申报'">年度申报</span>
          <span class="spanName" v-if="item.value == '统计风控'">统计风控</span>
          <span class="spanName" v-if="item.value == 'CRM'">CRM</span>
          <span class="spanName" v-if="item.value == '企微服'">企微服务</span>
        </span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "menuTop",
  data() {
    return {};
  },
  computed: {
    thisNav() {
      return store.getters["user/model"];
    },
    nav() {
      let nav2 = [];
      if (this.$store.getters["user/user"].primaryRouting) {
        this.$store.getters["user/user"].primaryRouting.map((v) => {
          if (
            ["管理设置", "授权登录", "订单管理", "自动记账", "通用"].includes(v)
          )
            return;
          nav2.push({
            value: v,
          });
        });
      }
      return nav2;
    },
  },
  methods: {
    goEtax(e, force) {
      let t = store.getters["user/model"];
      //为了防止重复调用 userInfo 做个判断
      if (store.getters["user/model"] == e && !force) {
        return;
      }
      this.$store.dispatch("user/setModel", e);
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          let indexPath = res.indexPath;
          this.$store.dispatch("router/SetAsyncRouter", res.roles).then(() => {
            if (e != t) {
              this.$router.push({ path: indexPath });
              this.$store.dispatch("tagsView/delAllViews");
            }
          });
        })
        .catch(() => {
          //有问题 返回登录页面 直接清空token 就可
          // removeToken()
          // location.reload()
        });
    },
  },
};
</script>

<!-- 样式调整 -->
<style lang="scss" scoped>
.left-big {
  width: 95%;
  float: left;
  display: flex;
  span {
    font-size: 14px;
  }
}
.spanName {
  padding: 0 20px;
}
.left-big::-webkit-scrollbar {
  width: 4px;
}
.left-big::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.el-menu-item:not(.is-disabled):hover {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}
.el-menu-item:not(.is-disabled):focus {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}
</style>
<style lang="scss">
.el-sub-menu__title:hover {
  background-color: var(--themeColorLeftMenu) !important;
}
//子级的类名
.el-menu-item:hover {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
}
</style>
<style lang="scss" scoped>
.el-sub-menu__title {
  font-weight: 500;
  font-size: 16px;
}
.el-menu-item {
  height: 50px !important;
  width: 85px;
  font-size: 14px !important;
}
</style>
<style lang="scss" scoped>
:deep(.el-menu-item.is-active) {
  color: #fff !important;
  border-bottom: none;
}
.cur {
  background-color: var(--themeColorMenuBg, #384e63) !important;
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
}
</style>
