<template>
  <!-- <el-select filterable v-model="id" placeholder="选择科目" size="small">
    <el-option style="color:red" label="新增+" value="add"></el-option>
    <el-option
      v-for="item in subjectListOldList"
      :key="item.sign"
      :label="item.name3"
      :value="item.sign"
    >
    <span style="float: left">{{ item.name3 }}</span>
    <span style="float: right;color: red;font-size: 14px;">{{item.fzhsItemName}}</span>

    </el-option>
  </el-select> -->

  <!-- <el-select :class="{border_none:borderNone}" size="small" clearable filterable v-model="id" placeholder="请选择" @focus="selectFocus">
    <el-option-group
      v-for="group in options"
      :key="group.label"
      :label="group.label">
      <el-option
        v-for="item in group.options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-option-group>
  </el-select> -->
  <el-select-v2
    size="small"
    :class="{border_none:borderNone}"
    v-model="id"
    filterable
    :options="options"
    placeholder ="请选择存货或服务"
    clearable
    :disabled="disabledIn"
  >
    <template #default="{ item }">
      <span v-if="item.label == '+新增存货'" style="color:red">{{ item.label }}</span>
      <span v-else>{{ item.label }}
      <span v-if="item.unit" style="color:var(--themeColor,#17a2b8)">({{item.unit}})</span>
      <span v-if="item.coding" style="color:var(--themeColor,#17a2b8)">({{item.coding}})</span>
      </span>
    </template>
  </el-select-v2>


  <add-inventory @success="addSuccess" ref="addInventory" @success2="closeDialog2" :comId="comId" :from="from"></add-inventory>
  <!-- <inventory-list inventoryName="xxx" v-model:inventoryId="a" v-model:inventoryType="b" :type="true"></inventory-list> -->
</template>
<script>
export default {
  props:{
    type: {//是否使用服务
      type: Boolean,
      default: false
    },
    inventoryId: {
      type: Number,
      default: 0
    },
    itemName: {//默认新增存货名称
      type: String,
      default: ""
    },
    inventoryName: {//默认新增存货名称
      type: String,
      default: ""
    },
    inventorySpec: {//默认新增存货型号
      type: String,
      default: ""
    },
    inventoryUnit: {//默认新增存货单位
      type: String,
      default: ""
    },
    inventoryType: {//存货还是服务  inventory service
      type: String,
      default: ""
    },
    borderNone:{
      type:Boolean,
      default:false
    },
    spec: {
      type: String,
      default: ""
    },
    unit: {
      type: String,
      default: ""
    },
    disabledIn:{
      type: Boolean,
      default: false
    },
    comId: {
      type: Number,
      default: 0
    },
    from:{
      type: String,
      default: ""
    }
  },
  emits: ['update:inventoryId', 'update:itemName', 'update:spec', 'update:unit', 'success', 'update:inventoryType'],
  computed: {
    options(){
      let inventorys = this.$store.getters["commons/inventorys"]
      console.log(inventorys);
      let options = []
      if(inventorys.services && this.type){
        options.push({
          label: "服务",
          options:inventorys.services.map((v,index) => ({
            value:v.id,
            label:v.name
          }))
        })
      }
      options.unshift({
        label: '新增存货',//不填 新增存货
        options:this.addOptions.map((v,index) => ({
            value:v.id,
            label:v.name
          }))
      })
      if(inventorys.inventoryYcl){
        options.push({
          label: "原材料",
          options:inventorys.inventoryYcl.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryKcsp){
        options.push({
          label: "库存商品",
          options:inventorys.inventoryKcsp.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }

      if(inventorys.inventoryBcp){
        options.push({
          label: "半成品",
          options:inventorys.inventoryBcp.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }

      if(inventorys.inventoryBzw){
        options.push({
          label: "包装物",
          options:inventorys.inventoryBzw.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryWtjgwz){
        options.push({
          label: "委托加工物资",
          options:inventorys.inventoryWtjgwz.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryZzcl){
        options.push({
          label: "周转材料",
          options:inventorys.inventoryZzcl.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryXhxswzc){
        options.push({
          label: "消耗性生物资产",
          options:inventorys.inventoryXhxswzc.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryDzyhp){
        options.push({
          label: "低值易耗品",
          options:inventorys.inventoryDzyhp.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryClcg){
        options.push({
          label: "材料采购",
          options:inventorys.inventoryClcg.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryScxswzc){
        options.push({
          label: "生产性生物资产",
          options:inventorys.inventoryScxswzc.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.inventoryGcwz){
        options.push({
          label: "工程物资",
          options:inventorys.inventoryGcwz.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      if(inventorys.services && !this.type){
        options.push({
          label: "服务",
          options:inventorys.services.map((v,index) => ({
            value:v.id,
            label:v.name,
            spec:v.spec,
            unit:v.unit,
            coding:v.coding
          }))
        })
      }
      return options 
    },
    id: {
      get() {
        if(!this.inventoryId || this.inventoryId == 'add'){
          return 
        }
        return this.inventoryId
      },
      set(val) {
        if(val == 'add'){
          let data = {
            id: undefined,
            name: this.inventoryName,
            spec: this.inventorySpec,
            unit: this.inventoryUnit,
            type: 2,
          }
          this.$refs.addInventory.init(data)
          this.$emit("update:inventoryId", 0)
          this.$emit("update:itemName", '')
          // let that = this
          // setTimeout(() => {
          //   that.$emit("update:inventoryId", 0)
          // }, 1);
          return
        }
        this.$emit("update:inventoryId", val)
        if(val <= 11){
          this.$emit("update:inventoryType", 'service')
        }else{
          this.$emit("update:inventoryType", 'inventory')
        }

        let info = {}
        this.options.map(v=>{
          v.options.map(z=>{
            if(z.value == val){
              info = z
            }
          })
        })
        this.$emit("update:itemName", info.label)
        this.$emit("update:spec", info.spec)
        this.$emit("update:unit", info.unit)
        this.$emit("success", val)
      }
      
    }
  },
  data () {
    return {
      addOptions:[
        {
          id: "add",
          name: '+新增存货',
        }
      ]
    }
  },
  methods: {
    addSuccess(e){
      this.id = e.id
      this.$emit("update:itemName", e.name2)
    },
  }
}
</script>

<style lang="scss">
.el-vl__window {
  width: 430px !important;
}
</style>