<template>
  <el-select @change="changeRate" filterable clearable v-model="clueStatus" placeholder="请选择" size="small" style="width:130px">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>
<script>
import { getFollowStageList,clueLevel } from "@/api/newCrm.js";

export default {
  props:{
    clueStatus: {
      type: String,
      default: '',
    },
    type:{
      type: String,
      default: ''
    },
    allType:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options:[],
    }
  },
  created(){
    if(this.type == 'follow'){
      getFollowStageList().then(res => {
        if (res.data.msg == 'success') {
          this.options = res.data.data.data
          if(this.allType){
            this.options.unshift({
              value: '',
              label: '全部'
            })
          }
        }
      })
    }else{
      clueLevel().then(res => {
        if (res.data.msg == 'success') {
          this.options = res.data.data.data
          if(this.allType){
            this.options.unshift({
              value: '',
              label: '全部'
            })
          }
        }
      })
    }
  },
  methods: {
    changeRate(){
      this.$emit("update:clueStatus", this.clueStatus)
      this.$emit("success", this.clueStatus)
    }
  }
}
</script>