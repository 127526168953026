<template>
  <div
    id="taxBar"
    style="width: 100%; min-height: 100px"
    :style="{ height: contentStyleObj }"
  ></div>
</template>

<script setup>
import { onMounted, getCurrentInstance, ref, nextTick } from "vue"
import * as echarts from "echarts"
const { proxy } = getCurrentInstance()
const contentStyleObj = ref({})
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(770)
})
const init = (info) => {
  nextTick(() => {
    initChart(info)
  })
}
const initChart = (info) => {
  var myChart = echarts.init(document.getElementById("taxBar"))
  myChart.setOption({
    tooltip: {},
    grid: {
      left: "20%",
      right: "4%",
      top: "7%",
      height: "76%",
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: ["未开始", "进行中", "已完成"],
      inverse: true,
    },
    series: [
      {
        type: "bar",
        barWidth: 13,
        data: [
          {
            value: info.countSkWks,
            itemStyle: {
              color: "#ffd023",
            },
          },
          {
            value: info.countSkJxz,
            itemStyle: {
              color: "#3fbcff",
            },
          },
          {
            value: info.countSkYwc,
            itemStyle: {
              color: "#0FC6C2",
            },
          },
        ],
      },
    ],
  })
  window.onresize = function () {
    myChart.resize()
  }
}
defineExpose({
  init,
})
</script>
