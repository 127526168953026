<template>
  <el-drawer v-model="drawer" title="操作日志" destroy-on-close size="60%">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px;"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入公司名称"
          v-model.trim="listQuery.name"
          style="width:180px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right:10px"
        >
          <el-icon><Search /></el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin:11px 15px 0px 15px;width:300px"
            label-width="80px"
            class="style_form"
          >
            <el-form-item label="选择人员：">
              <selectuser
                code="bookkeeping"
                v-model:userId="listQuery.userId"
                style="width:220px;margin: 0"
              ></selectuser>
            </el-form-item>
            <el-form-item label="账期：">
              <qzf-search-period
                v-model:period="listQuery.period"
                :clearable="true"
                style="width:220px"
              ></qzf-search-period>
            </el-form-item>
            <el-form-item label="操作时间：">
              <el-date-picker
                class="rangeDate"
                size="small"
                v-model="value1"
                style="width:220px !important"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div></div>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column prop="comName" label="公司名称" min-width="240">
        <template #default="scope">
          <div style="display: flex;align-items: center;">
            <div
              class="item_icon"
              style="width: 95%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
            >
              <span>{{ scope.row.comName }}</span>
            </div>
            <el-tooltip content="复制" placement="top" effect="dark">
              <i
                class="iconfont icon-fuzhi1"
                style="color:var(--themeColor,#17a2b8);cursor: pointer;"
                @click="$copyComName(scope.row.comName)"
              >
              </i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作人"
        width="120"
        prop="cnName"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column label="账期" width="100" prop="period">
      </el-table-column>
      <el-table-column label="操作类型" width="170" prop="method">
      </el-table-column>
      <el-table-column label="操作时间" width="160">
        <template #default="scope">
          <span>{{
            $parseTime(scope.row.createdAt, "{y}-{m}-{d} {h}:{i}:{s}")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </el-drawer>
</template>

<script>
import { operationLog } from "@/api/home";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      drawer: false,
      listQuery: {
        page: 1,
        limit: 20,
      },
      list: [],
      contentStyleObj: {},
      loading: false,
      value1: [],
      total: 0,
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(230);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList();
    },
    getList() {
      if (this.value1 && this.value1.length != 0) {
        this.listQuery.startTime = this.value1[0];
        this.listQuery.endTime = this.value1[1];
      } else {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      }
      this.loading = true;
      operationLog(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.list.map((v) => {
            let arr = v.content.split(";");
            v.comName = arr[0].substring(3);
            v.period = arr[1].substring(3);
            v.method = v.method.match(";") ? v.method.slice(0, -1) : v.method;
          });
        }
      });
    },
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
      };
      this.value1 = []
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.style_form :deep(.el-input__wrapper) {
  width: 220px;
}
</style>
