<template>
  <div class="left-big">
    <div class="menu_bg" v-if="!isCollapse"></div>
    <el-menu
      ref="menus"
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      :default-openeds="opens"
      @close="handleClose"
      :collapse="isCollapse"
    >
      <!-- 仅支持2级菜单 路由必须写2级的 -->
      <template v-for="(item, index) in routers">
        <!-- style="background: var(--themeColor,#17a2b8);" -->
        <el-sub-menu
          style="background: #fff !important;color: #fff !important;"
          v-if="item.children && item.children.length > 1 && !item.hidden"
          :index="index"
        >
          <template #title>
            <el-icon
              style="position:relative;bottom:2px;font-size: 17px;margin-right: 2px;"
            >
              <component :is="item.meta.icon" />
            </el-icon>
            <!-- <span>&nbsp;</span> -->
            <span style="color: #212529 !important;">{{
              item.meta.title
            }}</span>
          </template>

          <!-- <router-link v-for="(itemC,indexC) in item.children" class="router-link" :to="`${item.path}/${itemC.path}`"> -->
          <router-link
            v-for="(itemC, indexC) in item.children"
            class="router-link"
            :to="{ name: itemC.name }"
          >
            <el-menu-item v-if="!itemC.hidden" :index="itemC.name">
              <!-- <el-icon
                style="position:relative;bottom:1px;font-size: 17px;margin-left: 10px;"
              >
                <i
                  :class="'iconfont' + ' ' + itemC.meta.icon"
                  style="font-size: 17px;"
                ></i>
              </el-icon> -->
              <span style="margin-left: 25px;">{{ itemC.meta.title }}</span>
            </el-menu-item>
          </router-link>
        </el-sub-menu>

        <router-link
          v-else-if="item.children && item.children.length == 1 && !item.hidden"
          class="router-link"
          :to="{ name: item.children[0].name }"
        >
          <el-menu-item :index="index">
            <el-icon style="position:relative;bottom:2px;font-size: 16px">
              <component :is="item.children[0].meta.icon" />
            </el-icon>
            <span v-if="!isCollapse">{{ item.children[0].meta.title }}</span>
          </el-menu-item>
        </router-link>

        <router-link
          v-else-if="!item.hidden"
          class="router-link"
          :to="item.path"
        >
          <el-menu-item :index="index">
            <span>{{ item.meta.title }}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-menu>
    <el-button
      style="position: fixed;top:9px;left:137px"
      type="text"
      @click="changeRadio"
      v-if="!isCollapse"
    >
      <el-icon style="color: #fff;font-size: 20px;">
        <fold />
      </el-icon>
    </el-button>
    <el-button
      style="position: fixed;top:9px;left:60px"
      type="text"
      @click="changeRadio"
      v-else
    >
      <el-icon style="color: #fff;font-size: 20px;">
        <expand />
      </el-icon>
    </el-button>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "left",
  data() {
    return {
      // opens: ["/index", "/setting"], // 设置菜单栏展开, 默认展开子菜单
      opens: [0, 1, 2, 3, 4, 5], // 设置菜单栏展开, 默认展开子菜单
      // routers: store.getters["router/asyncRouters"],
      isCollapse: false,
      activeIndex: 1,
    };
  },
  computed: {
    routers() {
      // //console.log(store.getters["router/asyncRouters"]);
      let r = store.getters["router/asyncRouters"]
      let path = store.getters["user/indexPath"]
      if(store.getters["user/model"] != "正常" && store.getters["user/model"] != "历史"){
        r.map((v,i)=>{
          v.children.map((z,ii)=>{
            let path1 = v.path
            let path2 = z.path
            let len = v.children.length
            if(path.indexOf(path1) > -1 && path.indexOf(path2) > -1){
              if(len == 1){
                this.activeIndex = i;
              } else {
                this.activeIndex = z.name
              }
            }
          })
        })
      }
      return r;
    },
  },
  created() {
    this.routers.map((v) => {});
  },
  methods: {
    handleClose(key, keyPath) {
      if (!this.isCollapse) {
        this.$refs.menus.open(keyPath);
      }
    },
    changeRadio() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        this.$parent.$parent.changeWidths("50px", "53px");
      } else {
        this.$parent.$parent.changeWidths("130px", "133px");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.iconfont {
  font-size: 16px;
  margin-right: 10px;
}
.left-big {
  // overflow-y: auto;
  height: 100%;
  // background: #ebf1f7;
  background: #fff;
  span {
    font-size: 14px;
  }
}

/*滚动条宽度*/
.left-big::-webkit-scrollbar {
  width: 4px;
}
/*滚动条里面小方块*/
.left-big::-webkit-scrollbar-thumb {
  border-radius: 5px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(100, 100, 100, 0.2);
}
/*滚动条里面轨道*/
// .left-big::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   border-radius: 0;
//   background: rgba(0, 0, 0, 0.1);
// }
</style>
<style lang="scss">
.router-link {
  text-decoration: none;
}
.el-sub-menu__title:hover {
  background-color: var(--themeColorLeftMenu, #ecf7f9) !important;
}
</style>
<style lang="scss">
.el-sub-menu__title {
  // background-color: var(--themeColorLeftMenu) !important;
  // line-height: 42px !important;
  // height: 42px !important;
  line-height: 35px !important;
  height: 35px !important;
  font-weight: 500;
  font-size: 14px !important;
  padding-left: 15px !important;
}
// .left-big :deep(.el-menu-item) {
//   line-height: 30px !important;
//   height: 30px !important;
//   font-weight: 500;
//   font-size: 16px;
// }
</style>
<style lang="scss" scoped>
.el-sub-menu .el-menu-item {
  padding-left: 15px !important;
  //padding-top: 5px;
  min-width: 20px;
  line-height: 35px !important;
  height: 35px !important;
  font-weight: 500;
  font-size: 16px;
}
.el-menu-item {
  line-height: 35px !important;
  height: 35px !important;
  padding-left: 15px !important;
}
.el-sub-menu :deep(.el-sub-menu__icon-arrow) {
  display: none !important;
}
.el-menu-item.is-active {
  background-color: var(--themeColorLeftMenu, #ecf7f9) !important;
  color: #333 !important;
  //s border-left: 5px solid var(--themeColor, #17a2b8);
  padding-left: 15px !important;
  &::before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-left: 5px solid var(--themeColor, #17a2b8);
    width: 100px;
    height: 100%;
    box-sizing: border-box;
  }
}
.el-menu {
  border-right: 0 !important;
}
.menu_bg {
  width: 130px;
  height: 30px;
  // background-image: url("../../../assets/menuBg.svg");
  background-image: var(--menuBg);
  background-color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
}
.el-menu-item [class^="el-icon"] {
  margin-right: 0;
}
</style>
