<template>
  <!-- 导入其他银行 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="数据处理" v-model="dialogFormVisible" width="80%">
    <el-form ref="dataForm" :model="temp" label-position="right" label-width="100px">
      <div class="common_dialog_box clearfix">
        <div class="common_title">1.设置数据形式</div>
        <div class="item_one clearfix">
          <el-form-item label="期初数据形式:" class="item">
            <el-radio-group v-model="dataType" style="width:300px">
              <el-radio :label="1">借贷两列</el-radio>
              <el-radio :label="2">方向+余额</el-radio>
              <el-radio :label="3">+-余额</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        </div>
        <div class="common_dialog_box clearfix">
        <div class="common_title">表格示例<span> <i class="iconfont icon-sanjiaotanhao" style="color:#e6a23c;margin-right:4px"></i>温馨提示：余额非必导入列次！</span></div>
        <div class="item_one clearfix">
          <h6 v-if="dataType == 1"><span>提示：</span>交易日期-收入-支出-摘要-对方名称-余额</h6>
          <h6 v-if="dataType == 2"><span>提示：</span>交易日期-方向（收入/支出）-金额-摘要-对方名称-余额</h6>
          <h6 v-if="dataType == 3"><span>提示：</span>交易日期-+-金额-摘要-对方名称-余额</h6>
          <div class="table-big" style="width: 100%;" :style="{height:contentStyleObj}">
            <div class="table">
              <draggable 
                v-model="list" 
                group="people" 
                @start="drag=true" 
                @end="drag=false" 
                item-key="id">
                <template #item="{element,index}">
                  <div class="table-div">
                    <div class="top_icon">
                      <i @click="delItem(index)" class="iconfont icon-shanchu"></i>
                    </div>
                    <div v-for="(item2,index2) in element" :key="index2" class="table_icon">
                      <span v-if="item2 != 'del'">
                        {{item2}}
                      </span>
                      <span v-else>
                        <i @click="delItemRaw(index2)" class="iconfont icon-shanchu"></i>
                      </span>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>        
        </div>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
      <el-button type="primary" @click="updateData()" size="small" :loading="loading">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
    

<script>
import { saveBankDate } from "@/api/bank"
import draggable from 'vuedraggable'

import { time5toTime ,parseTime } from "@/utils"
import { autoBankImportCurrency } from '@/api/automatic'
export default {
    props: {
      bankName:'',
      bankId:0,
    },
    components: {
      draggable,
    },
    data () {
      return {
        list: [
          [1001,1002,1003,1004,1005,1006,1007,1008],
          [2,2,3,4,5,67,33,8],
          [3,2,3,4,5,67,33,8],
          [4,2,3,4,5,67,33,8],
          [5,2,3,4,5,67,33,8],
          [6,2,3,4,5,67,33,8],
          [7,2,3,4,5,67,33,8],
          [8,2,3,4,5,67,33,8],
          [9,2,3,4,5,67,33,8],
        ],
        dataType: 1,
        temp: {
          level: "4",
          len: ['4','2','2','2'],
          spe: "无"
        },
        level:[
          { value: '1',label: '1'},
          { value: '2',label: '2'},
          { value: '3',label: '3'},
          { value: '4',label: '4'},
          { value: '5',label: '5'},
          { value: '6',label: '6'}
        ], //科目层级
        code_type:[{ value: '无',label: '无'},{ value: '.', label: '.'},{ value: '-',label: '-'},{ value: '_',label: '_'}], //编码分隔符
        code_width:[{value: '1',label: '1'},{value: '2',label: '2'},{value: '3',label: '3'},{value: '4',label: '4'}], // 编码长度
        dialogFormVisible: false,
        loading:false,
        contentStyleObj:{}, //高度变化
        periodType:"",
        comId:0,
        period:""
      };
    },
    created(){
      this.contentStyleObj=this.$getHeight(530)
    },
    methods: {
      init(data,type,comId,period){
        this.periodType = type
        this.comId = comId ? comId:0
        this.period = period ? period:""
        let list = []
        data.map(v=>{
          for(let i=0;i<v.length;i++){
            if(!list[i]){
              list.push([])
            }
            list[i].push(v[i])
          }
        }) 
        // //console.log(list)
        let dd = this.$qzfCopy(list[0])
        for(let i=0;i<dd.length;i++){
          dd[i] = 'del'
        }
        list.unshift(dd)

        // list.push(dd)
        // //console.log("list",list)
        this.list = list
      },
      delItem(index){
        // if(index == this.list.length){
        //   this.$message.error('本列不可删除');
        //   return
        // }
        this.list.splice(index,1);
      },
      delItemRaw(index){
        this.list.map(v=>{
          v.splice(index,1);
        })
      },
      changeLevel(){
        let lens = []
        for(let i=0;i<this.temp.level*1;i++){
          lens.push("")
        }
        this.temp.len = lens
      },
      updateData(){
        if(this.list.length < 6 && (this.dataType != 3)){
            this.$message.error('列数异常请退出当前页面重新导入');
            return
        }
        if(this.list.length < 5 && (this.dataType == 3)){
            this.$message.error('列数异常请退出当前页面重新导入');
            return
        }
        let items = []
        let list = [];
        this.loading = true
        if(this.dataType == 1){
          for(let i=0;i<this.list[1].length;i++){
            if(true){
              let obj = {};
              let a = String(this.list[1][i]) //20181116 16:04:11
              a = a.split(".")[0]
              if(a.length  == 5){
                  obj.transDate = time5toTime(a);
              }else if(a.length == 14){
                let b = a.split(""); // ["2", "0", "1", "8", "1", "1", "1", "6", " ", "1", "6", ":", "0", "4", ":", "1", "1"]
                b.splice(4, 0, "-");
                b.splice(7, 0, "-");
                b.splice(10, 0," ")
                b.splice(13, 0,":")
                b.splice(16, 0,":")
                let c = b.join("");
                a = c
                obj.transDate = new Date(a);

              } else {
                  if(a.indexOf('-') > -1){
                  }else if(a.indexOf('/') > -1){
                    a = a.replace(/\//g, "-")
                  }else{
                    let b = a.split(""); // ["2", "0", "1", "8", "1", "1", "1", "6", " ", "1", "6", ":", "0", "4", ":", "1", "1"]
                    b.splice(4, 0, "-");
                    b.splice(7, 0, "-");
                    let c = b.join("");
                    a = c
                 }
                 obj.transDate = new Date(a);
              }
              let shouru = this.list[2][i]?String(this.list[2][i]).replace(`
`,"").moneyFilter():0
              let zhichu = this.list[3][i]?String(this.list[3][i]).replace(`
`,"").moneyFilter():0
              if (shouru) {//收入
                  obj.amount = shouru
                  obj.outAmount = shouru
              } else if (zhichu) {
                  obj.amount = zhichu
                  obj.outAmount = -zhichu
              }

              // 借贷方向
              obj.amount = Math.abs(Number(obj.amount));
              obj.summary = this.list[4][i]?String(this.list[4][i]):'';
              obj.bz = this.list[4][i]?String(this.list[4][i]):'';
              obj.type = obj.outAmount > 0 ? 1 : 2;
              obj.otherName = String(this.list[5][i]).replace(/\s*/g,"")
              //余额
              obj.bankAmount = this.list[6]&&this.list[6][i]?String(this.list[6][i]).replace(`
`,"").moneyFilter():0
              items.push(obj);
            }
          }
          items.map(v => {
            if(v.otherName == '对方户名' || v.otherName == '对方单位名称'){
              return
            }
            list.push({
              amount: Number(v.outAmount),
              bankNameId: Number(this.bankId),
              from:'import',
              bankName: this.bankName,
              type: 1,
              edit: true,
              show: true,
              items: [v],
              otherName: String(v.otherName).replace(/\s*/g,"")
            });
          });

        }else if(this.dataType == 2){
          //console.log(this.list);
            for(let i=0;i<this.list[1].length;i++){
              if(true){
                let obj = {};
                let a = String(this.list[1][i]); //20181116 16:04:11
                a = a.split(".")[0]

                if(a.length == 5){
                  obj.transDate = time5toTime(a);
                } else {
                  if(a.indexOf('-') > -1){

                    }else if(a.indexOf('/') > -1){
                      a = a.replace(/\//g, "-")
                    }else{
                      let b = a.split(""); // ["2", "0", "1", "8", "1", "1", "1", "6", " ", "1", "6", ":", "0", "4", ":", "1", "1"]
                      b.splice(4, 0, "-");
                      b.splice(7, 0, "-");
                      let c = b.join("");
                      a = c
                    }
                    obj.transDate = new Date(a);
                }
                let fangxiang = this.list[2][i]
                let jine = this.list[3][i]?String(this.list[3][i]).moneyFilter():0
                if (fangxiang.indexOf('收') > -1 || fangxiang.indexOf('入') > -1 || fangxiang.indexOf('贷') > -1) {
                  obj.amount = jine
                  obj.outAmount = jine
                } else {
                  obj.amount = jine
                  obj.outAmount = -jine
                  if(jine < 0){
                    fangxiang = "收入"
                    obj.amount = -jine
                    obj.outAmount = obj.amount
                  }
                }
                obj.amount = Number(obj.amount);
                obj.summary = this.list[4][i]?String(this.list[4][i]):'';
                obj.bz = this.list[4][i]?String(this.list[4][i]):'';
                if(fangxiang.indexOf('收') > -1 || fangxiang.indexOf('入') > -1 || fangxiang.indexOf('贷') > -1){
                  obj.type = 1
                }else{
                  obj.type = 2
                }
                obj.otherName = String(this.list[5][i]).replace(/\s*/g,"")
                //余额
                obj.bankAmount = this.list[6]&&this.list[6][i]?String(this.list[6][i]).moneyFilter():0
                items.push(obj);
              }
            }
            items.map(v => {
                list.push({
                  amount: Number(v.outAmount),
                  bankNameId: Number(this.bankId),
                  from:'import',
                  bankName: this.bankName,
                  type: 1,
                  edit: true,
                  show: true,
                  items: [v],
                  otherName: String(v.otherName).replace(/\s*/g,"")
                });
            });
        }else if(this.dataType == 3){
          for(let i=0;i<this.list[1].length;i++){
            if(true){
              let obj = {};
              let a = this.list[1][i]; //20181116 16:04:11
              if(a.toString().indexOf('-') > -1){

              }else if(a.toString().indexOf('/') > -1){
                  a = a.replace(/\//g, "-")
              }else{
                  let b = a.toString().split(""); // ["2", "0", "1", "8", "1", "1", "1", "6", " ", "1", "6", ":", "0", "4", ":", "1", "1"]
                  b.splice(4, 0, "-");
                  b.splice(7, 0, "-");
                  let c = b.join("");
                  a = c
              }
              obj.transDate = new Date(a);
              let jine = this.list[2][i]?String(this.list[2][i]).moneyFilter():0
               
              if (jine > 0){
                  obj.amount = Number(this.list[2][i].toString().replace(/,/g,"").replace("+","").replace("-",""));
                  obj.outAmount = Number(this.list[2][i].toString().replace(/,/g,"").replace("+","").replace("-",""));
                  obj.type = 1
              } else{
                  obj.amount = Number(this.list[2][i].toString().replace(/,/g,"").replace("+","").replace("-",""));
                  obj.outAmount = -Number(this.list[2][i].toString().replace(/,/g,"").replace("+","").replace("-",""));
                  obj.type = 2
              }

              obj.amount = Number(obj.amount);
              obj.summary = this.list[3][i]?String(this.list[3][i]):'';
              obj.bz = this.list[3][i]?String(this.list[3][i]):'';
              if(jine > 0){
                  obj.type = 1
              }else{
                  obj.type = 2
              }
              obj.otherName = String(this.list[4][i]).replace(/\s*/g,"")
              //余额
              obj.bankAmount = this.list[5]&&this.list[5][i]?String(this.list[5][i]).moneyFilter():0
              items.push(obj);
            }
          }
          items.map(v => {
            if(isNaN(v.amount)){
              return
            }
            list.push({
              amount: Number(v.outAmount),
              bankNameId: Number(this.bankId),
              from:'import',
              bankName: this.bankName,
              type: 1,
              edit: true,
              show: true,
              items: [v],
              otherName: String(v.otherName).replace(/\s*/g,"")
            });
          });
        }
        if(this.comId){
          let param = {
            period : this.period,
            bankNameId : this.bankId,
            comId:this.comId,
            list:list
          }
          autoBankImportCurrency(param).then(res=>{     //todo
            this.loading = false
            if(res.data.msg == "success"){
              this.dialogFormVisible = false
              this.$qzfMessage("导入成功")
              this.$emit('success')
            }
          })
        }else{
          let param = {
            list:list,
            periodType:this.periodType
          }
          saveBankDate(param).then(res=>{     //todo
            this.loading = false
            if(res.data.msg == "success"){
              this.dialogFormVisible = false
              this.$qzfMessage("导入成功")
              this.$emit('success')
            }
          })
        }
      },
    }
};
</script>
<style lang="scss" scoped>
.item_s {
    margin-bottom: 10px;
  }
  .table-big{
    // width: 664px;
    height: 320px;
    overflow: auto;
    border: 1px solid #eee;
    margin-top: 10px;
  .table{
      min-width: 3000px;
      height: 400px;
      
      // white-space:nowrap;
      .table-div{
        vertical-align: top;
        display: inline-block;
        width: 100px;
        border-right: 1px solid #eee;
        div{
          height: 30px;
          line-height: 30px;
          width: 100%;
          // display: inline-block;
          display: flex;
          white-space: nowrap;
          overflow: hidden;
          font-size: 14px;
          color: #333;
          padding:0 5px;
        }
        .iconfont icon-shanchu{
          cursor: pointer;
        }
        &:first-child .top_icon i{
          display: none;
        }
        &:first-child{
          width: 26px !important;
        }
      }
      .top_icon{
        width: 100%;
        border-bottom: 1px solid #eee;
        i{
            font-size: 20px;
            color: #F56C6C;
            cursor: pointer;
            // margin-top: 7px;
        }

      }
      .table_icon{
        border-bottom: 1px solid #eee;
        i{
            font-size: 20px;
            color: #F56C6C;
            cursor: pointer;
            // margin-top: 7px;
        }
        
      }
    }
    
  }
  
</style>


