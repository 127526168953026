import { loginByUsername, logout, getUserInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { getUrlParams } from '@/utils/fun'
import store from "../index";
import eventBus from '@/utils/bus.js'/// mitt 总线程引入
var timer
export const user = {
  namespaced: true,
  state: {
    indexPath: "/",
    model: "财务核算",
    token: '',
    users: [],
    roleName: "",
    user: {
      "cnName": "",
      "orgName": "",
      "tel": "",
      "userId": 0,
      "primaryRouting": [],
      "disCode": "",
      "isAdmin": 0,
      "isLeader": 0,
      "orgId": 0,
      "sysButton": '',
    },
    comInfo: {
      "taxNo": '',//纳税人识别号
      "comId": 0,
      "comName": "",//公司名称
      "period": "",
      "startPeriod": "",
      "lastStartPeriod": "",
      "codes": {},
      "comType": "",//小规模 一般纳税人
      "comKj": "",//会计准则
      "qysds": 1,//2是B类企业
      "t": '',
      "jzsy": 0,//1已经结转损益
      "isOld": 0,//是不是历史账期
    },
    roles: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_COMINFO: (state, comInfo) => {
      state.comInfo = comInfo
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_USERS: (state, users) => {
      state.users = users
    },
    SET_MODEL: (state, model) => {
      state.model = model
    },
    SET_INDEX: (state, model) => {
      state.indexPath = model
    },
    SET_ROLENAME: (state, model) => {
      state.roleName = model
    },
    SET_ROLES: (state, model) => {
      state.roles = model
    },
  },
  actions: {
    setModel({ commit }, model) {
      commit('SET_MODEL', model)
    },
    setComInfo({ commit }, comInfo) {
      commit('SET_COMINFO', comInfo)
    },
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setUsers({ commit }, users) {
      commit('SET_USERS', users)
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token)
    },
    setRoleName({ commit }, name) {
      commit('SET_ROLENAME', name)
    },
    setRoles({ commit }, name) {
      commit('SET_ROLES', name)
    },
    // 用户名登录
    LoginByUsername({ commit }, userInfo) {
      window.name = ""
      const username = userInfo.username.trim()
      const password = userInfo.password
      const captchaId = userInfo.captchaId
      const captchaValue = userInfo.captchaValue
      const captchaType = userInfo.captchaType
      const pointJson = userInfo.pointJson
      const token = userInfo.token
      return new Promise((resolve, reject) => {
        loginByUsername(username, password, captchaId, captchaValue, captchaType, pointJson, token).then(response => {
          const isDev = window.location.hostname == 'localhost' || window.location.hostname.indexOf('-dev') > -1;
          let token = '';
          if (isDev) {
            token = response.headers['token'] // header里的属性都会转成小写才能获取
          } else {
            token = response.headers['token']//Cookies.get('Token') // 后端需设置httpOnly为false,否则https模式获取不到cookies
          }
          if (token) {
            commit('SET_TOKEN', token)
            setToken(token)
            //开启定时器
            // timer = window.setInterval(function(){
            //   eventBus.emit('refreshTask')
            // },20000)
            //清除
            // window.clearInterval(timer2)
            resolve()
          } else {
            reject(response.data.msg)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (timer) {
          window.clearInterval(timer)
        }
        timer = window.setInterval(function () {
          eventBus.emit('refreshTask')
        }, 20000)


        let url = window.location.href
        let param = {
          roleName: 'etax',
          comId: 0,
        }
        //刷新页面的window.name
        let wName = window.name
        if (getUrlParams(url, 'roleName') || wName) {
          if (getUrlParams(url, 'roleName')) {
            param.roleName = getUrlParams(url, 'roleName')
          } else {
            param.roleName = wName.split("##")[1]
          }
        }
        if (getUrlParams(url, 'comId') || wName) {
          if (getUrlParams(url, 'comId')) {
            param.comId = getUrlParams(url, 'comId') * 1
          } else {
            param.comId = wName.split("##")[0] * 1
          }
          param.ti = String(new Date().getTime())
          if (param.comId) {
            if (state.model == "历史") {
              commit('SET_MODEL', "历史")//为了历史数据
            } else {
              commit('SET_MODEL', "正常")//为了历史数据
            }
          }
        }
        if (param.roleName == 'automaticBook') {
          param.ti = String(new Date().getTime())
          param.comId = 0
          param.roleName = "automaticBook"
          state.model == ""
        } else if ((state.model == "正常" || state.model == "历史") && param.comId == 0) {
          param.ti = String(new Date().getTime())
          param.comId = state.comInfo.comId * 1
          param.period = state.comInfo.period
          // param.ti = state.comInfo.t
          param.roleName = "accountBook"
        }
        param.model = state.model
        if (state.model == '财务核算' && param.roleName == "automaticBook") {
          param.model = '账务中心'
        }
        //state.token
        param.period = state.comInfo.period
        getUserInfo(param).then(response => {
          store.dispatch('inits/InitDis')
          if (response.data.msg == '无使用权限,请登录主账号分配权限') {
            // 调用userInfo清除缓存
            window.localStorage.clear();
            location.reload();
          }
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            reject('error')
          }
          const data = response.data.data
          if (!data.user.taxApp) {
            data.roles = data.roles.filter(item => item != 'taxApplet' && item != 'contributedCapital')
          }
          if (data.user.userId !== 100014 && data.user.orgId !== 3576) {
            data.roles = data.roles.filter(item => item != 'attachmentUpload')
          }
          if (data.user.userId == 120094) {
            data.roles = data.roles.filter(item => item != 'group1')
          }
          if (data.user.orgId == 3576) {
            data.roles = data.roles.filter(item => item != 'billStatistics')
          }
          let comInfo = data.comInfo ? data.comInfo : {}
          let user = data.user ? data.user : {}
          let users = data.users ? data.users : []
          commit('SET_COMINFO', comInfo)
          commit('SET_USER', user)
          commit('SET_USERS', users)
          commit('SET_MODEL', data.model)
          commit('SET_INDEX', data.indexPath)
          commit('SET_ROLENAME', data.roleName)
          commit('SET_ROLES', data.roles)
          //调用初始化账期
          if (param.comId) {
            document.title = comInfo.comName
            store.dispatch('commons/InitPeriod', param.comId)
            store.dispatch('commons/getSubjects', param.comId)
            store.dispatch('commons/getInventory', param.comId)
            store.dispatch('commons/getBusinessType', param.comId)
            store.dispatch('commons/getCorp', param.comId)
            store.dispatch('commons/getTaxCategoryList', param.comId)
          }
          if (data.roleName == 'automaticBook' && window.location.hostname == "daizhang.jsptax.com") {
            document.title = "鲸算盘AI财务工厂"
          }
          //必须要初始化的数据


          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {
    token(state) {
      return state.token
    },
    comInfo(state) {
      return state.comInfo
    },
    user(state) {
      return state.user
    },
    users(state) {
      return state.users
    },
    model(state) {
      return state.model
    },
    indexPath(state) {
      return state.indexPath
    },
    roleName(state) {
      return state.roleName
    },
    roles(state) {
      return state.roles
    },
  }
}
