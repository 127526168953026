<template>
  <div>
    <!-- v-model:currentPage="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="limit"
      layout="layout"
      :total="total" -->
    <el-pagination
      v-model:currentPage="currentPage"
      :page-sizes="pageSizes"
      :page-size="limit"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      small
    >
    </el-pagination>
  </div>
</template>

<!--
使用方法
<div class="pagination">
  <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
</div>
 -->
<script>
import { defineComponent, ref } from 'vue';
export default {
    name:'Pagination',
    props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default:[10, 20, 50, 100, 200 ,300,500]
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    },
    type:{
      type: String,
      default: ''
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if(this.type){
        localStorage.setItem(this.type,val)
      }
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange() {
      this.$emit('pagination', { page: this.currentPage, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  background: #fff;
  /* padding: 32px 16px; */
}
.pagination-container.hidden {
  display: none;
}
</style>
