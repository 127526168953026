<template>
  <el-select :disabled="status" @change="changeValue" v-model="type" placeholder="选择地区" size="small" clearable filterable style="width: 100px;">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.name"
      :value="item.code"  size="small" >
    </el-option>
  </el-select>
</template>

<script>
import { getAddress } from "@/api/company"
export default {
  name: 'select',
  props: {
    status: false,
    type: ''
  },
  data() {
    return {
    }
  },
  computed: {
    options() {
      return this.$store.getters['inits/dis']
    }
  },
  mounted() {
  },
  methods: {
    changeValue() {
      this.$emit('update:type', this.type)
      this.$emit("success",this.type)
    },   
  }
}
</script>

<style scoped>

</style>
<!-- 参数type  回调方法select -->