<template>
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogVisible"
    :title="type ? '打印设置' : '导出设置'"
    width="40%"
  >
    <el-form size="small" label-width="140px">
      <el-form-item label="所属期:">
        <div>
          <el-date-picker
            v-model="temp.startPeriod"
            type="month"
            placeholder="选择月"
            value-format="YYYYMM"
          >
          </el-date-picker>
          --
          <el-date-picker
            v-model="temp.endPeriod"
            type="month"
            placeholder="选择月"
            value-format="YYYYMM"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <div
        v-if="
          [
            'book_subject_balance',
            'book_all_account',
            'book_detail_account',
          ].includes(name)
        "
      >
        <el-form-item label="金额是否带千分符:">
          <el-switch v-model="temp.thousandSep" />
        </el-form-item>
        <el-form-item label="金额是否右对齐:">
          <el-switch v-model="temp.contentRight" />
        </el-form-item>
      </div>

      <el-form-item label="选择级别:" v-if="name == 'book_subject_balance'">
        <el-input placeholder="1" style="width: 90px" :disabled="true" />
        <span>-</span>
        <el-input
          placeholder="请输入级别"
          v-model.number="temp.level"
          style="width: 90px"
        />
      </el-form-item>
      <div v-if="name == 'swbb'">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <el-checkbox-group v-model="temp.bookNames" @change="handleOneChange">
          <el-checkbox
            v-for="item in swbbOptions"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="daochu"
          :loading="daochuLoading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { printTaxStatement } from "@/api/printSet";
import { bulkExportMq } from "@/api/export";
export default {
  name: "export",
  props: {
    name: {
      type: String,
      default: "",
    },
    period: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      type: "",
      dialogVisible: false,
      daochuLoading: false,
      temp: {
        comIds: [],
        bookNames: ["vat"],
        level: 3,
        thousandSep: false,
        contentRight: false,
      },
      //税务报表选项
      isIndeterminate: true,
      checkAll: false,
      swbbOptions: [
        {
          label: "增值税",
          value: "vat",
        },
        {
          label: "企业所得税",
          value: "gs_tax_quarter",
        },
        {
          label: "其他收入(工会经费)",
          value: "gs_qtsr",
        },
        {
          label: "财产与行为税",
          value: "gs_deed",
        },
        {
          label: "文化事业建设税",
          value: "gs_whsyjsfsbbygz",
        },
        {
          label: "残疾人保障金",
          value: "gs_cbj",
        },
        {
          label: "财务报表",
          value: "gs_cwbb",
        },
        {
          label: "消费税",
          value: "gs_xfs",
        },
        {
          label: "城市生活垃圾处置费",
          value: "gs_ljcl",
        },
        {
          label: "水利建设专项收入",
          value: "gs_sl",
        },
        {
          label: "个人所得税（定期定额）",
          value: "gs_tax_dqde",
        },
      ],
    };
  },
  methods: {
    init(ids, type) {
      this.resetTemp();
      this.temp.comIds = ids;
      this.temp.startPeriod = this.period;
      this.temp.endPeriod = this.period;
      this.type = type ? type : "";
      this.dialogVisible = true;
    },
    daochu() {
      this.daochuLoading = true;
      this.temp.mqType = this.name;
      if (this.temp.mqType == "salary") {
        this.temp.Type = "salary";
      }
      if (this.name == "swbb" && this.type == "print") {
        this.temp.zip = 1;
        this.temp.query = {
          beginTime: this.temp.startPeriod,
          endTime: this.temp.endPeriod,
        };
        printTaxStatement(this.temp).then((res) => {
          this.daochuLoading = false;
          this.dialogVisible = false;
          if (res.data.msg == "success") {
            if (res.data.data.msg) {
              this.$qzfMessage(res.data.data.msg);
            } else {
              window.open(res.data.data.path);
            }
          }
        });
      } else {
        bulkExportMq(this.temp).then((res) => {
          this.daochuLoading = false;
          this.dialogVisible = false;
          if (res.data.msg == "success") {
            if (res.data.data.msg) {
              this.$qzfMessage(res.data.data.msg);
            } else {
              window.open(res.data.data.path);
            }
          }
        });
      }
    },
    handleCheckAllChange(val) {
      this.temp.bookNames = val
        ? this.swbbOptions.map((item) => {
            return item.value;
          })
        : [];
      this.isIndeterminate = false;
    },
    handleOneChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.swbbOptions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.swbbOptions.length;
    },
    resetTemp() {
      this.temp = {
        comIds: [],
        bookNames: ["vat"],
        level: 3,
        thousandSep: true,
        contentRight: true,
      };
    },
  },
};
</script>

<style></style>
