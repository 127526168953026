// import  asyncRouterMap from '@/router/routerDt'
import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import { getToken, removeToken, setToken } from '@/utils/auth' // getToken from cookie
import { publicComColourList } from '@/api/public'
let asyncRouterFlag = 0
const whiteList = ['/login']// no redirect whitelist
import { getUrlParams } from '@/utils/fun'
//动态添加路由
const getRouter = async (e) => {
  let asyncRouterMap = []
  await store.dispatch('router/SetAsyncRouter', e)
  const asyncRouters = store.getters['router/routerList']
  asyncRouters.map(asyncRouter => {
    router.addRoute(asyncRouter.name, asyncRouter)
  })
}
router.beforeEach((to, from, next) => {
  if (window.location.href.indexOf("?token=") > -1) {
    let token = getUrlParams(window.location.href, "token").replace("#/", "")
    store.dispatch("user/setToken", token);
    setToken(token)
    let [url, test] = window.location.href.split("?")
    test = test.replaceAll("token=" + token, "")
    test = test[0] == '&' ? test.substring(1) : test
    window.location.href = (test == '#/' || !test) ? url : url + '?' + test
    // let s = `?token=`+token
    // window.location.href=window.location.href.replaceAll(s, "")
    // window.location.href=window.location.href.split("?")[0]
  }
  // 换颜色的
  if (!asyncRouterFlag) {
    publicComColourList({
      // domainName:"https://tax.jsptax.net/"
      // domainName:"https://ai.shuipiaoyun.cn"
      // domainName:"123.com"
    }).then(res => {
      if (res.data.data.id) {
        window.localStorage.setItem('ico', res.data.data.ico)
        window.localStorage.setItem('logo', res.data.data.logo)
        window.localStorage.setItem('loginImg', res.data.data.loginImg)
        window.localStorage.setItem('title', res.data.data.title)
        window.localStorage.setItem('smsAppUrl', res.data.data.smsAppUrl)
        window.localStorage.setItem('smsDocUrl', res.data.data.smsDocUrl)
        if (res.data.data.title == "") {
          window.localStorage.setItem('title', "鲸算盘智能财税代账云平台")
        }
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.rel = 'icon';
        link.href = '';
        link.href = window.localStorage.getItem('ico') ? window.localStorage.getItem('ico') : 'favicon1.ico'
        var t = document.createElement('title');
        t.id = 'jsp_title'
        t.text = '';
        t.text = window.localStorage.getItem('title') ? window.localStorage.getItem('title') : '鲸算盘智能财税代账云平台'
        var node = document.getElementById("jsp_title");
        if (node) {
          node.parentNode.removeChild(node);
        }
        document.getElementsByTagName('head')[0].appendChild(t);
        document.getElementsByTagName('head')[0].appendChild(link);
        document.getElementById("app").style.setProperty('--bigLogo', "url('" + res.data.data.unfoldLogo + "')")
        document.getElementById("app").style.setProperty('--smallLogo', "url('" + res.data.data.foldLogo + "')")
        document.getElementById("app").style.setProperty('--menuBg', "url('" + res.data.data.menuBg + "')")
        document.documentElement.style.setProperty('--themeColor', res.data.data.colour1)
        document.documentElement.style.setProperty('--themeColorLeftMenu', res.data.data.colour2)
        document.documentElement.style.setProperty('--themeColorPlain', res.data.data.colour3)
        document.documentElement.style.setProperty('--themeColorPlainBc', res.data.data.colour4)
        if (['#2764E4','#C7000B','#304354','#282864'].includes(res.data.data.colour1)) {
          document.documentElement.style.setProperty('--themeColorHeaderBg', res.data.data.colour1)
          document.documentElement.style.setProperty('--themeColorMenuBg', res.data.data.colour1)
          document.documentElement.style.setProperty('--themeColorMenuBottom', '#FFF')
          document.documentElement.style.setProperty('--themeColorBackground', '#E6EBF1')
          document.documentElement.style.setProperty('--themeColorStepBack', '#EDF5FF')
        }
      } else {
        window.localStorage.setItem('title', "鲸算盘智能财税代账云平台")
        var t = document.createElement('title');
        t.id = 'jsp_title'
        t.text = '';
        t.text = window.localStorage.getItem('title') ? window.localStorage.getItem('title') : '鲸算盘智能财税代账云平台'
        var node = document.getElementById("jsp_title");
        if (node) {
          node.parentNode.removeChild(node);
        }
        document.getElementsByTagName('head')[0].appendChild(t);
      }
      gogogo(to, from, next)
    })
  } else {
    gogogo(to, from, next)
  }

});
function gogogo(to, from, next) {
  //记录下from 的页面
  NProgress.start() // start progress bar
  //alert(getToken())
  if (getToken()) {
    // determine if there has token
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      // 添加flag防止多次获取动态路由和栈溢出
      if (!asyncRouterFlag) {
        asyncRouterFlag++
        store.dispatch('user/GetUserInfo').then(res => {
          //权限
          // getRouter(res.user.isAdmin)
          getRouter(res.roles)
          //该干嘛干嘛正常
          // next({ ...to, replace: true })//跳转当前路由也
          if (res.roleName == "accountBook") {
            next({ path: '/etaxHome' })//跳转根目录
          } else if (res.roleName == "etax") {
            if (res.user.userType == 9) {
              next({ path: "/user/group2" });
            } else {
              next({ path: res.indexPath }); //跳转根目录
            }
          } else if (res.roleName == 'automaticBook') {
            // next({ path: '/autoHome' })//跳转根目录
            next({ path: res.indexPath })//跳转根目录
          }
        }).catch(err => {
          //有问题 返回登录页面 直接清空token 就可
          // removeToken()
          // location.reload()
        })

      } else {
        //这个地方还得判断有没有此页面 没有 搞个404
        if (to.matched.length === 0) {
          next({ path: '/404' })
        }
        
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      //这个地方还得判断有没有此页面 没有 搞个404
      next()
    } else {
      next(`/login`) // 否则全部重定向到登录页
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
}

router.afterEach(() => {
  NProgress.done() // finish progress bar
})