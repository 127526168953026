<template>
  <div class="header-big ">
    <div class="left_header" :style="{ width: comId ? '67%' : '53%' }">
      <menuTop v-if="!comId && isAuto != '自动记账'" />
      <div class="has_com" v-if="comId">
        <!--   <span
          style="width:1px;height:37px;background-color:#efefef;margin-left: 10px;"
        ></span> -->
        <span class="aaaaa">
          <section v-if="orgId != 1" style="display: flex;align-items: center;">
            <el-select @change="changeComId" v-model="comId" filterable size="small" placeholder="公司名"
              :filter-method="filterCom" style="width:305px">
              <el-option v-for="item in companys" :key="item.id" :label="item.name" :value="item.id">
                <div style="display: flex;align-items: center;justify-content:space-between">
                  <div>
                    <span v-if="item.type == '1'" class="xgmStyle" style="font-size: 11px;">小</span>
                    <span v-if="item.type == '2'" class="ybStyle" style="font-size: 11px;">般</span>{{ item.name }}
                  </div>
                  <span class="gtStyle" style="font-size: 10px" v-if="item.automatic == 1">工厂</span>
                </div>
              </el-option>
            </el-select>
            <el-tooltip content="复制公司名称" placement="top" effect="dark">
              <el-icon @click="$copyComName(comName)" style="cursor: pointer;font-size: 19px;color: #fff;">
                <DocumentCopy />
              </el-icon>
            </el-tooltip>
          </section>
          <section v-if="orgId == 1">
            {{ comName }}
            <el-tooltip effect="dark" content="复制公司名称" placement="top">
              <el-icon @click="$copyComName(comName)" style="cursor: pointer;font-size: 19px;color: #fff;">
                <DocumentCopy />
              </el-icon>
            </el-tooltip>
          </section>
        </span>
        <el-tooltip effect="dark" content="档案" placement="top">
          <el-icon style="font-size: 19px;padding:0px;cursor: pointer;color: #fff;" @click="checkArchives()">
            <Reading />
          </el-icon>
        </el-tooltip>
        <span class="dateStyle">
          <el-icon style="position:absolute;left:8px;z-index:1;color: #666565;">
            <Calendar />
          </el-icon>
          <qzf-period @success="updatePeriods" v-model:period="period" @changeTitle="changeTitle"
            style="margin: 0;"></qzf-period>
        </span>
        <el-button v-if="this.settleWord == '未结账'" size="small" style="margin-left: 5px;" type="warning"
          class="iconfont icon-gantanhao iconfont1">
          <span style="color:#fff">{{ settleWord }}</span>
        </el-button>
        <el-button v-if="this.settleWord == '已结账'" size="small" style="margin-left: 5px;" type="primary"
          class="iconfont icon-duihao iconfont1">
          <span style="color:#fff">{{ settleWord }}</span>
        </el-button>
        <el-button size="small"
          style="margin-left: 5px;background-color: var(--themeColorHeaderBg,#304456);border-color: #fff;"
          type="primary" icon="Plus" @click="smallNotes" v-if="$buttonStatus('jzkh_ssj')">
          随手记
        </el-button>
        <span style="width:45px;margin-left:10px;cursor:pointer;" v-if="orgId == 3576" @click="dggTbData">
          <i class="iconfont icon-shoudongdenglu"></i>
          <span>同步</span>
        </span>
        <span style="width:75px;margin-left:10px;cursor:pointer;" @click="ticketOpen" v-if="$buttonStatus('sy_dpzt')">
          <i class="iconfont icon-bianji"></i>
          <span>{{ filterStatus(this.piaoStatus) }}</span>
        </span>

      </div>
      <div v-if="isAuto == '自动记账' " class="has_com" style="margin-left: 35px;">Ai财务工厂</div>
    </div>

    <div class="right_header">
      <img v-if="!comId && isAuto != '自动记账' && autoShow && groupId != 3278 && (groupId != 2921 || orgId == 4445)" style="display: block;width: 117px;cursor: pointer;"
        @click="goAuto" src="../../../assets/ai_cwgc.png" alt="" />
      <div class="header-message" style="width: 105px;" @click="goGw" v-if="userId == 101579">
        <el-icon>
          <Plus />
        </el-icon>
        <span>官网行业咨询</span>
      </div>
      <div class="header-message" style="width: 75px;" @click="goLoginTax"
        v-if="!comId && loginTaxBureauShow && isAuto != '自动记账'">
        <el-icon>
          <Message />
        </el-icon>
        <span>授权登录</span>
      </div>
      <div class="header-message" @click="getDownloadList" style="width: 85px;"
        v-if="this.userType != 9 && isAuto != '自动记账'">
        <i class="iconfont icon-a-download" style="font-size:14px;margin-right: 5px;"></i>
        <span>打印任务</span>
      </div>
      <div @click="messageShow(1)" v-if="this.userType != 9">
        <el-badge :value="messageTotal" :hidden="!messageTotal" class="header-message"
          style="width: 80px;margin-left: 0;" :max="99">
          <el-icon>
            <Bell />
          </el-icon>
          <span>信息公告</span>
        </el-badge>
      </div>
      <div class="header-message" style="width: 75px;" @click="goHabbit"
        v-if="!comId && userType != 9 && isAuto != '自动记账' && $buttonStatus('ty_tysz')">
        <el-icon>
          <Setting />
        </el-icon>
        <span>通用设置</span>
      </div>
      <div>
        <el-dropdown trigger="click" ref="operationDropdownMenuItem" :hide-on-click="false">
          <span class="header-message">
            <img src="@/assets/tu/zhu.png" v-if="this.isAdmin == 1 || this.userType == 9" alt=""
              style="width: 20px;height: 20px;margin-right: 5px;" />
            <img src="@/assets/tu/guan.png" v-else-if="this.isLeader == 1" alt=""
              style="width: 20px;height: 20px;margin-right: 5px;" />
            <img src="@/assets/tu/zi.png" v-else alt="" style="width: 20px;height: 20px;margin-right: 5px;" />

            <span>{{ userName }}</span>
            <el-icon>
              <CaretBottom />
            </el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="settingShow && this.userType != 9 && !comId && isAuto != '自动记账'">
                <span @click="guanlishezhi()" style="display: flex;align-items: center;">
                  <i class="iconfont icon-zhuzhanghao"></i>用户管理
                </span>
              </el-dropdown-item>
              <el-dropdown-item v-if="indentShow">
                <span style="display: flex;align-items: center;" @click="goOrder()">
                  <i class="iconfont icon-piaojucaiji1"></i>订单管理
                </span>
              </el-dropdown-item>
              <el-dropdown-item>
                <span style="display: flex;align-items: center;" @click="checkOperation()">
                  <i class="iconfont icon-caozuoshouce"></i>操作日志
                </span>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-popover placement="right-start" :width="165" trigger="hover" v-model:visible="loadVisible" >
                  <template #reference>
                    <span style="display: flex;align-items: center;" @click="loadVisible = true">
                      <i class="iconfont icon-xiazai"></i>工具下载 >
                    </span>
                  </template>
                  <ul>
                    <li v-for="(item,index) in gongjuOptions" :key="index" class="gongjuStyle">
                      <span @click="loadUrl(item.value)" v-if="groupId != 2921 || item.label != '税局小助手新版'">
                        <img :src="item.img"/>{{ item.label }}
                      </span>
                    </li>
                  </ul>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item v-if="users.length != 0">
                <el-popover placement="right-start" trigger="click" :width="120" :style="{ width: '120px !importent' }">
                  <template #reference>
                    <span style="display: flex;align-items: center;" @click="operationDropdownShow()">
                      <i class="iconfont icon-yonghuguanli1"></i>切换身份 >
                    </span>
                  </template>
                  <div @click="operationDropdownShow()">
                    <el-input size="small" placeholder="请输入姓名" @input="changeName" v-model="filterName"
                      clearable></el-input>
                    <el-scrollbar :maxHeight="contentStyleObj">
                      <ul v-for="(item, index) in userOption" :key="index">
                        <li @click="goUser(item.id)" style="padding: 2px 0;cursor: pointer;">
                          <img src="@/assets/tu/zhu.png" v-if="item.isAdmin == 1" alt=""
                            style="width: 18px;height: 18px;position: relative;top:4px" />
                          <img src="@/assets/tu/guan.png" v-else-if="item.isLeader == 1" alt=""
                            style="width: 18px;height: 18px;position: relative;top:4px" />
                          <img src="@/assets/tu/zi.png" v-else alt=""
                            style="width: 18px;height: 18px;position: relative;top:4px" />
                          {{ item.cnName }}
                        </li>
                      </ul>
                    </el-scrollbar>
                  </div>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item v-if="operationStatus">
                <span style="display: flex;align-items: center;" @click="goCenter()">
                  <i class="iconfont icon-gerenzhongxin"></i>个人中心
                </span>
              </el-dropdown-item>
              <el-dropdown-item
                v-if="this.userType != 9 && !comId && isAuto != '自动记账' && orgId != 4445 || (orgId == 4445 && isAdmin == 1)">
                <span @click="changePassWord()" style="display: flex;align-items: center;">
                  <i class="iconfont icon-anquanshezhi"></i>安全设置
                </span>
              </el-dropdown-item>
              <el-dropdown-item v-if="this.userType != 9">
                <span @click="copyToken()" style="display: flex;align-items: center;">
                  <i class="iconfont icon-kehuguanli"></i>复制登录key
                </span>
              </el-dropdown-item>
              <el-dropdown-item v-if="groupId != 3278">
                <span @click="logout()" style="display: flex;align-items: center;">
                  <i class="iconfont icon-tuichu"></i>退出登录
                </span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div @click="goback()" v-if="comId || isAuto == '自动记账'" class="header-message"
        style="width: 60px;margin: 0 10px 0 0;">
        <el-button type="primary" icon="Right" size="small">
          返回
        </el-button>
      </div>
    </div>

  </div>
  <div class="header_fixed" @click="checkTask" v-if="!comId && isAuto != '自动记账' && $buttonStatus('ty_sjrw')">
    <img src="../../../assets/sj.png" alt="" style="width: 20px;height: 20px;">
    <span style="margin-left: 3px;">任务</span>
  </div>
  <!-- 安全设置改成弹框样式 -->
  <el-dialog v-model="dialogVisible" title="安全设置-修改密码" width="350px" :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="83px" class="demo-ruleForm">
      <el-form-item label="原密码" prop="oldPassWord">
        <el-input v-model="ruleForm.oldPassWord" size="small" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="passWord">
        <el-input v-model="ruleForm.passWord" size="small" style="width:200px"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="passWord2">
        <el-input v-model="ruleForm.passWord2" size="small" style="width:200px"></el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="resetForm('ruleForm')" size="small">重置</el-button>
        <el-button size="small" type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 档案 -->
  <archivesDialog ref="archivesDialog" type="3" />
  <ticket-status ref="ticketStatus" :period="period" :comId="comId" @updateStatus="getPiaoStatus"></ticket-status>
  <message ref="message" @success="getMessageTotal"></message>
  <download ref="download" />
  <taskList ref="taskList" />
  <jotDown ref="jotDown" />
  <operationLog ref="operationLog" />
  <operationLogNew ref="operationLogNew" />
  <el-dialog :close-on-click-modal="false" destroy-on-close title="同步提示" v-model="dialogCallVisible" width="700px">
    <div style="max-height: 450px;overflow: auto;">
      <el-table :data="callList" size="small" border>
        <el-table-column prop="msg" label="同步类型" width="100px"></el-table-column>
        <el-table-column align="center" property="successNum" label="同步数量" width="100px"></el-table-column>

        <el-table-column align="center" property="reason" label="异常原因" show-overflow-tooltip></el-table-column>
      </el-table>
    </div>
    <div style="margin: 15px 0; padding-bottom:10px">
      <el-button style="float: right;margin-left:10px" @click="dialogCallVisible = false" size="small"
        type="primary">关闭</el-button>
    </div>
  </el-dialog>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="开通AI财务工厂" v-model="aiVisible" width="1200px">
    <div style="max-height: 590px;padding: 0px 20px;">
      <img src="https://file.listensoft.net/mnt/v2/img/hongbao.svg" alt="" style="width: 100%;height: 100%;border-radius: 10px;">
      <div style="display: flex;justify-content: flex-end;position: relative;bottom: 71px;">
        <img src="../../../assets/hbbtn1.svg" alt="" style="width: 200px;cursor: pointer;" @click="goStatistics">
        <img src="../../../assets/hbbtn2.svg" alt="" style="width: 200px;cursor: pointer;"  @click="lq">
      </div>
    </div>
    <!-- <template #footer>
      <div class="dialog-footer">
        <el-button style="float: right;margin-top:-35px;padding: 15px 20px;margin-right: 23px;" @click="lq" size="small"
          type="success">领取免费试用金</el-button>
        <el-button style="float: right;margin-left:22px;margin-top:-35px;padding: 15px 20px;margin-right: 163px;"
          @click="goStatistics" size="small" :icon="Histogram" type="primary">转型"会计工厂"预测</el-button>
      </div>
    </template> -->
  </el-dialog>
  <!-- <div>
  </div> -->
  <div v-if="aiOpen" style="z-index:99999" class="money" id="modal" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="money_img">
      <img src="../../../assets/08191703.gif" style="width:400px;height:auto"  @click="hby">
      <div class="ai_close">
        <el-icon
          color="#fff"
          size="40px"
          @click="closeAiOpen"
          style="cursor: pointer;"
        >
          <circle-close />
        </el-icon>
      </div>
    </div>
  </div>
  <div v-if="aiOpen1" style="z-index:99999" class="money">
    <div class="money_img">
      <img src="../../../assets/openRed.png" style="width: 328px;height: auto;">
      <div class="money_text">{{ aiForm.money }}</div>
      <div class="money_btn">
        <el-button @click="saveAI"  size="small" type="warning" style="padding: 14px 58px;">领取</el-button>
      </div>
      <div class="ai_close" style="position: absolute;bottom:-41px;left: 140px;">
        <el-icon
          color="#fff"
          size="40px"
          @click="aiOpen1 = false"
          style="cursor: pointer;"
        >
          <circle-close />
        </el-icon>
      </div>
    </div>
  </div>
  <div v-if="aiOpen2" style="position: absolute;z-index:99999">
    <div id="redzone"></div>
  </div>
  <!-- 统计 -->
  <aiStatistics ref="aiStatistics"></aiStatistics>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="closeImage"
    :url-list="imgSrc"
  />
</template>
<script>
import AiStatistics from './aiStatistics'
import { updateRead } from "@/api/org.js";
import { updatePeriod, updateUser } from "@/api/login";
import { saveApply,endMoneyOrgInfo,orgAutoSettingInfo } from "@/api/automatic.js";
import { getToken } from "@/utils/auth";
import store from "@/store";
import menuTop from "./menuTop.vue";
import { editPass } from "@/api/sys.js";
import archivesDialog from "../../crm/custom/components/archivesDialog.vue"//档案
import operationLog from './operationLog.vue'
import { checkoutEaCompanyList, syncDggData } from "@/api/company";
import message from "./information.vue";
import download from "./download.vue";
import jotDown from '@/components/jotDown'
import taskList from "./taskList.vue";
import aiStatistics from "./aiStatistics.vue";
import {DOWNLOAD_OPTIONS} from '@/utils/commonData'
import operationLogNew from './operationLogNew.vue'
let operaBox = null
export default {
  name: "headers",
  components: {
    AiStatistics,
    message,
    download,
    menuTop,
    taskList,
    jotDown,
    archivesDialog,
    operationLog,
    aiStatistics,
    operationLogNew
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("原密码不能为空"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.passWord2 !== "") {
          this.$refs.ruleForm.validateField("passWord2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.passWord) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      aiOpen1:false,
      timer: null,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      users: this.$store.getters["user/users"],
      companyId: {},
      companys: [],
      companysCopy: [],
      companysSearch: [],
      filterText: "",
      userName: this.$store.getters["user/user"].cnName,
      orgName: this.$store.getters["user/user"].orgName,
      orgId: this.$store.getters["user/user"].orgId,
      groupId: this.$store.getters["user/user"].groupId,
      isAdmin: this.$store.getters["user/user"].isAdmin,
      isManage: this.$store.getters["user/user"].isManage,
      isLeader: this.$store.getters["user/user"].isLeader,
      userType: this.$store.getters["user/user"].userType,
      userId: this.$store.getters["user/user"].userId,
      orgIsAuto: this.$store.getters["user/user"].autoStatus,
      settingShow: false,
      indentShow: false,
      visible: false,
      content: "",
      dialogVisible: false,
      piaoStatus: 0,
      piaoItems: [],
      settleWord: "",
      messageTotal: 0,
      svg: `
          <path class="path" d="
            M 30 15
            L 28 17
            M 25.61 25.61
            A 15 15, 0, 0, 1, 15 30
            A 15 15, 0, 1, 1, 27.99 7.5
            L 15 15
          " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
        `,
      imgUrl: "https://file.listensoft.net/mnt/v2/img/logo1.png",
      operationStatus: false,
      loadVisible: false,
      ruleForm: {
        oldPassWord: "",
        passWord: "",
        passWord2: "",
      },
      rules: {
        oldPassWord: [{ required: true, validator: checkAge, trigger: "blur" }],
        passWord: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        passWord2: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
      },
      isAuto: "",
      userOption: [],
      filterName: "",
      autoShow: false,
      callList: [],
      dialogCallVisible: false,
      generalShow: false,
      aiVisible: false,//ai财务工厂开通弹窗
      aiOpen: false,//申请工厂弹窗
      aiForm: {},
      aiOpen2:false,
      showImagePreview:false,
    };
  },
  computed: {
    thisNav() {
      return store.getters["user/model"];
    },
    period() {
      return store.getters["user/comInfo"].period;
    },
    comName() {
      return store.getters["user/comInfo"].comName;
    },
    gongjuOptions(){
      let list = DOWNLOAD_OPTIONS
      if(store.getters["user/user"].groupId == 3278){
        list[2].value = "https://file.jsptax.com/mnt/down/税局小助手新版QCL.exe";
        list[2].img = require("@/assets/sjxzsqcl.png");
        list[3].img = require("@/assets/dxzsqcl.png");
      }
      return list
    },
    imgSrc(){
      let img = window.localStorage.getItem("smsAppUrl") ? [window.localStorage.getItem("smsAppUrl")]:["https://file.listensoft.net/mnt/v2/other/sms_qr.png"]
      return img
    },
    operaCzsq(){
      let url = window.localStorage.getItem("smsDocUrl") ? window.localStorage.getItem("smsDocUrl") :"https://file.listensoft.net/mnt/v2/other/税局短信助手操作手册.docx"
      return url
    }
  },
  created() {
    if (window.location.hostname == 'daizhang.jsptax.com' || window.location.hostname == 'localhost' || window.location.hostname == 'tax.jsptax.net') {
      this.operationStatus = true;
    }
    this.init();
    this.contentStyleObj = this.$getHeight(500);
    if (this.$store.getters["user/user"].primaryRouting) {
      this.$store.getters["user/user"].primaryRouting.map((v) => {
        if (v == "管理设置") {
          this.settingShow = true;
          return;
        }
        if (v == "授权登录") {
          this.loginTaxBureauShow = true;
          return;
        }
        if (v == "订单管理") {
          this.indentShow = true;
          return;
        }
        if (v == "自动记账") {
          this.autoShow = true;
          return;
        }
        if (v == "通用") {
          this.generalShow = true;
          return;
        }
      });
    }
    this.isAuto = this.$store.getters["user/model"]
  },
  mounted() {
    if(this.operationStatus){
      updateRead({}).then((response) => {
        if (response.data.data.status) {
          this.messageTotal = 1;
          this.$refs.message.detail = response.data.data.info;
          this.$refs.message.dialogFormVisible = true;
        }
      });
    }
    let param = {
      comIds: [this.comId],
      period: this.$store.getters["user/comInfo"].period,
    };
    this.$refs.ticketStatus.ticketStatus(param);
    this.userOption = this.users
  },
  methods: {
    hby() {
      endMoneyOrgInfo({}).then((res) => {
        this.aiForm.money = res.data.data.info.money;
        this.aiForm.linkmanTel = res.data.data.info.linkmanTel;
        this.aiForm.orgId = res.data.data.info.orgId;
        this.aiForm.linkman = res.data.data.info.linkman;
      });
      this.aiOpen2 = true
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        var left = Math.random() * document.body.scrollWidth;
        var height = Math.random() * document.body.scrollHeight;
        var src = "https://file.listensoft.net/mnt/v2/img/hongbao.png";
        this.addBao(left, height, src);
      }, 100);
      setTimeout(()=> {
        this.aiOpen2 = false
        this.timer && clearInterval(this.timer);
        document.getElementById("redzone").style.display = "none";
        this.aiOpen = false;
        this.aiOpen1 = true;
      }, 2000);
    },
    addBao(left, height, src) {
      var div = document.createElement("div");
      var img = document.createElement("img");
      div.appendChild(img);
      img.className = "roll";
      img.src = src;
      div.style.left = left + "px";
      div.style.height = height + "px";
      div.className = "redBox";
      document.getElementById("redzone").appendChild(div);
    },
    lq() {
      this.aiOpen = true;
      this.aiVisible = false
    },
    //查看档案
    checkArchives() {
      let row = {}
      row.name = this.comName
      this.$refs.archivesDialog.checkArchivesList(row)
    },
    goUser(id) {
      const loading = this.$loading({
        lock: true,
        text: "切换人员中",
        spinner: this.svg,
        background: "rgba(255,255,255,0.9)",
      });
      setTimeout(() => {
        loading.close();
      }, 1500);
      updateUser({ id: id }).then(() => {
        location.reload();
      });
    },
    init() {
      checkoutEaCompanyList({ status: "2" }).then((res) => {
        this.companys = res.data.data.list;
        this.companysCopy = this.companys;
      });
      if (
        this.$store.getters["commons/periods"].length !== 0 &&
        this.$store.getters["commons/periods"][0].options[0].settleStatus == "4"
      ) {
        this.settleWord = "已结账";
      } else {
        this.settleWord = "未结账";
      }
    },
    changeTitle(options) {
      if (options.length > 0 && options[0].label == "正常账期") {
        options[0].options.map((v) => {
          if (this.$store.getters["user/comInfo"].period == v.period) {
            if (v.settleStatus == "4") {
              this.settleWord = "已结账";
            } else {
              this.settleWord = "未结账";
            }
          }
        });
      }
    },
    getMessageTotal(val) {
      this.messageTotal = val;
    },
    getPiaoStatus(e) {
      this.piaoStatus = e;
    },
    filterStatus(val) {
      if (val == 9) {
        return "未到票";
      } else if (val == 2) {
        return "全部到票";
      } else if (val == 1) {
        return "部分到票";
      } else {
        return "到票状态";
      }
    },
    goback() {
      // opener: 谁打开我的, 注意它不是parent!!
      if (window.opener) {
        // 主页面还存在
        // 关闭当前页
        // 切换到主页面 how ?
        // window.open(window.opener.location.href,  '_parent'); // 可以, 因为当前页面没有parent
        window.close();
      } else {
        // 主页面已经关了
        // location.reload()
        window.open(location.origin, "_blank");
        window.close();
      }
    },
    changeComId() {
      const loading = this.$loading({
        lock: true,
        text: "切换公司中",
        spinner: this.svg,
        background: "rgba(255,255,255,0.9)",
      });
      setTimeout(() => {
        loading.close();
      }, 1500);
      this.$store.dispatch("user/setComInfo", {
        comId: this.comId,
      });
      window.name = this.comId + "##accountBook";
      this.goEtax("正常", true);
    },
    goEtax(e, force) {
      //为了防止重复调用 userInfo 做个判断
      if (store.getters["user/model"] == e && !force) {
        this.gogogo(e);
        return;
      }
      this.$store.dispatch("user/setModel", e);
      this.$store
        .dispatch("user/GetUserInfo")
        .then((res) => {
          // getRouter(res.roles)
          this.$store
            .dispatch("router/SetAsyncRouter", res.roles)
            .then(() => {
              this.$store.dispatch("tagsView/delAllViews");
              this.gogogo(e);
            });
        })
        .catch(() => {
          //有问题 返回登录页面 直接清空token 就可
          // removeToken()
          // location.reload()
        });
    },
    gogogo(e) {
      if (e == "正常") {
        this.$router.replace({
          path: "/redirects",
          query: {
            path: "/etaxHome/etaxHomeIndex",
          },
        });
      } else {
        this.$router.replace({
          path: "/redirects",
          query: {
            path: store.getters["user/indexPath"],
          },
        });
      }
      checkoutEaCompanyList({ status: '2' }).then((res) => {
        this.companys = res.data.data.list;
        this.companysCopy = this.companys;
      });
    },
    //更改账期
    updatePeriods(data) {
      if (data.old == true) {
        this.settleWord = "已结账";
      } else {
        this.$store.getters["commons/periods"][0].options.map((v) => {
          if (data.period == v.period) {
            if (v.settleStatus == "4") {
              this.settleWord = "已结账";
            } else {
              this.settleWord = "未结账";
            }
          }
        });
      }

      let comInfo = store.getters["user/comInfo"];
      comInfo.period = data.period;
      updatePeriod({ period: data.period }).then((res) => {
        if (res.data.msg == "success") {
          comInfo.comType = res.data.data.info.comType;
          comInfo.comKj = res.data.data.info.comKj;
          comInfo.jzsy = res.data.data.info.jzsy;
          comInfo.isOld = res.data.data.info.isOld;
          this.$store.dispatch("user/setComInfo", comInfo);
          this.$store.dispatch("commons/getSubjects", comInfo.comId);
          //刷新
          this.$store.dispatch("tagsView/delAllViews"); //关闭
          // this.$router.push('/'); //lcs账期
          if (data.old) {
            this.goEtax("历史", false);
          } else {
            this.goEtax("正常", false);
          }
        }
      });
    },
    //消息
    messageShow(e) {
      this.tabName = e;
      this.$refs.message.showBig2(this.tabName);
    },
    logout() {
      this.$store.dispatch("user/LogOut").then(() => {
        // 'acc1.yinuojizhang.com'
        if (window.name.match("accountBook")) {
          if (window.closeAllHostname.includes(window.location.hostname)) {
            localStorage.setItem('closeAllWindow', '1')
          } else {
            window.open(location.origin, "_blank");
          }
          window.close();
        } else {
          if (window.closeAllHostname.includes(window.location.hostname)) {
            localStorage.setItem('closeAllWindow', '1')
            window.close()
          } else {
            window.localStorage.clear();
            location.reload();
          }
        }
      });
    },
    guanlishezhi() {
      this.goEtax("管理设置", false);
    },
    smallNotes() {
      this.$refs.jotDown.init(this.comName, this.comId, this.period)
    },
    ticketOpen() {
      this.$refs.ticketStatus.openDialog();
      let params = {
        comIds: [this.$store.getters["user/comInfo"].comId * 1],
        period: this.$store.getters["user/comInfo"].period,
      };
      this.$refs.ticketStatus.ticketStatus(params);
    },
    getDownloadList() {
      this.$refs.download.init();
    },
    filterCom(val) {
      if (val) {
        checkoutEaCompanyList({ name: val, status: '2' }).then((res) => {
          this.companys = res.data.data.list;
        });
      } else {
        this.companys = this.companysCopy;
      }
    },
    //授权登录
    goLoginTax() {
      this.$router.push({
        name: "loginTaxBureau",
      });
    },
    loadUrl(url) {
      this.loadVisible = false;
      if(url == '税局短信助手'){
        var div = document.createElement("div")
        div.innerHTML = `<a href="${this.operaCzsq}" target="_blank">税局短信助手操作手册</a>`
        div.classList.add('opera_style')
        document.body.appendChild(div)
        operaBox = div
        this.showImagePreview = true
      }else{
        window.open(url);
      }
    },
    closeImage(){
      this.showImagePreview = false,
      document.body.removeChild(operaBox)
    },
    // 安全设置修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editPass(this.ruleForm).then((res) => {
            if (res.data.msg == "success") {
              this.dialogVisible = false
              this.$qzfMessage("修改成功");
            }
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changePassWord() {
      this.dialogVisible = true;
    },
    goHabbit() {
      this.$router.push({
        name: "orgHabbitSetting",
      });
    },
    goCenter() {
      window.open('https://daizhang.jsptax.com/mnt/v2/html/user/index.html?token=' + getToken())
    },
    goOrder() {
      this.$router.push({
        name: "indent-manage",
      });
    },
    goAuto() {
      if ([110385].includes(this.userId) || this.orgIsAuto) {
          let routeData = this.$router.resolve({
            path: "/autoHome",
            query: {
              roleName: "automaticBook",
            },
          });
         window.open(routeData.href,"##automaticBook");
      } else {
        this.aiVisible = true
      }
    },
    goGw() {
      this.$router.push({
        path: "/platform/websiteInfomation",
        name: "websiteInfomation"
      });
    },
    goAI() {
      this.aiOpen = true
    },
    saveAI() {
      saveApply(this.aiForm).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage("领取成功,将为您跳转到财务工厂页面~")
          this.$store.getters["user/user"].autoStatus = 1;
          this.orgIsAuto = 1
          setTimeout(()=>{
            let routeData = this.$router.resolve({
              path: "/autoHome",
              query: {
                roleName: "automaticBook",
              },
            });
            window.open(routeData.href,"##automaticBook");
          },1000)
          // location.reload()
        } 
        this.aiVisible = false
        this.aiOpen1 = false;
      })
    },
    closeAiOpen(){
      this.aiOpen = false
    },
    // 统计
    goStatistics() {
      this.$refs.aiStatistics.init()
    },
    //申报监控
    checkTask() {
      this.$refs.taskList.init()
    },
    goDemo() {
      this.$router.push({
        name: "monitor",
      });
    },
    //人名筛选
    changeName() {
      this.userOption = this.users.filter(v => {
        return v.cnName.indexOf(this.filterName) > -1
      })
    },
    operationDropdownShow() {
      this.$refs.operationDropdownMenuItem.popperRef.onOpen()
      this.userOption = this.users.filter(v => {
        return v.cnName.indexOf(this.filterName) > -1
      })
    },
    //操作日志
    checkOperation() {
      //this.$refs.operationLog.init()
      this.$refs.operationLogNew.init()
    },
    //同步数据
    dggTbData() {
      const loading = this.$loading({
        lock: true,
        text: "同步数据中",
        spinner: this.svg,
        background: "rgba(255,255,255,0.9)",
      });
      syncDggData({}).then(res => {
        loading.close();
        if (res.data.msg == 'success') {
          this.callList = res.data.data ? res.data.data : []
          this.dialogCallVisible = true
          this.$qzfMessage('同步成功')
        }
      })
    },
    //复制登录key
    copyToken() {
      let str = getToken()
      this.$copyComName(str)
    }
  },
};
</script>

<style lang="scss" scoped>
.tit {
  margin: 3px 10px;
  vertical-align: middle;

  img {
    vertical-align: middle;
    margin-top: -2px;
  }

  span {
    margin-left: 3px;
  }
}

.none_border :deep(.el-input) {
  --el-input-border-color: #fff !important;
}

.none_border :deep(.el-input__wrapper:hover) {
  box-shadow: none;
}

:deep(.el-popover.el-popper) {
  min-width: 0;
}

.iconfont1 {
  color: white;
}

.header-big {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--themeColorHeaderBg, #304456);
  font-size: 14px;
}

.left_header {
  width: 59%;
  height: 50px;
}

.right_header {
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.header-message {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  color: #fff;
}

.el-icon {
  margin-right: 5px;
}

.el-scrollbar .el-dropdown-menu {
  padding: 5px 0;
  background: #fff;
  border-radius: 0px;
}

:deep(.el-dropdown-menu__item:not(.is-disabled):focus) {
  color: var(--themeColor, #17a2b8) !important;
  background-color: #f0f9f8 !important;
}

.nav1 {
  display: inline-block;
  padding: 0px;
  margin-left: 3px;

  span {
    color: $color-header-color;
    font-size: 16px;
    width: 135px;
    font-weight: 500;
    line-height: 52px;
    cursor: pointer;
  }
}

.cur {
  border-bottom: 3px solid var(--themeColor, #17a2b8);
}

.has_com {
  width: 100%;
  float: left;
  height: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 25px;
}

ul li {
  font-size: 13px;
}

.header-ai {
  height: 30px;
  background-color: #000;
  padding: 0 10px;
  border-radius: 30px;
}
.money{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color:rgba(0,0,0,0.5);
  z-index: 999;
  .money_img {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -12%);
    top: 25%;
    z-index: 999;
  }
  .ai_close{
    text-align: center;
  }
  .money_text{
    position: absolute;
    left: 126px;
    font-size: 35px;
    font-weight: bold;
    top: 178px;
  }
  .money_btn{
    position: absolute;
    left: 88px;
    font-size: 35px;
    font-weight: bold;
    bottom: 43px;
  }
}

</style>
<style lang="scss">
.aaaaa {
  margin-left: 5px;

  .el-input__inner {
    background-color: #fff;
    font-size: 15px;
    font-weight: 400;
  }
}

.dateStyle {
  display: flex;
  align-items: center;
  position: relative;

  .el-input__inner {
    font-size: 15px;
    width: 110px;
    font-weight: 500;
    padding-left: 23px;
    padding-right: 0px;
  }
}

.gongjuStyle {
  padding: 2px 0;
  cursor: pointer;
  position: relative;
  top: -4px;
  img{
    width: 18px;
    height: 18px;
    position: relative;
    top:4px;
    margin-right: 5px;
  }
}

.header_fixed {
  width: 68px;
  height: 28px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 99;
  background-color: rgba(27, 134, 221, 0.75);
  font-size: 13px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
  color: #fff;
  padding-left: 15px;
}
@keyframes redImg {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
  }
}

.redBox {
  position: absolute;
  /* opacity: 0;*/
  z-index: 10000;
  animation: redImg 3s infinite linear;
  -webkit-animation: redImg 3s infinite linear;
  -moz-animation: redImg 3s infinite linear;
  -ms-animation: redImg 3s infinite linear;
  -o-animation: redImg 3s infinite linear;
}

.redBox {
  position: fixed;

  .roll {
    display: block;
    width: 40px;
  }
}
.opera_style{
  position: fixed;
  bottom: 73.5%;
  left:50%;
  transform: translateX(-50%);
  z-index:10000;
  a{
    color: #fff;
    font-size: 20px;
    letter-spacing: 1.5px;
    font-weight:bold;
  }
}
</style>
