<template>
  <!-- 后端导入 -->
    <el-button v-if="!hideButton" type="success" @click="handImport()" size="small"   >
      <el-icon><FolderRemove /></el-icon><span  > 导入{{name}}</span>
    </el-button>
    
    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" width="600px" title="导入">
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="" v-if="this.from == '银行PDF'">
          <el-input style="width:70%;margin-right:10px" v-model="path"></el-input>
          <input size="small" ref="excel-upload-inputCs" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml,.pdf" @change="handleMultipleClick" multiple id="files">
          <el-button type="primary" @click="handleMultipleUpload()" size="small">请选择文件</el-button>
        </el-form-item>
        <el-form-item label="" v-else>
          <el-input style="width:70%;margin-right:10px" v-model="path"></el-input>
          <input size="small" ref="excel-upload-input" class="excel-upload-input" style="display:none" type="file" accept=".xlsx, .xls, .xml" @change="handleClick">
          <el-button type="primary" @click="handleUpload()" size="small">请选择文件</el-button>
        </el-form-item>
        <el-form-item label="">
          <el-radio-group @change="clickQt()" v-model="mubanType">
            <el-radio label="2" v-if="this.from == '银行' || this.from == '科目' || this.from == '历史科目' || this.from == '历史凭证'">通用导入</el-radio>
            <el-radio label="1">模板导入</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="" v-if="this.from == '进项' || this.from == '销项' || this.from == '银行' || this.from == '银行PDF'">
         按日期导入：<el-switch v-model="periodType" active-text="是" inactive-text="否" active-value="2" inactive-value="1"></el-switch>
         <span style="color:red" v-if="this.from == '进项' || this.from == '销项'">*若选择“是”，则按照发票日期分别导入到所属账期显示;若不选，默认全部导入到当前账期</span>
         <span style="color:red" v-if="this.from == '银行' || this.from == '银行PDF'">*若选择“是”，则按照交易日期分别导入到所属账期显示;若不选，默认全部导入到当前账期</span>
        </el-form-item>
        <el-form-item label="" v-if="this.from == '暂估入库'" style="margin-top:-10px">
         <span style="color:red">*温馨提示：当期暂估数据请通过【存货】-【暂估入库】手动操作</span>
        </el-form-item>
        <el-form-item label="" v-if="this.mubanType == '1'">
          <el-select size="small" v-model="type" placeholder="请选择" clearable @change="mubanChange" filterable>
            <el-option v-for="(item, index) in list" :key="index" :label="item.type" :value="item.type"></el-option>
          </el-select>
          <el-date-picker v-if="this.from == '历史科目'" v-model="period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
          <el-date-picker
          v-if="this.from == '历史凭证'"
          size="small"
          v-model="changeTime"
          type="monthrange"
          range-separator="-"
          value-format="YYYYMM"
          format="YYYYMM"
          start-placeholder="开始账期"
          end-placeholder="结束账期"
          style="width:220px"
          />
          <el-button size="small" type="primary" @click="downLoadUrl" v-if="this.from != '历史科目' && this.from != '历史凭证'">点击下载模板</el-button>
          <el-tooltip placement="top" v-if="mubanType == '1' && stepInstructions" content="点击查看此导出步骤">
            <span class="help" @click="checkHelp()">帮助<i class="iconfont icon-wenhao"></i></span>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="" v-if="this.mubanType == '2'">
          <el-date-picker v-if="this.from == '历史科目'" v-model="period" type="month" placeholder="请选择账期" value-format="YYYYMM" format="YYYYMM"></el-date-picker>
          <el-date-picker
          v-if="this.from == '历史凭证'"
          size="small"
          v-model="changeTime"
          type="monthrange"
          range-separator="-"
          value-format="YYYYMM"
          format="YYYYMM"
          start-placeholder="开始账期"
          end-placeholder="结束账期"
          style="width:220px"
          />
          <el-button size="small" type="primary" @click="downLoadUrl2" v-if="this.from != '历史科目' && this.from != '历史凭证'">点击下载模板</el-button>
        </el-form-item>
        <el-form-item label="" v-if="this.from == '历史科目' || this.from == '历史凭证'">
          <!-- <a :href="this.excelUrl">点击</a> -->
          <el-button size="small" type="primary" @click="downLoadUrl">点击下载模板</el-button>
        </el-form-item>
        <!-- <el-form-item label="" v-if="this.from == '存货导入'">
          <el-button size="small" type="primary" @click="downLoadUrl3">点击下载模板</el-button>
        </el-form-item> -->
      </el-form>
  
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="small">取消</el-button>
          <el-button :loading="loading" type="primary" @click="uploadExcel()" size="small" v-if="this.from != '银行PDF'">确定</el-button>
          <el-button :loading="loading" type="primary" @click="uploadMultipleExcel()" size="small" v-else>确定</el-button>
        </span>
      </template>
    </el-dialog>
  
    <el-dialog :close-on-click-modal="false"
      v-model="dialogVisible"
      title="提示"
      width="30%"
    >
      <el-table :data="gridData" height="500">
        <el-table-column label="提示信息" align="center" min-width="120">
          <template #default="scope">
            <span><span style="color:red">{{ scope.row }}</span></span>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="dialogVisible = false">
            关闭
          </el-button>
        </span>
      </template>
    </el-dialog>
     
    <otherBank ref="bankTy"></otherBank>
  </template>
  
  
  <script>
  
  import { importTemplate, inImport, outImport , shougouImport ,importExcel ,subjectImport ,empImport , salaryImport,salaryRemImport,salaryOnlyImport ,salaryInitImport ,bankImport , oldSubjectImport , oldVoucherImport , importInventory , assetImport , fzhsImport, voucherImport ,billImport , feeImport ,subjectFzhsImport ,empSpeImport ,commonTemplate ,inventoryImport ,inventoryEsImport ,empForeignImport ,cashImport} from "@/api/import"
  
  
  import otherBank from "./son/otherBank.vue"
  import { time5toTime } from "@/utils"
  
  export default {
    props: {
      hideButton: {
        type: Boolean,
        default: false
      },
      from: {
        type: String,
        default: ""
      },
      id:{
        type:Number,
        default:0
      },
      comId:{
        type:Number,
        default:0
      },
      liPeriod:{
        type:String,
        default:''
      },
      period2:{
        type:String,
        default:''
      },
      sheetId:{
        type:Number,
        default:0
      },
      bankName:{
        type: String,
        default: ""
      }
    },
    components: {
      otherBank
    },
    data () {
      return {
        loading: false,
        dialogFormVisible: false,
        rawFile: '',
        path: '',
        list: [],
        type: '',
        mubanType:'2',
        period:'',
        beginTime:'',
        endTime:'',
        changeTime:'',
        excelUrl:'',
        gridData:[],
        dialogVisible:false,
        periodType:"1",
        filesList:[],
        stepInstructions:"",
      }
    },
    created () {
      
    },
    mounted(){
      if(this.from != '银行' && this.from != '科目' && this.from != '历史科目' && this.from != '历史凭证'){
        this.mubanType = '1'
      }
    },
    methods: {
      uploadExcel(){
        // console.log(this.type,this.mubanType);
        if(this.type == "" && this.mubanType == '1'){
          this.$qzfMessage("请选择模板", 1)
          return
        }
        if(this.rawFile == ''){
          this.$qzfMessage("请上传文件", 1)
          return
        }
        const param = new FormData() // 创建form对象
        param.append('file', this.rawFile, this.rawFile.name) // 单个图片 ，多个用循环 append 添加
        param.append('type', this.type)
        param.append('from', this.from)
        // console.log(this.rawFile);
        if(this.comId){
          param.append('comId', this.comId)
        }
        if(this.id){
          param.append('id', this.id)
        }
        if(this.period){
          param.append('period', this.period)
        }
        if(this.period2){
          param.append('period2', this.period2)
        }
        if(this.liPeriod){
          param.append('period', this.liPeriod)
        }
        if(this.from == "进项"){
          param.append('periodType',this.periodType)
          this.loading = true
          inImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.success()
              if(res.data.data && res.data.data.log){
                this.$qzfMessage(res.data.data.log)
                return
              }
              this.$qzfMessage("导入成功")
              if(res.data.data && res.data.data.list && res.data.data.list.length != 0){
                this.dialogVisible = true
                this.gridData = res.data.data.list
              }
            }
          })
        }else if(this.from == "销项"){
          param.append('periodType',this.periodType)
          this.loading = true
          outImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.success()
              if(res.data.data && res.data.data.log){
                this.$qzfMessage(res.data.data.log)
                return
              }
              this.$qzfMessage("导入成功")
              if(res.data.data && res.data.data.list && res.data.data.list.length != 0){
                this.dialogVisible = true
                let list = [];
                res.data.data.list.map(v=>{
                  list.push(v.period + '账期已存在发票号码为：' + v.fphm)
                })
                this.gridData = list
              }
            }
          })
        }else if(this.from == "收购"){
          this.loading = true
          shougouImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "科目"){
          this.loading = true
          if(this.mubanType == '2'){
            importExcel(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                // console.log(res.data.data)
                this.$emit('success2',res.data.data.data[0].data)
                this.dialogFormVisible = false
              }
            })
          }else{
            subjectImport(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                this.success()
              }
            })
          }
        }else if(this.from == "员工"){
          this.loading = true
          empImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "境外人员"){
          this.loading = true
          empForeignImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "薪酬"){
          let id = this.sheetId ? this.sheetId : this.$store.getters["commons/params"].salaryNormalId
          param.append('sheetId',id)
          this.loading = true
          salaryImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$emit('success2',res.data.data)
            }
          })
        }else if(this.from == "劳务报酬"){
          param.append('sheetId',this.$store.getters["commons/params"].salaryServicesId)
          this.loading = true
          salaryRemImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$emit('success2',res.data.data)
            }
          })
        }else if(this.from == "全年一次性奖金"){
          param.append('sheetId',this.$store.getters["commons/params"].salaryBonusId)
          this.loading = true
          salaryOnlyImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$emit('success2',res.data.data)
            }
          })
        }else if(this.from == "税款计算"){
          this.loading = true
          salaryInitImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "银行"){
          this.loading = true
          if(this.mubanType == '2'){
            importExcel(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                // res.data.data.data[0].data.map(v => {
                //   v[0] = time5toTime(v[0]);
                // })
                let bankList = res.data.data.data[0].data
                let max = 0
                bankList.map(v => {
                  if(v.length > max){
                    max = v.length
                  }
                })
                bankList.map(v=>{
                  for(let i=0;i<max;i++){
                    v.push("")
                  }
                })
                this.$emit('success2',bankList,this.periodType)
                this.dialogFormVisible = false
              }
            })
          }else{
            param.append('periodType',this.periodType)
            bankImport(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                this.success()
              }
            })
          }
        }else if(this.from == "银行PDF"){
          this.loading = true
          bankImport(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                this.success()
              }
            })
        }else if(this.from == "历史科目"){
          if(!this.period){
            this.$qzfMessage("请选择账期",1)
            return
          }
          this.loading = true
          if(this.mubanType == '2'){
            importExcel(param).then(res=>{
              this.loading = false
              
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                let params = {
                  item:res.data.data.data[0].data,
                  comId:this.comId,
                  period:this.period
                }
                this.$emit('success2',params)
                this.dialogFormVisible = false
              }
            })
          }else{
            oldSubjectImport(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                this.success()
              }
            })
          }
        }else if(this.from == "历史凭证"){
          this.beginTime = this.changeTime[0]
          this.endTime = this.changeTime[1]
          if(!this.changeTime){
            this.$qzfMessage("请选择账期区间",1)
            return
          }
          if(this.beginTime){
            param.append('beginTime', this.beginTime)
          }
          if(this.endTime){
            param.append('endTime', this.endTime)
          }
          this.loading = true
          if(this.mubanType == '2'){
            importExcel(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                let params = {
                  item:res.data.data.data[0].data,
                  comId:this.comId,
                  beginTime:this.beginTime,
                  endTime:this.endTime,
                }
                this.$emit('success2',params)
                this.dialogFormVisible = false
              }
            })
          }else{
            oldVoucherImport(param).then(res=>{
              this.loading = false
              if(res.data.msg == "success"){
                this.$qzfMessage("导入成功")
                this.success()
              }
            })
          }
        }else if(this.from == "存货"){
          this.loading = true
          importInventory(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$store.dispatch("commons/getInventory")
              this.$store.dispatch("commons/getBusinessType")
            }
          })
        }else if(this.from == "固定资产" || this.from == "无形资产" || this.from == "费用摊销"){
          this.loading = true
          assetImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "辅助核算"){
          this.loading = true
          fzhsImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "凭证"){
          this.loading = true
          voucherImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              if(res.data.data && res.data.data.length != 0){
                this.$emit('success2',res.data.data)
              }else{
                this.$qzfMessage("导入成功")
              }
              this.success()
            }
          })
        }else if(this.from == "票据"){
          this.loading = true
          billImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$emit('success2')
            }
          })
        }else if(this.from == "费用"){
          this.loading = true
          feeImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "会计科目"){
          this.loading = true
          subjectFzhsImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
              this.$emit('success2')
            }
          })
        }else if(this.from == "专项扣除"){
          this.loading = true
          empSpeImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "存货导入"){
          this.loading = true
          inventoryImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }else if(this.from == "暂估入库"){
          this.loading = true
          inventoryEsImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              if(res.data.data.errs && res.data.data.errs.length != 0){
                this.$emit('success2',res.data.data)
              }else{
                this.$qzfMessage("导入成功")
              }
              this.$emit('success')
              this.dialogFormVisible = false
            }
          })
        }else if(this.from == "现金"){
          this.loading = true
          cashImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          })
        }
      },
      clickQt(e){
        // console.log(this.mubanType);
      },
      handImport(){
        importTemplate({source: this.from}).then(res=>{
          if(res.data.data.list){
            this.list = res.data.data.list
            this.type = this.list[0].type
            this.excelUrl = this.list[0].excelUrl
            this.stepInstructions = ""
            if(this.list[0].stepInstructions){
              this.stepInstructions = this.list[0].stepInstructions
            }
            let info = this.list.find(v=>v.type.includes(this.bankName))
            if(info){
              this.type = info.type
              this.excelUrl = info.excelUrl
              this.stepInstructions = info.stepInstructions
            }else if(res.data.data.memory != ""){
              this.list.map(v=>{
                if(v.type == res.data.data.memory){
                  this.type = v.type
                  this.excelUrl = v.excelUrl
                  if(v.stepInstructions){
                    this.stepInstructions = v.stepInstructions
                  }
                }
              })
            }
          } else {
            this.list = []
          }
        })
        this.path = ''
        this.dialogFormVisible = true
      },
      handleClick(e) {
        // console.log(e);
        const files = e.target.files;
        const rawFile = files[0]; // only use files[0]
        let path = e.target.value; //路径
        this.path = path; //用路径作为标题
        this.rawFile = rawFile
  
      },
      handleUpload() {
        this.$refs["excel-upload-input"].click();
      },
      success(e){
        this.dialogFormVisible = false
        this.$emit('success')
      },
      handleMultipleUpload(){
        this.$refs["excel-upload-inputCs"].click();
      },
      handleMultipleClick(e) {
        this.filesList = e.target.files;
        this.path = ''
        for(let i = 0 ;i < this.filesList.length ; i++){
          this.path +=  this.filesList[i].name + ','
        }
        // let path = e.target.value; //路径/
        // this.path = path; //用路径作为标题/
      },
      uploadMultipleExcel(){
        if(this.type == "" && this.mubanType == '1'){
          this.$qzfMessage("请选择模板", 1)
          return
        }
        if(this.filesList.length == 0){
          this.$qzfMessage("请上传文件", 1)
          return
        }
        for (let k = 0; k < this.filesList.length; k++) {
          const isLt10M = this.filesList[k].size / 1024 / 1024 < 10;
          if (!isLt10M) {
            this.$qzfMessage("请不要上传大于10M的文件。", 1);
            return;
          }
        }
        const param = new FormData() // 创建form对象
        for(let i = 0 ;i < this.filesList.length ; i++){
          param.append('file', this.filesList[i],this.filesList[i].name) // 单个图片 ，多个用循环 append 添加
        }
        param.append('type', this.type)
        param.append('from', this.from)
        if(this.comId){
          param.append('comId', this.comId)
        }
        if(this.id){
          param.append('id', this.id)
        }
        if(this.period){
          param.append('period', this.period)
        }
        if(this.liPeriod){
          param.append('period', this.liPeriod)
        }
        param.append('periodType',this.periodType)
        if(this.from == "银行PDF"){
          this.loading = true
          bankImport(param).then(res=>{
            this.loading = false
            if(res.data.msg == "success"){
              this.$qzfMessage("导入成功")
              this.success()
            }
          }).catch(()=>{
            this.$qzfMessage("[错误]模版不匹配，请下载模版查看模版样式或点击帮助查看下载位置",2)
            this.loading = false
          })
        }
      },
      mubanChange(e){
        this.list.map(v=>{
          if(e == v.type){
            this.excelUrl = v.excelUrl
            if(v.stepInstructions){
              this.stepInstructions = v.stepInstructions
            }else{
              this.stepInstructions = ""
            }
          }
        })
      },
      downLoadUrl(){
        if(this.excelUrl && this.mubanType == '1') {
          window.open(this.excelUrl)
        }else if(this.from == '历史凭证' && this.mubanType == '2'){
          window.open('https://file.listensoft.net/data/v2/other/历史凭证通用模板.xlsx')
        }else{
          this.$qzfMessage("请联系客服处理！",1)
        }
      },
      downLoadUrl2(){
        if(this.from == '科目'){
          window.open('https://file.listensoft.net/data/v2/subject/标准.xls')
        }else if(this.from == '银行'){
          window.open('https://file.listensoft.net/data/v2/bank/银行通用模板.xls')
        }else if(this.from == '历史凭证'){
          window.open('https://file.listensoft.net/data/v2/other/历史凭证通用模板.xls')
        }
      },
      downLoadUrl3(){
        commonTemplate({
          type:'inventory',
          from:'存货导入'
        }).then(res=>{
          if(res.data.msg == 'success'){
            window.open(res.data.data.path)
          }
        })
      },
      checkHelp(){
        window.open(this.stepInstructions)
      }
    }
  }
  </script>
<style lang="scss" scoped>
.help{
  color: #17a2b8;
  font-size: 13px;
  cursor: pointer;
  margin-left: 5px;
  i{
   color: #17a2b8;
   font-size: 13px;
   margin-left: 3px;
  }
}
</style>