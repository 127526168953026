import request from '@/utils/request'

// 包含存货服务
export function invoiceKpxm(data) {
  return request({
    url: 'api/v2/invoice/invoiceKpxm',
    method: 'post',
    data
  })
}

//查询税目
export function taxCategoryList(data) {
  return request({
    url: 'api/v2/invoice/taxCategoryList',
    method: 'post',
    data
  })
}

//往来单位用于进项销项
export function corps(data) {
  return request({
    url: 'api/v2/invoice/corps',
    method: 'post',
    data
  })
}

//w外币列表
export function wbList(data) {
  return request({
    url: 'api/v2/invoice/wbList',
    method: 'post',
    data
  })
}

//新增修改销项
export function saveOutInvoice(data) {
  return request({
    url: 'api/v2/invoice/saveOutInvoice',
    method: 'post',
    data
  })
}
//新增修改销项
export function outInvoiceInfo(data) {
  return request({
    url: 'api/v2/invoice/outInvoiceInfo',
    method: 'post',
    data
  })
}
//销项list
export function outInvoiceList(data) {
  return request({
    url: 'api/v2/invoice/outInvoiceList',
    method: 'post',
    data
  })
}

//销项补充第一步
export function outInvoiceBcOneList(data) {
  return request({
    url: 'api/v2/invoice/outInvoiceBcOneList',
    method: 'post',
    data
  })
}
//销项补充第二步
export function outInvoiceBcTwoList(data) {
  return request({
    url: 'api/v2/invoice/outInvoiceBcTwoList',
    method: 'post',
    data
  })
}

//保存销项补充第一步
export function outBcSaveOne(data) {
  return request({
    url: 'api/v2/invoice/outBcSaveOne',
    method: 'post',
    data
  })
}

//保存销项补充第二步
export function outBcSaveTwo(data) {
  return request({
    url: 'api/v2/invoice/outBcSaveTwo',
    method: 'post',
    data
  })
}

//销项自动补充第二步
export function outAutomaticBc(data) {
  return request({
    url: 'api/v2/invoice/outAutomaticBc',
    method: 'post',
    data
  })
}

//批量创建往来单位
export function bcCreateCorpAndSubject(data) {
  return request({
    url: 'api/v2/invoice/bcCreateCorpAndSubject',
    method: 'post',
    data
  })
}

//手动新增修改进项发票
export function saveInInvoice(data) {
  return request({
    url: 'api/v2/invoice/saveInInvoice',
    method: 'post',
    data
  })
}

//进项发票 详情
export function inInvoiceInfo(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceInfo',
    method: 'post',
    data
  })
}

//进项发票 列表
export function inInvoiceList(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceList',
    method: 'post',
    data
  })
}


//进项补充第一步
export function inInvoiceBcOneList(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceBcOneList',
    method: 'post',
    data
  })
}
//进项补充第二步
export function inInvoiceBcTwoList(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceBcTwoList',
    method: 'post',
    data
  })
}
//保存进项补充第一步
export function inBcSaveOne(data) {
  return request({
    url: 'api/v2/invoice/inBcSaveOne',
    method: 'post',
    data
  })
}
//保存进项补充第二步
export function inBcSaveTwo(data) {
  return request({
    url: 'api/v2/invoice/inBcSaveTwo',
    method: 'post',
    data
  })
}

//进项第二步 存货自动补充
export function inAutomaticBc(data) {
  return request({
    url: 'api/v2/invoice/inAutomaticBc',
    method: 'post',
    data
  })
}

//未认证进项列表
export function inInvoiceWzzList(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceWzzList',
    method: 'post',
    data
  })
}

//进项删除
export function delInInvoice(data) {
  return request({
    url: 'api/v2/invoice/delInInvoice',
    method: 'post',
    data
  })
}


//销项删除
export function delOutInvoice(data) {
  return request({
    url: 'api/v2/invoice/delOutInvoice',
    method: 'post',
    data
  })
}

//进销项查看统计
export function inOutSum(data) {
  return request({
    url: 'api/v2/invoice/inOutSum',
    method: 'post',
    data
  })
}

//采集方式判断
export function invoiceCollectionType(data) {
  return request({
    url: 'api/v2/invoice/invoiceCollectionType',
    method: 'post',
    data
  })
}

//转认证
export function uncertifiedSave(data) {
  return request({
    url: 'api/v2/invoice/uncertifiedSave',
    method: 'post',
    data
  })
}
//转未认证
export function uncertifiedSaveNo(data) {
  return request({
    url: 'api/v2/invoice/uncertifiedSaveNo',
    method: 'post',
    data
  })
}

//采集单张发票
export function invoiceCollectionOne(data) {
  return request({
    url: 'api/v2/invoice/invoiceCollectionOne',
    method: 'post',
    data
  })
}

//采集补充销项发票
export function invoiceCollectionBcOut(data) {
  return request({
    url: 'api/v2/invoice/invoiceCollectionBcOut',
    method: 'post',
    data
  })
}
//采集补充销项发票
export function invoiceCollectionBcIn(data) {
  return request({
    url: 'api/v2/invoice/invoiceCollectionBcIn',
    method: 'post',
    data
  })
}

//批量设置未认证
export function saveWrz(data) {
  return request({
    url: 'api/v2/invoice/saveWrz',
    method: 'post',
    data
  })
}

// 销项
//批量修改票面类型
export function saveOutWrz(data) {
  return request({
    url: 'api/v2/invoice/saveOutWrz',
    method: 'post',
    data
  })
}


//上一张下一张
export function invoiceNextInfo(data) {
  return request({
    url: 'api/v2/invoice/invoiceNextInfo',
    method: 'post',
    data
  })
}

// 提示是否有发票未补充
export function invoiceBcStatus(data) {
  return request({
    url: 'api/v2/invoice/invoiceBcStatus',
    method: 'post',
    data
  })
}

// 批量补充明细
export function bqOutInvoice(data) {
  return request({
    url: 'api/v2/invoice/bqOutInvoice',
    method: 'post',
    data
  })
}

// 修改到票状态
export function invoiceDpzt(data) {
  return request({
    url: 'api/v2/invoice/invoiceDpzt',
    method: 'post',
    data
  })
}

//转移发票仓库
export function inInvoiceMigrateWare(data) {
  return request({
    url: 'api/v2/invoice/inInvoiceMigrateWare',
    method: 'post',
    data
  })
}

//发票pdflist
export function invoicePdfList(data) {
  return request({
    url: 'api/v2/invoice/invoicePdfList',
    method: 'post',
    data
  })
}

//清除信息--进项
export function clearInInvoice(data) {
  return request({
    url: 'api/v2/invoice/clearInInvoice',
    method: 'post',
    data
  })
}

//清除信息--销项
export function clearOutInvoice(data) {
  return request({
    url: 'api/v2/invoice/clearOutInvoice',
    method: 'post',
    data
  })
}

//清除折扣信息
export function voucherClearZk(data) {
  return request({
      url: 'api/v2/invoice/voucherClearZk',
      method: 'post',
      data
  })
}

//批量结算
export function settlement(data) {
  return request({
      url: 'api/v2/invoice/settlement',
      method: 'post',
      data
  })
}

//采集发票页面的 明细
export function invoiceDetailList(data) {
  return request({
      url: 'api/v2/invoice/invoiceDetailList',
      method: 'post',
      data
  })
}

//删除发票仓库
export function delInInvoiceWare(data) {
  return request({
      url: 'api/v2/invoice/delInInvoiceWare',
      method: 'post',
      data
  })
}

// imagepdf回调
export function refreshPreview(data) {
  return request({
      url: 'api/v2/invoice/refreshPreview',
      method: 'post',
      data
  })
}

//进项自动补充
export function autoInInvoice(data) {
  return request({
      url: 'api/v2/invoice/autoInInvoice',
      method: 'post',
      data
  })
}

//销项自动补充
export function autoOutInvoice(data) {
  return request({
      url: 'api/v2/invoice/autoOutInvoice',
      method: 'post',
      data
  })
}

//发票银行自动补充状态
export function getAutoBcStatus(data) {
  return request({
      url: 'api/v2/invoice/autoBcStatus',
      method: 'post',
      data
  })
}

//查询是否结账
export function getperiodStatus(data) {
  return request({
    url: 'api/v2/invoice/periodStatus ',
    method: 'post',
    data
  })
}

//开票项目统计
export function inOutSumRemark(data) {
  return request({
    url: 'api/v2/invoice/inOutSumRemark',
    method: 'post',
    data
  })
}

//发票明细的明细
export function invoiceDetailInfoList(data) {
  return request({
    url: 'api/v2/invoice/invoiceDetailInfoList',
    method: 'post',
    data
  })
}

//发票影像恢复
export function invoiceHf(data) {
  return request({
    url: 'api/v2/invoice/invoiceHf',
    method: 'post',
    data
  })
}