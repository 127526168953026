<template>
  <el-popover
    placement="bottom-end"
    trigger="click"
    width="220"
    :style="{ background: '#ecf0f5' }"
    v-model:visible="visible"
  >
    <template #reference>
      <el-button size="small" type="primary" icon="Operation" :plain="plain">
        {{ btnName }}
      </el-button>
    </template>
    <div
      style="font-size:12px;padding-bottom:3px; border-bottom: 1px solid #efefef;"
    >
      <span style="font-weight: 600;color:#333;font-size: 14px;">字段配置</span>
      <span style="color:red;margin-left: 5px;" v-if="(name !== '线索池' && name !== '我的线索' && name !== '线索回收站')">*至少五条</span>
    </div>
    <div :style="{ maxHeight: '400px', overflowY: 'auto' }">
      <el-checkbox-group v-model="checkedTemp" :min="(name == '线索池' || name == '我的线索'|| name == '线索回收站')?0:5">
        <el-checkbox v-for="item in tempList" :label="item" :key="item">{{
          item
        }}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div style="flex: auto;float: right;margin-top: 5px;">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="confirmClick"
        >确认</el-button
      >
    </div>
  </el-popover>
</template>

<script>
import { arrangeSave, arrangeInfo } from "@/api/company";
export default {
  name: "colSetting",
  props: {
    btnName: {
      type: String,
      default: "显示列",
    },
    type: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
    plain: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tempList: [],
      visible: false,
      userId: this.$store.getters["user/user"].userId * 1,
      comId: this.$store.getters["user/comInfo"].comId * 1,
      contentStyleObj: {},
      checkedTemp: [],
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(650);
    this.getList();
  },
  methods: {
    getList() {
      this.$emit("load");
      let param;
      if (this.from == "book") {
        param = {
          comId: this.comId,
          name: this.name,
        };
      } else {
        param = {
          userId: this.userId,
          name: this.name,
        };
      }
      arrangeInfo(param).then((res) => {
        if (res.data.msg == "success") {
          if (res.data.data.temp.value) {
            let info = res.data.data.temp.value;
            this.tempList = info.split("#");
          }
          if (res.data.data.value.value) {
            let checked = res.data.data.value.value;
            this.checkedTemp = checked.split("#");
          } else {
            if(!['线索池','我的线索','线索回收站'].includes(this.name)){
              this.checkedTemp = this.tempList;
            }
            if(this.name == '记账客户'){
              this.checkedTemp = this.checkedTemp.filter(item => item != '启用账期' && item != '标签');
            }
            if(['停报客户','回收站'].includes(this.name)){
              this.checkedTemp = this.checkedTemp.filter(item => item != '标签');
            }
          }
          this.$emit("arrangeInfo", this.checkedTemp);
        }
      });
    },
    confirmClick() {
      let valueStr = "";
      valueStr = this.checkedTemp.join("#");
      let param;
      if (this.from == "book") {
        param = {
          comId: this.comId,
          name: this.name,
          value: valueStr,
        };
      } else {
        param = {
          userId: this.userId,
          name: this.name,
          value: valueStr,
        };
      }
      arrangeSave(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("修改成功");
          this.getList();
          this.visible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table .cell .el-radio-group {
  white-space: pre-wrap !important;
  text-align: left;
}

.checkbox-wrap .el-checkbox {
  height: 30px;
}
:deep(.el-checkbox) {
  width: 130px;
  margin-right: 0;
  height: 26px;
  //display: block;
  --el-checkbox-checked-bg-color: var(--themeColor, #17a2b8);
}
.el-checkbox-group :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
  color: #333 !important;
}
.el-popover :deep(.el-popper.is-light) {
  background: #ecf0f5;
}
</style>
