<template>
  <div class="show-auth-box">
    <div
      v-if="
        ['zhejiang', 'guangdong', 'tianjin', 'hubei'].includes(row.district) ||
        row.loginMethod != '新版登录'
      "
    >
      <el-tooltip content="无需授权" placement="top" effect="dark">
        <i class="iconfont icon-zidong no-need-auth" @click="toLoginTax(row)" />
      </el-tooltip>
    </div>
    <div v-else-if="row.mobileRec" class="go-auth-box">
      <span v-if="row.keepStatus != 1">
        <el-tooltip
          content="无税局登录权限，点击可以跳转到授权界面"
          placement="top"
          effect="dark"
        >
          <i class="iconfont icon-zidong go-auth" @click="toLoginTax(row)" />
        </el-tooltip>
      </span>
      <el-tooltip
        v-if="row.keepStatus == 1"
        content="已有税局登录权限"
        placement="top"
        effect="dark"
      >
        <i class="iconfont icon-zidong had-auth" @click="toLoginTax(row)" />
      </el-tooltip>
    </div>
    <template v-else>
      <div class="go-auth-box">
        <el-tooltip
          content="无税局登陆权限，点击设置：新版登录并绑定手机号！"
          placement="top"
          effect="dark"
        >
          <i class="iconfont icon-zidong go-auth" @click="toLoginTax(row)" />
        </el-tooltip>
      </div>
    </template>
    <authorizedLogin ref="authorizedLoginRef" />
  </div>
</template>

<script setup>
import { ref, defineProps, getCurrentInstance } from "vue";
import authorizedLogin from "@/components/authorizedLogin";
const { proxy } = getCurrentInstance();
const props = defineProps({
  row: {
    type: Object,
    default: () => null,
  },
  idKey: {
    type: String,
    default: "id",
  },
});
const authorizedLoginRef = ref();
const toLoginTax = (row) => {
  if (!proxy.$store.getters["user/user"].primaryRouting.includes("授权登录"))
    return;
  let info = Object.assign({}, row);
  if (props.idKey == "comId") {
    info.id = row.comId;
  }
  authorizedLoginRef.value.init(info);
};
</script>

<style lang="scss" scoped>
.no-need-auth {
  display: inline-block;
  font-weight: 600;
  color: #67c23a;
  cursor: pointer;
}
.go-auth-box {
  display: inline-block;
  .go-auth {
    font-weight: 600;
    color: #afacac;
    cursor: pointer;
  }
}

.had-auth {
  font-weight: 600;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}

.go-setting {
  display: inline-block;
  // .go-set-icon {
  //   font-weight: 600;
  //   color: #e6a23c;
  //   cursor: pointer;
  // }
}
.iconfont {
  font-size: 15px;
}
</style>
