<template>
  <div class="common_btn">
    <div v-if="status">
      <!-- 成功 或者 默认 -->
      <el-button v-if="state == 0" size="mini" type="success" plain @click="success()">{{name}}</el-button>
      <el-button v-else-if="state == 2" size="mini" type="success" plain @click="success()">{{name}}-成功</el-button>
      <!-- 采集中 -->
      <el-button v-else-if="state == 1" size="mini" type="warning" plain  @click="getInfo()">{{name}}-中</el-button>
      <!-- 失败 -->
      <el-tooltip v-else class="item" effect="dark" :content="errorMsg" placement="top">
        <el-button size="mini" type="danger" plain  @click="success()">{{name}}-失败</el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { getTaskState2 } from "@/api/ea";
export default {
  props:{
    name: {
      type: String,
    },
  },
  data() {
    return {
      status: false,
      state:0,
      errorMsg:''
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    success(){
      this.$emit("success")
    },
    getInfo(){
      let method = 0
      if(this.name == "累计数据采集"){
        method = 1
      } else if(this.name == "专项扣除"){
        method = 2
      } 
      if(method !== 0){
        this.refreshStatus(method)
      }
    },
    // 更新状态采集
    refreshStatus(method){
      let params
      params = {
        com_id: this.$store.getters.comInfo.comId,
        method:method,
      }
      getTaskState2(params).then(res=>{
        this.state = res.data.data.state
        this.errorMsg = res.data.data.error_msg
        this.status = true
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.common_btn{
  display:inline-block;
  margin-right: 8px;
}
</style>
