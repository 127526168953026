<template>
  <div class="main">
    <!-- 查看档案弹窗 -->
    <el-drawer
      :title="ComName"
      v-model="checkArchives"
      :direction="direction"
      destroy-on-close
      :size="pagewidth"
      class="elDrawer"
    >
      <div
        style="margin-bottom: 10px; display: flex; justify-content: flex-end"
      >
        <el-button size="small" type="primary" @click="add">新增</el-button>
        <el-button
          size="small"
          type="primary"
          plain
          style="float: right"
          @click="daochu"
          >导出</el-button
        >
      </div>
      <el-table
        :data="archivesList"
        stripe
        style="width: 100%"
        border
        :height="contentStyleObj2"
        @selection-change="handleSelectionChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="35"> </el-table-column>
        <el-table-column prop="recordTime" label="时间" width="150">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="120">
          <template #default="scope">
            <span class="text_initial">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="archivesDescribe" label="描述" width="220">
          <template #default="scope">
            <span class="text_initial">{{ scope.row.archivesDescribe }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户姓名" width="100">
          <template #default="scope">
            <span class="text_initial">{{ scope.row.customerName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="交接人" width="100">
          <template #default="scope">
            <span class="text_initial">{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="图片附件"
          min-width="100"
          align="center"
        >
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.recordImgs"
              :key="index"
            >
              <p @click="open(item)" v-if="item">
                <el-icon style="color: #17a2b8; margin-right: 3px"
                  ><Folder
                /></el-icon>
                <el-tooltip
                  effect="dark"
                  :content="filterFile(item)"
                  placement="top"
                >
                  {{ filterFile(item) }}
                </el-tooltip>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="文件附件" align="center" min-width="100">
          <template #default="scope">
            <div
              class="div_p"
              v-for="(item, index) in scope.row.fileUrl"
              :key="index"
            >
              <p @click="check(item)" v-if="item">
                <el-icon style="color: #17a2b8; margin-right: 3px"
                  ><Folder
                /></el-icon>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="filterFile(item)"
                  placement="top"
                >
                  {{ filterFile(item) }}
                </el-tooltip>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template #default="scope">
            <el-button
              size="small"
              type="danger"
              @click="delArchives(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-drawer>
    <!-- 添加档案弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      v-model="addArchives"
      title="添加客户档案"
      width="60%"
      destroy-on-close
    >
      <div class="archives_content">
        <el-scrollbar :max-height="contentStyleObj">
          <el-form :model="temp">
            <el-form-item label="标题：" :label-width="formLabelWidth">
              <el-input v-model="inputArchives" style="width: 90%" />
            </el-form-item>
            <el-form-item label="描述：" :label-width="formLabelWidth">
              <el-input
                v-model="textareaArichives"
                :rows="8"
                type="textarea"
                placeholder="请输入档案内容"
                style="width: 90%"
              />
            </el-form-item>
            <el-form-item label="客户姓名：" :label-width="formLabelWidth">
              <el-input v-model="customerName" style="width: 90%" />
            </el-form-item>
            <el-form-item label="交接人：" :label-width="formLabelWidth">
              <el-input v-model="userName" style="width: 90%" />
            </el-form-item>
            <el-form-item label="文件附件：" :label-width="formLabelWidth">
              <el-input
                style="width: 50%; margin-right: 10px"
                v-model="path"
              ></el-input>
              <input
                size="small"
                ref="excel-upload-input"
                class="excel-upload-input"
                style="display: none"
                type="file"
                @change="handleClick"
                multiple
                accept=".xlsx, .xls, .xml,.pdf,.doc,.docx"
              />
              <el-button type="primary" @click="handleUpload()" size="small"
                >请选择文件</el-button
              >
            </el-form-item>
            <el-form-item label="图片附件：" :label-width="formLabelWidth">
              <div class="bigimg" style="margin-left: 0">
                <el-button type="primary" size="small">
                  <input
                    @change="uploadimg($event)"
                    class="inputfl"
                    type="file"
                    name="upfile"
                    accept=".png, .jpg, .jpeg"
                    multiple
                  />上传图片
                </el-button>
              </div>
              <div class="archives_up">
                <div
                  class="pic"
                  v-for="(item, index) in img"
                  :key="index"
                  v-show="img.length != 0"
                >
                  <img
                    :src="item"
                    style="height: 80px; margin-right: 5px"
                    @click="checkImg(item)"
                  />
                  <a class="del_btn" @click="delImg(index)">x</a>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addArchives = false" size="small">取消</el-button>
          <el-button type="primary" @click="saveArchives()" size="small">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- 图片预览 -->
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
  />
</template>

<script>
import axios from "axios";
import {
  getRecodeList,
  saveRecord,
  delRecode,
  exportRecord,
} from "../../../../api/crm";
export default {
  name: "archivesDialog",
  components: {},
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      checkArchives: false,
      addArchives: false,
      archivesForm: {
        customerId: null,
        recordType: "",
      },
      archivesList: [],
      inputArchives: "",
      textareaArichives: "",
      path: "",
      url: "",
      loading: false,
      formLabelWidth: "90px",
      contentStyleObj: {},
      contentStyleObj2: {},
      ComName: "",
      customerName: "",
      userName: "",
      multipleSelection: [],
      ids: [],
      showImagePreview: false,
      imgSrc: [],
      img: [],
      filesList: [],
      uploadFile: [],
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(400);
    this.contentStyleObj2 = this.$getHeight(180);
  },
  computed: {
    pagewidth() {
      return window.innerWidth * 0.75;
    },
  },
  methods: {
    //获取用户档案
    checkArchivesList(row) {
      this.ComName = row.name;
      if (this.type == "2") {
        this.archivesForm.customerId = row.id;
        this.archivesForm.recordType = this.type;
      } else if (this.type == "3") {
        if (!row.id) {
          row.id = this.$store.getters["user/comInfo"].comId * 1;
        }
        this.archivesForm.comId = row.id;
        this.archivesForm.recordType = this.type;
      }
      this.getList();
    },
    add() {
      this.reset();
      this.addArchives = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ids = [];
      val.map((v) => {
        ids.push(v.id);
      });
      this.ids = ids;
    },
    getList() {
      this.checkArchives = true;
      getRecodeList(this.archivesForm)
        .then((res) => {
          if (res.data.msg == "success") {
            this.archivesList = res.data.data.list;
            this.archivesList.map((v) => {
              v.fileUrl = v.file.split(",");
            });
          }
        })
        .catch(() => {});
    },
    //新增档案
    saveArchives() {
      this.url = this.uploadFile.join(",");
      let param = {
        recordType: this.type,
        title: this.inputArchives,
        archivesDescribe: this.textareaArichives,
        recordImgs: this.img,
        file: this.url,
        customerName: this.customerName,
        userName: this.userName,
      };
      if (this.type == "2") {
        param.customerId = this.archivesForm.customerId;
      } else if (this.type == "3") {
        param.comId = this.archivesForm.comId;
      }
      saveRecord(param)
        .then((res) => {
          if (res.data.msg == "success") {
            this.addArchives = false;
            this.$qzfMessage("添加成功");
            this.getList();
          }
        })
        .catch(() => {});
    },
    //导出档案
    daochu() {
      if (this.ids.length == 0) {
        this.$qzfMessage("请至少选择一条档案", 1);
        return;
      }
      exportRecord({ recordId: this.ids }).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
    //删除档案
    delArchives(item) {
      this.$confirm("确定删除档案吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delRecode({
          id: item.id,
        })
          .then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("删除成功");
              this.getList();
            }
          })
          .catch(() => {});
      });
    },
    //上传文件
    handleClick(e) {
      this.filesList = e.target.files;
      this.path = "";
      for (let i = 0; i < this.filesList.length; i++) {
        this.path += this.filesList[i].name + ",";
      }
      this.uploadExcel();
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    async uploadExcel() {
      if (this.filesList.length == 0) {
        this.$qzfMessage("请上传文件", 1);
        return;
      }
      for (let k = 0; k < this.filesList.length; k++) {
        const isLt3M = this.filesList[k].size / 1024 / 1024 < 20;
        if (!isLt3M) {
          this.$qzfMessage("请不要上传大于20M的文件。", 1);
          return;
        }
      }
      this.uploadFile = [];
      for (let i = 0; i < this.filesList.length; i++) {
        const param = new FormData(); // 创建form对象
        let title = this.filesList[i].name.replace(/,/g, "");
        param.append("upFile", this.filesList[i], title); // 单个图片 ，多个用循环 append 添加
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        await axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            this.loading = false;
            if (res.data.msg == "success") {
              let url = "https://file.listensoft.net" + res.data.data.url;
              this.uploadFile.push(url);
            }
          })
          .catch(() => {});
      }
      this.$qzfMessage("上传成功");
    },
    //查看文件
    check(item) {
      window.open(item + "?" + new Date().getTime());
    },
    reset() {
      this.inputArchives = "";
      this.textareaArichives = "";
      this.img = [];
      this.path = "";
      this.url = "";
      this.filesList = [];
      this.uploadFile = [];
    },
    open(row) {
      this.showImagePreview = true;
      this.imgSrc = [row];
    },
    //上传图片
    uploadimg(event) {
      this.files = event.target.files;
      this.addSub();
    },
    async addSub() {
      if (this.files.length == 0) {
        this.$qzfMessage("请上传图片", 1);
        return;
      }
      for (let k = 0; k < this.files.length; k++) {
        const isLt3M = this.files[k].size / 1024 / 1024 < 10;
        if (!isLt3M) {
          this.$qzfMessage("请不要上传大于10M的文件。", 1);
          return;
        }
      }
      for (let i = 0; i < this.files.length; i++) {
        const param = new FormData(); // 创建form对象
        let title = this.files[i].name.replace(/,/g, "");
        param.append("upFile", this.files[i], title); // 单个图片 ，多个用循环 append 添加
        let config = { headers: { "Content-Type": "multipart/form-data" } };
        await axios
          .post("https://public.listensoft.net/api/uploadImg", param, config)
          .then((res) => {
            const imgs = res.data.data;
            this.img.push("https://file.listensoft.net" + imgs.url);
          })
          .catch(() => {});
      }
      this.$qzfMessage("上传成功");
    },
    //删除图片
    delImg(index) {
      this.img.splice(index, 1);
    },
    checkImg(item) {
      let arr = [];
      arr.push(item);
      this.img.map((v) => {
        if (v != item) {
          arr.push(v);
        }
      });
      this.imgSrc = arr;
      this.showImagePreview = true;
    },
    filterFile(type) {
      var match = type.match(/\/([^/?]+)$/);
      return match ? match[1] : null; // 返回匹配到的文件名，如果没有匹配到则返回null
    },
  },
};
</script>

<style scoped lang="scss">
.note_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img {
  width: 300px;
  margin-top: 5px;
}
.time {
  font-size: 18px;
}
.note {
  font-size: 14px;
  margin-top: 5px;
}
.archives_content {
  width: 90%;
  margin: 0 auto;
}
.archives_box {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.archives_up {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.file {
  padding: 10px 0;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
}
.div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active {
  display: none;
}
:deep(.el-drawer__header) {
  margin-bottom: 0px;
}
.bigimg {
  cursor: pointer;
}
.bigimg input {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic {
  display: flex;
}
.del_btn {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}
.div_p {
  line-height: 28px;
  border-bottom: 1px solid #efefef;
  height: 28px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
  }
  &:last-child {
    border-bottom: none;
  }
}
.text_initial {
  white-space: initial;
}
</style>
