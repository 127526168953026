<template>
  <div :loading="isLoading">
    <div class="load_box" v-loading.fullscreen.lock="fullscreenLoading">
      <el-select
        v-model="value"
        placeholder="请选择导入模板"
        size="small"
        style="width: 46%"
        filterable
      >
        <el-option
          v-for="item in urls"
          :key="item.label"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <a href="javascript:;" @click="loadValue(value)">下载模板</a>
    </div>
    <div style="text-align: right">
      <el-button
        size="small"
        type="primary"
        @click="uploadExcel"
        :loading="isLoading"
        >上传</el-button
      >
    </div>

    <!-- <upload-excel-component :rawFile='rawFile' :on-success="handleSuccess" :before-upload="beforeUpload" /> -->
  </div>
</template>

<script>
// import UploadExcelComponent from "@/components/import/son/index.vue";
import { importExcel } from "@/api/import";
import { saveEaCompany } from "@/api/company";
export default {
  name: "UploadFee",
  // components: { UploadExcelComponent },
  props: {
    rawFile: "",
  },
  data() {
    return {
      isLoading: false,
      dialogTableVisible: false,
      fullscreenLoading: false,
      urls: [
        {
          value:
            "https://file.listensoft.net/data/v2/customer/通用客户导入模板.xlsx",
          label: "通用客户导入模板",
        },
        {
          value:
            "https://file.listensoft.net/data/v2/customer/新版登录客户导入模板(新).xlsx",
          label: "新版登录客户导入模板",
        },
        {
          value:
            "https://file.listensoft.net/data/v2/customer/代理登录客户导入模板.xlsx",
          label: "代理登录客户导入模板",
        },
        {
          value:
            "https://file.listensoft.net/data/v2/customer/政务网登录客户导入模板.xlsx",
          label: "政务网登录客户导入模板",
        },
      ],
      value: "",
    };
  },
  created() {

  },
  methods: {
    uploadExcel() {
      this.isLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importExcel(param).then((res) => {
        if (res.data.msg == "success") {
          this.header = res.data.data.data[0].data;
          this.handleSuccess(this.header);
        }
      });
    },
    loadValue(e) {
      if (e == "") {
        this.$message({
          message: "请选择模板",
          type: "warning",
        });
        return;
      } else {
        window.open(this.value + "?" + new Date().getTime());
      }
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    handleSuccess(header) {
      //判断导入类型 1代理 2新版 3通用 0其他
      let dlType = 0;
      const headerValue = header[0][0];
      if (headerValue.includes("通用客户")) {
        dlType = 3;
      } else if (headerValue.includes("代理登录")) {
        dlType = 1;
      } else if (headerValue.includes("新版登录")) {
        dlType = 2;
      }
      let source = header.slice(2);
      let info = [];
      let taxNoStatus = false;
      if (dlType == 3) {
        source.map((v) => {
          if (taxNoStatus) {
            return;
          }
          if (v[1]) {
            let yue = v[16];
            if (yue < 10) {
              yue = "0" + yue;
            }
            if (!v[5]) {
              this.$message({
                message: v[1] + "请选择公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            let str = v[5].toString();
            if (
              str != "有限责任公司" &&
              str != "个体工商户" &&
              str != "个人独资企业" &&
              str != "合伙企业" &&
              str != "农村合作社" &&
              str != "民办非企业单位"
            ) {
              this.$message({
                message: v[1] + "不存在此公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[6]) {
              this.$message({
                message: v[1] + "请填写纳税人识别号",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (v[6].length < 15) {
              this.$message({
                message: v[1] + "纳税人识别号错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            let loginStr = v[7].toString();
            if (!['新版登录','政务网登录','代理登录'].includes(loginStr)) {
              this.$message({
                message: v[1] + "登录方式错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[10]) {
              this.$message({
                message: v[1] + "请填写密码",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            info.push({
              sortCom: v[0] ? v[0].toString() : "", //编号
              name: v[1].toString(), //企业名称
              type: v[2] == "增值税一般纳税人" ? "2" : "1", //纳税人资格
              remark: v[3]?.toString(), //
              districtCode: v[3].toString(), //所属税务局
              accountSystem: v[4].toString(), //会计准则
              companyType: v[5].toString(), //公司类型
              taxNo: v[6].toString(), //纳税人识别号
              idType: "纳税人识别号", //
              loginMethod: v[7].toString(), //登录方式
              zzridno: v[8] ? v[8].toString() : "", //
              idNo: v[9] ? v[9].toString() : "", //
              zzrmn: v[10] ? v[10].toString() : "", //
              yzmType: v[11] == "手动接收" ? 1 : 0,
              personalLoginType: v[12] ? v[12].toString() : "", //个税登录方式
              personalIdno: v[13] ? v[13].toString() : "", //个税实名账号
              personalTaxPwd: v[14] ? v[14].toString() : "", //个税密码
              startAccountPeriod: v[15].toString() + yue.toString(), //账套启用年份
              manager: "",
            });
          }
        });
      } else if (dlType == 1) {
        source.map((v) => {
          if (taxNoStatus) {
            return;
          }
          if (v[1]) {
            let yue = v[13];
            if (yue < 10) {
              yue = "0" + yue;
            }
            if (!v[2]) {
              this.$message({
                message: v[1] + "请选择纳税人资格",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[3]) {
              this.$message({
                message: v[1] + "请选择所属税务局",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[4]) {
              this.$message({
                message: v[1] + "请选择企业会计制度准则",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[5]) {
              this.$message({
                message: v[1] + "请选择公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            let str = v[5].toString();
            if (
              str != "有限责任公司" &&
              str != "个体工商户" &&
              str != "个人独资企业" &&
              str != "合伙企业" &&
              str != "农村合作社" &&
              str != "民办非企业单位"
            ) {
              this.$message({
                message: v[1] + "不存在此公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[6]) {
              this.$message({
                message: v[1] + "请填写纳税人识别号",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (v[6].length < 15) {
              this.$message({
                message: v[1] + "纳税人识别号错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[7] && v[7].toString() != '代理登录') {
              this.$message({
                message: v[1] + "登录方式错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[8]) {
              this.$message({
                message: v[1] + "请填写办税人密码",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[9]) {
              this.$message({
                message: v[1] + "请选择个税登录方式",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[11]) {
              this.$message({
                message: v[1] + "请填写个税密码",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[12]) {
              this.$message({
                message: v[1] + "请选择账套启用年份",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[13]) {
              this.$message({
                message: v[1] + "请选择账套启用月份",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            info.push({
              sortCom: v[0] ? v[0].toString() : "", //编号
              name: v[1].toString(), //企业名称
              type: v[2] == "增值税一般纳税人" ? "2" : "1", //纳税人资格
              remark: v[3]?.toString(), //
              districtCode: v[3].toString(), //所属税务局
              accountSystem: v[4].toString(), //会计准则
              companyType: v[5].toString(), //公司类型
              taxNo: v[6].toString(), //纳税人识别号
              idType: "纳税人识别号", //
              loginMethod: v[7].toString(), //登录方式
              zzrmn: v[8] ? v[8].toString() : "", //
              personalLoginType: v[9] ? v[9].toString() : "", //个税登录方式
              personalIdno: v[10] ? v[10].toString() : "", //个税实名账号
              personalTaxPwd: v[11] ? v[11].toString() : "", //个税密码
              startAccountPeriod: v[12].toString() + yue.toString(), //账套启用年份
              manager: "",
              zzridno: v[14] ? v[14].toString() : "", //
              idNo: v[15] ? v[15].toString() : "", //
              yzmType: v[16] == "手动接收" ? 1 : 0,
            });
          }
        });
      } else {
        source.map((v) => {
          if (taxNoStatus) {
            return;
          }
          if (v[1]) {
            let yue = v[14];
            if (yue < 10) {
              yue = "0" + yue;
            }
            if (!v[2]) {
              this.$message({
                message: v[1] + "请选择纳税人资格",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[3]) {
              this.$message({
                message: v[1] + "请选择所属税务局",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[4]) {
              this.$message({
                message: v[1] + "请选择企业会计制度准则",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[5]) {
              this.$message({
                message: v[1] + "请选择公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            let str = v[5].toString();
            if (
              str != "有限责任公司" &&
              str != "个体工商户" &&
              str != "个人独资企业" &&
              str != "合伙企业" &&
              str != "农村合作社" &&
              str != "民办非企业单位"
            ) {
              this.$message({
                message: v[1] + "不存在此公司类型",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[6]) {
              this.$message({
                message: v[1] + "请填写纳税人识别号",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (v[6].length < 15) {
              this.$message({
                message: v[1] + "纳税人识别号错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            let loginStr = v[7].toString();
            if (!['新版登录','政务网登录'].includes(loginStr)) {
              this.$message({
                message: v[1] + "登录方式错误",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[8]) {
              this.$message({
                message: v[1] + "请填写用户名/手机号",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[9]) {
              this.$message({
                message: v[1] + "请填写密码",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[10]) {
              this.$message({
                message: v[1] + "请选择个税登录方式",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[12]) {
              this.$message({
                message: v[1] + "请填写个税密码",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[13]) {
              this.$message({
                message: v[1] + "请选择账套启用年份",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            if (!v[14]) {
              this.$message({
                message: v[1] + "请选择账套启用月份",
                type: "warning",
              });
              taxNoStatus = true;
              this.isLoading = false;
              return;
            }
            info.push({
              sortCom: v[0] ? v[0].toString() : "", //编号
              name: v[1].toString(), //企业名称
              type: v[2] == "增值税一般纳税人" ? "2" : "1", //纳税人资格
              remark: v[3].toString(),
              districtCode: v[3].toString(), //所属税务局
              accountSystem: v[4].toString(), //会计准则
              companyType: v[5].toString(), //公司类型
              taxNo: v[6].toString(), //纳税人识别号
              idType: "纳税人识别号", //
              loginMethod: v[7].toString(), //登录方式
              zzridno: dlType == 2 ? (v[8] ? v[8].toString() : "") : "", //
              idNo: dlType == 0 ? (v[8] ? v[8].toString() : "") : "",
              zzrmn: v[9] ? v[9].toString() : "", //
              personalLoginType: v[10] ? v[10].toString() : "", //个税登录方式
              personalIdno: v[11] ? v[11].toString() : "", //个税实名账号
              personalTaxPwd: v[12] ? v[12].toString() : "", //个税密码
              startAccountPeriod: v[13].toString() + yue.toString(), //张套启用年份
              manager: "",
              yzmType: v[15].toString() == "手动接收" ? 1 : 0,
            });
          }
        });
      }
      if (taxNoStatus) {
        return;
      }
      saveEaCompany({ list: info }).then((res) => {
        this.isLoading = false;
        if (res.data.data.list !== "") {
          this.$emit("success", res.data.data);
        } else {
          this.$emit("success", res.data.data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.load_box {
  text-align: left !important;
  a {
    transform: translateY(-1px);
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    color: #fff;
    background: #67c23a;
  }
}
</style>
