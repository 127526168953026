<template>
  <div :class="['one_center', 'mt-10', { pink: title.match('权限') }, { null_data: count === 0 }]">
    <i class="iconfont icon-gantanhao"
      :style="{ color: count === 0 ? '#A7A7A7' : title.match('权限') ? '#fa6e83' : '#ff8d1a' }"></i>
    {{ title }} {{ count }}
    <div class="right_per">{{ percentage }}%</div>
  </div>
</template>

<script setup>
import { computed } from "vue"
const props = defineProps({
  title: String,
  count: Number,
  total: Number,
})
const percentage = computed(() => {
  if (props.total === 0) {
    return 0
  }
  let num = (props.count / props.total) * 100
  return parseFloat(num.toFixed(2))
})
</script>
<style lang="scss" scoped>
.one_center {
  text-align: center;
  position: relative;
  border-radius: 4px;
  padding: 2px 5px;
  background: #fff6ed;
  border: 0.6px solid #ff8d1a;
  color: #ff8d1a;
  font-size: 13px;

  .right_per {
    position: absolute;
    top: 2px;
    right: 10px;
  }
}

.pink {
  background: #feecef;
  border: 0.6px solid #fa6e83;
  color: #fa6e83;
}

.null_data {
  background: #f7f7f7;
  border: 0.6px solid #A7A7A7;
  color: #A7A7A7;
}

.icon-gantanhao {
  font-size: 13px;
}
</style>
