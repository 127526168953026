<template>
  <el-input :type="type" :size="size" @blur="confirm" v-model="num" :disabled="disabled"></el-input>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    num: {
      type: Number,
      default: 0,
    },
    size:{
      type:String,
      default:'small'
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      status: true,
    };
  },
  methods: {
    confirm(){
      let amount = (this.num + "").replace(/,/g, "")

      // alert(String(this.num).split("")[String(this.num).split("").length - 1])
      // if(String(this.num).indexOf('.0') != -1 && String(this.num).split("")[String(this.num).split("").length - 1] == 0){
      //   return
      // }
      if( isNaN(Number(amount))){
        this.$emit('update:num', 1)
        this.$emit('update:num', 0)
        this.$emit('changeLx',0)
        return
      }
      this.$emit('update:num', Number(amount))
      this.$emit('changeLx', Number(amount))
    },
  }
};
</script>
<style lang="scss" scoped>

</style>


