<template>
  <div
    v-for="item in list"
    :key="item.id"
    @click="selectCom(item)"
    class="content"
  >
    <img
      src="@/assets/tu/zhu.png"
      v-if="!item.correlationId"
      alt=""
      class="pic"
    />
    <span>{{ item.name }}</span
    ><el-icon style="color: #17a2b8;"><Right /></el-icon>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.list = this.$store.getters["commons/params"].combineList;
      this.list.sort((a,b)=>a.correlationId - b.correlationId)
    });
  },
  methods: {
    selectCom(item) {
      this.$emit("updateVal", item.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding-left: 40px;
  .pic {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: absolute;
    left: 15px;
  }
  span {
    cursor: pointer;
    color: #17a2b8;
    margin-right: 5px;
  }
}
</style>
