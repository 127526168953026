<template>
  <!-- 新增科目弹窗 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close title="新增科目" v-model="dialogVisible" width="650px" append-to-body>
    <el-form :model="addData" label-width="120px" label-position="right" size="small">
      <el-form-item label="上级科目：">
        <el-select @change="changeCode" v-model="addData.subjectId" filterable placeholder="请选择" size="small" :disabled="disabled" style="width:200px">
          <el-option
            v-for="item in subjectListOldList"
            :key="item.id"
            :label="item.name3"
            :value="item.id"
            :disabled="item.disabled"
          >
            <span style="float: left">{{ item.name3 }}</span>
            <span style="float: right;color: red;font-size: 14px;">{{item.fzhsName}}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <div v-if="addFzhsData.fzhsId == 0">
        <el-form-item label="科目名称：">
          <el-input v-model="addData.name" style="width:200px;" size="small" placeholder="请输入科目名称"></el-input>
          <span v-if="spec" class="xh" @click="changeSyxh(isSyxh)"><el-icon style="margin-right:2px"><Pointer /></el-icon>{{isSyxh || (addData.name).indexOf(spec) > -1?'科目名称不使用型号':'科目名称使用型号'}}</span>
        </el-form-item>
        <el-form-item label="推荐科目：" v-if="is5602">
          <el-input v-model="inputName" suffix-icon="Search" style="width: 200px" placeholder="请输入关键字" @input="changeInput"/>
          <div style="margin-left: 5px;">
            <el-checkbox-group v-model="recommend" :max="1" @change="changeRecommend">
              <el-checkbox v-for="item in option" :key="item" :label="item" :value="item">
                {{ item }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item class="item" label="余额方向：">
          <el-radio v-model="addData.type" :label="1" :disabled="disabled">借</el-radio>
          <el-radio v-model="addData.type" :label="2" :disabled="disabled">贷</el-radio>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="辅助核算代码：">
          <el-input v-model="addFzhsData.code" style="width:80%;" size="small"></el-input>
        </el-form-item>
        <el-form-item label="辅助核算名称：">
          <el-input v-model="addFzhsData.name" style="width:80%;" size="small"></el-input>
        </el-form-item>
      </div>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()" size="small">取 消</el-button>
        <el-button type="primary" @click="handleAddSure" size="small" :loading="isLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { saveSubject, fzhsItemSave, newFzhsCode } from "@/api/subject"
export default {
  setup() {
    
  },
  props: {
    disabled: false,
    comId: {
      type: Number,
      default: 0
    },
    spec:{
      type: String,
      default: ''
    }
  },
  computed: {
    subjectListOldList(){
      return this.$subjectFilterCode(this.codes, 2)
    }
  },
  data () {
    return {
      codes: [],
      dialogVisible:false,
      addData: {
        subjectId: undefined,
        name: "",
        type: 1
      },
      addFzhsData: {
        fzhsId: 0,//辅助核算项目id
        name: "",
        code: "",
      },
      isLoading:false,
      is5602:false,
      recommend:[],
      inputName:"",
      option:[],
      oldOption:["工资","办公费","社会保险费","住房公积金","办公用品",'补贴','福利费','业务招待费','差旅费',"保险费","展览费","样品费","经营费","报关费","开办费","佣金","代理费","检测费","其他","残保金",'广告费和业务宣传费',"折旧费",
      "交通费","汽车费","邮电费","通讯费","研究费用","劳保用品","租赁费","修理费","劳务费","会务费","装修费","职工教育经费","工会经费","基金","税费","运杂费","机物料消耗",
      "停工损失","房租","无形资产摊销","周转材料摊销","长期待摊费摊销","水电气物管费"],
      isSyxh:false
    }
  },
  methods: {
    cancel(){
      this.dialogVisible = false
      this.$emit("cancel")
    },
    init(data, codes){
      // console.log(data, codes,'data, codes')
      this.addData.subjectId = data.id
      this.addData.type = data.type
      this.addData.name = data.name
      this.is5602 = data.code && data.code == '5602' ? true : false
      if(codes){
        this.codes = codes
      }
      this.recommend = []
      this.option = this.oldOption
      this.isSyxh = false
      this.dialogVisible = true
    },
    changeCode(){
      let codes = this.subjectListOldList.filter(v=>{return this.addData.subjectId == v.id})
      if(codes.length != 0){
        this.addData.type = codes[0].type
        this.addFzhsData.subjectId = codes[0].id
        this.addFzhsData.fzhsId = codes[0].fzhsId
        if(codes[0].fzhsId != 0){
          newFzhsCode({fzhsId: codes[0].fzhsId}).then(res=>{
            this.addFzhsData.code = res.data.data.code
          })
        }
      }
    },
    changeSyxh(e){
      if(this.addData.name.indexOf(this.spec) > -1){
        this.addData.name = this.addData.name.replace(this.spec,"")
        this.isSyxh = false
      }else if(!e){
        this.addData.name = this.addData.name + this.spec
        this.isSyxh = !this.isSyxh
      }else{
        this.addData.name = this.addData.name.replace(this.spec,"")
        this.isSyxh = !this.isSyxh
      }
    },
    //新增科目
    handleAddSure() {
      if(!this.comId){
        if(this.$checkSettleStatus()) return
      }
      if(this.addFzhsData.fzhsId == 0){
        this.addSubjectSure()
      }else{
        this.addFzhsSure()
      }
      
    },
    addFzhsSure(){
      if (!this.addFzhsData.name) {
        this.$message.error("请输入辅助核算名称");
        return;
      }
      if (!this.addFzhsData.code) {
        this.$message.error("请输入辅助核算代码");
        return;
      }
      this.addFzhsData.code = this.addFzhsData.code
      this.addFzhsData.name = this.addFzhsData.name.trim(),
      this.isLoading = true
      fzhsItemSave([this.addFzhsData]).then(res => {
        this.isLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("新增成功")
          this.dialogVisible = false
          this.$store.dispatch("commons/getSubjects").then(r=>{
            let info = res.data.data.info
            info.sign = this.addFzhsData.subjectId + "-" + info.id
            this.$emit("success", info)
          })
          this.$store.dispatch("commons/getBusinessType")
        }
      });
    },
    addSubjectSure(){
      if (!this.addData.name) {
        this.$message.error("请输入科目名称");
        return;
      }
      if (!this.addData.subjectId) {
        this.$message.error("请选择上级科目");
        return;
      }
      this.addData.subjectId = this.addData.subjectId * 1
      this.addData.name = this.addData.name.trim();
      if(this.comId){
        this.addData.comId = this.comId
      }
      this.isLoading = true
      saveSubject(this.addData).then(res => {
        this.isLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage("新增成功")
          this.dialogVisible = false
          if(this.comId){
            this.$store.dispatch("commons/getSubjects",this.comId).then(r=>{
              let info = res.data.data.info
              info.sign = info.id + "-0"
              this.$emit("success", info)
            })
            this.$store.dispatch("commons/getBusinessType",this.comId)
          }else{
            this.$store.dispatch("commons/getSubjects").then(r=>{
              let info = res.data.data.info
              info.sign = info.id + "-0"
              this.$emit("success", info)
            })
            this.$store.dispatch("commons/getBusinessType")
            this.$bus.emit('invioceInUpdate')
            this.$bus.emit('invioceOutUpdate')
            this.$bus.emit('invioceFeeUpdate')
            this.$bus.emit('eaSubjectUpdate')
          }
        }
      });
    },
    changeRecommend(e){
      this.recommend = e
      this.addData.name = this.recommend[0]
    },
    changeInput(){
      this.option = this.oldOption.filter(v=>{
        return v.indexOf(this.inputName) > -1
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select{
  margin:0
}
.xh{
  color:var(--themeColor,#17a2b8);
  margin-left:5px;
  cursor: pointer;
}
</style>